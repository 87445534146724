import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { ContainerGateMovesModel } from 'src/app/shared/models/container-gate-moves.model';
import { getContainerGateMoves } from 'src/app/shared/state/operations/operations.action';
import { selectContainerGateMoves } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-container-gate-moves',
  templateUrl: './container-gate-moves.component.html',
  styleUrls: ['./container-gate-moves.component.scss']
})
export class ContainerGateMovesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  selectedPortId: string;

  containerGateMoves$: Observable<ContainerGateMovesModel[]> = this.store.select(selectContainerGateMoves);
  public settings: any = {
    fetchedData: [],
    chartData: CHART.DATA.GATE_MOVES.SETTINGS.CHART_DATA,
    colors: CHART.DATA.GATE_MOVES.SETTINGS.COLORS,
    getChartData: function () {
      let tmp = []
      for (let i = 0; i < this.chartData.length; i++) {
        tmp.push(this.chartData[i].value)
      }
      return tmp
    },
    chartType: CHART.TYPE.PIE
  }
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false

    },
    plugins: {
      labels: {
        fontSize: 0,
      },
    },
  };
  public pieChartLabels: Label[] = CHART.DATA.GATE_MOVES.SETTINGS.LABELS;
  public pieChartData: SingleDataSet = []
  public pieChartType: ChartType = this.settings.chartType;
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Array<any> = [{
    backgroundColor: CHART.DATA.GATE_MOVES.SETTINGS.COLORS,
    borderWidth: 0
  }]
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;

  constructor(private store: Store) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.store.dispatch(getContainerGateMoves({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.containerGateMoves$.subscribe((data) => {
      if (data?.length) {
        this.initializeContainerGateMovesData(data);
        this.noData = false;
      } else {
        this.noData = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getContainerGateMoves({ portId: this.selectedPortId }));
      }
    }
  }

  initializeContainerGateMovesData(data: ContainerGateMovesModel[]) {
    data.map(el => {
      const entryType = el.entry_type
      const containerStatus = el.container_status

      if (entryType == 'Empty In' && containerStatus == 'Empty') {
        this.settings.chartData[0].value = el.quantity
      } else if (entryType == 'Empty Out' && containerStatus == 'Empty') {
        this.settings.chartData[1].value = el.quantity
      } else if (entryType == 'Full In' && containerStatus == 'Full') {
        this.settings.chartData[2].value = el.quantity
      } else {
        this.settings.chartData[3].value = el.quantity
      }
    })
    this.pieChartData = this.settings.getChartData()
  }

  ngOnDestroy() {
    for (let i = 0; i < this.settings.chartData.length; i++) {
      this.settings.chartData[i].value = 0
    }
  }
}

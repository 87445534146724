import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralMapComponent } from './general-map.component';
import { AuthMembersModule } from './auth-members/auth-members.module';

const routes: Routes = [
  {
    path: "",
    component: GeneralMapComponent,
    data: { breadcrumb: "" },
  },
  {
    path: 'auth-members',
    loadChildren: () => AuthMembersModule,
    data: { breadcrumb: "" },
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralMapRoutingModule { }


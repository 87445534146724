import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortAuthorityLayoutRoutingModule } from './port-authority-layout-routing.module';
import { RegionalMapComponent } from './regional-map/regional-map.component';
import { HttpClientModule } from '@angular/common/http';
import { GeneralMapComponent } from './general-map/general-map.component';

@NgModule({
  declarations: [RegionalMapComponent, GeneralMapComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    PortAuthorityLayoutRoutingModule, RouterModule, HttpClientModule
  ],
  exports: [


  ],
  providers: [DatePipe]
})
export class PortAuthorityLayoutModule { }

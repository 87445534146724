import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  private operationsDash = environment.basePath + API.DASHBOARD;
  private operations = environment.basePath + API.OPERATIONS;

  //to be removed
  private mockUrl = "https://450d6647-3134-4942-a659-f9b964957448.mock.pstmn.io" + API.OPERATIONS;

  constructor(private http: HttpClient) { }

  getOperations(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operationsDash}/operations`, { params: params });
  }

  getPortProductivity(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/port-productivity`, { params: params });
  }

  getPortMap(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/port-map`, { params: params });
  }

  getBerthingStatus(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/berthing-status`, { params: params });
  }

  getOperatingStatus(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/operating-status`, { params: params });
  }

  getEquipmentStatus(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-status`, { params: params });
  }

  getEquipmentJobOrder(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-job-order`, { params: params });
  }

  getEquipmentList(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-list`, { params: params });
  }

  getCraneProductivity(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/crane-productivity-movement`, { params: params });
  }

  getCraneMovement(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/crane-movement-steps`, { params: params });
  }

  getCraneUtilization(portId?: string, equipmentId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/crane-utilization-history`, { params: params });
  }

  getMovementTimeOverview(portsId?: string, equipmentId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/movement-time-overview`, { params: params });
  }

  getEquipmentMaintenanceSummary(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-maintenance-summary`, { params: params });
  }

  getEquipmentPartsInspection(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-parts-inspection`, { params: params });
  }

  getHistoricalMaintenance(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-historical-maintenance`, { params: params });
  }

  getEquipmentMaintenanceAlert(portId?: string, equipmentId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = equipmentId ? params.append('equipment_id', equipmentId) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/equipment-alert-data`, { params: params });
  }

  getEquipmentFailureChart(portsId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/crane-mmbf-vs-mtbf`, { params: params });
  }

  getEquipmentAlarmAnalysis(portsId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/crane-equipment-alarm-analysis`, { params: params });
  }

  getTotalContainersMovement(portsId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/total-crane-containers-movements`, { params: params });
  }

  getContainersMovementTypes(portsId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : null;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/crane-containers-movement-types`, { params: params });
  }

  getCraneUtilizationAnalytics(portId?: string, startDate?: string, endDate?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = startDate ? params.append('start_date', startDate) : params;
    params = endDate ? params.append('end_date', endDate) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/crane-utilization`, { params: params });
  }

  getPortProductivityEquipmentBerthUtilization(portId?: string, filter?: number) {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = filter ? params.append('filter', filter.toLocaleString()) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/port-productivity-equipment-berth-utilization`, { params: params });
  }

  getCraneSensorEvaluation(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/sensor-evaluation`, { params: params });
  }

  getTopVesselDelay(portId?: string, filter?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    params = filter ? params.append('filter', filter.toLocaleString()) : params;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/analytics/top-vessels-delay`, { params: params });
  }

  getBerthingStatusOverview(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/overview/berthing-status`, { params: params });
  }

  getQuayYardStatus(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/overview/quay-yard-status`, { params: params });
  }

  getTugboatStatus(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/overview/tugboat-status`, { params: params });
  }

  getEquipmentAlertAnalytics(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/alerts/equipment-alerts`, { params: params });
  }

  getAlertsLogData(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/alerts/log-data`, { params: params });
  }

  getPortProductivityKpi(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/kpi/port-productivity`, { params: params });
  }

  getCraneKpi(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/kpi/crane`, { params: params });
  }

  getTugboatKpi(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/kpi/tugboat`, { params: params });
  }

  getAverageTurnTime(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/average-turn-time`, { params: params });
  }

  getContainerGateMoves(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/container-gate-moves`, { params: params });
  }

  getOverstayingTrucks(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/overstaying-trucks`, { params: params });
  }

  getAverageContainerDwellTime(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/average-container-dwell-time`, { params: params });
  }

  getYardContainerSizeType(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/yard-container-size-type`, { params: params });
  }

  getHazardousMaterial(portId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portId ? new HttpParams().set('port_id', portId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.operations}/yard/hazardous-material`, { params: params });
  }
}

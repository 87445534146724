import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DigitalTwinLayoutRoutingModule } from './digital-twin-layout-routing.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { DigitalTwinNavComponent } from './digital-twin-nav/digital-twin-nav.component';
import { DigitalTwinLayoutComponent } from './digital-twin-layout.component';
import { GaugeChartModule } from 'angular-gauge-chart';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [DigitalTwinNavComponent, DigitalTwinLayoutComponent],
  imports: [
    CommonModule,
    DigitalTwinLayoutRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    MatTabsModule,
    GaugeChartModule
  ]
})
export class DigitalTwinLayoutModule { }

import { Component, OnInit, Input, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { CraneList, CraneListKPIModel } from 'src/app/shared/models/craneKpi';
import { CraneAverage } from 'src/app/shared/models/craneKpi';
import { MESSAGE_DISPLAY, } from 'src/app/shared/constants/api.constant';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { AGGREGATED_NAMES } from 'src/app/shared/constants/aggregated-names.constant';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { ICONS } from 'src/app/shared/constants/icons.contant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCraneKpi } from 'src/app/shared/state/operations/operations.selectors';
import { getCraneKPI } from 'src/app/shared/state/operations/operations.action';
import { MatTableDataSource } from '@angular/material/table';
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';


@Component({
  selector: 'app-crane-kpi',
  templateUrl: './crane-kpi.component.html',
  styleUrls: ['./crane-kpi.component.scss']
})
export class CraneKpiComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public craneList: CraneList[] = [];
  @Input() public craneAverage: CraneAverage = {};
  @Input() public selectedPortId: string;

  crane$: Observable<CraneListKPIModel> = this.store.select(selectCraneKpi);
  public newCraneAvarage: any = {};
  public newCraneList: CraneList[] = [];
  public dataSource = new MatTableDataSource<CraneList>();

  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;

  public thresholdStatus = { ...THRESHOLD.STATUS };
  public thresholdValue = { ...THRESHOLD.VALUE };

  public chartTypes = { ...CHART.TYPE };
  public equipmentType = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public craneStatus = { ...EQUIPMENT.EQUIPMENT_STATUS, MAINTENANCE: EQUIPMENT.EQUIPMENT_STATUS.MAINTENANCE };
  public craneIcons = { ...ICONS.EQUIPMENT_STATUS, MAINTENANCE: ICONS.EQUIPMENT_STATUS.MAINTENANCE };
  public displayedColumns = ['equipmentId', 'idlingRate', 'utilizationRate', 'availability', 'reliability', 'bchActual', 'avgBch', 'tpc'];


  public percent = '%';

  constructor(private store: Store) { }



  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getCraneKPI({ portId: this.selectedPortId }));
      }
    }
  }
  ngOnInit(): void {
    this.store.dispatch(getCraneKPI({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.crane$.subscribe((data) => {
        if (data) {
          data = this.roundAllValuesToTwoDecimal(data);
          this.setNewCraneList(data);
          this.setNewCraneAverage(data.crane_average);
          this.dataSource.data = this.newCraneList;
          this.noData = false;
        }
      });
    });
  }

  roundAllValuesToTwoDecimal(input: any): any {
    if (typeof input === 'number') {
      return Math.round(input * 100) / 100;
    } else if (Array.isArray(input)) {
      return input.map(item => this.roundAllValuesToTwoDecimal(item));
    } else if (typeof input === 'object' && input !== null) {
      return Object.keys(input).reduce((acc, key) => {
        acc[key] = this.roundAllValuesToTwoDecimal(input[key]);
        return acc;
      }, {});
    }
    return input;
  }

  setNewCraneList(data: CraneListKPIModel) {


    const processedList = this.roundAllValuesToTwoDecimal(data.crane_list.map((crane) => {

      const bchActualThreshold = this.customThresholdChecker(crane.bch_actual, AGGREGATED_NAMES.BCH, crane.equipment_id);
      const avgBCHThreshold = this.customThresholdChecker(crane.bch_average, AGGREGATED_NAMES.AVG_BCH, crane.equipment_id);
      const tpcThreshold = this.customThresholdChecker(crane.tpc, AGGREGATED_NAMES.TPC, crane.equipment_id);

      return {
        ...crane,
        bchActualThreshold,
        avgBCHThreshold,
        tpcThreshold
      };
    }));
    this.newCraneList = processedList.sort((a, b) => a.equipment_name.localeCompare(b.equipment_name));

  }


  setNewCraneAverage(data: CraneAverage) {
    data = this.roundAllValuesToTwoDecimal(data);
    this.craneAverage = data;

    const averageBCHActualThreshold = this.customThresholdChecker(data.average_bch_actual, AGGREGATED_NAMES.AVERAGE_BCH);
    const averageAVGBCHThreshold = this.customThresholdChecker(data.average_bch_average, AGGREGATED_NAMES.AVERAGE_AVG_BCH);
    const averageTPCThreshold = this.customThresholdChecker(data.average_tpc, AGGREGATED_NAMES.AVERAGE_TPC);

    this.newCraneAvarage = {
      ...this.craneAverage,
      averageBCHActualThreshold,
      averageAVGBCHThreshold,
      averageTPCThreshold
    };
  }

  // This is for BCH, AVG BCH and Time/Move only
  customThresholdChecker(aggValue: number, craneKpiName: string, craneId: string = '') {
    let thresholdStatus: string;
    let envLowThreshold, envHighhreshold;
    switch (craneKpiName) {
      case AGGREGATED_NAMES.BCH:
        envLowThreshold = feConfig[craneId + 'BCHActualLowThreshold'];
        envHighhreshold = feConfig[craneId + 'BCHActualHighThreshold'];
        break;
      case AGGREGATED_NAMES.AVG_BCH:
        envLowThreshold = feConfig[craneId + 'AVGBCHLowThreshold'];
        envHighhreshold = feConfig[craneId + 'AVGBCHHighThreshold'];
        break;
      case AGGREGATED_NAMES.TPC:
        envLowThreshold = feConfig[craneId + 'TPCLowThreshold'];
        envHighhreshold = feConfig[craneId + 'TPCHighThreshold'];
        break;
      case AGGREGATED_NAMES.AVERAGE_BCH:
        envLowThreshold = feConfig.AVGBCHActualLowThreshold;
        envHighhreshold = feConfig.AVGBCHActualHighThreshold;
        break;
      case AGGREGATED_NAMES.AVERAGE_AVG_BCH:
        envLowThreshold = feConfig.AVGAVGBCHLowThreshold;
        envHighhreshold = feConfig.AVGAVGBCHHighThreshold;
        break;
      case AGGREGATED_NAMES.AVERAGE_TPC:
        envLowThreshold = feConfig.AVGTPCLowThreshold;
        envHighhreshold = feConfig.AVGTPCHighThreshold;
        break;
    }
    if (envLowThreshold !== undefined && envHighhreshold !== undefined) {
      if (craneKpiName === AGGREGATED_NAMES.AVERAGE_TPC || craneKpiName === AGGREGATED_NAMES.TPC) {
        if (aggValue < envLowThreshold) {
          thresholdStatus = this.thresholdStatus.HIGH;
        } else if (aggValue >= envLowThreshold && aggValue <= envHighhreshold) {
          thresholdStatus = this.thresholdStatus.MID;
        } else if (aggValue > envHighhreshold) {
          thresholdStatus = this.thresholdStatus.LOW;
        }
      } else {
        if (aggValue < envLowThreshold) {
          thresholdStatus = this.thresholdStatus.LOW;
        } else if (aggValue >= envLowThreshold && aggValue <= envHighhreshold) {
          thresholdStatus = this.thresholdStatus.MID;
        } else if (aggValue > envHighhreshold) {
          thresholdStatus = this.thresholdStatus.HIGH;
        }
      }
    } else {
      thresholdStatus = this.thresholdStatus.LOW;
    }
    return thresholdStatus;
  }
}

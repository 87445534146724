import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import * as data from  'src/app/shared/mock-data/outward.json'
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-outward',
  templateUrl: './outward.component.html',
  styleUrls: ['./outward.component.scss']
})
export class OutwardComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) 
  public paginator: MatPaginator;
  public dataSource;
  public pageIndex = PAGINATOR.EQUIPMENT.LIST.pageIndex;
  public pageSize = PAGINATOR.EQUIPMENT.LIST.pageSize;
  public pageSizeOptions = PAGINATOR.EQUIPMENT.LIST.pageSizeOptions;
  public columnHeaders = [
    'terminalId', 
    'vesselName', 
    'vesselType',
    'imoNum', 
    'destination', 
    'location', 
    'departure', 
    'tat', 
    'bookingId'
  ];
  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(data.vessels);
  }

}

import { Component, OnInit } from '@angular/core';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { PortProductivityKpiModel } from 'src/app/shared/models';
import { Store } from '@ngrx/store';
//to be removed
import * as response from 'src/app/shared/mock-data/port-productivity-kpi.json';
import { TugboatKpiModel } from 'src/app/shared/models/tugboat-kpi.model';
import { Observable } from 'rxjs';
import { selectAnalyticsPortId, selectKpiPortId } from 'src/app/shared/state/operations/operations.selectors';
import { routerTransition } from 'src/app/shared/router.animations';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  animations: [routerTransition()]
})
export class KpiComponent implements OnInit {
  public portProductivity: PortProductivityKpiModel;
  public tugboatKpiList: TugboatKpiModel[] = [];

  // public craneList: CraneKpi[] = [];
  // public craneAverage: CraneAverage = {};


  constructor(private roundPipe: RoundPipe, private store: Store) { }
 
  kpiPortId$: Observable<string> = this.store.select(selectAnalyticsPortId);

  ngOnInit() {
    this.getKPI();
  }

  getKPI() {
    // const queryParams = { 'portId': environment.portId };
    // const api = CommonFunction.getStreamEndPoint('/kpi', queryParams);
    // this.observable = this.myseService.getServerSentEvent(api, 'kpi');
    // this.subscription = this.observable.subscribe(data => {
    // if (data.tag !== undefined) {
    //   if (data.code === RESPONSE_CODE.OK) {
    //     if (data.tag === STREAM_TAG.PORT_PRODUCTIVITY) {
    this.portProductivity = response.portProductivity;
    this.portProductivity.bor = this.roundPipe.transform(this.portProductivity.bor * 100);
    this.portProductivity.bsh = this.roundPipe.transform(this.portProductivity.bsh);
    this.portProductivity.bsh_average = this.roundPipe.transform(this.portProductivity.bsh_average);
    //     } else if (data.tag === STREAM_TAG.TUGBOAT_KPI) {
    this.tugboatKpiList = response.tugboatList;
    this.tugboatKpiList = this.tugboatKpiList.map((tugboatKpi: TugboatKpiModel) => {
      tugboatKpi.idlingRate = this.roundPipe.transform(tugboatKpi.idlingRate * 100);
      tugboatKpi.utilizationRate = this.roundPipe.transform(tugboatKpi.utilizationRate * 100);
      tugboatKpi.reliability = this.roundPipe.transform(tugboatKpi.reliability * 100);
      tugboatKpi.availability = this.roundPipe.transform(tugboatKpi.availability * 100);
      return tugboatKpi;
    });
    //     } else if (data.tag === STREAM_TAG.CRANE_KPI) {
    //       this.craneList = data.craneList;
    //       this.craneList = this.craneList.map((crane: CraneKpi) => {
    //         crane.idlingRate = this.roundPipe.transform(crane.idlingRate * 100);
    //         crane.utilizationRate = this.roundPipe.transform(crane.utilizationRate * 100);
    //         crane.availability = this.roundPipe.transform(crane.availability * 100);
    //         crane.reliability = this.roundPipe.transform(crane.reliability.month * 100);

    //         crane.bchActual = this.roundPipe.transform(crane.bchActual);
    //         crane.bchAverage = this.roundPipe.transform(crane.bchAverage);
    //         crane.tpc = this.roundPipe.transform(crane.tpc);
    //         return crane;
    //       });
    //       this.craneAverage = data.craneAverage;
    //       this.craneAverage.averageIdlingRate = this.roundPipe.transform(this.craneAverage.averageIdlingRate * 100);
    //       this.craneAverage.averageUtilizationRate = this.roundPipe.transform(this.craneAverage.averageUtilizationRate * 100);
    //       this.craneAverage.averageAvailability = this.roundPipe.transform(this.craneAverage.averageAvailability * 100);
    //       this.craneAverage.averageReliability = this.roundPipe.transform(this.craneAverage.averageReliability.month * 100);
    //       this.craneAverage.averageBchActual = this.roundPipe.transform(this.craneAverage.averageBchActual);
    //       this.craneAverage.averageBchAverage = this.roundPipe.transform(this.craneAverage.averageBchAverage);
    //       this.craneAverage.averageTpc = this.roundPipe.transform(this.craneAverage.averageTpc);
    //     }
    //   }
    // }
    // }, (error) => {
    //   this.subscription.unsubscribe();
    //   this.getKPI();
    // });
  }
}

export const SHIP_DETAILS = {
  SHIP2: {
    lat: 38.4541,
    long: 15.8866,
    name: "Ocean Wanderer",
    // name: "King Solomon",
    sign: "SK012934-6",
    location: "35.689°N, 139.692°E",
    booking_Info: "Confirmed",
  },

  SHIP1: {
    lat: 38.4697,
    long: 15.8722,
    name: "King Solomon",
    sign: "SK012934-6",
    location: "35.689°N, 139.692°E",
    booking_Info: "N/A",
  },
};

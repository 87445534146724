import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sustainability-nav',
  templateUrl: './sustainability-nav.component.html',
  styleUrls: ['./sustainability-nav.component.scss']
})
export class SustainabilityNavComponent implements OnInit {
  public activeButton: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.checkActivatedRoute();
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1]

    switch (activePath.toLocaleLowerCase()) {
      case 'carbon-management':
        this.activeButton = 2;
        break;
      case 'water-conservation':
        this.activeButton = 3;
        break;
      case 'waste-management':
        this.activeButton = 4;
        break;
      case 'biodiversity':
        this.activeButton = 5;
        break;
      case 'social-responsibility':
        this.activeButton = 6;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }
}

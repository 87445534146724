import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { element } from 'protractor';
import { CHART } from 'src/app/shared/constants/chart.constant';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @Input() selectedCrane: string;
  @Input() itemData: any = [];
  @Input() totalDuration: any = [];
  @Input() selectedDates: any = [];
  @Input() septemberData: any = [];
  @Input() threeMonths: any = [];
  public chartTypes = { ...CHART.TYPE };
  public dateIndex: number = 0;
  displayedColumns: string[] = ['category', 'alarm', 'average', 'staticAverage'];
  public dataSource: MatTableDataSource<any>;
  public ELEMENT_DATA: tableData[] = [];
  public categories: any[] = [];
  public categorySum: number[] = [];
  public categorySumAve: number[] = [];
  public categoryName: string[] = [];
  public doughnutData: number[] = [];
  public chartData: any[];
  public chartLabels: string[] = [];
  public chartEquipmentId: string[] = [];
  public chartOptions: any;
  public chartColors: any[];
  public chartLegend: boolean;
  public chartType: string;
  public chartDataSet: number[] = [];
  public isFound: boolean;


  public staticAverage: any[] = [
    {
      "QC01": [
        { category: 'BH', month: 1, threeMonths: 37.33 },
        { category: 'BH1', month: 0, threeMonths: 3.66 },
        { category: 'CRS', month: 0.23, threeMonths: 9.33 },
        { category: 'GA', month: 2.53, threeMonths: 84.66 },
        { category: 'GA1', month: 0.46, threeMonths: 5.33 },
        { category: 'GA2', month: 0.26, threeMonths: 3 },
        { category: 'GE', month: 0.6, threeMonths: 33.33 },
        { category: 'HO', month: 2.4, threeMonths: 91 },
        { category: 'HO1', month: 0.03, threeMonths: 0.66 },
        { category: 'HO2', month: 0, threeMonths: 0.33 },
        { category: 'LVD', month: 0.53, threeMonths: 29.33 },
        { category: 'OM', month: 10.80, threeMonths: 339.66 },
        { category: 'RT', month: 0.23, threeMonths: 2.66 },
        { category: 'SPR', month: 0.93, threeMonths: 32.66 },
        { category: 'SU1', month: 0, threeMonths: 5.66 },
        { category: 'SU2', month: 0.033, threeMonths: 3.66 },
        { category: 'SU3', month: 0, threeMonths: 2.33 },
        { category: 'TLS', month: 0.06, threeMonths: 2.33 },
        { category: 'TR', month: 0.1, threeMonths: 9.66 },
        { category: 'TR1', month: 0, threeMonths: 0.33 },
      ],
      "QC02": [
        { category: 'BH', month: 0.93, threeMonths: 27 },
        { category: 'BH1', month: 0, threeMonths: 0 },
        { category: 'CRS', month: 0.9, threeMonths: 13.33 },
        { category: 'GA', month: 0.8, threeMonths: 27.66 },
        { category: 'GA1', month: 0, threeMonths: 1 },
        { category: 'GA2', month: 0, threeMonths: 0 },
        { category: 'GE', month: 1.1, threeMonths: 32 },
        { category: 'HO', month: 4.16, threeMonths: 136 },
        { category: 'HO1', month: 0.26, threeMonths: 4.33 },
        { category: 'HO2', month: 0, threeMonths: 0 },
        { category: 'LVD', month: 0.83, threeMonths: 15 },
        { category: 'OM', month: 12.73, threeMonths: 387.33 },
        { category: 'RT', month: 0, threeMonths: 0.66 },
        { category: 'SPR', month: 2.23, threeMonths: 55.33 },
        { category: 'SU1', month: 0, threeMonths: 3.33 },
        { category: 'SU2', month: 0, threeMonths: 0 },
        { category: 'SU3', month: 0, threeMonths: 0 },
        { category: 'TLS', month: 0, threeMonths: 0 },
        { category: 'TR', month: 0.1, threeMonths: 7 },
        { category: 'TR1', month: 0, threeMonths: 0 },
      ],
      "QC03": [
        { category: 'BH', month: 0.86, threeMonths: 28 },
        { category: 'BH1', month: 0, threeMonths: 0.33 },
        { category: 'CRS', month: 0.30, threeMonths: 13 },
        { category: 'GA', month: 2.96, threeMonths: 27 },
        { category: 'GA1', month: 0.36, threeMonths: 4 },
        { category: 'GA2', month: 0.2, threeMonths: 2 },
        { category: 'GE', month: 0.53, threeMonths: 37.33 },
        { category: 'HO', month: 4.16, threeMonths: 151.33 },
        { category: 'HO1', month: 0, threeMonths: 0.66 },
        { category: 'HO2', month: 0, threeMonths: 0.33 },
        { category: 'LVD', month: 0.46, threeMonths: 24 },
        { category: 'OM', month: 13.86, threeMonths: 451.33 },
        { category: 'RT', month: 0.06, threeMonths: 1.33 },
        { category: 'SPR', month: 1.1, threeMonths: 41.33 },
        { category: 'SU1', month: 0, threeMonths: 2.33 },
        { category: 'SU2', month: 0.033, threeMonths: 4 },
        { category: 'SU3', month: 0, threeMonths: 0.66 },
        { category: 'TLS', month: 0.06, threeMonths: 0.66 },
        { category: 'TR', month: 0.83, threeMonths: 21.66 },
        { category: 'TR1', month: 0, threeMonths: 0.33 },
      ]
    }

  ];

  constructor(public datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getData();
    this.plotChart()
  }

  ngAfterViewInit() {
    this.ELEMENT_DATA = [];
    this.getData();
    this.plotChart();

    this.dataSource = new MatTableDataSource<tableData>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }


  getData() {
    let indx: number;
    this.itemData.forEach((element, index) => {
      for (const iterator of element) {
        if (iterator.dailyDuration !== 0) {
          //check if element does exists on the elementdata
          this.isFound = this.ELEMENT_DATA.some((element, index) => {
            if (element.category === iterator.category) {
              indx = index;
              return true;
            }
            return false;
          });
          //if not found, insert
          if (!this.isFound) {
            this.ELEMENT_DATA.push({
              category: iterator.category,
              description: iterator.categoryName,
              alarmCount: 1,
              average: 0,
              staticAverage: 0,
            });
          } else {
            this.ELEMENT_DATA[indx].alarmCount += 1;
          }
        }
      }
    });


    //this.computeAverage(this.ELEMENT_DATA);
    this.addStaticAverage();
    this.getCategoryDescription(this.ELEMENT_DATA);

  }

  addStaticAverage() {
    //console.log(this.staticAverage[0].QC01);
    let data: any[] = [];
    switch (this.selectedCrane) {
      case "QC01":
        this.staticAverage[0].QC01.forEach(element => {
          data.push(element)
        });

        break;
      case "QC02":
        this.staticAverage[0].QC02.forEach(element => {
          data.push(element)
        });
        break;
      case "QC03":
        this.staticAverage[0].QC03.forEach(element => {
          data.push(element)
        });
        break;
    }

    this.ELEMENT_DATA.map((item) => {
      data.map((d) => {
        if (d.category == item.category) {
          item.average = d.month;
          item.staticAverage = d.threeMonths;
        }
      })
    })
  }

  computeAverage(arr: any) {
    arr.map((item) => {
      item.average = item.alarmCount / this.itemData.length;
    })
  }



  getCategoryDescription(arr: any) {
    arr.map((item) => {
      this.categoryName.push(item.description);
    })
  }

  plotChart() {
    this.chartData = [
      {
        data: this.ELEMENT_DATA.map(v => v.average),
        borderColor: '#fff',
      }
    ];

    this.chartLabels = this.ELEMENT_DATA.map(l => l.category);
    this.initializeChart();

  }

  initializeChart() {
    this.chartOptions = {
      tooltips: {
        titleFontFamily: CHART.FONTS.GOTHAM,
        bodyFontFamily: CHART.FONTS.GOTHAM,
        callbacks: {
          label: (tooltipItem, data) => {
            let val: number = data.datasets[0].data[tooltipItem.index];
            return data.labels[tooltipItem.index] + ":" + val.toFixed(2);

          },
          title: (tooltipItem, data) => {
            return this.categoryName[tooltipItem[0].index];
          }
        }
      },
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      legend: { position: 'right' },
      label: { display: true },
    };

    this.chartColors = [
      {
        backgroundColor: [
          '#0F52BA',
          '#120a8f',
          '#003153',
          '#00008b', '#0892d0',
          '#00bfff', '#2a52be',
          '#0073cf', '#003366',
          '#0abab5', '#add8e6',
          '#0047ab', '#000080',
          '#4169e1', '#40e0d0',
          '#1560bd', '#6050dc',
          '#5D8AA8', '#73c2fb', '#0f4d92']
      },
    ];
    this.chartLegend = true;
    this.chartType = CHART.TYPE.DOUGHNUT;
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }
}

export interface tableData {
  category: string;
  description: string;
  alarmCount: number;
  average: number;
  staticAverage: number;
}

export interface threeMonths {
  category: string;
  alarmCount: number;
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnergyMgtLayoutComponent } from './energy-mgt-layout.component';
import { EnergyNavModule } from './energy-nav/energy-nav.module';

const routes: Routes = [
  {
  path: "",
  component: EnergyMgtLayoutComponent,
  data: { breadcrumb: "Energy Dashboard" },
  children: [
    {
      path: "",
      redirectTo: "page",
      pathMatch: "full"
    },
    {
      path: "page",
      loadChildren: () => EnergyNavModule,
      data: { breadcrumb: "" },
    },

  ]
},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnergyMgtLayoutRoutingModule { }


import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  public name = '';
  public isMultiport: boolean = false;
  public isMultiapp: boolean = false;
  public isTerminalManager: boolean = false;
  public test: boolean = false;
  public logo: string = "assets/images/acn.png";
  logoW: number;
  logoH: number;
  isAuthenticated: boolean;
  userName: string;

  constructor(
    private translate: TranslateService,
    public router: Router,
    private authService: MsalService) {
    this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de', 'zh-CHS']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.name = sessionStorage.getItem('name');
    if (this.name == "Terminal Manager") {
      this.isTerminalManager = true;
    }

    this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
    if (this.isAuthenticated) {
      let account = this.authService.instance.getAllAccounts()[0];
      this.userName = account.username.split('@')[0];
    }
  }

  logout() {
    sessionStorage.clear();
    this.authService.logoutRedirect();
  }
}

import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  breadcrumbs: { label: string, url: string }[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      const urlPaths = window.location.pathname.split('/').filter((a => a));

      if (urlPaths.length === 2 || urlPaths.length === 5) {
        this.checkAndRedirect(urlPaths, this.activatedRoute.root);
      }
    });
  }

  ngOnInit(): void { }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: { label: string, url: string }[] = []): { label: string, url: string }[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      if (breadcrumbLabel) {
        breadcrumbs.push({ label: breadcrumbLabel, url: url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  private checkAndRedirect(urlPaths: string[], route: ActivatedRoute): void {

    if (urlPaths.includes('sustainability-dashboard') && urlPaths.includes('carbon')) {
      this.router.navigate(['/sustainability-dashboard/all/carbon-management']);
    } else if (urlPaths.includes('equipment')) {
      let currentRoute = route;
      let params = {};
      let segmentCount = 0;

      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;
        params = { ...params, ...currentRoute.snapshot.params };
        segmentCount += currentRoute.snapshot.url.length;
      }

      if (params['equipmentType'] && params['equipmentId'] && params['equipmentName']) {
        this.router.navigate(['/operations-dashboard/page/equipment-list']);
      }
    }
  }
}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationsDashboardRoutingModule } from './operations-dashboard-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { OperationsDashboardComponent } from './operations-dashboard.component';
import { OperationsNavComponent } from './operations-nav/operations-nav.component';
import { ModalLoaderComponent } from './components/modal-loader/modal-loader.component';
import { AlertDialogComponent } from './components/modal-loader/alert-dialog/alert-dialog.component';
import { JobOrderComponent } from './components/job-order/job-order.component';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ColorCodedBarPieModule } from './components/color-coded-bar-pie/color-coded-bar-pie.module';
import { EquipmentIconModule } from './components/equipment-icon/equipment-icon.module';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    EquipmentIconModule,
    ColorCodedBarPieModule,
    OperationsDashboardRoutingModule
  ],
  declarations: [
    OperationsDashboardComponent,
    OperationsNavComponent,
    JobOrderComponent,
    ModalLoaderComponent,
    AlertDialogComponent
  ],
  providers: []
})
export class OperationsDashboardModule { }

import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartDataSets } from 'chart.js'
import { Observable, Subscription } from 'rxjs';
import { MESSAGE_DISPLAY, YARD_OPERATIONS } from 'src/app/shared/constants/api.constant';
import { CHARTv2 } from 'src/app/shared/constants/chart.constant';
import { HazardousMaterialModel } from 'src/app/shared/models/hazardous-material.model';
import { getHazardousMaterial } from 'src/app/shared/state/operations/operations.action';
import { selectHazardousMaterial } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-hazardous-material',
  templateUrl: './hazardous-material.component.html',
  styleUrls: ['./hazardous-material.component.scss']
})
export class HazardousMaterialComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  hazardousMaterial$: Observable<HazardousMaterialModel[]> = this.store.select(selectHazardousMaterial);
  public barChartColors: any[] = [];
  public barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {}
    },
    scales: {
      xAxes: [{
        maxBarThickness: 50,
        gridLines: {
          // maxBarThickness: 30,
          display: false
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 5
        }
      }],
    },
  };
  public barChartLabels: string[] = [];
  public barChartType: string = CHARTv2.CHART_TYPE.bar;
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [{
    data: [],
    label: '',
    stack: 'a',
    backgroundColor: '',
    hoverBackgroundColor: ''
  }];
  public static barChartTooltips = [];
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public chartIsReady = false;

  public barChartStepSize: number = 0;
  public chartDataSet = YARD_OPERATIONS.HAZARDOUS_MATERIAL.DATASET_SUB_DIVISION
  public static chartLabelName = [];
  public static chartLabelInfo = [];

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getHazardousMaterial({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.hazardousMaterial$.subscribe((data) => {
      if (data?.length) {
        this.initializeHazardousMaterialData(data);
        this.noData = false;
      } else {
        this.noData = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getHazardousMaterial({ portId: this.selectedPortId }));
      }
    }
  }

  initializeHazardousMaterialData(data: HazardousMaterialModel[]) {
    const tmp = data;
    data.map((el, i) => {
      this.barChartLabels.push(el.material_id.toLocaleString());
      HazardousMaterialComponent.chartLabelName.push(el.material_name);
      HazardousMaterialComponent.chartLabelInfo[i] = [];
      let count = 0;
      el.details.forEach((ele, index) => {
        HazardousMaterialComponent.chartLabelInfo[i][index] = ele.division + ': ' + ele.classification
        count += 1;
        switch (index) {
          case 0:
            this.chartDataSet.sub1.push(ele.count)
            break;
          case 1:
            this.chartDataSet.sub2.push(ele.count)
            break;
          case 2:
            this.chartDataSet.sub3.push(ele.count)
            break;
          case 3:
            this.chartDataSet.sub4.push(ele.count)
            break;
          case 4:
            this.chartDataSet.sub5.push(ele.count)
            break;
          case 5:
            this.chartDataSet.sub6.push(ele.count)
            break;
          default:
        }
      });
      switch (count) {
        case 1: {
          this.chartDataSet.sub2.push(0);
          this.chartDataSet.sub3.push(0);
          this.chartDataSet.sub4.push(0);
          this.chartDataSet.sub5.push(0);
          this.chartDataSet.sub6.push(0);
        }
      }

    })
    Object.entries(this.chartDataSet).forEach((el, index) => {
      if (Math.max(...el[1]) > this.barChartStepSize) {
        this.barChartStepSize = (Math.max(...el[1]));
      }
      const item = {
        data: [...el[1]],
        label: '',
        stack: 'a',
        backgroundColor: YARD_OPERATIONS.HAZARDOUS_MATERIAL.COLORS[index],
        hoverBackgroundColor: YARD_OPERATIONS.HAZARDOUS_MATERIAL.COLORS[index]
      }
      this.barChartData.push(item)
    });
    this.barChartStepSize = this.barChartStepSize / 5
    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        labels: {

          fontSize: 0,
        },
      },
      tooltips: {
        enabled: true,
        // displayColors: false,
        xPadding: 15,
        yPadding: 15,
        callbacks: {
          title: function (tooltipItem, data) {
            return HazardousMaterialComponent.chartLabelName[tooltipItem[0].index]
          },

          label: function (tooltipItem, data) {
            let tmpTotal = 0
            const curr = tmp[tooltipItem.index]

            curr.details.map(t => {
              tmpTotal += t.count
            })
            const subLabel = `${((tooltipItem.value / tmpTotal) * 100).toFixed(1)}`

            return HazardousMaterialComponent.chartLabelInfo[tooltipItem.index][tooltipItem.datasetIndex - 1] + ` (${tooltipItem.value}, ${subLabel}%)`
          }
        }

      },
      scales: {
        xAxes: [{
          maxBarThickness: 50,
          gridLines: {
            display: false
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: this.barChartStepSize,
            callback: function (value) { { return Math.round(value); } }
          }
        }],
      },
    };
    this.barChartLegend = false;
    this.barChartType = CHARTv2.CHART_TYPE.bar;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subs-settings',
  templateUrl: './subs-settings.component.html',
  styleUrls: ['./subs-settings.component.scss']
})
export class SubsSettingsComponent implements OnInit {

  constructor() { }
  public subscriptions: features[] = [
    {
      imageUrl: "../../../../assets/images/login01.jpg",
      title: " Real-Time AI based Analytics",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      imageUrl: "../../../../assets/images/login02.jpg",
      title: "Energy Consumption Analytics",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      imageUrl: "../../../../assets/images/login03.jpg",
      title: "Robotic Arm Data Analytics",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
  ];

  ngOnInit(): void {
  }

}


export interface features {
  imageUrl: string;
  title: string;
  description: string;
}
import { createReducer, on } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import { ApiStatus } from '../../enum/api-status.enum';
import { SocialResponsibilityModel } from '../../models/social-responsibility.model';
import {
  getSocialResponsibility,
  getSocialResponsibilityFailure,
  getSocialResponsibilitySuccess,
  getWorkforceDiversity,
  getWorkforceDiversityFailure,
  getWorkforceDiversitySuccess,
  getEngagementAndTrainingMetrics,
  getEngagementAndTrainingMetricsFailure,
  getEngagementAndTrainingMetricsSuccess,
  getSafetyAndHealthMetrics,
  getSafetyAndHealthMetricsFailure,
  getSafetyAndHealthMetricsSuccess,
  getLostTimeInjuryFrequency,
  getLostTimeInjuryFrequencyFailure,
  getLostTimeInjuryFrequencySuccess
} from './social-responsibility.actions';
import {
  WorkforceDiversityModel,
  EngagementAndTrainingMetricsModel,
  LTIFModel,
  SafetyAndHealthMetricsModel
} from '../../models';

export interface SocialResponsibilityState {
  socialResponsibility: SocialResponsibilityModel,
  workforceDiversity: WorkforceDiversityModel
  engagementTrainingMetrics: EngagementAndTrainingMetricsModel,
  safetyHealthMetrics: SafetyAndHealthMetricsModel,
  lostTimeInjuryFrequency: LTIFModel,
  error: ErrorResponse,
  status: 'pending' | 'loading' | 'error' | 'success'
}

export const initialState: SocialResponsibilityState = {
  socialResponsibility: null,
  workforceDiversity: null,
  engagementTrainingMetrics: null,
  safetyHealthMetrics: null,
  lostTimeInjuryFrequency: null,
  error: null,
  status: ApiStatus.Pending
}

export const socialResponsibilityReducer = createReducer(
  initialState,
  on(getSocialResponsibility, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getSocialResponsibilitySuccess, (state, { socialResponsibility }) => ({
    ...state,
    socialResponsibility: socialResponsibility,
    status: ApiStatus.Success,
    error: null
  })),
  on(getSocialResponsibilityFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getWorkforceDiversity, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getWorkforceDiversitySuccess, (state, { workforceDiversity }) => ({
    ...state,
    workforceDiversity: workforceDiversity,
    status: ApiStatus.Success,
    error: null
  })),
  on(getWorkforceDiversityFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getEngagementAndTrainingMetrics, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getEngagementAndTrainingMetricsSuccess, (state, { engagementTrainingMetrics }) => ({
    ...state,
    engagementTrainingMetrics: engagementTrainingMetrics,
    status: ApiStatus.Success,
    error: null
  })),
  on(getEngagementAndTrainingMetricsFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getSafetyAndHealthMetrics, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getSafetyAndHealthMetricsSuccess, (state, { safetyHealthMetrics }) => ({
    ...state,
    safetyHealthMetrics: safetyHealthMetrics,
    status: ApiStatus.Success,
    error: null
  })),
  on(getSafetyAndHealthMetricsFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getLostTimeInjuryFrequency, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getLostTimeInjuryFrequencySuccess, (state, { lostTimeInjuryFrequency }) => ({
    ...state,
    lostTimeInjuryFrequency: lostTimeInjuryFrequency,
    status: ApiStatus.Success,
    error: null
  })),
  on(getLostTimeInjuryFrequencyFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  }))
);

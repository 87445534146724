import {Directive, Input, ElementRef, Renderer2, AfterContentInit, OnChanges} from '@angular/core';

@Directive({
  selector: '[appProgressBarCustom]'
})

export class ProgressBarCustom implements OnChanges, AfterContentInit {

  @Input() appProgressBarCustom;
  @Input() colorName;
  private directiveLoaded = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(): void {
    if (this.directiveLoaded) {
      this.updateColor();
    }
  }

  ngAfterContentInit() {
    this.updateColor();
    this.directiveLoaded = true;
  }

  private updateColor() {
    this.renderer.addClass(this.el.nativeElement.parentNode, 'mat-custom-progress-bar--' + this.colorName);
  }
}

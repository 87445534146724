
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { JobOrderComponent } from 'src/app/operations-dashboard/components/job-order/job-order.component';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import { EquipmentDetail } from 'src/app/shared/models';
import { EquipmentStatusModel } from 'src/app/shared/models/equipment-status.model';
import { getEquipmentStatus } from 'src/app/shared/state/operations/operations.action';
import { selectEquipmentStatus } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-equipment-status',
  templateUrl: './equipment-status.component.html',
  styleUrls: ['./equipment-status.component.scss']
})
export class EquipmentStatusComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  selectedPortId: string;

  equipmentStatus$: Observable<EquipmentStatusModel[]> = this.store.select(selectEquipmentStatus);
  dataSource = new MatTableDataSource<EquipmentStatusModel>();
  displayedColumns = ['equipmentId', 'viewDetail'];
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  pageIndex = PAGINATOR.DASHBOARD.EQUIPMENT_STATUS.pageIndex;
  pageSize = PAGINATOR.DASHBOARD.EQUIPMENT_STATUS.pageSize;

  constructor(private dialog: MatDialog, private store: Store) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.equipmentStatus$.subscribe((data) => {
        if (data) {
          this.createTable(data);
          this.getSortedEquipment(data);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEquipmentStatus({ portsId: this.selectedPortId }));
      }
    }
  }

  getSortedEquipment(data: EquipmentStatusModel[]): EquipmentStatusModel[] {
    const sortedData = data.sort((a, b) => a.equipment_name.localeCompare(b.equipment_name));
    return sortedData;
  }

  createTable(data: EquipmentStatusModel[]) {
    this.dataSource.data = data;
  }

  clickViewDetails(equipmentDetail: EquipmentDetail) {
    this.dialog.open(JobOrderComponent, {
      data: {
        port_id: this.selectedPortId,
        ...equipmentDetail
      },
    });
  }
}

import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { ICON } from "src/app/shared/constants/assets.constant";
import { getCommunityEducation } from "src/app/shared/state/biodiversity/biodiversity.actions";
import { selectCommunityEducation } from "src/app/shared/state/biodiversity/biodiversity.selectors";


@Component({
  selector: 'app-community-education',
  templateUrl: './community-education.component.html',
  styleUrls: ['./community-education.component.scss']
})
export class CommunityEducationComponent implements OnInit {
  @Input() selectedYear: string;

  communityEducation$ = this.store.select(selectCommunityEducation);

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getCommunityEducation({}));

  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedYear && !changes.selectedYear.firstChange;
    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getCommunityEducation({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getCommunityEducation({}));
      }
    }
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { routerTransition } from 'src/app/shared/router.animations';
import { selectAnalyticsPortId } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [routerTransition()]
})
export class OverviewComponent implements OnInit {
  selectedPortId$: Observable<string> = this.store.select(selectAnalyticsPortId);

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

}

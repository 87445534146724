import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesRoutingModule } from './directives-routing.module';
import {ProgressBarCustom} from './progress-bar-custom';
import {ProgressBarTarget} from './progress-bar-target';
import {SteppedLineChartCustomDirective} from './stepped-line-chart-custom';
import { TextColorCustomDirective } from './text-color-custom.directive';
import { TrueFalseValueDirective } from './true-false-value.directive';
import { AgmOverlayDirective } from './agm-overlay.directive';
// import { AccessTokenExpired } from './access-token-expired';

@NgModule({
  declarations: [ProgressBarCustom, ProgressBarTarget, SteppedLineChartCustomDirective, TextColorCustomDirective, TrueFalseValueDirective, AgmOverlayDirective],
  imports: [
    CommonModule,
    DirectivesRoutingModule
  ],
  exports: [
    ProgressBarCustom,
    ProgressBarTarget,
    SteppedLineChartCustomDirective,
    TextColorCustomDirective,
    TrueFalseValueDirective,
    AgmOverlayDirective
    // AccessTokenExpired
  ]
})
export class DirectivesModule { }

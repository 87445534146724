import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorPageRoutingModule } from './error-page-routing.module';
import { ErrorPageComponent } from './error-page.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ErrorPageRoutingModule
  ]
})
export class ErrorPageModule { }

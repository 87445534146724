import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { EquipmentDetailsRoutingModule } from './equipment-details-routing.module';
import { EquipmentDetailsComponent } from './equipment-details.component';
import { PerformanceTargetComponent } from './performance-target/performance-target.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { EquipmentIconModule } from 'src/app/operations-dashboard/components/equipment-icon/equipment-icon.module';
import { CraneMovementComponent } from './performance-target/crane-movement/crane-movement.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MaintenanceSummaryComponent } from './maintenance/maintenance-summary/maintenance-summary.component';
import { DueForInspectionComponent } from './maintenance/due-for-inspection/due-for-inspection.component';
import { MatTableModule } from '@angular/material/table';
import { MovementTimeOverviewComponent } from './performance-target/movement-time-overview/movement-time-overview.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CraneUtilizationComponent } from './performance-target/crane-utilization/crane-utilization.component';
import { HistoricalMaintenanceComponent } from './maintenance/historical-maintenance/historical-maintenance.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AlertsComponent } from './maintenance/alerts/alerts.component';
import { CraneOperationCycleStepsComponent } from './performance-target/crane-operation-cycle-steps/crane-operation-cycle-steps.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';



@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    EquipmentDetailsComponent,
    PerformanceTargetComponent,
    MaintenanceComponent,
    CraneMovementComponent,
    MaintenanceSummaryComponent,
    DueForInspectionComponent,
    MovementTimeOverviewComponent,
    CraneUtilizationComponent,
    HistoricalMaintenanceComponent,
    AlertsComponent,
    CraneOperationCycleStepsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ChartsModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    EquipmentIconModule,
    DirectivesModule,
    EquipmentDetailsRoutingModule
  ]
})
export class EquipmentDetailsModule { }

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-social-responsibility",
  templateUrl: "./social-responsibility.component.html",
  styleUrls: ["./social-responsibility.component.scss"],
})
export class SocialResponsibilityComponent implements OnInit {
  isArrowUpVisible = false;
  isDropdownVisible: boolean = false;
  selectedOption = "2024";
  selectedValue = "Select Year";
  yearOptions = [
    { id: "twentyFour", value: "2024", label: "2024" },
    { id: "twentyThree", value: "2023", label: "2023" },
    { id: "twentyTwo", value: "2022", label: "2022" },
  ];

  constructor() { }

  ngOnInit(): void { }

  selectOption(value: string) {
    this.selectedOption = value;
    this.selectedValue = value;
  }

  handleMouseLeave(): void {
    this.isArrowUpVisible = false;
    this.isDropdownVisible = false;
  }

  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
}

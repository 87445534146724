import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationsNavRoutingModule } from './operations-nav-routing.module';
import { OperationsComponent } from './operations/operations.component';
import { PortMapComponent } from './port-map/port-map.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { PortProductivityComponent } from './operations/port-productivity/port-productivity.component';
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonModule } from "@angular/material/button";
import { BerthingStatusComponent } from './operations/berthing-status/berthing-status.component';
import { OperatingStatusComponent } from './operations/operating-status/operating-status.component';
import { EquipmentStatusComponent } from './operations/equipment-status/equipment-status.component';
import { EquipmentMapComponent } from './operations/equipment-map/equipment-map.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CustomPipesModule } from 'src/app/shared/custom-pipes/custom-pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { EquipmentIconModule } from 'src/app/operations-dashboard/components/equipment-icon/equipment-icon.module';
import { MapEquipmentMapViewComponent } from './port-map/map-equipment-map-view/map-equipment-map-view.component';
import { WorkOrderComponent } from '../components/work-order/work-order.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EquipmentTableComponent } from './equipment-list/equipment-table/equipment-table.component';
import { PortOptimizationModule } from './port-optimization/port-optimization.module';
import { PortOptimizationComponent } from './port-optimization/port-optimization.component';
import { MapOptiComponent } from './port-optimization/map-opti/map-opti.component';
import { OptiServiceComponent } from './port-optimization/opti-service/opti-service.component';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    OperationsComponent,
    PortMapComponent,
    EquipmentListComponent,
    PortProductivityComponent,
    BerthingStatusComponent,
    OperatingStatusComponent,
    EquipmentStatusComponent,
    EquipmentMapComponent,
    MapEquipmentMapViewComponent,
    WorkOrderComponent,
    EquipmentTableComponent,
    PortOptimizationComponent,
    MapOptiComponent,
    OptiServiceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconSpriteModule,
    NgbDropdownModule,
    MatDialogModule,
    NgbTooltipModule,
    EquipmentIconModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    CustomPipesModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    OperationsNavRoutingModule,
    PortOptimizationModule
  ],

  exports: [MapEquipmentMapViewComponent],
  providers: []
})
export class OperationsNavModule { }

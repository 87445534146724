import { Component, OnInit } from '@angular/core';
import * as socialData from '../../../shared/mock-data/social-responsibility.json'


@Component({
  selector: 'app-social-respon-page',
  templateUrl: './social-respon-page.component.html',
  styleUrls: ['./social-respon-page.component.scss']
})
export class SocialResponPageComponent implements OnInit {
  public dataSocialRes;
  constructor() { }

  ngOnInit(): void {

    this.dataSocialRes = socialData.social_responsibility;
  }

}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from '../assets/config.json';
export const environment = {
  production: (config.ENV === 'PROD'),
  debug: (config.DEBUG && config.ENV !== 'PROD'),
  basePath: config.API,
  // basePath: '/connPort',
  // BASE_PATH: 'http://localhost:3000',
  BASE_PATH: 'https:ix-connectedports-be-dev.eastus.cloudapp.azure.com/',
  REDIRECT_URI: 'https://ix-connports-dev.eastus.cloudapp.azure.com/',
  azureMapSubcriptionKey: 'IkgwnWxwHUOFu97_QeiATVSdNhBjq1yVoy07_clmKNo',
  dateTimeFormat: 'dd MMM yyyy HH:mm',
  dateFormat: 'dd MMM yyyy',
  timeFormat: 'HH:mm',
  timeZone: '+0700',
  // For Weather API
  weather: 10000,
  // For DashBoard API's
  ShipAtBerthinterval: 10000,
  BerthAgregatedDataCalc: 10000,
  BerthQuayCraneAgregatedDataCalc: 10000,
  EquipmentOverview: 10000,
  EquipmentLatestEvent: 10000,
  // Overview Dashboard
  EquipmentCurrentTelemetryData: 10000,
  EquipmentAggregatedDataCalc: 10000,
  BerthQuayCraneLoadingStatus: 10000,
  EquipmentAggregatedDataTimeSeries: 10000,
  UtilizationKPI: 10000,
  IdlingKPI: 10000,
  TugboatLoadingStatus: 10000,
  // Equipment Overview
  EquipmentLogs: 10000,
  EquipmentMaintenanceAlert: 10000,
  EquipmentOverViewKPI: 10000,
  CraneOperationCycleSteps: 10000,
  EquipmentOverviewEquipment: 10000,
  PortEquipmentOverview: 10000,
  EquipmentLatestEventEquipment: 10000,
  // Digital twin
  digitalTwinportId: 'PORT03',
  // Port Authority
  regionCode: 1,
  ravennaPortId: 'c74df1b5-0520-4d58-9508-a0f84c76f55d'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../models/user.model';
import { 
  createUserSessionFailure, 
  createUserSessionSuccess, 
  createUserSession, 
  getUserDetails, 
  getUserDetailsSuccess, 
  getUserDetailsFailure } from './user.actions';
import { ApiStatus } from '../../enum/api-status.enum';


export interface UserState {
  user: UserModel,
  isValidSession: boolean,
  createSessionStatus: ApiStatus,
  getUserDetailStatus: ApiStatus,
  error: any
}

export const initialState: UserState = {
  user: null,
  isValidSession: false,
  createSessionStatus: ApiStatus.Pending,
  getUserDetailStatus: ApiStatus.Pending,
  error: null
}

export const userReducer = createReducer(
  //supply initial state
  initialState,
  //Handle create user session
  on(createUserSession, (state) => ({
    ...state,
    createSessionStatus: ApiStatus.Loading,
    error: null
  })),
  //Handle successfully loaded create session
  on(createUserSessionSuccess, (state, { isValidSession }) => ({
    ...state,
    isValidSession: isValidSession,
    createSessionStatus: ApiStatus.Success,
    error: null,
  })),
  //Handle failed create session
  on(createUserSessionFailure, (state, { error }) => ({
    ...state,
    createSessionStatus: ApiStatus.Error,
    error: error,
  })),

  on(getUserDetails, (state) => ({
    ...state,
    getUserDetailStatus: ApiStatus.Loading,
    error: null
  })),

  on(getUserDetailsSuccess, (state, { userDetail }) => ({
    ...state,
    getUserDetailStatus: ApiStatus.Success,
    user: userDetail,
    error: null
  })),
  
  on(getUserDetailsFailure, (state, { error }) => ({
    ...state,
    getUserDetailStatus: ApiStatus.Error,
    error: error,
  })),

);
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout.component";
import { DashboardModule } from "../dashboard/dashboard.module";
import { OperationsDashboardModule } from "../operations-dashboard/operations-dashboard.module";
import { SustainabilityDashboardModule } from "../sustainability-dashboard/sustainability-dashboard.module";
import { SettingsLayoutModule } from "./settings-layout/settings-layout.module";
import { PortAuthorityLayoutModule } from "./port-authority-layout/port-authority-layout.module";
import { DigitalTwinLayoutModule } from "../digital-twin-layout/digital-twin-layout.module";
import { EnergyMgtLayoutModule } from "../energy-mgt-layout/energy-mgt-layout.module";


const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      },
      {
        path: "dashboard",
        loadChildren: () => DashboardModule,
      },
      {
        path: "operations-dashboard",
        loadChildren: () => OperationsDashboardModule,
      },
      {
        path: "sustainability-dashboard",
        loadChildren: () => SustainabilityDashboardModule,
      },
      {
        path: "settings",
        loadChildren: () => SettingsLayoutModule,
      },
      {
        path: "port-authority",
        loadChildren: () => PortAuthorityLayoutModule,
      },
      {
        path: "digital-twin",
        loadChildren: () => DigitalTwinLayoutModule,
      },

      {
        path: "energy-dashboard",
        loadChildren: () => EnergyMgtLayoutModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapOptiComponent } from './map-opti/map-opti.component';
import { OptiServiceComponent } from './opti-service/opti-service.component';
import { PortOptimizationComponent } from './port-optimization.component';
import { PortOptimizationModule } from './port-optimization.module';
import { OptiServiceModule } from './opti-service/opti-service.module';
import { ServicesInfoModule } from './opti-service/services-info/services-info.module';








const routes: Routes = [
  {
    path: '',
    component: PortOptimizationComponent,
    data: { breadcrumb: '' },
    children: [
      { path: '', redirectTo: 'map-opti', pathMatch: 'full' },
      {
        path: 'map-opti',
        component: MapOptiComponent,
        data: { breadcrumb: 'Port Map' }
      },
   
      {
        path: 'opti-service',
        loadChildren: () => OptiServiceModule,
        data: { breadcrumb: 'Services' }
      },
    

    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortOptimizationRoutingModule { }

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitCamelCasePipe } from './split-camel-case.pipe';
import { PluralPipe } from './plural.pipe';
import { RoundPipe } from './round.pipe';
import { TimeRemainingPipe } from './time-remaining.pipe';
import { SortByPipe } from './sort-by.pipe';

@NgModule({
  declarations: [
    SplitCamelCasePipe,
    PluralPipe,
    RoundPipe,
    TimeRemainingPipe,
    SortByPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SplitCamelCasePipe,
    PluralPipe,
    RoundPipe,
    TimeRemainingPipe,
    SortByPipe
  ]
})
export class CustomPipesModule {
  static forRoot(): ModuleWithProviders<CustomPipesModule> {
    return {
      ngModule: CustomPipesModule,
      providers: [
        SplitCamelCasePipe,
        PluralPipe,
        RoundPipe,
        TimeRemainingPipe
      ]
    };
  }
}

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { ACTIVITY_HEALTH } from 'src/app/shared/constants/activity-health.constant';
import { BerthingStatusOverviewModel, BerthStatus } from 'src/app/shared/models';
import { Store } from '@ngrx/store';
import { getBerthingStatusOverview } from 'src/app/shared/state/operations/operations.action';
import { selectBerthingStatusOverview } from 'src/app/shared/state/operations/operations.selectors';

//to be removed
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';


@Component({
  selector: 'app-berthing-status-overview',
  templateUrl: './berthing-status-overview.component.html',
  styleUrls: ['./berthing-status-overview.component.scss']
})
export class BerthingStatusOverviewComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatSort, { static: true }) set matSort(ms: MatSort) {
    if (ms != undefined) {
      this.sort = ms;
      this.setDataSourceAttributes();
    }
  }

  @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
    if (mp != undefined) {
      this.paginator = mp;
      this.setDataSourceAttributes();
    }
  }

  @Input()
  selectedPortId: string;

  berthingStatusOverview$ = this.store.select(selectBerthingStatusOverview);
  berthStatus: any = [];
  berthRatio: number;
  berthStatusReady = false;
  dataSource;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true
  berthStatContainerProgIconName: string = "";
  isTableHasData = false;
  private paginator: MatPaginator;
  private sort: MatSort;
  displayedColumns: string[] = ['shipBerth', 'berthingTimeRemaining', 'containerProgress', 'craneGmph'];


  constructor(private store: Store, private roundPipe: RoundPipe) { }

  ngOnInit() {
    this.store.dispatch(getBerthingStatusOverview({ portId: this.selectedPortId }));

  }

  ngAfterViewInit() {
    this.berthingStatusOverview$.subscribe((berthingStatusOverviewModel) => {
      if (berthingStatusOverviewModel) {
        this.initializeTableData(berthingStatusOverviewModel);
      }
    })

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getBerthingStatusOverview({ portId: this.selectedPortId }));
      }
    }
  }

  initializeTableData(data: BerthingStatusOverviewModel) {
    this.berthRatio = this.roundPipe.transform(data.bor);
    this.berthStatus = data.berth_status;
    this.berthStatus = this.berthStatus.map((berth: BerthStatus) => {
      berth.activity_health = this.roundPipe.transform(berth.activity_health);
      berth.start_time = berth.start_time.replace(/\./g, ":");
      berth.end_time = berth.end_time.replace(/\./g, ":");
      berth.berth_remaining_time = new Date(berth.end_time).getTime() - new Date().getTime();
      berth.berth_remaining_time = this.roundPipe.transform(berth.berth_remaining_time > 0 ? berth.berth_remaining_time : 0);
      berth.berth_remaining_time = Math.floor(berth.berth_remaining_time / 60000);
      const berthFill = this.roundPipe.transform((berth.berth_remaining_time / berth.berth_total_time) * 100);
      const berthBuffer = 100 - berthFill;
      const berthTimeRemainingGraph = [berthFill, berthBuffer];
      const containerFillLoading = this.roundPipe.transform((berth.container_completed_loading / berth.container_assigned_loading) * 100);
      const containerBufferLoading = 100 - containerFillLoading;
      const containerFillUnloading = this.roundPipe.transform((berth.container_completed_unloading / berth.container_assigned_unloading) * 100);
      const containerBufferUnloading = 100 - containerFillUnloading;
      let containerFillColor: string;
      if (berth.activity_health < feConfig.ActivityHealthLowThreshold) {
        containerFillColor = ACTIVITY_HEALTH.STATUS.BAD;
      } else if (berth.activity_health >= feConfig.ActivityHealthLowThreshold &&
        berth.activity_health <= feConfig.ActivityHealthHighThreshold) {
        containerFillColor = ACTIVITY_HEALTH.STATUS.WARNING;
      } else if (berth.activity_health > feConfig.ActivityHealthHighThreshold) {
        containerFillColor = ACTIVITY_HEALTH.STATUS.GOOD;
      }
      const statusColorGenerated = containerFillColor;

      return {
        ...berth,
        berthTimeRemainingGraph,
        berthFill,
        berthBuffer,
        containerFillLoading,
        containerBufferLoading,
        containerFillUnloading,
        containerBufferUnloading,
        containerFillColor,
        statusColorGenerated
      };
    });
    this.dataSource = new MatTableDataSource(this.berthStatus);
    this.berthStatusReady = true;


    if (this.berthStatus === undefined || this.berthStatus === null || this.berthStatus.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;

      if (!feConfig.PortType || feConfig.PortType === "") {
        this.berthStatContainerProgIconName = "Ship";
      } else {
        if (feConfig.PortType.toLowerCase() === "sea") {
          this.berthStatContainerProgIconName = "Ship";
        } else if (feConfig.PortType.toLowerCase() === "land") {
          this.berthStatContainerProgIconName = "Truck";
        }
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setDataSourceAttributes() {
    this.dataSource = new MatTableDataSource(this.berthStatus['data']);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.cdref.detectChanges();
  }

}

import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { PortState } from "./port.reducer";

export const selectPortState = (state: AppState) => state.ports;

export const selectPorts = createSelector(
  selectPortState,
  (state: PortState) => state.ports
);

import { createReducer, on } from '@ngrx/store';
import { getWaterConservation, getWaterConservationFailure, getWaterConservationSuccess, getWaterFootprintUsageEfficiency, getWaterFootprintUsageEfficiencyFailure, getWaterFootprintUsageEfficiencySuccess, getWaterQualityIndexCompliance, getWaterQualityIndexComplianceFailure, getWaterQualityIndexComplianceSuccess, getWaterQualityProfile, getWaterQualityProfileFailure, getWaterQualityProfileSuccess, getWaterRecyclingRateTreatEff, getWaterRecyclingRateTreatEffFailure, getWaterRecyclingRateTreatEffSuccess, getWaterSourceDiversity, getWaterSourceDiversityFailure, getWaterSourceDiversitySuccess, getWaterUsageShare, getWaterUsageShareFailure, getWaterUsageShareSuccess } from './water-conservation.actions';
import { ErrorResponse } from '../../models/error-response.model';
import { WaterConservationModel } from '../../models/water-conservation.model';
import { ApiStatus } from '../../enum/api-status.enum';
import { WaterQualityProfileModel } from '../../models/water-quality-profile.model';
import { WaterQualityIndexComplianceModel } from '../../models/water-quality-index-compliance.model';
import { WaterUsageShareModel } from '../../models/water-usage-share.model';
import { WaterFootprintUsageEfficiencyModel } from '../../models/water-footprint-usage-efficiency.mode';
import { WaterSourceDiversityModel } from '../../models/water-source-diversity.model';
import { WaterRecyclingRateTreatEffModel } from '../../models/water-recycling-rate-treat-efficiency.model';



export interface WaterConservationState {
    waterConservation: WaterConservationModel,
    waterQualityProfile: WaterQualityProfileModel,
    waterQualityIndexCompliance: WaterQualityIndexComplianceModel,
    waterUsageShare: WaterUsageShareModel,
    waterFootprintUsageEfficiency: WaterFootprintUsageEfficiencyModel,
    waterSourceDiversity: WaterSourceDiversityModel,
    waterRecyclingRateTreatEff: WaterRecyclingRateTreatEffModel,
    error: ErrorResponse,
    status: 'pending' | 'loading' | 'error' | 'success'
}

export const initialState: WaterConservationState  = {
    waterConservation: null,
    waterQualityProfile: null,
    waterQualityIndexCompliance: null,
    waterUsageShare: null,
    waterFootprintUsageEfficiency: null,
    waterSourceDiversity: null,
    waterRecyclingRateTreatEff: null,
    error: null,
    status: ApiStatus.Pending
}

export const waterConservationReducer = createReducer(
    initialState,
    on(getWaterConservation, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterConservationSuccess, (state, { waterConservation }) => ({
        ...state,
        waterConservation: waterConservation,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterConservationFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterQualityProfile, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterQualityProfileSuccess, (state, { waterQualityProfile }) => ({
        ...state,
        waterQualityProfile: waterQualityProfile,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterQualityProfileFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterQualityIndexCompliance, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterQualityIndexComplianceSuccess, (state, { waterQualityIndexCompliance }) => ({
        ...state,
        waterQualityIndexCompliance: waterQualityIndexCompliance,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterQualityIndexComplianceFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterUsageShare, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterUsageShareSuccess, (state, { waterUsageShare }) => ({
        ...state,
        waterUsageShare: waterUsageShare,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterUsageShareFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterFootprintUsageEfficiency, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterFootprintUsageEfficiencySuccess, (state, { waterFootprintUsageEfficiency }) => ({
        ...state,
        waterFootprintUsageEfficiency: waterFootprintUsageEfficiency,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterFootprintUsageEfficiencyFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterSourceDiversity, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterSourceDiversitySuccess, (state, { waterSourceDiversity }) => ({
        ...state,
        waterSourceDiversity: waterSourceDiversity,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterSourceDiversityFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),

    on(getWaterRecyclingRateTreatEff, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWaterRecyclingRateTreatEffSuccess, (state, { waterRecyclingRateTreatEff }) => ({
        ...state,
        waterRecyclingRateTreatEff: waterRecyclingRateTreatEff,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWaterRecyclingRateTreatEffFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),
);
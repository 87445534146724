import { Injectable } from '@angular/core';
import { TURN_TIME } from '../constants/turn-time.constant';

@Injectable({
  providedIn: 'root'
})
export class TurnTimeService {

  constructor() { }

  getDelimiters(data) {
    const endval = TURN_TIME.RANGE_LABEL.end
    let rawValues = []
    
    data.map(item => {
      // rawValues.push((item / endval) * 100)
      const comp = (item / endval) * 100
      if (comp > 100) {
        rawValues.push(99.99)
      } else {
        rawValues.push(comp)
      }
    })

    return rawValues
  }
}

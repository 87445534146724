import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortAuthorityLayoutComponent } from './port-authority-layout.component';
import { RegionalMapModule } from './regional-map/regional-map.module';
import { GeneralMapModule } from './general-map/general-map.module';
import { GeneralMapComponent } from './general-map/general-map.component';






const routes: Routes = [
  {
    path: "",
    component: PortAuthorityLayoutComponent,
    data: { breadcrumb: "Port Authority" },
    children: [
      {
        path: "",
        redirectTo: "all",
        pathMatch: "full"
      },
      {
        path: "",
        loadChildren: () => RegionalMapModule,
        data: { breadcrumb: "" },
      },
      {
        path: 'general-map',
        loadChildren: () => GeneralMapModule,
        data: { breadcrumb: 'Port Authority Members' }
      }
    ]
  },
];





@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortAuthorityLayoutRoutingModule { }

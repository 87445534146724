import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scenario-simulation',
  templateUrl: './scenario-simulation.component.html',
  styleUrls: ['./scenario-simulation.component.scss']
})
export class ScenarioSimulationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { data } from 'src/app/shared/mock-data/energy-table.json';

@Component({
  selector: 'app-energy-table',
  templateUrl: './energy-table.component.html',
  styleUrls: ['./energy-table.component.scss']
})
export class EnergyTableComponent implements OnInit {
  @Input() energyLabel: string;
  public obsMonthlyTable: Observable<any>;
  public subsMonthlyTable: Subscription;
  public noData: boolean;
  public costsData: consumptionData[] = [];
  public yearSet: string[] = ["2022", "2023"];
  public selectedYr = this.yearSet[0];

  constructor() { }

  ngOnInit() {
    this.getMonthlyTable(this.selectedYr);
  }

  getMonthlyTable(year: string) {
    const responseList = data


    if (!responseList || responseList.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
      const consumptionData = responseList[0].monthlyConsumptionData.map(item => ({
        month: item.month,
        consumption: parseFloat(item.consumption),
        cost: parseFloat(item.cost)
      }));

      this.costsData = consumptionData;
    }
  }
}

export interface consumptionData {
  month: string;
  consumption: number;
  cost: number;
}




import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { WasteManagementService } from "../../services/waste-management.service";
import { getWasteManagement, getWasteManagementFailure, getWasteManagementSuccess } from "./waste-management.action";


@Injectable()
export class WasteManagementEffects {
    constructor(
        private actions$: Actions,
        private wasteManagementService: WasteManagementService) { }

    getWasteManagement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getWasteManagement),
            switchMap((action) =>
                this.wasteManagementService.getWasteManagement(action.portsId ? action.portsId : null).pipe(
                    map((response) => response.data),
                    map((data) => getWasteManagementSuccess({ wasteManagement: data })),
                    catchError((error) => of(getWasteManagementFailure({ error })))
                )
            )
        )
    );


}




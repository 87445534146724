import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import { SessionManager } from "../shared/common-function/session-manager";
import { Store } from "@ngrx/store";
import { createUserSession } from "../shared/state/user/user.actions";
import { selectCreateSessionStatus } from "../shared/state/user/user.selectors";
import { ApiStatus } from "../shared/enum/api-status.enum";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  public createSessionStatus$ = this.store.select(selectCreateSessionStatus);

  constructor(
    private translate: TranslateService,
    private authService: MsalService,
    public router: Router,
    private store: Store
  ) {
    this.translate.addLangs([
      "en",
      "fr",
      "ur",
      "es",
      "it",
      "fa",
      "de",
      "zh-CHS",
    ]);
    this.translate.setDefaultLang("en");
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : "en"
    );
  }

  ngOnInit() {
    if(SessionManager.isValid() && sessionStorage.getItem('transaction_id')){
      this.router.navigate(['/']);
    } else {
      this.getToken();
    }
  }

  getToken() {
    this.authService.acquireTokenSilent({ scopes: ['user.read'] })
      .subscribe(response => {
        console.log('Response acquired inside login component:', response);
        this.initializeAuthSession(response);
      }, error => {
        console.error('Token acquisition error inside login component:', error);
      });
  }


  initializeAuthSession(response: AuthenticationResult) {
    // role based implementation
    // SessionManager.put("accessToken", accessToken);
    // const userDetails = JSON.parse(atob(accessToken.split('.')[1]));
    // SessionManager.put("userName", SessionManager.getFromToken("username"));
    // SessionManager.put("name", userDetails.name);
    // SessionManager.put("userDetails", accessToken.split('.')[1]);
    // if (userDetails.roleId == 1) {
    //   SessionManager.put("isAdmin", "true");
    // }

    // if (userDetails.companyCode === "ACN01") {
    //   SessionManager.put('isClient', "false");
    // } else {
    //   SessionManager.put('isClient', "true");
    // }


    // var routeLink: string;
    // switch (userDetails.roleName) {
    //   case "admin":
    //     this.router.navigate(['/multi-app']);
    //     SessionManager.put("isAdmin", "true");
    //     break;
    //   case "controlcenter":
    //     this.router.navigate(['/multi-app']);
    //     SessionManager.put("isAdmin", "false");
    //     break;
    //   case "terminaloperator":
    //     this.router.navigate(['/multi-app']);
    //     SessionManager.put("isAdmin", "false");
    //     break;
    //   case "terminalmanager":
    //     SessionManager.put("portId", userDetails.ports[0]);
    //     SessionManager.put("isAdmin", "false");
    //     this.appConfigService.load().then(() => {
    //       this.router.navigate(['/dashboard']);
    //     });
    //     break;
    //   case "adsp":
    //     SessionManager.put("portId", userDetails.ports[0]);
    //     SessionManager.put("isAdmin", "false");
    //     this.appConfigService.load().then(() => {
    //       this.router.navigate(['/port-authority']);
    //     });
    //     break;
    //   default:
    //     sessionStorage.clear();
    //     this.responseMessage = 'Unexpected Error Encountered';
    //     this.messageDisplay = true;
    //     this.subscription.unsubscribe();
    //     this.loading = false;
    //     break;
    // }

    if (response.accessToken) {
      SessionManager.put("accessToken", response.accessToken);
      SessionManager.put("idToken", response.idToken);
      SessionManager.put("accountId", response.account.homeAccountId);
      SessionManager.put("username", response.account.username);
      SessionManager.put("name", response.account.name);
      SessionManager.put("transactionId", uuidv4());
      this.store.dispatch(createUserSession());

      this.createSessionStatus$.subscribe( status => {
        if(status === ApiStatus.Success) {
          SessionManager.put("isValidSession", 'true');
          this.router.navigate(['/']);
        }
      });
    }
  }

  ngOnDestroy() { }
}

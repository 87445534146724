import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { GaugeChartModule } from "angular-gauge-chart";
import { ChartsModule } from 'ng2-charts';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { OperationsComponent } from '../dashboard/operations/operations.component';
import { EnergyManagementComponent } from '../dashboard/energy-management/energy-management.component';
import { LogisticsComponent } from '../dashboard/logistics/logistics.component';
import { ContainerManagementComponent } from '../dashboard/container-management/container-management.component';
import { SustainabilityComponent } from '../dashboard/sustainability/sustainability.component';
import { WorkerSafetyManagementComponent } from '../dashboard/worker-safety-management/worker-safety-management.component';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    GaugeChartModule,
    DashboardRoutingModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDgRfHv2rctz8ZwUUG9ArN40cjHlil2WYg' as string,
    } as LazyMapsAPILoaderConfigLiteral)
  ],
  declarations: [
    DashboardComponent,
    OperationsComponent,
    LogisticsComponent,
    EnergyManagementComponent,
    ContainerManagementComponent,
    SustainabilityComponent,
    WorkerSafetyManagementComponent
  ]
})
export class DashboardModule { }

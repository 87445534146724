export const ACTIVITY_HEALTH = {
  STATUS: {
    GOOD: 'activity-status-good',
    WARNING: 'activity-status-warning',
    BAD: 'activity-status-bad'
  },
  COLOR: {
    GOOD: '#1abc9c',
    WARNING: '#F5A623',
    BAD: '#e74c3c',
  }
};

import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHARTv2, FONTS } from 'src/app/shared/constants/chart.constant';
import { DatePipe } from '@angular/common';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { CraneUtilizationAnalyticsData, CraneUtilizationAnalyticsModel, CraneUtilizationHistoryModel } from 'src/app/shared/models/crane-utilization.model';
import { selectCraneUtilizationAnalytics } from 'src/app/shared/state/operations/operations.selectors';
import { Store } from '@ngrx/store';
import { getCraneUtilizationAnalytics } from 'src/app/shared/state/operations/operations.action';


@Component({
  selector: 'app-crane-status-chart',
  templateUrl: './crane-status-chart.component.html',
  styleUrls: ['./crane-status-chart.component.scss']
})
export class CraneStatusChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;;

  craneUtilizationAnalytics$: Observable<CraneUtilizationHistoryModel[]> = this.store.select(selectCraneUtilizationAnalytics)
  public chartData: any[];
  public chartLabels: string[] = [];
  public chartOptions: any;
  public chartColors: any[];
  public chartLegend: boolean;
  public chartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public noDataPie = true;
  public chartIsReady = false;
  public currentDate = new Date();
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public ngDateFrom = new Date('2022-1-1');
  public ngDateTo = new Date('2022-1-7');
  public initialDateFilter = new Date();
  public initialDT = new Date();
  public dateRangeError: boolean;
  public equipmentIdList: string[] = [];
  public craneId: string = "ALL";
  public ArrActive: number[] = [];
  public ArrNonActive: number[] = [];
  public ArrIdle: number[] = [];
  public dropdownData: string[] = [];
  public craneModel: CraneUtilizationAnalyticsModel[];
  // public initialFilter: string = "ALL";
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private roundPipe: RoundPipe,
    private dateAdapter: DateAdapter<Date>
  ) {
    datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 60);
    this.rangeFrom = this.datePipe.transform(this.initialDateFilter.setDate(this.initialDateFilter.getDate() - 7), 'yyyy-MM-dd');
    this.rangeTo = this.datePipe.transform(this.currentDate.setDate(this.currentDate.getDate() - 1), 'yyyy-MM-dd');

    this.store.dispatch(getCraneUtilizationAnalytics({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.craneUtilizationAnalytics$.subscribe((data) => {
        if (data?.length) {
          this.craneModel = data;
          this.initializeCraneUtilizationData(this.craneModel);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getCraneUtilizationAnalytics({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
      }
    }
  }

  initializeCraneUtilizationData(dataList: CraneUtilizationAnalyticsModel[]) {
    const dateFormat2 = 'yyyy-MM-dd';
    this.equipmentIdList = [];
    this.chartLabels = [];
    this.ArrActive = [];
    this.ArrNonActive = [];
    this.ArrIdle = [];

    dataList.map((data: CraneUtilizationAnalyticsModel) => {
      this.equipmentIdList.push(data.equipment_name);
      if (data.equipment_name === this.craneId) {
        data.daily_utilization_history.map((item: CraneUtilizationAnalyticsData) => {
          item.date_acquired = this.datePipe.transform(item.date_acquired, dateFormat2);
          if (!this.chartLabels.includes(item.date_acquired)) {
            this.chartLabels.push(item.date_acquired);
          }
          if (item.crane_idling) {
            this.ArrIdle.push(
              this.roundPipe.transform(item.crane_idling, '1.0-2')
            );
          } else {
            this.ArrIdle.push(0);
          }
          if (item.crane_unutilized) {
            this.ArrNonActive.push(
              this.roundPipe.transform(item.crane_unutilized, '1.0-2')
            );
          } else {
            this.ArrNonActive.push(0);
          }
          if (item.crane_utilization) {

            this.ArrActive.push(
              this.roundPipe.transform(item.crane_utilization, '1.0-2')
            );
          } else {
            this.ArrActive.push(0);
          }
        });
      }

    });


    this.chartData = [
      {
        type: 'bar',
        label: 'Active',
        data: this.ArrActive,
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: 'Idle',
        data: this.ArrIdle,
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: 'Non-Active',
        data: this.ArrNonActive,
        stack: 'Stack 0',
      },

    ];
    this.chartLabels;
    this.initializeChart();
    this.noData = false;
    this.chartIsReady = true;
  }

  initializeChart() {
    this.chartOptions = {
      scaleShowVerticalLines: true,
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: {
        mode: CHARTv2.TOOLTIP_MODE_NEAREST,
        callbacks: {
          label: (context, value) => {
            let val = context.value;

            return value.datasets[context.datasetIndex].label + ": " + val + '%'
          }
        }
      },
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: 'white',
          fontSize: 0,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            min: CHARTv2.SCALE_0,
            max: CHARTv2.SCALE_100,
            fontFamily: FONTS.FAMILY.GOTHAM,
            fontSize: FONTS.SIZE.TEN,
            callback: function (value) {
              return value + "%"
            }
          }
        }],
        xAxes: [{
          ticks: {
            maxRotation: CHARTv2.ROTATION_0,
            minRotation: CHARTv2.ROTATION_0,
            fontFamily: FONTS.FAMILY.GOTHAM,
            fontSize: FONTS.SIZE.TEN,
            callback: function (label: any) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            }
          }
        }],

      }
    };
    this.chartColors = [
      { backgroundColor: CHARTv2.COLOR.BLUE },
      { backgroundColor: CHARTv2.COLOR.RED },
      { backgroundColor: CHARTv2.COLOR.GRAY },

    ];
    this.chartLegend = true;
    this.chartType = CHARTv2.CHART_TYPE.bar;
  }

  onFilterCrane(craneId: string) {
    this.craneId = craneId;
    this.chartIsReady = false;
    this.chartData = [];
    this.ArrActive = [];
    this.ArrIdle = [];
    this.ArrNonActive = [];
    this.chartLabels = [];
    this.equipmentIdList = [];


    if (this.rangeFrom !== null && this.rangeTo !== null) {
      this.initializeCraneUtilizationData(this.craneModel);
    } else {
      this.initializeCraneUtilizationData(this.craneModel);

    }
  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate;
    } else {
      this.rangeTo = selectedDate;
    }
    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;
    }

    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;

      this.store.dispatch(getCraneUtilizationAnalytics({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    }
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }

}

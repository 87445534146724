import { Component, OnInit, Input, } from '@angular/core';
import { ACTIVITY_HEALTH } from 'src/app/shared/constants/activity-health.constant';
import { CHART, CHARTv2 } from 'src/app/shared/constants/chart.constant';

@Component({
  selector: 'app-berthing-time-remaining',
  templateUrl: './berthing-time-remaining.component.html',
  styleUrls: ['./berthing-time-remaining.component.scss'],
})
export class BerthingTimeRemainingComponent implements OnInit {

  @Input() statusColorGenerated: any;
  @Input() berthTimeRemainingGraph: any;
  @Input() berthRemainingTime: any;

  // @Input() berthStatus: any = [];
  public pieChartColors = {
    COLOR_HEALTH_GOOD: [{ backgroundColor: [ACTIVITY_HEALTH.COLOR.GOOD, CHART.COLOR.WHITE] }],
    COLOR_HEALTH_WARNING: [{ backgroundColor: [ACTIVITY_HEALTH.COLOR.WARNING, CHART.COLOR.WHITE] }],
    COLOR_HEALTH_BAD: [{ backgroundColor: [ACTIVITY_HEALTH.COLOR.BAD, CHART.COLOR.WHITE] }],
  };
  public utilPieChartLabels: string[] = ['Consumed Time', 'Time Remaining'];
  public utilPieChartOptions = CHARTv2.CHART_CONFIGS.PIE.CHART_OPTIONS;
  public utilPieChartLegend = false;
  public utilPieChartType = CHART.TYPE.PIE;
  pieChartColorNames = { ...ACTIVITY_HEALTH.STATUS };

  constructor() { }

  ngOnInit() {
  }


}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ACTIVITY_HEALTH } from 'src/app/shared/constants/activity-health.constant';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BerthingStatusModel } from 'src/app/shared/models/berthing-status.model';
import { getBerthingStatus } from 'src/app/shared/state/operations/operations.action';
import { selectBerthingStatus } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-berthing-status',
  templateUrl: './berthing-status.component.html',
  styleUrls: ['./berthing-status.component.scss']
})
export class BerthingStatusComponent implements OnInit, OnChanges {
  @Input()
  selectedPortId: string;

  berthStatus$: Observable<BerthingStatusModel> = this.store.select(selectBerthingStatus);
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  yesterdayDate: Date;
  
  constructor(private roundPipe: RoundPipe, private store: Store) { }

  ngOnInit(): void {
    const today = new Date();     
    this.yesterdayDate = new Date(today);  
    this.yesterdayDate.setDate(today.getDate() - 1);
 
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getBerthingStatus({ portId: this.selectedPortId }));
      }
    }
  }

  getStatusColorGenerated(activityHealth: number) {
    let statusColorGenerated: string;
    if (activityHealth < feConfig.ActivityHealthLowThreshold) {
      statusColorGenerated = ACTIVITY_HEALTH.STATUS.BAD;

    } else if (activityHealth >= feConfig.ActivityHealthLowThreshold &&
      activityHealth <= feConfig.ActivityHealthHighThreshold) {
      statusColorGenerated = ACTIVITY_HEALTH.STATUS.WARNING;
    } else if (activityHealth > feConfig.ActivityHealthHighThreshold) {
      statusColorGenerated = ACTIVITY_HEALTH.STATUS.GOOD;
    }

    return statusColorGenerated;
  }

  getProgressFill(completed: number, assigned: number) {
    return this.roundPipe.transform((completed / assigned) * 100)
  }

  getProgressBuffer(completed: number, assigned: number) {
    const containerFill = this.roundPipe.transform((completed / assigned) * 100);
    return 100 - containerFill;
  }

  getBerthFill(berthRemainingTime: number) {
    return this.roundPipe.transform((berthRemainingTime / 2460.85) * 100);
  }

  getBerthBuffer(berthRemainingTime: number) {
    const berthFill = this.roundPipe.transform((berthRemainingTime / 2460.85) * 100);
    return 100 - berthFill;
  }
}




import { Component, OnInit, ViewChild } from '@angular/core';
import * as vesselData from 'src/app/shared/mock-data/vessel.json'
import { MatTableDataSource } from '@angular/material/table';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss']
})
export class HistoricalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  public paginator: MatPaginator;
  public dataSource;
  public pageIndex = PAGINATOR.EQUIPMENT.LIST.pageIndex;
  public pageSize = PAGINATOR.EQUIPMENT.LIST.pageSize;
  public pageSizeOptions = PAGINATOR.EQUIPMENT.LIST.pageSizeOptions;
  public columnHeaders = [
    'terminalId',
    'vesselName',
    'vesselType',
    'imoNum',
    'arrival',
    'departure',
    'prevPort',
    'nextPort',
    'tat',
    'bookingId'
  ];
  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(vesselData.vessels);
  }

}

import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { BaseChartDirective } from 'ng2-charts';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EquipmentFailureChartModel, KPI } from 'src/app/shared/models/failure-chart.model';
import { selectEquipmentFailureChart } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentFailureChart } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-failure-chart',
  templateUrl: './failure-chart.component.html',
  styleUrls: ['./failure-chart.component.scss']
})
export class FailureChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: false })
  public chart: BaseChartDirective;

  @Input()
  selectedPortId: string;

  equipmentFailure$: Observable<EquipmentFailureChartModel[]> = this.store.select(selectEquipmentFailureChart);
  public isZoom: boolean;
  public barLineChartData: any[];
  public barLineChartLabels: string[] = [];
  public barLineChartOptions: any;
  public barLineChartColors: any[];
  public barLineChartLegend: boolean;
  public barLineChartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public chartIsReady = false;
  public filters = { ...CHART.FILTERS };
  public initialFilter: string = "ALL";
  public currentDate = new Date();
  public selectedFilter: string = "ALL";
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public dateRangeError: boolean;
  public equipmentIdList: string[] = [];
  public tableHeader: string[] = [];
  public tableDataMMBF: any = [];
  public isTableVisible: boolean = false;
  public ngDateFrom = new Date('2022-01-01');
  public ngDateTo = new Date('2022-01-07');
  public initialDF = '2022-01-01';
  public initialDT = '2022-12-30';
  item: KPI;

  constructor(
    private store: Store,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {
    datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 60);
    this.rangeFrom = this.initialDF;
    this.rangeTo = this.initialDT;
    this.store.dispatch(getEquipmentFailureChart({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEquipmentFailureChart({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.equipmentFailure$.subscribe((data) => {
        if (data?.length) {
          this.initializeFailureChartData(this.selectedFilter, data);
        }
      });
    });
  }

  getEquipmentFailureChart(startDate: string, endDate: string) {
    startDate = this.initialDF;
    endDate = this.initialDT;

    this.store.dispatch(getEquipmentFailureChart({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  initializeFailureChartData(selectedFilter: string, data: EquipmentFailureChartModel[]) {
    const dateFormat2 = 'dd/MM';
    this.barLineChartLabels = [];
    const barLineChartDataDataMMBF = [];
    const barLineChartDataDataMTBF = [];
    const responseList: EquipmentFailureChartModel[] = data;
    this.tableDataMMBF = data;

    if (!responseList || responseList === undefined || responseList === null || responseList.length === 0) {
      this.noData = true;
    } else {
      responseList.map((data: EquipmentFailureChartModel) => {
        this.equipmentIdList.push(data.equipment_name);

        if (data.equipment_name === selectedFilter) {
          data.kpi.map((item) => {
            item.date_acquired = this.datePipe.transform(item.date_acquired, dateFormat2);
            this.barLineChartLabels.push(item.date_acquired);
            this.tableHeader.push(item.date_acquired);

            // validate if MMBF exists
            if (item.mtbf) {
              //data.mtbf = this.roundPipe.transform(data.mtbf * 100);
              barLineChartDataDataMTBF.push(item.mtbf.toFixed(1));
            } else {
              barLineChartDataDataMTBF.push(0);

            }
            if (item.mmbf) {
              barLineChartDataDataMMBF.push(item.mmbf.toFixed(1));

            } else {
              barLineChartDataDataMMBF.push(0);
            }

          });
        }

      });
      this.barLineChartData = [
        {
          label: 'Mean Move Between Failure (per day)',
          data: barLineChartDataDataMMBF,
          yAxisID: 'MMBF',
          fill: false,
          borderColor: CHART.COLOR.ANALYTICS.VIOLET,
          pointRadius: 5,
          pointHoverRadius: 8,
          lineTension: 0,
          type: CHART.TYPE.LINE,
        }, {
          label: 'Mean Time Between Failure (per day)',
          data: barLineChartDataDataMTBF,
          yAxisID: 'MTBF',
          fill: false,
          borderColor: CHART.COLOR.ANALYTICS.TORQUOISE,
          pointRadius: 5,
          pointHoverRadius: 8,
          lineTension: 0,
          type: CHART.TYPE.LINE
        },
      ];
      this.barLineChartLabels;
      this.initializeChart();

      this.noData = false;
      this.chartIsReady = true;
    }
  }

  initializeChart() {
    this.barLineChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: {
        titleFontFamily: CHART.FONTS.GOTHAM,
        bodyFontFamily: CHART.FONTS.GOTHAM
      },
      scales: {
        yAxes: [{
          id: 'MMBF',
          position: 'left',
          ticks: {
            min: 0,
            fontFamily: CHART.FONTS.GOTHAM, fontSize: 12
          },
          gridLines: {
            display: false
          }
        }, {
          id: 'MTBF',
          position: 'right',
          ticks: {
            min: 0,
            max: 100,
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 12
          }
        }],
        xAxes: [{
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 0,
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 12
          },
          gridLines: {
            display: false
          }
        }]
      },
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      pan: {
        enabled: this.isZoom,
        threshold: 10,
        mode: 'x',
      },
      zoom: {
        mode: 'xy',
        enabled: this.isZoom,
      },
    };

    this.barLineChartColors = [
      { backgroundColor: CHART.COLOR.ANALYTICS.VIOLET },
      { backgroundColor: CHART.COLOR.ANALYTICS.TORQUOISE },
    ];
    this.barLineChartLegend = true;
    this.barLineChartType = CHART.TYPE.BAR;
  }

  onFilterSelected(selectedFilter: any) {
    this.selectedFilter = selectedFilter;
    this.chartIsReady = false;
    this.barLineChartData = [];
    this.barLineChartLabels = [];
    this.tableHeader = [];
    this.equipmentIdList = [];

    // Fetch new data based on the selected filter
    if (this.rangeFrom !== null && this.rangeTo !== null) {
      this.getEquipmentFailureChart(this.rangeFrom, this.rangeTo);
    } else {
      this.getEquipmentFailureChart(this.rangeFrom, this.rangeTo);
    }
  }

  //Added to
  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate
    } else {
      this.rangeTo = selectedDate
    }
    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;

    } else {
      this.dateRangeError = false;
      this.onFilterSelected(this.selectedFilter);
    }
  }

  onViewTable(type: string) {
    if (type === 'open') {
      this.isTableVisible = true;
    } else {
      this.isTableVisible = false;
    }
  }



  onResetChart() {
    // @ts-ignore
    this.chart.chart.resetZoom();
  }

  onZoom() {
    if (!this.isZoom) {
      this.isZoom = true;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = true
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = true
    } else {
      this.isZoom = false;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = false
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = false
      // @ts-ignore
      this.chart.chart.resetZoom();
    }
    this.chart.chart.update();
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }

}

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Observable } from 'rxjs';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { WorkforceDiversityModel } from 'src/app/shared/models/workforce-diversity.model';
import { getWorkforceDiversity } from 'src/app/shared/state/social-responsibility/social-responsibility.actions';
import { selectWorkforceDiversity } from 'src/app/shared/state/social-responsibility/social-responsibility.selectors';

@Component({
  selector: 'app-workforce-diversity',
  templateUrl: './workforce-diversity.component.html',
  styleUrls: ['./workforce-diversity.component.scss']
})
export class WorkforceDiversityComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  workforceDiversity$: Observable<WorkforceDiversityModel> = this.store.select(selectWorkforceDiversity);
  private staffBarChart: Chart;
  private classBarChart: Chart;
  private departmentsBarChart: Chart;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getWorkforceDiversity({}));
  }

  ngAfterViewInit(): void {
    this.workforceDiversity$.subscribe((workforceDiversity) => {
      if (workforceDiversity) {
        this.barChartStaff(workforceDiversity);
        this.barChartClass(workforceDiversity);
        this.barChartDepartment(workforceDiversity);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getWorkforceDiversity({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getWorkforceDiversity({}));
      }
    }
  }

  barChartStaff(data: WorkforceDiversityModel) {
    if (!data.staff_diversity.male && !data.staff_diversity.female) {
      this.staffBarChart?.destroy();
      return;
    }

    if (this.staffBarChart) {
      this.staffBarChart.destroy();
    }

    const canvas = document.getElementById(
      "diversityByStaffChart"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d"); // Get the 2D context

    this.staffBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Gender Distribution"],
        datasets: [
          {
            label: "Male",
            data: [data.staff_diversity.male],
            backgroundColor: CHART.COLOR.STAFF_DIVERSITY.MALE,
          },
          {
            label: "Female",
            data: [data.staff_diversity.female],
            backgroundColor: CHART.COLOR.STAFF_DIVERSITY.FEMALE,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
          position: "top",
          fontSize: 16,
          fontColor: "#111",
        },
        legend: {
          display: true,
          position: "left",
          labels: {
            fontColor: "#111",
            fontSize: 15,
            fontFamily: 'Montserrat',
            boxWidth: 10,
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true
              },
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: "#000",
            textAlign: "center",
            //offset: 1,
            font: {
              lineHeight: 1.6,
              size: 12,
              weight: 'bold',
              family: 'Montserrat'
            },
            formatter: function (value, ctx) {
              return value.toLocaleString();
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  barChartClass(data: WorkforceDiversityModel) {
    if (!data) {
      return;
    }

    if (this.classBarChart) {
      this.classBarChart.destroy();
    }

    const codes = data.diversity_by_class.map((diversity) => diversity.code);
    const classLabels = codes.map((code) => data.diversity_by_class.find((diversity) => diversity.code === code).class);
    const maleData = codes.map((code) => data.diversity_by_class.find((diversity) => diversity.code === code).male);
    const femaleData = codes.map((code) => data.diversity_by_class.find((diversity) => diversity.code === code).female);

    const canvas = document.getElementById(
      "diversityByClassChart"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d"); // Get the 2D context

    this.classBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: classLabels,
        datasets: [
          {
            label: "Male",
            data: maleData,
            backgroundColor: CHART.COLOR.DIVERSITY_BY_CLASS.MALE,
          },
          {
            label: "Female",
            data: femaleData,
            backgroundColor: CHART.COLOR.DIVERSITY_BY_CLASS.FEMALE,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
          text: "Diversity By Class",
          position: "top",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#111",
            fontFamily: 'Montserrat'
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: { beginAtZero: true },
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: "#000",
            textAlign: "center",
            //offset: 1,
            font: {
              lineHeight: 1.6,
              size: 12,
              weight: 'bold',
              family: 'Montserrat'
            },
            formatter: function (value, ctx) {
              return value.toLocaleString();
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  barChartDepartment(data: WorkforceDiversityModel) {
    if (!data) {
      return;
    }

    if (this.departmentsBarChart) {
      this.departmentsBarChart.destroy();
    }

    const codes = data.diversity_by_department.map((diversity) => diversity.code);
    const departmentLabels = codes.map((code) => data.diversity_by_department.find((diversity) => diversity.code === code).department);
    const maleData = codes.map((code) => data.diversity_by_department.find((diversity) => diversity.code === code).male);
    const femaleData = codes.map((code) => data.diversity_by_department.find((diversity) => diversity.code === code).female);

    const canvas = document.getElementById(
      "diversityByDeptChart"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d"); // Get the 2D context

    this.departmentsBarChart = new Chart(ctx, {
      type: "bar",

      data: {
        labels: departmentLabels,
        datasets: [
          {
            label: "Male",
            data: maleData,
            backgroundColor: CHART.COLOR.DIVERSITY_BY_DEPARTMENT.MALE,
          },
          {
            label: "Female",
            data: femaleData,
            backgroundColor: CHART.COLOR.DIVERSITY_BY_DEPARTMENT.FEMALE,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
          position: "top",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#111",
            fontFamily: 'Montserrat'
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: { beginAtZero: true },
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: "#000",
            textAlign: "center",
            //offset: 1,
            font: {
              lineHeight: 1.6,
              size: 12,
              weight: 'bold',
              family: 'Montserrat'
            },
            formatter: function (value, ctx) {
              return value.toLocaleString();
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { MARKER_DETAILS } from "src/app/shared/constants/map.constant";
import * as atlas from "azure-maps-control";
import { Map, AuthenticationType } from "azure-maps-control";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-container-management",
  templateUrl: "./container-management.component.html",
  styleUrls: ["./container-management.component.scss"],
})
export class ContainerManagementComponent implements OnInit {
  @ViewChild("map", { static: true }) mapElement: ElementRef;
  public markers: atlas.HtmlMarker[] = [];
  public zoom_multiport = 3;
  public viewmap_coor = MARKER_DETAILS.VIEW_MAP;

  constructor() {}

  ngOnInit(): void {
    this.azureMap();
  }

  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }
  onMouseOut(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.close();
  }
  azureMap() {
    const map = new atlas.Map(this.mapElement.nativeElement, {
      center: [6.33032428179620865, 44.461044888828326],
      zoom: 2,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      showFeedbackLink: false,
      showLogo: false,
      language: "en-GB",
    });

    var datasource;

    map.events.add("ready", function () {
      map.imageSprite
        .add(
          "arrow-icon",
          "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1717245/purpleArrowRight.png"
        )
        .then(function () {
          datasource = new atlas.source.DataSource();
          map.sources.add(datasource);

          datasource.add(
            new atlas.data.Feature(
              new atlas.data.LineString([
                [-0.33032428179620865, 39.461044888828326],
                [-0.3096730528069429, 39.46032393637407],
                [2.1493343673221545, 39.209532808644155],
                [2.1493343673221545, 39.209532808644155],
                [9.117407919422732, 38.276902563496094],
                [14.865315353344727, 38.71646780795004],
                [15.899225380093753, 38.44136065561622],
                [15.913883561838134, 38.47025488080891],
                [15.904991668841006, 38.45749224734905],
                [15.898418731016164, 38.44243799173765],
                [15.880216154521747, 38.44599736301794],
                [15.666465276697636, 38.25678731207273],
                [15.599068378270601, 38.227144984353544],
                [15.574839531042102, 38.0993192560899],
                [15.539363454412808, 38.00897663957022],
                [15.709969465243205, 37.781578316670135],
                [16.16644122241101, 37.770470530824184],
                [19.18527568247876, 39.729161142064726],
                [18.21099179912423, 41.059207017805846],
                [17.32524569357571, 41.719064787814176],
                [16.325675144741098, 42.46902388689432],
                [13.152705952862277, 44.87469152538105],
                [13.450537822690848, 45.55895628623364],
                [13.76000055, 45.63118956],
              ])
            )
          );
          map.layers.add([
            new atlas.layer.LineLayer(datasource, null, {
              strokeColor: "black",
              strokeWidth: 1,
            }),
          ]);
        });
    });

    const imageUrl = "assets/images/r-sts.png";
    const markerPositions = [{ position: [13.76000055, 45.63118956] }];

    markerPositions.forEach((markerPosition) => {
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];
      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrl}" alt="Marker">`,
      });
      const popup = new atlas.Popup({
        closeButton: false,
      });
      map.markers.add(marker);
      this.markers.push(marker);

      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content:
            '<div style="padding-left:8px;padding-right:8px;margin:10px; font-weight:bold; font-size: 13px"> Port of Trieste </div>',

          // content: '<div style="padding:10px"> Port of Trieste </div>',
          position: [latitude, longitude],
          pixelOffset: [0, -33],
        });
        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });
    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });

    const imageUrl1 = "assets/images/r-sts.png";
    const markerVal = [{ position: [-0.330327240750222, 39.461061198313445] }];

    markerVal.forEach((markerPosition) => {
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];
      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrl1}" alt="Marker">`,
      });
      const popup = new atlas.Popup({
        closeButton: false,
      });
      map.markers.add(marker);
      this.markers.push(marker);

      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content:
            '<div style="padding-left:8px;padding-right:8px;margin:10px; font-weight:bold; font-size: 13px"> Port of Valencia </div>',
          position: [latitude, longitude],
          pixelOffset: [0, -30],
        });
        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });

    const imageUrl3 = "assets/images/ship_red.svg";
    const markerCont = [{ position: [15.914000341854887, 38.47027322376644] }];

    markerCont.forEach((markerPosition) => {
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];
      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrl3}" alt="Marker">`,
      });
      const popup = new atlas.Popup({
        closeButton: false,
      });
      map.markers.add(marker);
      this.markers.push(marker);

      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content: (document.createElement("div").innerHTML = `
          <div style="font-family: Arial, sans-serif; font-size: 14px;margin:10px">
  
         <div>  <span style="font-weight:bold;font-size:13px">Container:</span> <span> "AC34566"</span> </div>
         <div>  <span style="font-weight:bold;font-size:13px">From:</span> <span> "Valencia"</span> </div>
         <div>  <span style="font-weight:bold;font-size:13px">To:</span> <span> "Gioia Tauro"</span> </div>
         <div>  <span style="font-weight:bold;font-size:13px">Date:</span> <span> "09/10/2022"</span> </div>
         <div>  <span style="font-weight:bold;font-size:13px">Days Left:</span> <span> "13"</span> </div>
        
        
    
       
      
        </div>
        `),
          position: [latitude, longitude],
          pixelOffset: [0, -10],
        });
        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });

    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as data from 'src/app/shared/mock-data/refresh-rate.json'


@Component({
  selector: 'app-refresh-rate-settings',
  templateUrl: './refresh-rate-settings.component.html',
  styleUrls: ['./refresh-rate-settings.component.scss']
})
export class RefreshRateSettingsComponent implements OnInit {

  value = 'Clear me';
  public refreshRate: any[] = [{
    "weather": [{ label: "Weather Refresh Rate", value: 5 }],
    "Dashboard": [{ category: "Dashboard", label: "Ship At Berth Rate", value: 5 },
    { label: "Berth Agregated Data Calc", value: 5 },
    { label: "Berth Quay Crane Agregated Data Calc", value: 5 },
    { label: "Equipment Overview", value: 5 },
    { label: "Equipment Latest Event", value: 5 }],
    "Overview": [{ label: "Equipment Current Telemetry Data", value: 5 },
    { label: "Equipment Aggregated Data Calc", value: 5 },
    { label: "Berth Quay Crane Loading Status", value: 5 },
    { label: "Equipment Aggregated Data Time Series", value: 5 }],
    "Equipment": [{ label: "Equipment Logs", value: 5 },
    { label: "Equipment Maintenance Alert", value: 5 },
    { label: "Equipment Over View KPI", value: 5 },
    { label: "Crane Operation Cycle Steps", value: 5 },
    { label: "Equipment Overview", value: 5 },
    { label: "Port Equipment Overview", value: 5 },
    { label: "Equipment Latest Event", value: 5 }],
  }];
  tabLoadTimes: Date[] = [];


  constructor(private http: HttpClient) { }



  ngOnInit() {

    for (let val of this.refreshRate) {
      val.Dashboard.map((item) => {
        console.log(item);
      });
    }

    // For Weather API
    this.setWeatherMilliseconds();

    // For Dashboard
    this.setShipAtBerthMilliseconds();
    this.setBerthAgregatedDataCalcMilliseconds();
    this.setBerthQuayCraneAgregatedDataCalcMilliseconds();
    this.setEquipmentOverviewMilliseconds();
    this.setEquipmentLatestEventMilliseconds();

    // For Overview Dashboard
    this.setEquipmentCurrentTelemetryDataMilliseconds();
    this.setEquipmentAggregatedDataCalcMilliseconds();
    this.setBerthQuayCraneLoadingStatusMilliseconds();
    this.setEquipmentAggregatedDataTimeSeriesMilliseconds();

    // For Equipment Overview
    this.setEquipmentLogsMilliseconds();
    this.setEquipmentMaintenanceAlertMilliseconds();
    this.setEquipmentOverViewKPIMilliseconds();
    this.setCraneOperationCycleStepsMilliseconds();
    this.setEquipmentOverviewEquipmentMilliseconds();
    this.setPortEquipmentOverviewMilliseconds();
    this.setEquipmentLatestEventEquipmentMilliseconds();

  }

  getTimeLoaded(index: number) {
    if (!this.tabLoadTimes[index]) {
      this.tabLoadTimes[index] = new Date();
    }

    return this.tabLoadTimes[index];
  }
  // For Weather API
  weather: number;
  weatherMillisec: number;

  // For Dash Board API
  setShipAtBerthinterval: number;
  setShipAtBerthmilliseconds: number;

  BerthAgregatedDataCalcinterval: number;
  BerthAgregatedDataCalcintervalmilliseconds: number;

  BerthQuayCraneAgregatedDataCalc: number;
  BerthQuayCraneAgregatedDataCalcmilliseconds: number;

  EquipmentOverview: number;
  EquipmentOverviewmilliseconds: number;

  EquipmentLatestEvent: number;
  EquipmentLatestEventmilliseconds: number;


  EquipmentCurrentTelemetryData: number;
  EquipmentCurrentTelemetryDatamiliseconds: number;

  EquipmentAggregatedDataCalc: number;
  EquipmentAggregatedDataCalcmiliseconds: number;

  BerthQuayCraneLoadingStatus: number;
  BerthQuayCraneLoadingStatusmiliseconds: number;

  EquipmentAggregatedDataTimeSeries: number;
  EquipmentAggregatedDataTimeSeriesmiliseconds: number;


  // Equipment Overview
  EquipmentLogs: number;
  EquipmentLogsmilliseconds: number;

  EquipmentMaintenanceAlert: number;
  EquipmentMaintenanceAlertmilliseconds: number;

  EquipmentOverViewKPI: number;
  EquipmentOverViewKPImilliseconds: number;

  CraneOperationCycleSteps: number;
  CraneOperationCycleStepsmilliseconds: number;

  EquipmentOverviewEquipment: number;
  EquipmentOverviewEquipmentmilliseconds: number;

  PortEquipmentOverview: number;
  PortEquipmentOverviewmilliseconds: number;

  EquipmentLatestEventEquipment: number;
  EquipmentLatestEventEquipmentmilliseconds: number;

  test: any;









  // For Weather
  setWeatherMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {

    if (environment.weather === 10000) {
      temp = data.RestApiResponse;
      this.weather = temp.weather / 1000;
      environment.weather = this.weather * 1000;
    } else {
      if (isNaN(this.weather)) {
        this.weather = environment.weather / 1000;
      } else {
        environment.weather = this.weather * 1000;
      }
    }
    this.weatherMillisec = environment.weather;
    // });

  }


  // For Dashboard
  setShipAtBerthMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.ShipAtBerthinterval === 10000) {
      temp = data.RestApiResponse;
      // temp = response;
      this.setShipAtBerthinterval = temp.ShipAtBerthinterval / 1000;
      // console.log(temp.ShipAtBerthinterval);
      environment.ShipAtBerthinterval = this.setShipAtBerthinterval * 1000;
    } else {
      if (isNaN(this.setShipAtBerthinterval)) {
        this.setShipAtBerthinterval = environment.ShipAtBerthinterval / 1000;
      } else {
        environment.ShipAtBerthinterval = this.setShipAtBerthinterval * 1000;
      }
    }
    this.setShipAtBerthmilliseconds = environment.ShipAtBerthinterval;
    // });

  }




  setBerthAgregatedDataCalcMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.BerthAgregatedDataCalc === 10000) {
      temp = data.RestApiResponse;
      this.BerthAgregatedDataCalcinterval = temp.BerthAgregatedDataCalc / 1000;
      // console.log(temp.BerthAgregatedDataCalc);
      environment.BerthAgregatedDataCalc = this.BerthAgregatedDataCalcinterval * 1000;
    } else {
      if (isNaN(this.BerthAgregatedDataCalcinterval)) {
        this.BerthAgregatedDataCalcinterval = environment.BerthAgregatedDataCalc / 1000;
      } else {
        environment.BerthAgregatedDataCalc = this.BerthAgregatedDataCalcinterval * 1000;
      }
    }

    this.BerthAgregatedDataCalcintervalmilliseconds = environment.BerthAgregatedDataCalc;
    // });
  }





  setBerthQuayCraneAgregatedDataCalcMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.BerthQuayCraneAgregatedDataCalc === 10000) {
      temp = data.RestApiResponse;
      this.BerthQuayCraneAgregatedDataCalc = temp.BerthQuayCraneAgregatedDataCalc / 1000;
      // console.log(temp.BerthQuayCraneAgregatedDataCalc);
      environment.BerthQuayCraneAgregatedDataCalc = this.BerthQuayCraneAgregatedDataCalc * 1000;
    } else {
      if (isNaN(this.BerthQuayCraneAgregatedDataCalc)) {
        this.BerthQuayCraneAgregatedDataCalc = environment.BerthQuayCraneAgregatedDataCalc / 1000;
      } else {
        environment.BerthQuayCraneAgregatedDataCalc = this.BerthQuayCraneAgregatedDataCalc * 1000;
      }
    }
    this.BerthQuayCraneAgregatedDataCalcmilliseconds = environment.BerthQuayCraneAgregatedDataCalc;
    // });
  }




  setEquipmentOverviewMilliseconds(): void {


    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentOverview === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentOverview = temp.EquipmentOverview / 1000;
      // console.log(temp.EquipmentOverview);
      environment.EquipmentOverview = this.EquipmentOverview * 1000;

    } else {
      if (isNaN(this.EquipmentOverview)) {
        this.EquipmentOverview = environment.EquipmentOverview / 1000;
      } else {
        environment.EquipmentOverview = this.EquipmentOverview * 1000;
      }
    }

    this.EquipmentOverviewmilliseconds = environment.EquipmentOverview;
    // });
  }




  setEquipmentLatestEventMilliseconds(): void {


    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentLatestEvent === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentLatestEvent = temp.EquipmentLatestEvent / 1000;
      // console.log(temp.EquipmentLatestEvent);
      environment.EquipmentLatestEvent = this.EquipmentLatestEvent * 1000;
    } else {
      if (isNaN(this.EquipmentLatestEvent)) {
        this.EquipmentLatestEvent = environment.EquipmentLatestEvent / 1000;
      } else {
        environment.EquipmentLatestEvent = this.EquipmentLatestEvent * 1000;
      }
    }

    this.EquipmentLatestEventmilliseconds = environment.EquipmentLatestEvent;
    // });
  }



  // For Overview Dashboard
  setEquipmentCurrentTelemetryDataMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentCurrentTelemetryData === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentCurrentTelemetryData = temp.EquipmentCurrentTelemetryData / 1000;
      // console.log(temp.EquipmentCurrentTelemetryData);

      environment.EquipmentCurrentTelemetryData = this.EquipmentCurrentTelemetryData * 1000;

    } else {
      if (isNaN(this.EquipmentCurrentTelemetryData)) {
        this.EquipmentCurrentTelemetryData = environment.EquipmentCurrentTelemetryData / 1000;
      } else {
        environment.EquipmentCurrentTelemetryData = this.EquipmentCurrentTelemetryData * 1000;
      }
    }

    this.EquipmentCurrentTelemetryDatamiliseconds = environment.EquipmentCurrentTelemetryData;
    // });
  }




  setEquipmentAggregatedDataCalcMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentAggregatedDataCalc === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentAggregatedDataCalc = temp.EquipmentAggregatedDataCalc / 1000;
      // console.log(temp.EquipmentAggregatedDataCalc);
      environment.EquipmentAggregatedDataCalc = this.EquipmentAggregatedDataCalc * 1000;

    } else {
      if (isNaN(this.EquipmentAggregatedDataCalc)) {
        this.EquipmentAggregatedDataCalc = environment.EquipmentAggregatedDataCalc / 1000;
      } else {
        environment.EquipmentAggregatedDataCalc = this.EquipmentAggregatedDataCalc * 1000;
      }
    }

    this.EquipmentAggregatedDataCalcmiliseconds = environment.EquipmentAggregatedDataCalc;
    // });
  }



  setBerthQuayCraneLoadingStatusMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.BerthQuayCraneLoadingStatus === 10000) {
      temp = data.RestApiResponse;
      this.BerthQuayCraneLoadingStatus = temp.BerthQuayCraneLoadingStatus / 1000;
      // console.log(temp.BerthQuayCraneLoadingStatus);
      environment.BerthQuayCraneLoadingStatus = this.BerthQuayCraneLoadingStatus * 1000;
    } else {
      if (isNaN(this.BerthQuayCraneLoadingStatus)) {
        this.BerthQuayCraneLoadingStatus = environment.BerthQuayCraneLoadingStatus / 1000;
      } else {
        environment.BerthQuayCraneLoadingStatus = this.BerthQuayCraneLoadingStatus * 1000;
      }
    }
    this.BerthQuayCraneLoadingStatusmiliseconds = environment.BerthQuayCraneLoadingStatus;
    // });
  }



  setEquipmentAggregatedDataTimeSeriesMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentAggregatedDataTimeSeries === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentAggregatedDataTimeSeries = temp.EquipmentAggregatedDataTimeSeries / 1000;
      // console.log(temp.EquipmentAggregatedDataTimeSeries);
      environment.EquipmentAggregatedDataTimeSeries = this.EquipmentAggregatedDataTimeSeries * 1000;
    } else {
      if (isNaN(this.EquipmentAggregatedDataTimeSeries)) {
        this.EquipmentAggregatedDataTimeSeries = environment.EquipmentAggregatedDataTimeSeries / 1000;
      } else {
        environment.EquipmentAggregatedDataTimeSeries = this.EquipmentAggregatedDataTimeSeries * 1000;
      }
    }
    this.EquipmentAggregatedDataTimeSeriesmiliseconds = environment.EquipmentAggregatedDataTimeSeries;
    // });
  }


  // Equipment Overview
  setEquipmentLogsMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentLogs === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentLogs = temp.EquipmentLogs / 1000;
      // console.log(temp.EquipmentLogs);
      environment.EquipmentLogs = this.EquipmentLogs * 1000;
    } else {
      if (isNaN(this.EquipmentLogs)) {
        this.EquipmentLogs = environment.EquipmentLogs / 1000;
      } else {
        environment.EquipmentLogs = this.EquipmentLogs * 1000;
      }
    }
    this.EquipmentLogsmilliseconds = environment.EquipmentLogs;
    // });
  }



  setEquipmentMaintenanceAlertMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentMaintenanceAlert === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentMaintenanceAlert = temp.EquipmentMaintenanceAlert / 1000;
      // console.log(temp.EquipmentMaintenanceAlert);
      environment.EquipmentMaintenanceAlert = this.EquipmentMaintenanceAlert * 1000;
    } else {
      if (isNaN(this.EquipmentMaintenanceAlert)) {
        this.EquipmentMaintenanceAlert = environment.EquipmentMaintenanceAlert / 1000;
      } else {
        environment.EquipmentMaintenanceAlert = this.EquipmentMaintenanceAlert * 1000;
      }
    }
    this.EquipmentMaintenanceAlertmilliseconds = environment.EquipmentMaintenanceAlert;
    // });
  }



  setEquipmentOverViewKPIMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentOverViewKPI === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentOverViewKPI = temp.EquipmentOverViewKPI / 1000;
      // console.log(temp.EquipmentOverViewKPI);
      environment.EquipmentOverViewKPI = this.EquipmentOverViewKPI * 1000;
    } else {
      if (isNaN(this.EquipmentOverViewKPI)) {
        this.EquipmentOverViewKPI = environment.EquipmentOverViewKPI / 1000;
      } else {
        environment.EquipmentOverViewKPI = this.EquipmentOverViewKPI * 1000;
      }
    }
    this.EquipmentOverViewKPImilliseconds = environment.EquipmentOverViewKPI;
    // });
  }


  setCraneOperationCycleStepsMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.CraneOperationCycleSteps === 10000) {
      temp = data.RestApiResponse;
      this.CraneOperationCycleSteps = temp.CraneOperationCycleSteps / 1000;
      // console.log(temp.CraneOperationCycleSteps);
      environment.CraneOperationCycleSteps = this.CraneOperationCycleSteps * 1000;
    } else {
      if (isNaN(this.CraneOperationCycleSteps)) {
        this.CraneOperationCycleSteps = environment.CraneOperationCycleSteps / 1000;
      } else {
        environment.CraneOperationCycleSteps = this.CraneOperationCycleSteps * 1000;
      }
    }
    this.CraneOperationCycleStepsmilliseconds = environment.CraneOperationCycleSteps;
    // });
  }


  setEquipmentOverviewEquipmentMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentOverviewEquipment === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentOverviewEquipment = temp.EquipmentOverviewEquipment / 1000;
      // console.log(temp.EquipmentOverviewEquipment);
      environment.EquipmentOverviewEquipment = this.EquipmentOverviewEquipment * 1000;
    } else {
      if (isNaN(this.EquipmentOverviewEquipment)) {
        this.EquipmentOverviewEquipment = environment.EquipmentOverviewEquipment / 1000;
      } else {
        environment.EquipmentOverviewEquipment = this.EquipmentOverviewEquipment * 1000;
      }
    }
    this.EquipmentOverviewEquipmentmilliseconds = environment.EquipmentOverviewEquipment;
    // });
  }


  setPortEquipmentOverviewMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.PortEquipmentOverview === 10000) {
      temp = data.RestApiResponse;
      this.PortEquipmentOverview = temp.PortEquipmentOverview / 1000;
      // console.log(temp.PortEquipmentOverview);
      environment.PortEquipmentOverview = this.PortEquipmentOverview * 1000;
    } else {
      if (isNaN(this.PortEquipmentOverview)) {
        this.PortEquipmentOverview = environment.PortEquipmentOverview / 1000;
      } else {
        environment.PortEquipmentOverview = this.PortEquipmentOverview * 1000;
      }
    }
    this.PortEquipmentOverviewmilliseconds = environment.PortEquipmentOverview;
    // });
  }


  setEquipmentLatestEventEquipmentMilliseconds(): void {

    let temp: any;
    // this.rest.getJSON().subscribe((response: {}) => {
    if (environment.EquipmentLatestEventEquipment === 10000) {
      temp = data.RestApiResponse;
      this.EquipmentLatestEventEquipment = temp.EquipmentLatestEventEquipment / 1000;
      // console.log(temp.EquipmentLatestEventEquipment);
      environment.EquipmentLatestEventEquipment = this.EquipmentLatestEventEquipment * 1000;
    } else {
      if (isNaN(this.EquipmentLatestEventEquipment)) {
        this.EquipmentLatestEventEquipment = environment.EquipmentLatestEventEquipment / 1000;
      } else {
        environment.EquipmentLatestEventEquipment = this.EquipmentLatestEventEquipment * 1000;
      }
    }

    this.EquipmentLatestEventEquipmentmilliseconds = environment.EquipmentLatestEventEquipment;
    // });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-nav',
  templateUrl: './energy-nav.component.html',
  styleUrls: ['./energy-nav.component.scss']
})
export class EnergyNavComponent implements OnInit {

  public activeButton: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.checkActivatedRoute();
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1]

    switch (activePath.toLocaleLowerCase()) {
      case 'idle-time-tracker':
        this.activeButton = 2;
        break;
      case 'energy-source':
        this.activeButton = 3;
        break;
      case 'energy-equipment':
        this.activeButton = 4;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }
}

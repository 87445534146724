import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { SustainabilityService } from "../../services/sustainability.service";
import { BiodiversityService } from "../../services/biodiversity.service";
import { 
  getCarbonCreditTradingSequestration, 
  getCarbonCreditTradingSequestrationFailure, 
  getCarbonCreditTradingSequestrationSuccess, 
  getCarbonEmissionReduceSummary, 
  getCarbonEmissionReduceSummaryFailure, 
  getCarbonEmissionReduceSummarySuccess, 
  getCarbonMngmtAndEmissionReduction, 
  getCarbonMngmtAndEmissionReductionFailure, 
  getCarbonMngmtAndEmissionReductionSuccess, 
  getEmissionFactors, 
  getEmissionFactorsFailure, 
  getEmissionFactorsSuccess, 
  getOverallCarbonIntensity, 
  getOverallCarbonIntensityFailure, 
  getOverallCarbonIntensitySuccess, 
  getPortElectrificationInsight, 
  getPortElectrificationInsightFailure, 
  getPortElectrificationInsightSuccess, 
  getPortElectrificationOverview, 
  getPortElectrificationOverviewFailure, 
  getPortElectrificationOverviewSuccess, 
   getRenewableEnergyGraph, 
  getRenewableEnergyGraphFailure, 
  getRenewableEnergyGraphSuccess, 
  getRenewableEnergyShare, 
  getRenewableEnergyShareFailure, 
  getRenewableEnergyShareSuccess } from "./sustainability.actions";

@Injectable()
export class SustainabilityEffects {
  constructor(
    private actions$: Actions,
    private sustainabilityService: SustainabilityService
  ) { }

  getCarbonMngmtEmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCarbonMngmtAndEmissionReduction),
      switchMap((action) =>
        this.sustainabilityService.getCarbonMngmtAndEmissionReduction(action.portsId ? action.portsId : null).pipe(
          map((response) => response.data),
          map((data) => getCarbonMngmtAndEmissionReductionSuccess({ carbonMngmtEmission: data })),
          catchError((error) => of(getCarbonMngmtAndEmissionReductionFailure({ error })))
        )
      )
    )
  );

  getOverallCarbonIntensity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOverallCarbonIntensity),
      switchMap((action) =>
        this.sustainabilityService.getOverallCarbonIntensity(action.years ? action.years : null).pipe(
          map((response) => response.data),
          map((data) => getOverallCarbonIntensitySuccess({ overallCarbonIntensity: data })),
          catchError((error) => of(getOverallCarbonIntensityFailure({ error })))
        )
      )
    )
  );

  getCarbonEmissionSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCarbonEmissionReduceSummary),
      switchMap((action) =>
        this.sustainabilityService.getCarbonReducedEmissionSummary(action.years ? action.years : null).pipe(
          map((response) => response.data),
          map((data) => getCarbonEmissionReduceSummarySuccess({ carbonEmissionSummary: data })),
          catchError((error) => of(getCarbonEmissionReduceSummaryFailure({ error })))
        )
      )
    )
  );


  getPortElectrificationOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortElectrificationOverview),
      switchMap((action) =>
        this.sustainabilityService.getPortElectrificationOverview(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getPortElectrificationOverviewSuccess({ portElectrificationOverview: data })),
          catchError((error) => of(getPortElectrificationOverviewFailure({ error })))
        )
      )
    )
  );

  getPortElectrificationInsight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortElectrificationInsight),
      switchMap((action) =>
        this.sustainabilityService.getPortElectrificationInsight(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getPortElectrificationInsightSuccess({ portElectrificationInsight: data })),
          catchError((error) => of(getPortElectrificationInsightFailure({ error })))
        )
      )
    )
  );

  getCarbonCreditTradingSequestration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCarbonCreditTradingSequestration),
      switchMap((action) =>
        this.sustainabilityService.getCarbonCreditTradingSequestration(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getCarbonCreditTradingSequestrationSuccess({ carbonCreditTradingSequestration: data })),
          catchError((error) => of(getCarbonCreditTradingSequestrationFailure({ error })))
        )
      )
    )
  );

  getEmissionFactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmissionFactors),
      switchMap(() =>
        this.sustainabilityService.getEmissionFactors().pipe(
          map((response) => response.data.factors),
          map((data) => getEmissionFactorsSuccess({ emissionFactors: data })),
          catchError((error) => of(getEmissionFactorsFailure({ error })))
        )
      )
    )
  );

  getRenewableEnergyShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRenewableEnergyShare),
      switchMap((action) =>
        this.sustainabilityService.getRenewableEnergyShare(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getRenewableEnergyShareSuccess({ renewableEnergyShare: data })),
          catchError((error) => of(getRenewableEnergyShareFailure({ error })))
        )
      )
    )
  );

  getRenewableEnergyGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRenewableEnergyGraph),
      switchMap((action) =>
        this.sustainabilityService.getRenewableEnergyGraph(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getRenewableEnergyGraphSuccess({ renewableEnergyGraph: data })),
          catchError((error) => of(getRenewableEnergyGraphFailure({ error })))
        )
      )
    )
  );




}
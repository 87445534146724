import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { ICON } from "src/app/shared/constants/assets.constant";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  getQuarterlyShare,
  getTreePlantingMetrics,
} from "src/app/shared/state/biodiversity/biodiversity.actions";
import {
  selectQuarterlyShare,
  selectTreePlantingMetrics,
} from "src/app/shared/state/biodiversity/biodiversity.selectors";
import { QuarterlyPlantShareModel } from "src/app/shared/models/quarterly-share.model";
import { TotalPlantingTreesModel } from "src/app/shared/models/total-planted-trees.model";
import { Observable } from "rxjs";
import { CHART } from "src/app/shared/constants/chart.constant";

@Component({
  selector: "app-greener-environment",
  templateUrl: "./greener-environment.component.html",
  styleUrls: ["./greener-environment.component.scss"],
})
export class GreenerEnvironmentComponent implements OnInit {
  @Input() selectedYear: string;

  treePlantingMetric$: Observable<TotalPlantingTreesModel> = this.store.select(selectTreePlantingMetrics);
  quarterlyShare$: Observable<QuarterlyPlantShareModel> = this.store.select(selectQuarterlyShare);
  private plantedTreesLocationPieChart: Chart;
  private quarterlyShareBarChart: Chart;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getQuarterlyShare({}));
    this.store.dispatch(getTreePlantingMetrics({}));
  }

  ngAfterViewInit(): void {
    this.quarterlyShare$.subscribe((quarterlyShare) => {
      if (quarterlyShare) {
        this.pieChart(quarterlyShare);
        this.barChart(quarterlyShare);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getQuarterlyShare({ year: this.selectedYear }));
        this.store.dispatch(
          getTreePlantingMetrics({ year: this.selectedYear })
        );
      } else {
        this.store.dispatch(getQuarterlyShare({}));
        this.store.dispatch(getTreePlantingMetrics({}));
      }
    }
  }

  pieChart(quarterlyShare: QuarterlyPlantShareModel) {
    if (this.plantedTreesLocationPieChart) {
      this.plantedTreesLocationPieChart.destroy();
    }

    const plantedTreesLocation = quarterlyShare.planted_trees_location;
    const codes = plantedTreesLocation.map((location) => location.code);
    const bgColors = codes.map((code) => CHART.COLOR.PLANTED_TREES_LOCATION[code]);
    const quarterLocations = codes.map((code) => plantedTreesLocation.find((location) => location.code === code).location);
    const quarterValues = codes.map((code) => plantedTreesLocation.find((location) => location.code === code).percentage);

    const canvas = document.getElementById(
      "treesLocationChart"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    this.plantedTreesLocationPieChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: quarterLocations,
        datasets: [
          {
            data: quarterValues,
            backgroundColor: bgColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        title: {
          display: false,
          text: "Planted Trees Location",
          position: "top",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 10,
            padding: 15,
            fontColor: "#111",
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: "#fff",
            textAlign: "center",
            font: {
              lineHeight: 1.6,
              size: 14
            },
            formatter: function (value, ctx) {
              return ctx.chart.data.labels[ctx.dataIndex] + "\n" + value + "%";
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  barChart(quarterlyShare: QuarterlyPlantShareModel) {
    if (this.quarterlyShareBarChart) {
      this.quarterlyShareBarChart.destroy();
    }

    const quarterLabels = quarterlyShare.quarterly.map((q) => q.quarter);
    quarterLabels.sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));

    const urbanData = quarterLabels.map(
      (label) => quarterlyShare.quarterly.find(
        (quarter) => quarter.quarter === label).properties.find(
          (location) => location.code === CHART.CODES.PLANTED_TREES_LOCATION.URBAN
        ).value
    );
    const ruralData = quarterLabels.map(
      (label) => quarterlyShare.quarterly.find(
        (quarter) => quarter.quarter === label).properties.find(
          (location) => location.code === CHART.CODES.PLANTED_TREES_LOCATION.RURAL
        ).value
    );
    const forestData = quarterLabels.map(
      (label) => quarterlyShare.quarterly.find(
        (quarter) => quarter.quarter === label).properties.find(
          (location) => location.code === CHART.CODES.PLANTED_TREES_LOCATION.FOREST
        ).value
    );

    const canvas = document.getElementById(
      "treesLocationQuarterly"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    this.quarterlyShareBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: quarterLabels,
        datasets: [
          {
            label: "Forest",
            data: forestData,
            backgroundColor: CHART.COLOR.PLANTED_TREES_LOCATION.FRS,
          },
          {
            label: "Rural",
            data: ruralData,
            backgroundColor: CHART.COLOR.PLANTED_TREES_LOCATION.RRL,
          },
          {
            label: "Urban",
            data: urbanData,
            backgroundColor: CHART.COLOR.PLANTED_TREES_LOCATION.UBN,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
          text: "Planted Trees Location Quarterly Share",
          position: "top",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#111",
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: "#000",
            textAlign: "center",

            font: {
              lineHeight: 1.6,
              size: 10,
            },
            formatter: function (value, ctx) {
              return value.toLocaleString();
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  getAbsoluteValue(percent: number): string {
    return Math.abs(percent).toFixed(2);
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

import { createAction, props } from "@ngrx/store";
import { ErrorResponse } from "../../models/error-response.model";
import { BiodiversityModel } from "../../models/biodiversity.model";
import { QuarterlyPlantShareModel } from "../../models/quarterly-share.model";
import { ProtectionWatersModel } from "../../models/protection-waters.model";
import { CommunityEducationModel } from "../../models/community-education.model";
import { TotalPlantingTreesModel } from "../../models/total-planted-trees.model";


export const getBiodiversity = createAction(
  '[Sustainability Dashboard Page] Get Biodiversity',
  props<{ portsId?: string }>()
);

export const getBiodiversitySuccess = createAction(
  '[Sustainability Dashboard Page] Get Biodiversity Success',
  props<{ biodiversity: BiodiversityModel }>()
);

export const getBiodiversityFailure = createAction(
  '[Sustainability Dashboard Page] Get Biodiversity Failure',
  props<{ error: ErrorResponse }>()
);

export const getQuarterlyShare = createAction(
  '[Biodiversity Page] Get Quarterly Share',
  props<{ year?: string }>()
);

export const getQuarterlyShareSuccess = createAction(
  '[Biodiversity Page] Get Quarterly Share Successful',
  props<{ quarterlyShare: QuarterlyPlantShareModel }>()
);

export const getQuarterlyShareFailure = createAction(
  '[Biodiversity Page] Get Quarterly Share Failed',
  props<{ error: ErrorResponse }>()
);

export const getTreePlantingMetrics = createAction(
  '[Biodiversity Page] Get Tree Planting Metrics',
  props<{ year?: string }>()
);

export const getTreePlantingMetricsSuccess = createAction(
  '[Biodiversity Page] Get Tree Planting MetricsSuccessful',
  props<{ TreePlantingMetrics: TotalPlantingTreesModel }>()
);

export const getTreePlantingMetricsFailure = createAction(
  '[Biodiversity Page] Get Tree Planting Metrics Failed',
  props<{ error: ErrorResponse }>()
);

export const getProtectionWaters = createAction(
  '[Biodiversity Page] Get Protection in Waters',
  props<{ year?: string }>()
);

export const getProtectionWatersSuccess = createAction(
  '[Biodiversity Page] Get Protection in Waters Successful',
  props<{ protectionWaters: ProtectionWatersModel }>()
);

export const getProtectionWatersFailure = createAction(
  '[Biodiversity Page] Get Protection in Waters Failed',
  props<{ error: ErrorResponse }>()
);
export const getCommunityEducation = createAction(
  '[Biodiversity Page] Get Community Education',
  props<{ year?: string }>()
);

export const getCommunityEducationSuccess = createAction(
  '[Biodiversity Page] Get Community Education Successful',
  props<{ communityEducation: CommunityEducationModel }>()
);

export const getCommunityEducationFailure = createAction(
  '[Biodiversity Page] Get Community Education Failed',
  props<{ error: ErrorResponse }>()
);

import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import { Observable } from 'rxjs';
import { ICON } from 'src/app/shared/constants/assets.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import {
  PortElectrificationOverviewModel,
  EnergySourceModel,
  PortElectrificationInsightModel,
  CarbonCreditTradingSequestrationModel
} from 'src/app/shared/models';
import { getCarbonCreditTradingSequestration, getPortElectrificationInsight, getPortElectrificationOverview } from 'src/app/shared/state/sustainability/sustainability.actions';
import { selectCarbonCreditTradingSequestration, selectPortElectrificationInsight, selectPortElectrificationOverview } from 'src/app/shared/state/sustainability/sustainability.selectors';

@Component({
  selector: 'app-port-equipment-electrification',
  templateUrl: './port-equipment-electrification.component.html',
  styleUrls: ['./port-equipment-electrification.component.scss']
})
export class PortEquipmentElectrificationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  portElectrificationOverview$: Observable<PortElectrificationOverviewModel> = this.store.select(selectPortElectrificationOverview);
  portElectrificationInsight$: Observable<PortElectrificationInsightModel> = this.store.select(selectPortElectrificationInsight);
  carbonCreditTradingSequestration$: Observable<CarbonCreditTradingSequestrationModel> = this.store.select(selectCarbonCreditTradingSequestration);
  private portShareBarChart: Chart;
  fuel: EnergySourceModel;
  hybrid: EnergySourceModel;
  electric: EnergySourceModel;

  constructor(private store: Store, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.store.dispatch(getPortElectrificationOverview({}));
    this.store.dispatch(getPortElectrificationInsight({}));
    this.store.dispatch(getCarbonCreditTradingSequestration({}));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.portElectrificationOverview$.subscribe((portElectrificationOverview) => {
        if (portElectrificationOverview) {
          this.initializePortOverview(portElectrificationOverview);
        }
      });
      this.portElectrificationInsight$.subscribe((portElectrificationInsight) => {
        if (portElectrificationInsight) {
          this.initializePortShareChart(portElectrificationInsight);
        }
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getPortElectrificationOverview({ year: this.selectedYear }));
        this.store.dispatch(getPortElectrificationInsight({ year: this.selectedYear }));
        this.store.dispatch(getCarbonCreditTradingSequestration({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getPortElectrificationInsight({}));
        this.store.dispatch(getPortElectrificationInsight({}));
        this.store.dispatch(getCarbonCreditTradingSequestration({}));
      }
    }
  }

  initializePortOverview(data: PortElectrificationOverviewModel) {
    this.fuel = data.energy_sources.find((source) => source.source_name === CHART.CODES.PORT_ELECTRIFICATION.FUEL);
    this.hybrid = data.energy_sources.find((source) => source.source_name === CHART.CODES.PORT_ELECTRIFICATION.HYBRID);
    this.electric = data.energy_sources.find((source) => source.source_name === CHART.CODES.PORT_ELECTRIFICATION.ELECTRIC);
  }

  initializePortShareChart(data: PortElectrificationInsightModel) {
    const quartersLabel = Object.keys(data).sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));
    const rawData = quartersLabel.map((quarter) => ({
      fuel: data[quarter].energy_source.find((energy) => energy.source_name == CHART.CODES.PORT_ELECTRIFICATION.FUEL).value,
      hybrid: data[quarter].energy_source.find((energy) => energy.source_name == CHART.CODES.PORT_ELECTRIFICATION.HYBRID).value,
      electric: data[quarter].energy_source.find((energy) => energy.source_name == CHART.CODES.PORT_ELECTRIFICATION.ELECTRIC).value
    }));

    const quartersData = quartersLabel.map((year) =>
      this.selectedYear == 'Select Year'
        ? `${year}-2024`
        : `${year}-${this.selectedYear}`);
    const fuelData = rawData.map((q) => q.fuel);
    const hybridData = rawData.map((q) => q.hybrid);
    const electricData = rawData.map((q) => q.electric);

    this.createBarChart(quartersData, fuelData, hybridData, electricData);
  }

  createBarChart(quartersData: string[], fuelData: number[], hybridData: number[], electricData: number[]) {
    if (this.portShareBarChart) {
      this.portShareBarChart.destroy();
    }

    const canvas = this.elementRef.nativeElement.querySelector('#portShareBarChart');
    if (!canvas) {
      console.error('Canvas element with id "portShareBarChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const bgColorPlugin = {
      id: 'bgColorPlugin',
      afterDraw: function (chartInstance: Chart) {
        const { ctx, chartArea } = chartInstance;
        const quarterWidth = chartArea.right / 4;

        for (let i = 0; i < 4; i++) {
          ctx.fillStyle = CHART.COLOR.PORT_ELECTRIFICATION.LIGHT_GRAY;
          ctx.fillRect(chartArea.left + i * quarterWidth, chartArea.top, quarterWidth - 10, chartArea.bottom - chartArea.top);
        }
      }
    }

    this.portShareBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: quartersData,
        datasets: [{
          label: 'Fuel',
          data: fuelData,
          backgroundColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_GRAY,
          borderColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_GRAY,
          borderWidth: 1,
          categoryPercentage: 0.60,
          order: 1,
        },
        {
          label: 'Hybrid',
          data: hybridData,
          backgroundColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_BLUE,
          borderColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_BLUE,
          borderWidth: 1,
          categoryPercentage: 0.60,
          order: 2,
        },
        {
          label: 'Electric',
          data: electricData,
          backgroundColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_GREEN,
          borderColor: CHART.COLOR.PORT_ELECTRIFICATION.DARK_GREEN,
          borderWidth: 1,
          categoryPercentage: 0.60,
          order: 3,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 12,
          bodyFontSize: 12,
          caretPadding: 20,
          yPadding: 10
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              display: false,
              max: 200,
            }
          }]
        },
        legend: {
          display: false,
        },
        plugins: {
          labels: {
            render: 'value',
            fontSize: 12,
            fontFamily: 'Montserrat'
          }
        },
      },
      plugins: [bgColorPlugin]
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP
  }

  numColor(percent: number): string {
    return percent < 0 ? 'percent-red' : 'percent-green';
  }
}

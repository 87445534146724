import { Component, OnInit } from '@angular/core';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { EquipmentDetailsModel } from 'src/app/shared/models/equipment-details.model';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAlertsModel } from 'src/app/shared/models';
import { WorkOrderComponent } from '../components/work-order/work-order.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectEquipmentDetails } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentDetails } from 'src/app/shared/state/operations/operations.action';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent implements OnInit {
  equipmentDetails$: Observable<EquipmentDetailsModel> = this.store.select(selectEquipmentDetails);

  activeButton: number = 1;
  equipmentType: string;
  equipmentName: string;
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  private alert: EquipmentAlertsModel = {} as EquipmentAlertsModel;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.equipmentType = params.get('equipmentType');
      this.equipmentName = params.get('equipmentName');
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getEquipmentDetails({ equipmentId: this.equipmentName }));
    this.checkActivatedRoute();
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1]

    switch (activePath.toLocaleLowerCase()) {
      case 'performance':
        this.activeButton = 1;
        break;
      case 'maintenance':
        this.activeButton = 2;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }

  popUpAlert() {
    this.alert.equipment_name = this.equipmentName;
    this.alert.equipment_type = this.equipmentType;

    const dialogRef = this.dialog.open(WorkOrderComponent, {
      data: { ...this.alert }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log(result);
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consump-cards',
  templateUrl: './consump-cards.component.html',
  styleUrls: ['./consump-cards.component.scss']
})
export class ConsumpCardsComponent implements OnInit {

  constructor() { }

  public dataConsumption: any[] = [
    { usage: 0.08, label: "Current Usage", sublabel: "value updates every minute", image: "assets/icons/current-usage.png" },
    { usage: 457.964, label: "Daily Consumption", sublabel: "value from previous day", image: "assets/icons/daily-consumption.png" },
    { usage: 3133.363, label: "Weekly Consumption", sublabel: "value from previous week", image: "assets/icons/weekly-consumption.png" },
    { usage: 0, label: "Monthly Consumption", sublabel: "value from previous month", image: "assets/icons/monthly-consumption.png" },
  ];

  ngOnInit(): void {
  }
}

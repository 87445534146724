import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHARTv2, FONTS } from 'src/app/shared/constants/chart.constant';
import { PortProductivityEquipmentBerthUtilizationModel } from 'src/app/shared/models/port-productivity-equipment-berth-utilization.model';
import { getPortProductivityEquipmentBerthUtilization } from 'src/app/shared/state/operations/operations.action';
import { selectPortProductivityEquipmentBerthUtilization } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-port-productivity',
  templateUrl: './port-productivity.component.html',
  styleUrls: ['./port-productivity.component.scss']
})
export class PortProductivityComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  portEquipmentBerth$: Observable<PortProductivityEquipmentBerthUtilizationModel[]>
    = this.store.select(selectPortProductivityEquipmentBerthUtilization);
  public barLineChartData: any[];
  public barLineChartLabels: string[] = [];
  public barLineChartOptions: any;
  public barLineChartColors: any[];
  public barLineChartLegend: boolean;
  public barLineChartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public chartIsReady = false;
  public filters = { ...CHARTv2.CHART_FILTERS };
  public selectedFilter = this.filters.LAST_SEVEN_DAYS;


  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private roundPipe: RoundPipe
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getPortProductivityEquipmentBerthUtilization({ portId: this.selectedPortId, filter: this.selectedFilter }));
  }

  ngAfterViewInit(): void {
    this.portEquipmentBerth$.subscribe((portBerthEquipment) => {
      if (portBerthEquipment?.length > 0) {
        this.initializePortProductivityBerthEquipmentData(portBerthEquipment);
      } else {
        this.noData = true;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getPortProductivityEquipmentBerthUtilization({ portId: this.selectedPortId, filter: this.selectedFilter }));
      }
    }
  }

  initializePortProductivityBerthEquipmentData(data: PortProductivityEquipmentBerthUtilizationModel[]) {
    const dateFormat1 = 'EEE';
    const dateFormat2 = 'dd/MM';
    this.barLineChartLabels = [];
    const barLineChartDataDataPort = [];
    const barLineChartDataDataBerth = [];
    const barLineChartDataDataEquipment = [];

    if (!data || data === undefined || data === null || data.length === 0) {
      this.noData = true;
    } else {
      const dayOfWeekRegex = /^[A-Z][a-z]{2}$/;
      data.map((port: PortProductivityEquipmentBerthUtilizationModel) => {
        // format dateAcquired
        if (this.selectedFilter === this.filters.LAST_SEVEN_DAYS) {
          if (!dayOfWeekRegex.test(port.date_acquired)) {
            port.date_acquired = this.datePipe.transform(port.date_acquired, dateFormat1);
          }
        } else {
          port.date_acquired = this.datePipe.transform(port.date_acquired, dateFormat2);
        }
        this.barLineChartLabels.push(port.date_acquired);

        // validate if portProductivity exists
        if (port.port_productivity) {
          barLineChartDataDataPort.push(port.port_productivity);
        } else {
          barLineChartDataDataPort.push(0);
        }

        // validate if berthUtilization exists
        if (port.berth_utilization) {
          port.berth_utilization = this.roundPipe.transform(port.berth_utilization);
          barLineChartDataDataBerth.push(port.berth_utilization);
        } else {
          barLineChartDataDataBerth.push(0);
        }

        // validate if equipmentUtilization exists
        if (port.equipment_utilization) {
          port.equipment_utilization = this.roundPipe.transform(port.equipment_utilization);
          barLineChartDataDataEquipment.push(port.equipment_utilization);
        } else {
          barLineChartDataDataEquipment.push(0);
        }
      });

      this.barLineChartData = [
        {
          label: CHARTv2.CHART_LABELS.BERTH_UTILIZATION,
          data: barLineChartDataDataBerth.reverse(),
          yAxisID: 'utilization',
          fill: false,
          borderColor: CHARTv2.COLOR.ANALYTICS.blue,
          pointRadius: 5,
          pointHoverRadius: 8,
          lineTension: 0,
          type: CHARTv2.CHART_TYPE.line
        }, {
          label: CHARTv2.CHART_LABELS.EQUIPMENT_UTILIZATION,
          data: barLineChartDataDataEquipment.reverse(),
          yAxisID: 'utilization',
          fill: false,
          borderColor: CHARTv2.COLOR.ANALYTICS.orange,
          pointRadius: 5,
          pointHoverRadius: 8,
          lineTension: 0,
          type: CHARTv2.CHART_TYPE.line
        }, {
          label: CHARTv2.CHART_LABELS.CONTAINER_HANDLED_PER_DAY,
          data: barLineChartDataDataPort.reverse(),
          yAxisID: 'container',
          backgroundColor: [CHARTv2.COLOR.ANALYTICS.green]
        },
      ];

      this.barLineChartLabels.reverse();
      this.initializeChart();
      this.noData = false;
      this.chartIsReady = true;
    }
  }


  initializeChart() {
    this.barLineChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: { titleFontFamily: FONTS.FAMILY.GOTHAM, bodyFontFamily: FONTS.FAMILY.GOTHAM },
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      scales: {
        yAxes: [{
          id: 'container',
          position: 'left',
          ticks: { min: 0, fontFamily: FONTS.FAMILY.GOTHAM, fontSize: FONTS.SIZE.TWELVE },
          gridLines: { display: false }
        }, {
          id: 'utilization',
          position: 'right',
          ticks: { min: 0, max: 100, fontFamily: FONTS.FAMILY.GOTHAM, fontSize: FONTS.SIZE.TWELVE }
        }],
        xAxes: [{
          ticks: { autoSkip: false, maxRotation: 90, minRotation: 0, fontFamily: FONTS.FAMILY.GOTHAM, fontSize: FONTS.SIZE.TWELVE },
          gridLines: { display: false }
        }],

      }
    };
    this.barLineChartColors = [
      { backgroundColor: CHARTv2.COLOR.ANALYTICS.blue },
      { backgroundColor: CHARTv2.COLOR.ANALYTICS.orange },
      { backgroundColor: CHARTv2.COLOR.ANALYTICS.green }
    ];
    this.barLineChartLegend = false;
    this.barLineChartType = CHARTv2.CHART_TYPE.bar;
  }


  onFilterSelected(selectedFilter: any) {
    this.selectedFilter = selectedFilter;
    this.chartIsReady = false;
    this.barLineChartData = [];
    this.barLineChartLabels = [];

    this.store.dispatch(getPortProductivityEquipmentBerthUtilization({ portId: this.selectedPortId, filter: selectedFilter }));
  }


  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }
}

export const EQUIPMENT = {
  EQUIPMENT_TYPE: {
    INIT_TYPE: '',
    QUAYCRANE: 'quaycrane',
    CRANE: 'crane',
    RTG: 'rtg',
    TUGBOAT: 'tugboat'
  },
  EQUIPMENT_STATUS: {
    ACTIVE: 'Active',
    IDLE: 'Idling',
    NON_UTILIZED: 'Inactive',
    INACTIVE: 'Inactive',
    NON_PRODUCTIVE: 'Non Productive',
    MAINTENANCE: true,
  },
  EQUIPMENT_STATUS_TOOLTIP: {
    ACTIVE: 'Active State (Green) happens when the Equipment is Working',
    IDLE: 'Idling State (Yellow) happens when the Equipment is On but not Working',
    NON_UTILIZED: 'Inactive State (Grey) happens when the Equipment is Off',
    INACTIVE: 'Inactive State (Grey) happens when the Equipment is Off',
    NON_PRODUCTIVE: 'Non Productive State (Orange) happens when the Equipment is Active but there is no work order',
    MAINTENANCE: 'Maintenance Status is indicated by the Warning Sign',
    ACTIVE_STATUS_TRUE: 'In warning state, limited operation',
    INACTIVE_STATUS_TRUE: 'In fault state, Crane down',
  },
  EQUIPMENT_STATUS_DESC: {
    ACTIVE: 'Active',
    IDLE: 'Idling',
    NON_UTILIZED: 'Inactive',
    INACTIVE: 'Inactive'
  },
  AGGREGATED_GMPH_NAME: 'GMPH'
};

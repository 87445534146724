import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { ICON } from 'src/assets/icons/assets.constant';

@Component({
  selector: 'app-waste-diversion',
  templateUrl: './waste-diversion.component.html',
  styleUrls: ['./waste-diversion.component.scss']
})
export class WasteDiversionComponent implements OnInit {
  private shareByClassificationBarChart: Chart;
  private shareByCategoryBarChart: Chart;

  constructor() { }

  ngOnInit(): void {
    this.createStackedBarClassification();
    this.createStackedBarCategory();
  }

  createStackedBarClassification() {
    if (this.shareByClassificationBarChart) {
      this.shareByClassificationBarChart.destroy();
    }

    const canvas = document.getElementById('wasteClassificationChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "wasteClassificationChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d'); // Get the 2D context
    const COLOR = CHART.COLOR.SHARE_BY_CLASSIFICATION;

    this.shareByClassificationBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Share %'],
        datasets: [
          {
            label: 'Non-Diverted Waste',
            data: [63.8],
            backgroundColor: COLOR.BLUE,
            barThickness: 150,
          },
          {
            label: 'Diverted Waste',
            data: [36.2],
            backgroundColor: COLOR.GREEN,
            barThickness: 150,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            offset: true,
            gridLines: {
              display: true,
              offsetGridLines: true,
            },
            ticks: {
              fontColor: COLOR.BLACK,
              fontFamily: 'Montserrat',
              fontSize: 12,
              fontStyle: 'bold',
            }
          }],
          yAxes: [
            {
              stacked: true,
              display: true,
              gridLines: {
                display: false,
              },
              ticks: {
                max: 100,
                beginAtZero: true,
                stepSize: 50,
                fontColor: COLOR.BLACK,
                fontFamily: 'Montserrat',
                fontSize: 12,
                fontStyle: 'bold',
                padding: 10,
                callback: function (value) {
                  return value + '%';
                },
              },
            },
          ],
          gridLines: { display: false },
        },
        title: {
          display: false,
          text: 'Share by Classification',
          position: 'chartArea',
          fontSize: 16,
          fontColor: COLOR.BLACK_1,
          padding: 20,
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: COLOR.BLACK_1,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: COLOR.WHITE,
            align: 'center',
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              family: 'Montserrat',
              size: 12,
              style: 'normal',
              weight: 'normal',
            },
            formatter: function (value, ctx) {
              return ctx.dataset.label + '\n' + value + '%';
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  createStackedBarCategory() {
    if (this.shareByCategoryBarChart) {
      this.shareByCategoryBarChart.destroy();
    }

    const canvas = document.getElementById('wasteCategoryChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "wasteCategoryChart" not found');
      return;
    }
    const ctx = canvas.getContext('2d'); // Get the 2D context
    const COLOR = CHART.COLOR.SHARE_BY_CATEGORY;

    this.shareByCategoryBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Non-Diverted Waste', 'Diverted Waste'],
        datasets: [
          {
            label: 'Residual',
            data: [100, 0],
            backgroundColor: COLOR.BLUE,
          },
          {
            label: 'Recyclable',
            data: [0, 35],
            backgroundColor: COLOR.ORANGE,
          },
          {
            label: 'Others',
            data: [0, 33],
            backgroundColor: COLOR.YELLOW,
          },
          {
            label: 'Biodegradable',
            data: [0, 22],
            backgroundColor: COLOR.GREEN,
          },
          {
            label: 'Hazardous',
            data: [0, 10],
            backgroundColor: COLOR.GRAY,

          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            ticks: {
              fontColor: COLOR.BLACK,
              fontFamily: 'Montserrat',
              fontSize: 12,
              fontStyle: 'bold',
            }
          }],
          yAxes: [
            {
              stacked: true,
              display: false,
              ticks: {
                max: 100,
                beginAtZero: true,
                stepSize: 50,
                callback: function (value) {
                  return value + '%';
                },
              },
            },
          ],
          gridLines: { display: true },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: COLOR.BLACK_1,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: COLOR.WHITE,
            align: 'center',
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              family: 'Montserrat',     // Font family
              size: 12,                 // Font size in pixels
              style: 'normal',          // Font style (normal, italic, etc.)
              weight: 'normal',            // Font weight (normal, bold, 500, etc.)
            },
            formatter: function (value, ctx) {
              if (value > 0) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(',');
                return ctx.dataset.label + '\n' + value + '%';
              } else {
                value = '';
                return value;
              }
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? 'percent-red' : 'percent-green';
  }
}

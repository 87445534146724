import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as atlas from "azure-maps-control";
import { environment } from "src/environments/environment";
import { AZUREMAP_ICONS } from "src/app/shared/constants/assets.constant";
import { SHIP_DETAILS } from "src/app/shared/constants/ship.constants";
import { BUOY } from "src/app/shared/constants/buoy.constants";

@Component({
  selector: 'app-map-opti',
  templateUrl: './map-opti.component.html',
  styleUrls: ['./map-opti.component.scss']
})
export class MapOptiComponent implements OnInit {

  @ViewChild("map", { static: true }) mapElement: ElementRef;
  public markers: atlas.HtmlMarker[] = [];
  public map: atlas.Map;
  public zoom_multiport = 13.2;
  public latitude: number;
  public longitude: number;
  public lat: Number = 38.45457202;
  public lng: Number = 15.8912593;
  public shipsCoor = [

    {
      lat: SHIP_DETAILS.SHIP1.long,
      long: SHIP_DETAILS.SHIP1.lat,
      ship_name: SHIP_DETAILS.SHIP1.name,
      Call_Sign: SHIP_DETAILS.SHIP1.sign,
      Location: SHIP_DETAILS.SHIP1.location,
      Booking_Info: SHIP_DETAILS.SHIP1.booking_Info,
    },

    {
      lat: SHIP_DETAILS.SHIP2.long,
      long: SHIP_DETAILS.SHIP2.lat,
      ship_name: SHIP_DETAILS.SHIP2.name,
      Call_Sign: SHIP_DETAILS.SHIP2.sign,
      Location: SHIP_DETAILS.SHIP2.location,
      Booking_Info: SHIP_DETAILS.SHIP2.booking_Info,
    },


  ];

  public latlng = [{ name: "Gioaia Tauro", lat: 38.4576, lng: 15.9084 }];

  public inactiveCoor = [
    {
      lati: BUOY.SB10.lat,
      long: BUOY.SB10.long,
      name: BUOY.SB10.name,
      status: BUOY.SB10.status,
    },
  ];

  public latlongSB = [
    {
      lat: BUOY.SB1.lat,
      long: BUOY.SB1.long,
      name: BUOY.SB1.name,
      status: BUOY.SB1.status,
    },

    {
      lat: BUOY.SB2.lat,
      long: BUOY.SB2.long,
      name: BUOY.SB2.name,
      status: BUOY.SB2.status,
    },

    {
      lat: BUOY.SB3.lat,
      long: BUOY.SB3.long,
      name: BUOY.SB3.name,
      status: BUOY.SB3.status,
    },
    {
      lat: BUOY.SB4.lat,
      long: BUOY.SB4.long,
      name: BUOY.SB4.name,
      status: BUOY.SB4.status,
    },
    {
      lat: BUOY.SB5.lat,
      long: BUOY.SB5.long,
      name: BUOY.SB5.name,
      status: BUOY.SB5.status,
    },
    {
      lat: BUOY.SB9.lat,
      long: BUOY.SB9.long,
      name: BUOY.SB6.name,
      status: BUOY.SB6.status,
    },
    {
      lat: BUOY.SB7.lat,
      long: BUOY.SB7.long,
      name: BUOY.SB7.name,
      status: BUOY.SB7.status,
    },
    {
      lat: BUOY.SB8.lat,
      long: BUOY.SB8.long,
      name: BUOY.SB8.name,
      status: BUOY.SB8.status,
    },
    {
      lat: BUOY.SB6.lat,
      long: BUOY.SB6.long,

      name: BUOY.SB9.name,
      status: BUOY.SB9.status,
    },
  ];

  constructor() { }

  ngOnInit() {
    this.azureMap();
  }
  azureMap() {
    const map = new atlas.Map(this.mapElement.nativeElement, {
      center: [this.lng, this.lat],
      zoom: this.zoom_multiport,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      showFeedbackLink: false,
      showLogo: false,
      language: "en-US",
      style: "satellite_road_labels",

    });
    this.InializeMap(map);
    this.initializeMarker(map);

    map.controls.add(
      new atlas.control.StyleControl({
        mapStyles: [
          "road",
          "grayscale_dark",
          "night",
          "road_shaded_relief",
          "satellite",
          "satellite_road_labels",
        ],
        layout: "list",
      }),
      {
        position: "top-right" as atlas.ControlPosition,
      }
    );
  }

  InializeMap(map: any) {
    map.events.add("ready", () => {
      const dataSource = new atlas.source.DataSource();
      map.sources.add(dataSource);

      const polygonCoordinates1 = [
        [15.887032605635284, 38.443833116685795],
        [15.888676708561206, 38.4404571990286],
        [15.894275545550414, 38.4394478733997],
        [15.899385595184214, 38.43993513581],
        [15.90138517982308, 38.44268462633815],
        [15.901118568538209, 38.44477277701455],
        [15.897030528831493, 38.44738288040347],
        [15.893209100409706, 38.44752208326611],
        [15.888587838132253, 38.44644325405761],
        [15.88712147606418, 38.44522520169804],
        [15.887032605635284, 38.443833116685795],
      ];

      const polygonCoordinates2 = [
        [15.898881813814967, 38.46900319597839],
        [15.890828369152814, 38.46936145661013],
        [15.882408858825016, 38.46456061626904],
        [15.876643324578225, 38.45803956011167],
        [15.872525085831398, 38.44893775999196],
        [15.872891151497186, 38.440336578927344],
        [15.876002709662032, 38.434028394701556],
        [15.881402178242155, 38.43445851568086],
        [15.885611933406068, 38.43653739762735],
        [15.885794966239388, 38.44083834264188],
        [15.885154351323195, 38.44628583846551],
        [15.890279270652854, 38.44850772528321],
        [15.894122960151066, 38.44965447881097],
        [15.89778361681499, 38.45409797660241],
        [15.900529109313169, 38.45997444048163],
        [15.902817019728417, 38.46477558610394],
        [15.898881813814967, 38.46900319597839],
      ];

      dataSource.add(new atlas.data.Polygon([polygonCoordinates1]));
      dataSource.add(new atlas.data.Polygon([polygonCoordinates2]));

      const polygonLayer = new atlas.layer.PolygonLayer(
        dataSource,
        "myPolygonLayer",
        {
          fillColor: "rgb(180, 180, 180)",
          fillOpacity: 0.2,
        }
      );

      const lineLayer = new atlas.layer.LineLayer(dataSource, "myLineLayer", {
        strokeColor: "grey",
        strokeWidth: 1,
      });

      map.layers.add([polygonLayer, lineLayer]);
    });
  }

  initializeMarker(map) {
    //ACTIVE MARKERS
    const activeMark = AZUREMAP_ICONS.active;
    const markerSB = [];

    this.latlongSB.forEach((port) => {
      const markerPosition = { position: [port.long, port.lat] };
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];

      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${activeMark}" alt="Marker">`,
      });

      const popup = new atlas.Popup();
      map.markers.add(marker);
      markerSB.push(marker);
      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content:
            '<div style="padding:10px;font-weight: bold">Buoy ID: ' +
            port.name +
            '<div style="padding-top: 2px; font-weight: bold">Status: ' +
            port.status +
            "</div></div>",

          position: [latitude, longitude],
          pixelOffset: [0, -30],
        });

        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });


    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });

    //INACTIVE MARKERS
    const imageUrlinAct = AZUREMAP_ICONS.inactive;
    const markerinAct = [];

    this.inactiveCoor.forEach((port) => {
      const markerPosition = { position: [port.long, port.lati] };
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];

      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrlinAct}" alt="Marker">`,
      });

      const popup = new atlas.Popup();
      map.markers.add(marker);
      markerinAct.push(marker);
      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content:
            '<div style="padding:10px;font-weight: bold">Buoy ID: ' +
            port.name +
            '<div style="padding-top: 2px; font-weight: bold">Status: ' +
            port.status +
            "</div></div>",

          position: [latitude, longitude],
          pixelOffset: [0, -30],
        });

        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });


    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });

    //SHIP
    const imageUrlinAct1 = AZUREMAP_ICONS.ship;
    const markerinAct1 = [];

    this.shipsCoor.forEach((port) => {
      const markerPosition = { position: [port.lat, port.long] };
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];

      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrlinAct1}" alt="Marker" class="marker-image">`,
      });

      const popup = new atlas.Popup();
      map.markers.add(marker);
      markerinAct1.push(marker);
      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content:
            '<div style="padding:2px;font-weight: bold">ShipName: ' +
            port.ship_name +
            '</div><div style="padding-top: 2px; font-weight: bold">Status: ' +
            port.Call_Sign +
            '</div><div style="padding:2px;font-weight: bold">Location: ' +
            port.Location +
            '</div><div style="padding:2px;font-weight: bold">BookingInfo: ' +
            port.Booking_Info +
            "</div>",

          position: [latitude, longitude],
          pixelOffset: [0, -20],
        });

        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();

        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });

    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });

    //PORT MARKER
    const imageUrl1 = AZUREMAP_ICONS.port;
    const markers = [];

    this.latlng.forEach((port) => {
      const markerPosition = { position: [port.lng, port.lat] };
      const latitude = markerPosition.position[0];
      const longitude = markerPosition.position[1];

      const marker = new atlas.HtmlMarker({
        position: [latitude, longitude],
        htmlContent: `<img src="${imageUrl1}" alt="Marker" class="marker">`,
      });

      const popup = new atlas.Popup();
      map.markers.add(marker);
      markers.push(marker);

      marker.getElement().addEventListener("mouseenter", () => {
        popup.setOptions({
          content: '<div style="padding:10px">' + port.name + "</div>",
          position: [latitude, longitude],
          pixelOffset: [0, -30],
        });
        popup.open(map);
        map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        map.getCanvasContainer().style.cursor = "grab";
      });
    });

    map.events.add("load", () => {
      map.getCanvasContainer().style.cursor = "grab";
    });
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PortModel } from 'src/app/shared/models';
import { setEquipmentSelectedPortId } from 'src/app/shared/state/operations/operations.action';
import { getPort } from 'src/app/shared/state/port/port.actions';
import { selectPorts } from 'src/app/shared/state/port/port.selectors';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit, AfterViewInit {
  public ports$ = this.store.select(selectPorts);
  public selectedPortId: string;
  showOptions: boolean = false;
  isArrowUpVisible: boolean = false;
  public selectedOption: string = "Select Port";

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getPort());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 1) {
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }

  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;
    this.store.dispatch(setEquipmentSelectedPortId({ portId: this.selectedPortId }));
  }

  showCheckboxes() {
    if ((this.showOptions = true)) {
      this.isArrowUpVisible = true;
    } else if ((this.showOptions = false)) {
      this.isArrowUpVisible = false;
    }
  }

  handleCheckboxClick(port: any): void {
    this.selectedOption = port.port_name;
    this.selectedPortId = port.port_id;
  }

  handleMouseLeave() {
    this.showOptions = false;
    this.isArrowUpVisible = false;
  }
}

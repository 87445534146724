import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { OperationsService } from "../../services/operations.service";
import {
  getOperation,
  getOperationFailure,
  getOperationSuccess,
  getPortProductivity,
  getPortProductivitySuccess,
  getPortProductivityFailure,
  getPortMap,
  getPortMapSuccess,
  getPortMapFailure,
  getBerthingStatus,
  getBerthingStatusSuccess,
  getBerthingStatusFailure,
  getOperatingStatus,
  getOperatingStatusSuccess,
  getOperatingStatusFailure,
  getEquipmentStatus,
  getEquipmentStatusSuccess,
  getEquipmentStatusFailure,
  getEquipmentJobOrder,
  getEquipmentJobOrderSuccess,
  getEquipmentJobOrderFailure,
  getEquipmentList,
  getEquipmentListSuccess,
  getEquipmentListFailure,
  getCraneProductivity,
  getCraneProductivitySuccess,
  getCraneProductivityFailure,
  getCraneMovement,
  getCraneMovementSuccess,
  getCraneMovementFailure,
  getCraneUtilizationHistory,
  getCraneUtilizationHistorySuccess,
  getCraneUtilizationHistoryFailure,
  getMovementTimeOverview,
  getMovementTimeOverviewSuccess,
  getMovementTimeOverviewFailure,
  getEquipmentMaintenanceSummary,
  getEquipmentMaintenanceSummarySuccess,
  getEquipmentMaintenanceSummaryFailure,
  getEquipmentPartsInspectionFailure,
  getEquipmentPartsInspectionSuccess,
  getEquipmentPartsInspection,
  getHistoricalMaintenance,
  getHistoricalMaintenanceSuccess,
  getHistoricalMaintenanceFailure,
  getEquipmentAlert,
  getEquipmentAlertSuccess,
  getEquipmentAlertFailure,
  getEquipmentFailureChart,
  getEquipmentFailureChartSuccess,
  getEquipmentFailureChartFailure,
  getEquipmentAlarmAnalysis,
  getEquipmentAlarmAnalysisSuccess,
  getEquipmentAlarmAnalysisFailure,
  getTotalContainersMovement,
  getTotalContainersMovementSuccess,
  getTotalContainersMovementFailure,
  getContainersMovementTypes,
  getContainersMovementTypesSuccess,
  getContainersMovementTypesFailure,
  getCraneUtilizationAnalytics,
  getCraneUtilizationAnalyticsSuccess,
  getCraneUtilizationAnalyticsFailure,
  getPortProductivityEquipmentBerthUtilization,
  getPortProductivityEquipmentBerthUtilizationFailure,
  getPortProductivityEquipmentBerthUtilizationSuccess,
  getCraneSensorEvaluation,
  getCraneSensorEvaluationSuccess,
  getCraneSensorEvaluationFailure,
  getTopVesselDelay,
  getTopVesselDelaySuccess,
  getTopVesselDelayFailure,
  getBerthingStatusOverview,
  getBerthingStatusOverviewSuccess,
  getBerthingStatusOverviewFailure,
  getQuayYardStatus,
  getQuayYardStatusSuccess,
  getQuayYardStatusFailure,
  getTugboatStatus,
  getTugboatStatusSuccess,
  getTugboatStatusFailure,
  getEquipmentAlertAnalyticsSuccess,
  getEquipmentAlertAnalyticsFailure,
  getEquipmentAlertAnalytics,
  getAlertsLogDataSuccess,
  getAlertsLogDataFailure,
  getAlertsLogData,
  getPortProductivityKpi,
  getPortProductivityKpiSuccess,
  getPortProductivityKpiFailure,
  getCraneKPI,
  getCraneKPISuccess,
  getCraneKPIFailure,
  getTugboatKpi,
  getTugboatKpiSuccess,
  getTugboatKpiFailure,
  getAverageTurnTime,
  getAverageTurnTimeSuccess,
  getAverageTurnTimeFailure,
  getContainerGateMoves,
  getContainerGateMovesSuccess,
  getContainerGateMovesFailure,
  getOverstayingTrucks,
  getOverstayingTrucksSuccess,
  getOverstayingTrucksFailure,
  getAverageContainerDwellTime,
  getAverageContainerDwellTimeSuccess,
  getAverageContainerDwellTimeFailure,
  getYardContainerSizeType,
  getYardContainerSizeTypeSuccess,
  getYardContainerSizeTypeFailure,
  getHazardousMaterial,
  getHazardousMaterialSuccess,
  getHazardousMaterialFailure
} from "./operations.action";

@Injectable()
export class OperationsEffects {

  constructor(
    private actions$: Actions,
    private operationsService: OperationsService
  ) { }

  getOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOperation),
      switchMap((action) =>
        this.operationsService.getOperations(action.portsId ? action.portsId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getOperationSuccess({ operation: data })),
            catchError((error) => of(getOperationFailure({ error })))
          )
      )
    )
  );

  getPortProductivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortProductivity),
      switchMap((action) =>
        this.operationsService.getPortProductivity(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getPortProductivitySuccess({ portProductivity: data })),
            catchError((error) => of(getPortProductivityFailure({ error })))
          )
      )
    )
  );

  getPortMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortMap),
      switchMap((action) =>
        this.operationsService.getPortMap(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getPortMapSuccess({ portMap: data })),
            catchError((error) => of(getPortMapFailure({ error })))
          )
      )
    )
  );

  getBerthingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBerthingStatus),
      switchMap((action) =>
        this.operationsService.getBerthingStatus(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getBerthingStatusSuccess({ berthingStatus: data })),
            catchError((error) => of(getBerthingStatusFailure({ error })))
          )
      )
    )
  );

  getOperatingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOperatingStatus),
      switchMap((action) =>
        this.operationsService.getOperatingStatus(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getOperatingStatusSuccess({ operatingStatus: data })),
            catchError((error) => of(getOperatingStatusFailure({ error })))
          )
      )
    )
  );

  getEquipmentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentStatus),
      switchMap((action) =>
        this.operationsService.getEquipmentStatus(action.portsId ? action.portsId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentStatusSuccess({ equipmentStatus: data })),
            catchError((error) => of(getEquipmentStatusFailure({ error })))
          )
      )
    )
  );

  getEquipmentJobOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentJobOrder),
      switchMap((action) =>
        this.operationsService
          .getEquipmentJobOrder(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentJobOrderSuccess({ equipmentJobOrder: data })),
            catchError((error) => of(getEquipmentJobOrderFailure({ error })))
          )
      )
    )
  );

  getEquipmentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentList),
      switchMap((action) =>
        this.operationsService.getEquipmentList(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentListSuccess({ equipmentList: data })),
            catchError((error) => of(getEquipmentListFailure({ error })))
          )
      )
    )
  );

  getCraneProductivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneProductivity),
      switchMap((action) =>
        this.operationsService.getCraneProductivity(
          action.portId ? action.portId : null,
          action.equipmentId ? action.equipmentId : null
        )
          .pipe(
            map((response) => response.data),
            map((data) => getCraneProductivitySuccess({ craneProductivity: data })),
            catchError((error) => of(getCraneProductivityFailure({ error })))
          )
      )
    )
  );

  getCraneMovement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneMovement),
      switchMap((action) =>
        this.operationsService
          .getCraneMovement(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          ).pipe(
            map((response) => response.data),
            map((data) => getCraneMovementSuccess({ craneMovement: data })),
            catchError((error) => of(getCraneMovementFailure({ error })))
          )
      )
    )
  );

  getCraneUtilization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneUtilizationHistory),
      switchMap((action) =>
        this.operationsService
          .getCraneUtilization(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null
          ).pipe(
            map((response) => response.data),
            map((data) => getCraneUtilizationHistorySuccess({ craneUtilizationHistory: data })),
            catchError((error) => of(getCraneUtilizationHistoryFailure({ error })))
          )
      )
    )
  );

  getMovementTimeOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMovementTimeOverview),
      switchMap((action) =>
        this.operationsService
          .getMovementTimeOverview(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getMovementTimeOverviewSuccess({ movementTimeOverview: data })),
            catchError((error) => of(getMovementTimeOverviewFailure({ error })))
          )
      )
    )
  );

  getEquipmentMaintenanceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentMaintenanceSummary),
      switchMap((action) =>
        this.operationsService
          .getEquipmentMaintenanceSummary(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentMaintenanceSummarySuccess({ equipmentMaintenanceSummary: data })),
            catchError((error) => of(getEquipmentMaintenanceSummaryFailure({ error })))
          )
      )
    )
  );

  getEquipmentPartsInspection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentPartsInspection),
      switchMap((action) =>
        this.operationsService
          .getEquipmentPartsInspection(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentPartsInspectionSuccess({ equipmentPartsInspection: data })),
            catchError((error) => of(getEquipmentPartsInspectionFailure({ error })))
          )
      )
    )
  );

  getHistoricalMaintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoricalMaintenance),
      switchMap((action) =>
        this.operationsService
          .getHistoricalMaintenance(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getHistoricalMaintenanceSuccess({ historicalMaintenance: data })),
            catchError((error) => of(getHistoricalMaintenanceFailure({ error })))
          )
      )
    )
  );

  getEquipmentMaintenanceAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentAlert),
      switchMap((action) =>
        this.operationsService
          .getEquipmentMaintenanceAlert(
            action.portId ? action.portId : null,
            action.equipmentId ? action.equipmentId : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentAlertSuccess({ equipmentAlert: data })),
            catchError((error) => of(getEquipmentAlertFailure({ error })))
          )
      )
    )
  );

  getEquipmentFailureChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentFailureChart),
      switchMap((action) =>
        this.operationsService
          .getEquipmentFailureChart(
            action.portId ? action.portId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null,
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentFailureChartSuccess({ equipmentFailureChart: data })),
            catchError((error) => of(getEquipmentFailureChartFailure({ error })))
          )
      )
    )
  );

  getEquipmentAlarmAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentAlarmAnalysis),
      switchMap((action) =>
        this.operationsService
          .getEquipmentAlarmAnalysis(
            action.portId ? action.portId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null,
          )
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentAlarmAnalysisSuccess({ equipmentAlarmAnalysis: data })),
            catchError((error) => of(getEquipmentAlarmAnalysisFailure({ error })))
          )
      )
    )
  );

  getTotalContainersMovement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTotalContainersMovement),
      switchMap((action) =>
        this.operationsService
          .getTotalContainersMovement(
            action.portId ? action.portId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getTotalContainersMovementSuccess({ totalContainersMovement: data })),
            catchError((error) => of(getTotalContainersMovementFailure({ error })))
          )
      )
    )
  );

  getContainersMovementTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContainersMovementTypes),
      switchMap((action) =>
        this.operationsService
          .getContainersMovementTypes(
            action.portId ? action.portId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getContainersMovementTypesSuccess({ containersMovementTypes: data })),
            catchError((error) => of(getContainersMovementTypesFailure({ error })))
          )
      )
    )
  );

  getCraneUtilizationAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneUtilizationAnalytics),
      switchMap((action) =>
        this.operationsService
          .getCraneUtilizationAnalytics(
            action.portId ? action.portId : null,
            action.startDate ? action.startDate : null,
            action.endDate ? action.endDate : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getCraneUtilizationAnalyticsSuccess({ craneUtilizationAnalytics: data })),
            catchError((error) => of(getCraneUtilizationAnalyticsFailure({ error })))
          )
      )
    )
  );

  getPortProductivityEquipmentBerthUtilization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortProductivityEquipmentBerthUtilization),
      switchMap((action) =>
        this.operationsService
          .getPortProductivityEquipmentBerthUtilization(
            action.portId ? action.portId : null,
            action.filter ? action.filter : null
          ).pipe(
            map((response) => response.data),
            map((data) => getPortProductivityEquipmentBerthUtilizationSuccess({ portEquipmentBerth: data })),
            catchError((error) => of(getPortProductivityEquipmentBerthUtilizationFailure({ error })))
          )
      )
    )
  );

  getCraneSensorEvaluation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneSensorEvaluation),
      switchMap((action) =>
        this.operationsService
          .getCraneSensorEvaluation(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getCraneSensorEvaluationSuccess({ craneSensorEvaluation: data })),
            catchError((error) => of(getCraneSensorEvaluationFailure({ error })))
          )
      )
    )
  );

  getTopVesselDelay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTopVesselDelay),
      switchMap((action) =>
        this.operationsService
          .getTopVesselDelay(
            action.portId ? action.portId : null,
            action.filter ? action.filter : null
          )
          .pipe(
            map((response) => response.data),
            map((data) => getTopVesselDelaySuccess({ topVesselDelay: data })),
            catchError((error) => of(getTopVesselDelayFailure({ error })))
          )
      )
    )
  );

  getBerthingStatusOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBerthingStatusOverview),
      switchMap((action) =>
        this.operationsService
          .getBerthingStatusOverview(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getBerthingStatusOverviewSuccess({ berthingStatusOverview: data })),
            catchError((error) => of(getBerthingStatusOverviewFailure({ error })))
          )
      )
    )
  );

  getQuayYardStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQuayYardStatus),
      switchMap((action) =>
        this.operationsService
          .getQuayYardStatus(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getQuayYardStatusSuccess({ quayYardStatus: data })),
            catchError((error) => of(getQuayYardStatusFailure({ error })))
          )
      )
    )
  );

  getTugboatStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTugboatStatus),
      switchMap((action) =>
        this.operationsService
          .getTugboatStatus(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getTugboatStatusSuccess({ tugboatStatus: data })),
            catchError((error) => of(getTugboatStatusFailure({ error })))
          )
      )
    )
  );

  getEquipmentAlertAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipmentAlertAnalytics),
      switchMap((action) =>
        this.operationsService
          .getEquipmentAlertAnalytics(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getEquipmentAlertAnalyticsSuccess({ equipmentAlertsAnalytics: data })),
            catchError((error) => of(getEquipmentAlertAnalyticsFailure({ error })))
          )
      )
    )
  );

  getAlertsLogData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAlertsLogData),
      switchMap((action) =>
        this.operationsService
          .getAlertsLogData(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getAlertsLogDataSuccess({ logData: data })),
            catchError((error) => of(getAlertsLogDataFailure({ error })))
          )
      )
    )
  );

  getPortProductivityKpi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortProductivityKpi),
      switchMap((action) =>
        this.operationsService
          .getPortProductivityKpi(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getPortProductivityKpiSuccess({ portProductivityKpi: data })),
            catchError((error) => of(getPortProductivityKpiFailure({ error })))
          )
      )
    )
  );

  getCraneKPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCraneKPI),
      switchMap((action) =>
        this.operationsService
          .getCraneKpi(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getCraneKPISuccess({ craneKpi: data })),
            catchError((error) => of(getCraneKPIFailure({ error })))
          )
      )
    )
  );

  getTugboatKPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTugboatKpi),
      switchMap((action) =>
        this.operationsService
          .getTugboatKpi(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getTugboatKpiSuccess({ tugboatKpi: data })),
            catchError((error) => of(getTugboatKpiFailure({ error })))
          )
      )
    )
  );

  getAverageTurnTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAverageTurnTime),
      switchMap((action) =>
        this.operationsService
          .getAverageTurnTime(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getAverageTurnTimeSuccess({ averageTurnTime: data })),
            catchError((error) => of(getAverageTurnTimeFailure({ error })))
          )
      )
    )
  );

  getContainerGateMoves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContainerGateMoves),
      switchMap((action) =>
        this.operationsService
          .getContainerGateMoves(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getContainerGateMovesSuccess({ containerGateMoves: data })),
            catchError((error) => of(getContainerGateMovesFailure({ error })))
          )
      )
    )
  );

  getOverstayingTrucks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOverstayingTrucks),
      switchMap((action) =>
        this.operationsService
          .getOverstayingTrucks(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getOverstayingTrucksSuccess({ overstayingTrucks: data })),
            catchError((error) => of(getOverstayingTrucksFailure({ error })))
          )
      )
    )
  );

  getAverageContainerDwellTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAverageContainerDwellTime),
      switchMap((action) =>
        this.operationsService
          .getAverageContainerDwellTime(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getAverageContainerDwellTimeSuccess({ averageContainerDwellTime: data })),
            catchError((error) => of(getAverageContainerDwellTimeFailure({ error })))
          )
      )
    )
  );

  getYardContainerSizeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getYardContainerSizeType),
      switchMap((action) =>
        this.operationsService
          .getYardContainerSizeType(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getYardContainerSizeTypeSuccess({ yardContainerSizeType: data })),
            catchError((error) => of(getYardContainerSizeTypeFailure({ error })))
          )
      )
    )
  );

  getHazardousMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHazardousMaterial),
      switchMap((action) =>
        this.operationsService
          .getHazardousMaterial(action.portId ? action.portId : null)
          .pipe(
            map((response) => response.data),
            map((data) => getHazardousMaterialSuccess({ hazardousMaterial: data })),
            catchError((error) => of(getHazardousMaterialFailure({ error })))
          )
      )
    )
  );
}

import { createAction, props } from '@ngrx/store';
import { MapMemberCoordinatesModel, MapRegionListModel, PortMemberDetailsModel } from '../../models/port-authority-models/map-region-list.model';
import { ErrorResponse } from '../../models';
import { PortMemberGeneralDetailsModel, PortMemberUtlizationRateModel, TraffifDwellTimeModel } from '../../models/port-authority-models/analytics-details.model';

export const getportAuthMembers = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member',
    props<{ portRegionCode?: number }>()

);

export const getportAuthMembersSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Successful',
    props<{ portAuthMember: Array<MapRegionListModel> }>()
);

export const getportAuthMembersFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Failed',
    props<{ error: ErrorResponse }>()
);

export const getportAuthMembersDetails = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member Details',
    props<{ portsId?: string, portRegionCode?: number }>()
);

export const getportAuthMembersDetailsSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber  Details Successful',
    props<{ portAuthMemberDetails: PortMemberDetailsModel }>()
);

export const getportAuthMembersDetailsFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Details Failed',
    props<{ error: ErrorResponse }>()
);


export const getportAuthMembersCoordinates = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member Coordinates',
    props<{ portsId?: string, portRegionCode?: number }>()
);

export const getportAuthMembersCoordinatesSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber  Coordinates Successful',
    props<{ portAuthMemberCoordinates: MapMemberCoordinatesModel }>()
);

export const getportAuthMembersCoordinatesFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Coordinates Failed',
    props<{ error: ErrorResponse }>()
);



export const getportAuthGeneralInfoAnalytics = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member Coordinates',
    props<{ portsId?: string, portRegionCode?: number }>()
);

export const getportAuthGeneralInfoAnalyticsSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber  Coordinates Successful',
    props<{ portAuthGeneralInfoAnalytics: PortMemberGeneralDetailsModel }>()
);

export const getportAuthGeneralInfoAnalyticsFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Coordinates Failed',
    props<{ error: ErrorResponse }>()
);



export const getportAuthUtilizationRateAnalytics = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member Coordinates',
    props<{ portsId?: string, portRegionCode?: number }>()
);

export const getportAuthUtilizationRateAnalyticsSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber  Coordinates Successful',
    props<{ portAuthUtilizationRateAnalytics: PortMemberUtlizationRateModel }>()
);

export const getportAuthUtilizationRateAnalyticsFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Coordinates Failed',
    props<{ error: ErrorResponse }>()
);


export const getportAuthTrafficDwellTimeAnalytics = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Member Coordinates',
    props<{ portsId?: string, portRegionCode?: number }>()
);

export const getportAuthTrafficDwellTimeAnalyticsSuccess = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber  Coordinates Successful',
    props<{ portAuthTrafficDwellTime: TraffifDwellTimeModel }>()
);

export const getportAuthTrafficDwellTimeAnalyticsFailure = createAction(
    '[Port Authority Dashboard Page] Get Port Authority Memmber Coordinates Failed',
    props<{ error: ErrorResponse }>()
);
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthGuard } from './shared/guard/auth.guard';
import { LayoutModule } from './layout/layout.module';
import { ErrorPageModule } from './error-page/error-page.module';
import { LoginModule } from './login/login.module';

const routes: Routes = [
  {
    path: "login",
    loadChildren: () => LoginModule,
    canActivate: [MsalGuard]
  },
  {
    path: "error/:error",
    loadChildren: () => ErrorPageModule,
  },
  {
    path: "",
    loadChildren: () => LayoutModule,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { createAction, props } from '@ngrx/store';
import { EnergyManagementModel } from '../../models/energy-management.model';
import { ErrorResponse } from '../../models/error-response.model';

export const getEnergyMngt = createAction(
  '[Dashboard] Get Energy Management',
  props<{portsId?: string}>()
);

export const getEnergyMngtSuccess = createAction(
  '[Get Energy Management API] Get Energy Management Successful',
  props<{ energymanagement: EnergyManagementModel }>()
);

export const getEnergyMngtFailure = createAction(
    '[Get Energy Management API] Get Energy Management Failed',
    props<{ error: ErrorResponse }>()
);

export const resetEnergyMngmt = createAction(
  '[Dashboard] Reset Energy Management'
);
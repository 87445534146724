import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartDataSets } from 'chart.js'
import { Observable, Subscription } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART, CHARTv2, FONTS } from 'src/app/shared/constants/chart.constant';
import { AverageContainerDwellTimeModel } from 'src/app/shared/models/average-container-dwell-time.model';
import { OutboundTrucksService } from 'src/app/shared/services/specs/outbound-truck-service';
import { getAverageContainerDwellTime } from 'src/app/shared/state/operations/operations.action';
import { selectAverageContainerDwellTime } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-container-dwell-time',
  templateUrl: './container-dwell-time.component.html',
  styleUrls: ['./container-dwell-time.component.scss']
})
export class ContainerDwellTimeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  averageContainerDwellTime$: Observable<AverageContainerDwellTimeModel[]> = this.store.select(selectAverageContainerDwellTime);
  private chartDataSet = CHART.DATA.YARD_OPERATIONS.CONTAINER_DWELL_TIME.DATASET_SET;
  public barChartColors: any[] = [];
  public barChartData: ChartDataSets[] = [{
    data: [],
    label: '',
    stack: 'a',
    backgroundColor: '',
    hoverBackgroundColor: ''
  }];
  public barChartLabels: string[] = [];
  public barChartOptions: any;
  public barChartLegend: boolean;
  public barChartType: string;
  public barChartStepSize: number = 0;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public dateToDisplay: string;
  public noData = true;
  public chartIsReady = false;

  constructor(
    private store: Store,
    private obtService: OutboundTrucksService
  ) { }

  ngOnInit() {
    this.store.dispatch(getAverageContainerDwellTime({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.averageContainerDwellTime$.subscribe((data) => {
      if (data?.length) {
        this.initializeChart(data);
        this.noData = false;
      } else {
        this.noData = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getAverageContainerDwellTime({ portId: this.selectedPortId }));
      }
    }
  }

  initializeChart(data: AverageContainerDwellTimeModel[]) {
    data.sort((a, b) => {
      const dateA = new Date(a.date_range.split(' to ')[0]);
      const dateB = new Date(b.date_range.split(' to ')[0]);

      return dateA.getTime() - dateB.getTime();
    }).map(el => {

      // this.barChartLabels.push(formatDate(newDate, 'd MMM y', 'en-US'))
      // let newDate = new Date(el.date);

      this.barChartLabels.push(`${this.formatDay(el.date_range.split(' to ')[0], true)} -  ${this.formatDay(el.date_range.split(' to ')[1], true)}`)

      this.chartDataSet.import.push(Math.round(el.import_avg_dwell_time))
      this.chartDataSet.export.push(Math.round(el.export_avg_dwell_time))

    })

    // this.dateToDisplay = '(' + this.barChartLabels[0].slice(0,5) + ' - ' + this.barChartLabels[this.barChartLabels.length - 1].slice(0, -5) + ')';
    this.dateToDisplay = `(${this.formatDay(this.barChartLabels[0].split(' - ')[0], true)} - ${this.formatDay(this.barChartLabels[this.barChartLabels.length - 1].split(' - ')[1], true)})`

    Object.entries(this.chartDataSet).forEach((el, index) => {
      if (Math.max(...el[1]) > this.barChartStepSize) {
        this.barChartStepSize = (Math.max(...el[1]));
      }
      const YARD_OPERATIONS = CHART.DATA.YARD_OPERATIONS;
      const item = {
        data: [...el[1]],
        label: YARD_OPERATIONS.CONTAINER_DWELL_TIME.LABELS[index],
        stack: el[0] == 'import' ? 'a' : 'b',
        backgroundColor: YARD_OPERATIONS.CONTAINER_DWELL_TIME.COLORS[index],
        hoverBackgroundColor: YARD_OPERATIONS.CONTAINER_DWELL_TIME.COLORS[index],
        borderColor: YARD_OPERATIONS.CONTAINER_DWELL_TIME.COLORS[index],
        pointRadius: 5,
        pointHoverRadius: 8,
        fill: false,
        lineTension: 0,
        type: CHART.CHART_TYPE.line
      }
      this.barChartData.push(item)
    });
    this.barChartStepSize = this.barChartStepSize / 5
    this.barChartOptions = {
      responsive: true,
      bezierCurve: false,
      // barValueSpacing : 1,
      maintainAspectRatio: false,
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      tooltips: {
        titleFontFamily: FONTS.FAMILY.GOTHAM, bodyFontFamily: FONTS.FAMILY.GOTHAM
      },
      scales: {
        xAxes: [{
          // maxBarThickness: 50,
          gridLines: {
            display: false
          },
        }],
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            stepSize: this.barChartStepSize,
            callback: function (value) { { return Math.round(value); } }
          }
        }],
      }, hover: {
        onHover: ''
      }
    };
    this.barChartColors = [
      { backgroundColor: CHARTv2.COLOR.ANALYTICS.blue }
    ];
    this.barChartLegend = false;
    this.barChartType = CHART.CHART_TYPE.bar;

  }

  formatDay(data, withYear) {
    const d = new Date(data);
    //d.setFullYear(2023);
    const day = d.getDate()
    const month = this.obtService.monthsAll[d.getMonth()]

    if (day < 10) return `${day.toString().padStart(2, '0')} ${month} ${withYear ? d.getFullYear() : ''}`
    else return `${day.toString()} ${month} ${withYear ? d.getFullYear() : ''}`
  }
}

import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { CraneMovementModel, CraneMovementSteps, Steps } from 'src/app/shared/models/crane-movement-steps.model';
import { selectCraneMovement } from 'src/app/shared/state/operations/operations.selectors';
import { Observable } from 'rxjs';
import { getCraneMovement } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-crane-operation-cycle-steps',
  templateUrl: './crane-operation-cycle-steps.component.html',
  styleUrls: ['./crane-operation-cycle-steps.component.scss']
})
export class CraneOperationCycleStepsComponent implements OnInit, AfterViewInit {
  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  @Input()
  equipmentType: string;

  craneMovement$: Observable<CraneMovementModel> = this.store.select(selectCraneMovement);
  public craneMovementSteps: any[] = [];
  public chartDataReady = false;
  public equipmentTypes = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;

  constructor(
    private store: Store,
    private roundPipe: RoundPipe
  ) { }

  ngOnInit() {
    this.store.dispatch(getCraneMovement({ portId: this.portId, equipmentId: this.equipmentId }));
  }

  ngAfterViewInit(): void {
    this.craneMovement$.subscribe((craneMovement) => {
      if (craneMovement) {
        this.getCraneMovements(craneMovement);
      }
    });
  }

  getCraneMovements(data: CraneMovementModel) {
    this.craneMovementSteps = data.crane_movement_steps.map((res: CraneMovementSteps) => {
      const name = res.move_date;
      const category = res.move_type;
      const values = res.steps.map((step: Steps) => {
        const section = step.step,
          value = this.roundPipe.transform(step.duration);
        return { section, value };
      });
      return { name, category, values };
    });

    this.chartDataReady = true;
  }

}

import { Component, OnInit } from '@angular/core';
import * as data from '../../../shared/mock-data/inward.json'


@Component({
  selector: 'app-water-conservation-page',
  templateUrl: './water-conservation-page.component.html',
  styleUrls: ['./water-conservation-page.component.scss']
})
export class WaterConservationPageComponent implements OnInit {
  public dataSourceWaterConserve;
  constructor() { }

  ngOnInit(): void {
    this.dataSourceWaterConserve = data.waterConservation;
  }

}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PortService } from "../../services/port.service";

import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { getPort, getPortFailure, getPortSuccess } from "./port.actions";

@Injectable()
export class PortEffects { 
    constructor(
    private actions$: Actions,
    private portService: PortService
  ) { }

  getPort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPort),
      switchMap(() =>
        this.portService.getPort().pipe(
          map((response) => response.data.ports),
          map((ports) => getPortSuccess({ ports: ports.sort((a, b) => a.port_name.localeCompare(b.port_name))})),
          catchError((error) => of(getPortFailure({ error })))
        )
      )
    )
  );
}
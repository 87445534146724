import {AfterContentInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTextColorCustom]'
})
export class TextColorCustomDirective implements AfterContentInit {

  @Input() appTextColorCustom;
  @Input() color = 'blue';

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    this.renderer.addClass(this.el.nativeElement.parentNode, 'text-color-custom--' + this.color);
  }

}

import { Component, OnInit, Renderer2 } from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

@Component({
  selector: 'app-digital-twin',
  templateUrl: './digital-twin.component.html',
  styleUrls: ['./digital-twin.component.scss']
})
export class DigitalTwinComponent implements OnInit {
  private engine: BABYLON.Engine;
  private scene: BABYLON.Scene;
  private camera: BABYLON.ArcRotateCamera;
  private isDragging: boolean = false;
  private dragStartPosition: BABYLON.Vector2 = new BABYLON.Vector2(0, 0);

  constructor(private renderer: Renderer2) { }
  ngOnInit() {
    const canvas = document.getElementById('renderCanvas') as HTMLCanvasElement;
    this.renderer.listen(canvas, 'mousedown', this.onMouseDown.bind(this));
    this.renderer.listen(canvas, 'mousemove', this.onMouseMove.bind(this));
    this.renderer.listen(canvas, 'mouseup', this.onMouseUp.bind(this));

    canvas.style.marginTop = '-2000px';
    this.engine = new BABYLON.Engine(canvas, true);

    this.scene = new BABYLON.Scene(this.engine);

    const cameraPosition = new BABYLON.Vector3(0, 145, 0);
    const cameraTarget = new BABYLON.Vector3(0, 0, 0);
    this.camera = new BABYLON.ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2, 0, cameraTarget, this.scene);

    this.camera.inputs.add(new BABYLON.ArcRotateCameraPointersInput());
    this.camera.inputs.removeByType("ArcRotateCameraPointersInput");
    this.camera.attachControl(canvas, false);
    this.camera.setPosition(cameraPosition);
    this.camera.upperBetaLimit = Math.PI / 2;
    this.camera.lowerBetaLimit = -Math.PI / 2;
    this.camera.upperAlphaLimit = Math.PI / 2;
    this.camera.lowerAlphaLimit = -Math.PI / 2;
    const canvasContainer = document.getElementById('renderCanvas');
    const offset = 962;
    canvasContainer.style.marginTop = `-${offset}px`;

    BABYLON.SceneLoader.ImportMesh("", " https://ixacnconnportsdemoops.blob.core.windows.net/digital-twin-3d/", "port2D%201.glb?sp=r&st=2024-09-09T04:00:08Z&se=2025-09-30T12:00:08Z&spr=https&sv=2022-11-02&sr=b&sig=IT3iKgqL9jozzNJP%2BnEaKeno5E53tC%2FiC1UK9cnMgRg%3D", this.scene, (meshes) => {
      console.log("2D Model loaded successfully!");

      const mesh = meshes[0]; // Assuming the first mesh is the one you want to drag
      const pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragPlaneNormal: new BABYLON.Vector3(0, 1, 0) });
      pointerDragBehavior.attach(mesh);
      pointerDragBehavior.useObjectOrientationForDragging = false;

      this.addLights();

      this.engine.runRenderLoop(() => {
        this.scene.render();
      });

      window.addEventListener('resize', () => {
        this.engine.resize();
      });
    });
  }

  private addLights() {
    const directionalLight = new BABYLON.DirectionalLight("dir01", new BABYLON.Vector3(1, -1, -1), this.scene);
    directionalLight.diffuse = new BABYLON.Color3(2, 2, 2);
    directionalLight.intensity = 3;

    const hemisphericLight = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 1, 0), this.scene);
    hemisphericLight.diffuse = new BABYLON.Color3(0.5, 0.5, 0.5);
    hemisphericLight.intensity = 0.5;
  }

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.dragStartPosition.x = event.clientX;
    this.dragStartPosition.y = event.clientY;
  }

  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const deltaX = event.clientX - this.dragStartPosition.x;
      const deltaY = event.clientY - this.dragStartPosition.y;

      this.camera.alpha += deltaX / 100;
      this.camera.beta += deltaY / 100;

      this.dragStartPosition.x = event.clientX;
      this.dragStartPosition.y = event.clientY;
    }
  }

  onMouseUp() {
    this.isDragging = false;
  }


}

import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonFunction } from 'src/app/shared/common-function/common-function';
import { APIs, RESPONSE_CODE, STREAM_TAG } from 'src/app/shared/constants/api.constant';
import { ContainerDwellTime } from 'src/app/shared/models/yard-operations/container-dwell-time.model';
import { EmptiesInYard } from 'src/app/shared/models/yard-operations/empties-in-yard.model';
import { GateMovesData } from 'src/app/shared/models/yard-operations/gate-moves.model';
import { HazardousMaterial } from 'src/app/shared/models/yard-operations/hazardous-material.model';
import { TruckData } from 'src/app/shared/models/yard-operations/outbound-trucks.model';
import { YardInventoryAccessories } from 'src/app/shared/models/yard-operations/yard-inventory-accessories.model';
import { YardInventoryChassis } from 'src/app/shared/models/yard-operations/yard-inventory-chassis.model';
import { YardInventory } from 'src/app/shared/models/yard-operations/yard-inventory.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-simulation-information',
  templateUrl: './simulation-information.component.html',
  styleUrls: ['./simulation-information.component.scss']
})

export class SimulationInformationComponent implements OnInit, AfterViewInit {
  visible = true;
  activeButton: number = 1;
  
  yardopsSubs: Subscription;
  gatemoves: GateMovesData[];
  yardinventory_containers: YardInventory[];
  outboundtrucks: TruckData[];
  turntime: any;
  scantime = 0;
  vehicleInspectionStatus = {
    totalVehicles: 105,
    completed: 52,
    inProgress: 15,
    inQueue: 38,
  };
  hazardousmaterial: HazardousMaterial[];
  yardinventory_chassis: YardInventoryChassis[];
  yardinventory_accessories: YardInventoryAccessories[];
  emptiesinyard: EmptiesInYard[];
  containerdwelltime: ContainerDwellTime[];

  constructor(
    private http: HttpClient
  ) { }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
    this.scantime = 65;
  }

  ngOnInit(): void {
  }
  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
    this.getData(); 
  }
  getData() {
    Object.entries(APIs.YARD_OPERATIONS).forEach((path) => {
      const queryParams = { portId: environment.digitalTwinportId };
      const api = CommonFunction.getStreamEndPoint(path[1], queryParams);
      const yardopsObs: Observable<any> = this.http.get<any>(api);
      this.yardopsSubs = yardopsObs.subscribe(
        (data) => {
     
          if (data.code === RESPONSE_CODE.OK && data.message === "Success") {
            switch (data.tag) {
              case STREAM_TAG.GATE_MOVES:
                this.gatemoves = data.containerGateMoves;
                break;
              case STREAM_TAG.OUTBOUND_TRUCKS:
                this.outboundtrucks = data.truckList;
                break;
              case STREAM_TAG.YARD_INVENTORY_CONTAINERS:
                this.yardinventory_containers = data.yardContainersTypeSize;
                break;
              case STREAM_TAG.HAZARDOUS_MATERIAL:
                this.hazardousmaterial = data.materialDetails;
                break;
              case STREAM_TAG.YARD_INVENTORY_CHASSIS:
                this.yardinventory_chassis = data.chassisList;
                break;
              case STREAM_TAG.YARD_INVENTORY_ACCESSORIES:
                this.yardinventory_accessories = data.yardAccessoriesType;
                break;
              case STREAM_TAG.EMPTIES_IN_YARD:
                this.emptiesinyard = data.emptiesInYard;
                break;
              case STREAM_TAG.TURN_TIME:
                this.turntime = data.averageTurnTime;
                break;
              case STREAM_TAG.CONTAINER_DWELL_TIME:
                this.containerdwelltime = data.avgDwellTime;
                break;
              default:
                break;
            }
          }
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
    });
  }
}

import {
  Component, ElementRef, OnInit, ViewChild, AfterViewInit,
  OnDestroy,
  Input
} from "@angular/core";
import { Router } from "@angular/router";
import { GENMAP, MAP } from "src/app/shared/constants/map.constant";
import { EquipmentDetail } from "src/app/shared/models";
import { environment } from "src/environments/environment";
import * as atlas from "azure-maps-control";
import { Store } from '@ngrx/store';
import { Observable, Subscription } from "rxjs";
import { data } from "src/app/shared/mock-data/ravenna-coordinates.json";
import { equipList } from "src/app/shared/mock-data/equipment-list.json"

import { PortMemberDetailsModel } from "src/app/shared/models/port-authority-models/map-region-list.model";
import { selectPortAuthorityGeneralInfoAnalytics, selectPortAuthorityMemberDetails, selectPortAuthorityTrafficDwellTimeAnalytics, selectPortAuthorityUtilizationRateAnalytics } from "src/app/shared/state/port-authority/port-authority.selectors";
import { getportAuthGeneralInfoAnalytics, getportAuthMembersDetails, getportAuthTrafficDwellTimeAnalytics, getportAuthUtilizationRateAnalytics } from "src/app/shared/state/port-authority/port-authority.action";
import { PortMemberGeneralDetailsModel, PortMemberUtlizationRateModel, TraffifDwellTimeModel } from "src/app/shared/models/port-authority-models/analytics-details.model";

@Component({
  selector: 'app-auth-members',
  templateUrl: './auth-members.component.html',
  styleUrls: ['./auth-members.component.scss']
})
export class AuthMembersComponent implements OnInit, AfterViewInit {

  RegionCode = environment.regionCode;
  selectedPortId = environment.ravennaPortId;
  @ViewChild('map', { static: false })
  mapElement!: ElementRef;
  @ViewChild("unityFrame", { static: false })
  unityFrame!: ElementRef<HTMLIFrameElement>;
  @ViewChild("Modal")
  modal: ElementRef;


  public markers: atlas.HtmlMarker[] = [];
  public map: atlas.Map;
  public zoom_multiport = GENMAP.MULTI_TERMINAL_ZOOM;
  public longitudeV = -347.788;
  public latitudeV = 44.35;
  public longitudeT = 12.2686145;
  public latitudeT = 45.4576;
  public longitudeRavenna = 12.278743950280159;
  public latitudeRavenna = 44.485179040917075;
  public latitude: number;
  public longitude: number;
  public geolatitude: number;
  public geolongitude: number;
  lat: Number = this.latitudeV;
  lng: Number = this.longitudeV;
  public portCode: string;
  public portRegionName: string;
  public portName: string;
  public cargoType: string;
  public facility: string;
  public weather: string;
  public oneweather: string;
  public twoweather: string;
  public location: string;
  public onelocation: string;
  public secondlocation: string;
  public onecargo: string;
  public secondcargo: string;
  public onefacility: string;
  public secondfacility: string;
  public latlng: any[] = [];
  zoom: Number = 40;
  dir = undefined;
  showPortDetails: boolean = false;
  showAnalytics: boolean = false;
  show3D: boolean = false;
  hideData: boolean = false;
  public start_end_mark: markerDetail[] = [];
  public currentValueYur: number;
  public previousDayYur: number;
  public currentValueBur: number;
  public previousDayBur: number;
  public currentValueEur: any;
  public previousDayEur: any;
  public convertEur: any;
  public convertBur: any;
  public convertYur: any;
  public deltaYur: any;
  public resultYur: any;
  public currentValueCdt: number;
  public previousDayCdt: number;
  public deltaCdt: number;
  public resultCdt: any;
  public currentValueVth: number;
  public previousDayVth: number;
  public deltaVth: number;
  public resultVth: any;
  public currentValueVslth: number;
  public previousDayVslth: number;
  public deltaVslth: number;
  public resultVslth: any;
  public currentValueCt: number;
  public previousDayCt: number;
  public currentValueThc: number = 0;
  public previousDayThc: number = 0;
  public currentValueTi: any;
  public previousDayTi: any;
  public deltaBur: any;
  public resultBur: any;
  public deltaThc: any;
  public resultThc: any;
  public resultTi: any;
  public deltaTi: any;
  public resultCt: any;
  public deltaTCt: string;
  public resultEur: any;
  public deltaTEur: string;
  public modalImageSource: string = "";
  public imageIndex: number = 0;
  public imageSources: string[] = [
    "assets/images/portAuthImages/image-12.png",
    "assets/images/portAuthImages/image-13.png",
    "assets/images/portAuthImages/image-11.png",
  ];
  public indicators: boolean[] = [];
  showPreviousButton: boolean = false;
  showNextButton: boolean = true;
  public portRegionCode: number;
  public convertCt: any;
  public activeDiv: string = "portDetails";
  public showDiv: string = "portDetails";
  public totalCt: string;
  public convertVth: any;
  public convCdt: any;
  public zoom_dashboard = MAP.PORT_MINI_MAP_ZOOM;
  public equipmentList: EquipmentDetail[];
  showMap: boolean = false;
  show2D: boolean = false;
  show3DMap: boolean = true;
  div1Content = "Content 1";
  div2Content = "Content 2";
  div3Content = "Content 3";
  selectedView: string;
  isMapXHovered: boolean = false;
  isTwoDHovered: boolean = false;
  isthreeDHovered: boolean = false;
  public currentMap: string;
  unselectedViews: String[];
  arr = [];
  private isZoomedIn: boolean = false;
  private originalHeight: string;

  portMemberDetails$: Observable<PortMemberDetailsModel> = this.store.select(selectPortAuthorityMemberDetails)
  portGeneralAnalytics$: Observable<PortMemberGeneralDetailsModel> = this.store.select(selectPortAuthorityGeneralInfoAnalytics)
  portUtilizationRate$: Observable<PortMemberUtlizationRateModel> = this.store.select(selectPortAuthorityUtilizationRateAnalytics)
  portTrafficDwellTime$: Observable<TraffifDwellTimeModel> = this.store.select(selectPortAuthorityTrafficDwellTimeAnalytics);


  datas: PortMemberDetailsModel;
  time: TraffifDwellTimeModel;
  rate: PortMemberUtlizationRateModel;
  gen: PortMemberGeneralDetailsModel;

  constructor(public router: Router, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getportAuthMembersDetails({ portsId: this.selectedPortId, portRegionCode: this.RegionCode }));
    this.store.dispatch(getportAuthGeneralInfoAnalytics({ portsId: this.selectedPortId, portRegionCode: this.RegionCode }));
    this.store.dispatch(getportAuthUtilizationRateAnalytics({ portsId: this.selectedPortId, portRegionCode: this.RegionCode }));
    this.store.dispatch(getportAuthTrafficDwellTimeAnalytics({ portsId: this.selectedPortId, portRegionCode: this.RegionCode }));


    this.activatedDiv(this.activeDiv);
    console.log('Virtual Asset Component Initialized');
  }

  ngAfterViewInit() {


    this.portTrafficDwellTime$.subscribe((time) => {
      if (time) {
        this.getPortAuthorityTrafficAndDwellTime(time);
      }
    });

    this.portUtilizationRate$.subscribe((rate) => {
      if (rate) {
        this.getPortAuthorityMemberUtilizationRatesAnalytics(rate);
      }
    });

    this.portGeneralAnalytics$.subscribe((info) => {
      if (info) {
        this.getPortAuthorityMemberGeneralInfoAnalytics(info);
      }
    });



    this.portMemberDetails$.subscribe((data) => {
      if (data) {
        this.getPortAuthorityMemberData(data);
      }
    });




    if (this.unityFrame) {
      const iframe = this.unityFrame.nativeElement;
      iframe.addEventListener(
        "fullscreenchange",
        this.handleExitFullscreen.bind(this)
      );
    }

    // this.azureMap();
    this.getMapDetails();

  }


  toggleZoom() {
    const iframe = this.unityFrame.nativeElement;

    if (this.isZoomedIn) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }

      iframe.height = "835px";
    } else {
      const originalHeight = iframe.height;

      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      }

      iframe.height = "100%";

      setTimeout(() => {
        iframe.height = originalHeight;
      }, 100);
    }

    this.isZoomedIn = !this.isZoomedIn;
  }

  handleExitFullscreen() {
    if (!this.isZoomedIn && this.originalHeight) {
      const iframe = this.unityFrame.nativeElement;
      iframe.height = this.originalHeight;
      this.originalHeight = null;
    }
  }

  onUnityServerLoaded() { }

  getMapDetails() {
    this.equipmentList = equipList;

    this.equipmentList.map((item) => {
      item.latitude = Number(item.latitude);
      item.longitude = Number(item.longitude);
      return item;
    });
  }

  switchToMapX() {
    if (!this.unselectedViews?.length) {
      this.unselectedViews = ["mapX", "twoD"];
    }

    this.currentMap = "mapX";
    const index = this.unselectedViews.indexOf(this.currentMap);
    this.unselectedViews.splice(
      index,
      1,
      this.showDiv === "3D" ? "threeD" : this.showDiv
    );
    this.showDiv = "mapX";
  }

  switchToTwoD() {
    if (!this.unselectedViews?.length) {
      this.unselectedViews = ["mapX", "twoD"];
    }

    this.currentMap = "twoD";
    const index = this.unselectedViews.indexOf(this.currentMap);
    this.unselectedViews.splice(
      index,
      1,
      this.showDiv === "3D" ? "threeD" : this.showDiv
    );
    this.showDiv = "twoD";
  }

  switchToThreeD() {
    if (!this.unselectedViews?.length) {
      this.unselectedViews = ["mapX", "twoD"];
    }

    this.currentMap = "threeD";
    const index = this.unselectedViews.indexOf(this.currentMap);
    this.unselectedViews.splice(
      index,
      1,
      this.showDiv === "3D" ? "threeD" : this.showDiv
    );
    this.showDiv = "threeD";
  }

  getUnselectedViews() {
    return this.unselectedViews;
  }

  change3D() {
    this.show3D = !this.show3D;
  }

  getPortAuthorityMemberGeoCoordinates() {
    this.start_end_mark.push({
      latitude: data[0].geojson[0].latitude,
      longitude: data[0].geojson[0].longitude,
      port: data[0].portName,
      flag: false,
    });

    this.azureMap();
  }
  azureMap() {
    const map = new atlas.Map(this.mapElement.nativeElement, {
      center: [this.longitudeV, this.latitudeV],
      zoom: 9,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      // showFeedbackLink: false,
      // showLogo: false,
      language: "en-US",
    });


    map.events.add("load", () => {
      this.start_end_mark.forEach((port) => {
        const popup = new atlas.Popup({
          content: `<div style="padding:10px">${port.port}</div>`,
          pixelOffset: [0, -30],
        });

        const marker = new atlas.HtmlMarker({
          color: "red",
          position: [port.longitude, port.latitude],
          popup: popup,
        });

        map.markers.add(marker);

        marker.getElement().addEventListener("mouseover", () => {
          marker.togglePopup();
        });


        marker.getElement().addEventListener("mouseleave", () => {
          popup.close();
        });
      });
    });
  }

  getPortAuthorityMemberData(data: PortMemberDetailsModel) {
    // const response = data;
    this.portName = data?.port_name;
    this.cargoType = data?.cargo_type;
    this.onecargo = this.cargoType.split(". ")[0] + ".";
    this.secondcargo = this.cargoType.split(". ")[1];
    this.facility = data.facilities_available;
    this.onefacility = this.facility.split(". ")[0] + ".";
    this.secondfacility = this.facility.split(". ")[1];
    this.weather = data.weather_conditions;
    this.oneweather = this.weather.split(". ")[0] + ".";
    this.twoweather = this.weather.split(". ")[1];
    this.location = data.location;
    this.onelocation = this.location.split(". ")[0] + ".";
    this.secondlocation = this.location.split(". ")[1];
  }

  getPortAuthorityMemberUtilizationRatesAnalytics(rate: PortMemberUtlizationRateModel) {

    this.currentValueEur = rate.equipment_utilization_rate.current_value_eur;

    this.previousDayEur = rate.equipment_utilization_rate.previous_day_eur;
    this.convertEur = ((this.currentValueEur / 150) * 100).toFixed(0);
    const deltaTEur = (
      ((this.currentValueEur - this.previousDayEur) / this.previousDayEur) *
      100
    ).toFixed(0);
    this.resultEur = Math.abs(parseFloat(deltaTEur));
    if (isNaN(this.resultEur) || this.resultEur === 0) {
      this.resultEur = "";
    } else {
      this.resultEur = this.resultEur + "%";
    }
    //**Berth Utilization Rate */
    this.currentValueBur = rate.berth_utilization_rate.current_value_ti;

    this.previousDayBur = rate.berth_utilization_rate.previous_day_ti;

    this.convertBur = isNaN(this.currentValueBur) ? 0 : ((this.currentValueBur / 100) * 100).toFixed(0);
    this.deltaBur = (
      ((this.currentValueBur - this.previousDayBur) / this.previousDayBur) *
      100
    ).toFixed(0);
    this.resultBur = Math.abs(parseFloat(this.deltaBur));
    if (isNaN(this.resultBur) || this.resultBur === 0) {
      this.resultBur = "";
    } else {
      this.resultBur = this.resultBur + "%";
    }
    //**Yard Utilization Rate */
    this.currentValueYur = rate.yard_utilization_rate.current_value_ct;

    this.previousDayYur = rate.yard_utilization_rate.previous_day_ct;
    this.convertYur = isNaN(this.currentValueYur) ? 0 : ((this.currentValueYur / 80) * 100).toFixed(0);
    this.deltaYur = (
      ((this.currentValueYur - this.previousDayYur) / this.previousDayYur) *
      100
    ).toFixed(0);
    this.resultYur = Math.abs(parseFloat(this.deltaYur));
    if (isNaN(this.resultYur) || this.resultYur === 0) {
      this.resultYur = "";
    } else {
      this.resultYur = this.resultYur + "%";
    }
  }

  getPortAuthorityTrafficAndDwellTime(
    time: TraffifDwellTimeModel
  ) {

    // const response = trafficDwell;
    this.currentValueVth = this.formatNumber(
      time.vehicle_traffic_hours.current_value_vth
    );
    this.previousDayVth = this.formatNumber(
      time.vehicle_traffic_hours.previous_day_vth
    );
    this.convertVth = this.currentValueVth - this.previousDayVth;
    this.deltaVth = (this.convertVth / this.previousDayVth) * 100;
    this.resultVth = Math.round(Math.abs(this.deltaVth));
    if (isNaN(this.resultVth) || this.resultVth === 0) {
      this.resultVth = "";
    } else {
      this.resultVth = this.resultVth + "%";
    }

    this.previousDayVslth = Number.isInteger(time.vessel_traffic_hours.previous_day_vslth)
      ? this.formatNumber(time.vessel_traffic_hours.previous_day_vslth)
      : time.vessel_traffic_hours.previous_day_vslth;


    this.currentValueVslth = Number.isInteger(time.vessel_traffic_hours.current_value_vslth)
      ? this.formatNumber(time.vessel_traffic_hours.current_value_vslth)
      : time.vessel_traffic_hours.current_value_vslth;


    let convertVslth = this.currentValueVslth - this.previousDayVslth;
    let deltaVslth = (convertVslth / this.previousDayVslth) * 100;
    this.resultVslth = Math.round(Math.abs(deltaVslth));
    if (isNaN(this.resultVslth) || this.resultVslth === 0) {
      this.resultVslth = "";
    } else {
      this.resultVslth = this.resultVslth + "%";
    }


    this.currentValueCdt = Number.isInteger(time.container_dwell_time.current_value_cdt)
      ? this.formatNumber(time.container_dwell_time.current_value_cdt)
      : time.container_dwell_time.current_value_cdt;

    this.previousDayCdt = Number.isInteger(time.container_dwell_time.previous_day_cdt)
      ? this.formatNumber(time.container_dwell_time.previous_day_cdt)
      : time.container_dwell_time.previous_day_cdt;

    let convertCdt = this.currentValueCdt - this.previousDayCdt;
    let deltaCdt = (convertCdt / this.previousDayCdt) * 100;
    this.resultCdt = Math.round(Math.abs(deltaCdt));
    if (isNaN(this.resultCdt) || this.resultCdt === 0) {
      this.resultCdt = "";
    } else {
      this.resultCdt = this.resultCdt + "%";
    }
  }

  getPortAuthorityMemberGeneralInfoAnalytics(info: PortMemberGeneralDetailsModel

  ) {
    // const response = info;
    this.currentValueThc = info.total_head_count.current_value_thc;
    this.previousDayThc = info.total_head_count.previous_day_thc;
    this.deltaThc = (
      ((this.currentValueThc - this.previousDayThc) / this.previousDayThc) *
      100
    ).toFixed(2);
    this.resultThc = Math.abs(this.deltaThc);
    this.resultThc = Math.round(this.resultThc);
    if (isNaN(this.resultThc) || this.resultThc === 0) {
      this.resultThc = "";
    } else {
      this.resultThc = this.resultThc + "%";
    }
    //** Total Incident */
    this.currentValueTi = info.total_incidents.current_value_ti;
    this.previousDayTi = info.total_incidents.current_value_ti;
    this.deltaTi = (
      ((this.currentValueTi - this.previousDayTi) / this.previousDayTi) *
      100
    ).toFixed(0);
    this.resultTi = Math.abs(this.deltaTi);
    if (isNaN(this.resultTi) || this.resultTi === 0) {
      this.resultTi = "";
    } else {
      this.resultTi = this.resultTi + "%";
    }

    //** Container Throughput */
    this.currentValueCt = info.container_throughput.current_value_ct;
    this.previousDayCt = info.container_throughput.previous_day_ct;
    this.totalCt = (
      ((this.currentValueCt - this.previousDayCt) / this.previousDayCt) *
      100
    ).toFixed(0);
    this.resultCt = Math.abs(parseFloat(this.totalCt));
    this.convertCt = this.currentValueCt;
    if (isNaN(this.resultCt) || this.resultCt === 0) {
      this.resultCt = "";
    } else {
      this.resultCt = this.resultCt + "%";
    }
  }

  formatNumber(number) {
    if (Number.isInteger(number)) {
      return number;
    } else {
      return number.toFixed(1);
    }
  }

  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  onMouseOut(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.close();
  }

  showImages() {
    this.modalImageSource = this.imageSources[0];
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.modal.nativeElement.style.display = "block";
    this.updateIndicators();
  }

  updateIndicators() {
    this.indicators = Array(this.imageSources.length).fill(false);
    this.indicators[this.imageIndex] = true;
    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    this.showPreviousButton = this.imageIndex !== 0;
    this.showNextButton = this.imageIndex !== this.imageSources.length - 1;
  }

  updateImage(index: number) {
    this.imageIndex = index;
    this.modalImageSource = this.imageSources[this.imageIndex];
    this.updateIndicators();
  }

  navigateImage(step: number) {
    this.imageIndex += step;

    if (this.imageIndex < 0) {
      this.imageIndex = this.imageSources.length - 1;
    } else if (this.imageIndex >= this.imageSources.length) {
      this.imageIndex = 0;
    }
    this.updateImage(this.imageIndex);
  }

  jumpToImage(index: number) {
    this.updateImage(index);
  }

  openModal(index: number) {
    this.imageIndex = index;
    this.modalImageSource = this.imageSources[this.imageIndex];
  }

  closeModal() {
    this.modal.nativeElement.style.display = "none";
  }

  activatedDiv(div: string) {
    if (div == "portDetails") {
      this.getPortAuthorityMemberData(this.datas);
      this.getPortAuthorityMemberGeoCoordinates();
    } else if (div == "analytics") {
      this.getPortAuthorityMemberUtilizationRatesAnalytics(this.rate

      );
      this.getPortAuthorityTrafficAndDwellTime(this.time

      );
      this.getPortAuthorityMemberGeneralInfoAnalytics(this.gen

      );
    }
  }

  toggleDiv(div: string): void {
    this.showDiv = div;
    this.activeDiv = div;
    this.activatedDiv(div);
    this.unselectedViews = [];
  }
}

export interface markerDetail {
  latitude: number;
  longitude: number;
  port: string;
  flag: boolean;
}

export interface latlng {
  lat: number;
  lng: number;
}

import { Component, OnInit, ViewChild, AfterViewInit , Input, OnChanges} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import * as data from 'src/app/shared/mock-data/overstay-truck.json';
import { OutboundTrucksService } from "src/app/shared/services/specs/outbound-truck-service";
import { MatDialog } from "@angular/material/dialog";
import {MatSort} from '@angular/material/sort';
import { OUTBOUND_TRUCKS, MESSAGE_DISPLAY } from "src/app/shared/constants/api.constant";
import { ObtDetailsComponent } from "src/app/operations-dashboard/components/obt-details/obt-details.component";
import { TruckData } from "src/app/shared/models/yard-operations/outbound-trucks.model";



@Component({
  selector: "app-overstay-truck",
  templateUrl: "./overstay-truck.component.html",
  styleUrls: ["./overstay-truck.component.scss"],
})
export class OverstayTruckComponent implements OnInit, OnChanges, AfterViewInit{
  // dataSource = new MatTableDataSource<any>(data.overstayTruck as any); 
  constructor(
    private outboundTrucksService: OutboundTrucksService,
    private dialog: MatDialog
  ) {  }
  private data: TruckData[] = []
  private initialData: TruckData[] = []
  private paginator: MatPaginator
  private sort: MatSort
  @Input() outboundtrucksdata;
  @ViewChild(MatPaginator, {static: false}) set matPaginator (mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator
  }
  @ViewChild(MatSort, {static: false}) set matSort(ms:MatSort) {
    this.sort = ms
    this.dataSource.sort = this.sort;
  }
  public dataSource = new MatTableDataSource<TruckData>(data.truckList as any)
  public columnHeaders = ['vehicleLicense', 'containers', 'status', 'time', 'gateIn', 'trouble_ticket', 'View details'];
  public pageIndex = OUTBOUND_TRUCKS.PAGINATOR.PAGE_INDEX
  public pageSize = OUTBOUND_TRUCKS.PAGINATOR.PAGE_SIZE
  public pageSizeOptions = OUTBOUND_TRUCKS.PAGINATOR.PAGE_SIZE_OPTIONS
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public tooltipval
  public noData = false;

  ngOnInit() { }
  
ngAfterViewInit(): void {
}
  ngOnChanges(): void {
    if (this.outboundtrucksdata != undefined && this.outboundtrucksdata.length !== 0) {
      console.log("OBT Data",this.displayOBTData);
      this.displayOBTData()
    }
  }
  displayOBTData() {
    const dateToday = new Date();
    const c = [
      `${dateToday.getDate() < 10 ? '0' : ''}${dateToday.getDate()}`,
      this.outboundTrucksService.monthsAll[dateToday.getMonth()],
      dateToday.getFullYear().toString()
    ].join('-');
  
    let dataForToday = [];
  
    this.data = this.outboundtrucksdata.map((el: TruckData) => {
      if (el.transactionDate) {
        const newArr = this.outboundTrucksService.formatDateTime(el);
        const d = el.transactionDate.slice(0, 11);
  
        if (el.containers.length > 1) this.tooltipval = el.containers.join('\n');
        if (d == c && el.time) dataForToday.push({
          ...el,
          containerNumber: el.containers.length > 1 ? el.containers[0] : el.containers
          
        });
      }
    });
  
    this.noData = dataForToday.length === 0;
    this.dataSource.data = dataForToday;
  }


  openModal(element) {
    this.dialog.open(ObtDetailsComponent, {
      data: {
        ...element
      },
      maxWidth: '800px',
      width: '720px',
      panelClass: 'custom_dialog',
      autoFocus: false
    })
  }
  
}

export const THRESHOLD = {
  STATUS: {
    HIGH: 'high_threshold',
    MID: 'mid_threshold',
    LOW: 'low_threshold'
  },
  VALUE: {
    HIGH: 80,
    LOW: 40
  },
  COLOR: {
    GREEN: '#1abc9c',
    YELLOW: 'yellow',
    RED: '#e74c3c'
  },
  COLOR_VALUE: {
    HIGH: '#1abc9c',
    MID: '#F5A623',
    LOW: '#e74c3c',
  }
};

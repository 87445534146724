import { Injectable, NgZone } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpClientInterceptor implements HttpInterceptor {
  private allowedBaseUrls: Array<string> = [environment.basePath];

  constructor(
    private _ngZone: NgZone,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const baseUrlMatches = this.allowedBaseUrls.some(baseUrl => req.url.trim().startsWith(baseUrl.trim()));

    if (baseUrlMatches) {
      const accessToken = sessionStorage.getItem('accessToken');
      const transactionId = sessionStorage.getItem('transactionId');

      if (accessToken) {
        req = req.clone({
          setHeaders: {
            "Content-Type": 'application/json',
            Authorization: accessToken,
            transaction_id: transactionId
          }
        });
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._ngZone.run(() => {
            sessionStorage.clear();
            this.router.navigate(['/error/401']);
          })
        }
        throw new Error(JSON.stringify(error));
      })
    );
  }
}

import {Directive, Input, ElementRef, OnInit, OnChanges, OnDestroy} from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import {SplitCamelCasePipe} from '../custom-pipes/split-camel-case.pipe';

@Directive({
  selector: '[appSteppedLineChart]',
})
export class SteppedLineChartCustomDirective implements OnInit, OnChanges, OnDestroy {

  @Input() appSteppedLineChart: ElementRef;
  @Input() data: Data[] = [] as Data[];
  private flattenData: any[];
  /*
  Sample data:
  [
    {
      name: "Group 1",
      category: "label 1",
      values: [
        {"section" : "A", value: 30}
      ]
    },
    {
      name: "Group 2",
      category: "label 1",
      values: [
        {"section" : "A", value: 5}
      ]
    }
  ]

  Sample HTML implementation
  <div appSteppedLineChart [data]="data"></div>
  */
  private hostElement: any;
  private svg;
  private g;
  private xScale;
  private yScale;
  private colorScale;
  private margin = {left: 50, right: 30, top: 30, bottom: 50};
  private width: number;
  private height: number;
  private xAxis;
  private yAxis;
  private xAxisGrid;
  private yAxisGrid;
  private line;
  private lines;
  private tooltipFilters = {};
  private sameEntriesMessage = 'Movement/s';

  constructor(
    private chartContainer: ElementRef,
    private splitCamelCasePipe: SplitCamelCasePipe) {
  }

  ngOnInit(): void {
    if (this.data.length > 0) {
      this.flatData();
      this.createChart();
      this.drawAxis();
      this.drawLines();
    }
    // Resize a browser
    d3.select(window).on('resize', () => this.onResize() );
  }

  ngOnChanges(): void {
    if (this.svg === undefined) {
      this.createChart();
    } else {
      this.flatData();
      this.setScale();
      this.setAxis();
      this.setLines();
      this.drawAxis();
      this.drawLines();
    }
  }

  ngOnDestroy(): void {
    d3.select(this.hostElement).select('svg').remove();
  }

  private flatData() {
    this.flattenData = this.data.map(item => {
      item.values.forEach(v => {
        item[v.section] = v.value;
      });
      return {
        ...item
      };
    });
  }

  private createChart(): void {
    this.initSvg();
    this.initTooltip();
    this.setScale();
    this.setAxis();
    this.setLines();
  }


  private initSvg() {
    this.hostElement = this.chartContainer.nativeElement;
    // create an svg
    d3.select(this.hostElement).select('svg').remove();
    this.svg = d3.select(this.hostElement).append('svg')
      .attr('width', '100%')
      .attr('height', '400px')
      .attr('class', 'stepped-line-chart');

    // set bounds
    const bounds = (d3.select(this.hostElement).select('svg').node() as HTMLElement).getBoundingClientRect();
    this.width = bounds.width - this.margin.left - this.margin.right;
    this.height = bounds.height - this.margin.top - this.margin.bottom;

    // create over all group
    this.g = this.svg.append('g')
      .attr('transform', 'translate(' + this.margin.left +
        ', ' + this.margin.top + ')');
  }

  private initTooltip() {
    // Define 'div' for tooltips
    d3.select('.tooltip-slc').remove();
    d3.select('body')
      .append('div')
      .attr('class', 'tooltip-slc')
      .style('opacity', 0);
  }

  private setScale() {
    this.xScale = d3.scaleBand().rangeRound([0, this.width]).paddingOuter(0.3).paddingInner(0);
    this.yScale = d3.scaleLinear().range([this.height, 0]);
    this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);
  }

  private setAxis() {
    // Define axes
    this.xAxis = d3.axisBottom(this.xScale);
    this.yAxis = d3.axisLeft(this.yScale);
    this.xAxisGrid = d3.axisBottom(this.xScale);
    this.yAxisGrid = d3.axisLeft(this.yScale);
    // Set the domain of the axes
    this.xScale.domain(this.data[0].values.map(d => d.section));
    // this.yScale.domain([0, d3.max(this.getTickValues())]);
    this.yScale.domain([0, d3.max(d3.merge(this.data.map(d => d.values)), (d: Value) => d.value)]).nice();
  }

  private setLines() {
    // Define lines
    this.line = d3.line()
      .x(d => this.xScale(d['section']))
      .y(d => this.yScale(d['value']))
      .curve(d3.curveStepAfter);
  }

  private drawAxis() {
    d3.select(this.hostElement).select('svg').select('g').select('g.axis-x').remove();
    d3.select(this.hostElement).select('svg').select('g').select('g.axis-y').remove();
    d3.select(this.hostElement).select('svg').select('g').select('g.grid-x').remove();
    d3.select(this.hostElement).select('svg').select('g').select('g.grid-y').remove();
    // add the X Axis
    this.g.append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .attr('class', 'axis-x')
      .call(this.xAxis);

    // add the Y Axis
    this.g.append('g')
      .attr('class', 'axis-y')
      .call(d3.axisLeft(this.yScale));

    // add grid x line
    this.g.append('g')
      .attr('class', 'grid-x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(this.xAxisGrid.tickSize(-this.height))
      .selectAll('text').remove();

    // add grid y line
    this.g.append('g')
      .attr('class', 'grid-y')
      .call(this.yAxisGrid.tickSize(-this.width))
      .selectAll('text').remove();
  }

  private generatePath(locData, svgWidth) {
    // generate custom path. added some line extension to the graph vertically
    let linePath = this.line(locData);
    linePath += 'L' + (this.xScale(locData[locData.length - 1]['section']) +
      (((svgWidth / locData.length) / 4) * 3) + 8) +
      ',' +
      this.yScale(locData[locData.length - 1].value);
    return linePath;
  }

  private onResize() {
    const bounds = (d3.select(this.hostElement).select('svg').node() as HTMLElement).getBoundingClientRect();
    const widthR = bounds.width - this.margin.left - this.margin.right;
    const heightR = bounds.height - this.margin.top - this.margin.bottom;

    // Update the range of the scale with new width/height
    this.xScale.range([0, widthR]);
    this.yScale.range([heightR, 0]);

    // Update the axis and text with the new scale
    this.svg.select('.axis-x')
      .attr('transform', 'translate(0,' + heightR + ')')
      .call(this.xAxis);

    this.svg.select('.axis-y')
      .call(this.yAxis);

    this.svg.select('.grid-x')
      .attr('transform', 'translate(0,' + heightR + ')')
      .call(this.xAxisGrid.tickSize(-heightR))
      .selectAll('text').remove();

    this.svg.select('.grid-y')
      .call(this.yAxisGrid.tickSize(-widthR))
      .selectAll('text').remove();

    // Force D3 to recalculate and update the line
    this.svg.selectAll('.line')
      .attr('d', (d) => {
        return this.generatePath(d['values'], widthR);
      });
  }

  private drawLines() {
    d3.select(this.hostElement).select('svg').select('g').select('g.lines').remove();
    this.lines = this.g.append('g').attr('class', 'lines');
    this.lines.selectAll('.line-group')
      .data(this.data).enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .attr('d', d => this.generatePath(d.values, this.width))
      .attr('fill', 'none')
      .style('stroke-width', 3)
      .attr('stroke', (d, i) => this.colorScale(i.toString()))
      .on('mouseover', (d: Data, i) => {
        d3.select('.tooltip-slc').select('.tooltip-content').remove();
        this.tooltipFilters = {};
        d.values.forEach(v => {
          this.tooltipFilters[v.section] = v.value;
        });
        const sameHoveredData =  _.filter(this.flattenData, {...this.tooltipFilters});
        d3.selectAll('.line').style('opacity', 0).style('stroke-width', 1);
        d3.select(d3.event.currentTarget)
          .style('opacity', 1.0)
          .style('stroke-width', 5)
          .style('cursor', 'pointer');
        if (sameHoveredData.length === 0) {
          d3.select('.tooltip-slc').style('opacity', 1);
          const tooltip_content = d3.select('.tooltip-slc')
            .append('div')
            .attr('class', 'tooltip-content');
          const tooltip_content_header = tooltip_content.append('div').attr('class', 'tooltip-content-header');
          tooltip_content_header.append('span')
            .attr('class', 'tooltip-content-header-box')
            .style('background-color', this.colorScale(i.toString()));
          tooltip_content_header.append('span')
            .attr('class', 'tooltip-content-header-text')
            .html(d.name);
          tooltip_content_header.append('div')
            .attr('class', 'tooltip-content-header-text')
            .html(d.category);
          const tooltip_content_details = tooltip_content.selectAll('.section')
            .data(d.values)
            .enter()
            .append('div')
            .attr('class', 'tooltip-content-details');
          tooltip_content_details.append('span')
            .attr('class', 'tooltip-content-details-label')
            .html((v: Value) => v.section);
          tooltip_content_details.append('span').html(':');
          tooltip_content_details.append('span')
            .attr('class', 'tooltip-content-details-value')
            .html((v: Value) => v.value.toString());
        } else if (sameHoveredData.length > 0) {
          d3.select('.tooltip-slc').style('opacity', 1);
          const tooltip_content = d3.select('.tooltip-slc')
            .append('div')
            .attr('class', 'tooltip-content');
          const tooltip_content_header = tooltip_content.append('div').attr('class', 'tooltip-content-header');
          tooltip_content_header.append('span')
            .attr('class', 'tooltip-content-header-box')
            .style('background-color', this.colorScale(i.toString()));
          tooltip_content_header.append('span')
            .attr('class', 'tooltip-content-header-text')
            .html(this.sameEntriesMessage);
          tooltip_content.append('div')
            .attr('class', 'tooltip-content-details');
          this.populateTable(sameHoveredData, '.tooltip-content-details');
        }
      })
      .on('mouseout', (d, i) => {
        d3.selectAll('.line').style('opacity', 1).style('stroke-width', 3);
        d3.select(d3.event.currentTarget).style('stroke-width', 3).style('cursor', 'none');
        d3.select('.tooltip-slc').select('.tooltip-content').remove();
        d3.select('.tooltip-slc').style('opacity', 0);
      })
      .on('mousemove', () => {
        const body = (d3.select('body').node() as HTMLElement).getBoundingClientRect();
        const tooltip = (d3.select('.tooltip-slc').node() as HTMLElement).getBoundingClientRect();
        d3.select('.tooltip-slc')
          .style('top', ((<any>d3.event).pageY - (tooltip.height + 10)) + 'px');
        if (((<any>d3.event).pageX + (tooltip.width / 2)) > body.width) {
          d3.select('.tooltip-slc')
            .style('left', ((<any>d3.event).pageX - tooltip.width - ((body.width - ((<any>d3.event).pageX + (tooltip.width / 2))))) + 'px');
        } else {
          d3.select('.tooltip-slc')
            .style('left', ((<any>d3.event).pageX - (tooltip.width / 2)) + 'px');
        }
      });
  }

  private populateTable(sameHoveredData: any[], element) {
    const columns = ['name', 'category', ...this.data[0].values.map(d => d.section)];
    const table = d3.select(element).append('table'),
      thead = table.append('thead'),
      tbody = table.append('tbody');

    thead.append('tr')
      .selectAll('th')
      .data(columns)
      .enter()
      .append('th')
      .text((column) => {
        if (column === 'name') {
          column = 'moveDate';
        } else if (column === 'category') {
          column = 'moveType';
        }
        return this.splitCamelCasePipe.transform(column);
      });

    const rows = tbody.selectAll('tr')
      .data(sameHoveredData)
      .enter()
      .append('tr');

    rows.selectAll('td')
      .data(row => {
        return columns.map(column => {
          return {value: row[column]};
        });
      })
      .enter()
      .append('td')
      .text(d => d.value);
  }

  getTickValues () {
    // Incase needs to have a custom tick values
    // let max = d3.max(d3.merge(this.data.map(d => d.values)), (d: Value) => d.value);
    // const tickValues = [];
    // if (max % this.options.tickStep !== 0) {
    //   max = max + (this.options.tickStep - (max % this.options.tickStep));
    // }
    // for (let i = 0; i <= max; i++ ) {
    //   if (i % this.options.tickStep === 0) {
    //     tickValues.push(i);
    //   }
    // }
    // return tickValues;
  }
}

export interface Data {
  name:   string;
  category:   string;
  values: Value[];
}

export interface Value {
  section: string;
  value:   number;
}

export interface Options {
  tickStep: number;
}

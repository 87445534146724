import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EquipmentDetailsComponent } from './equipment-details.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PerformanceTargetComponent } from './performance-target/performance-target.component';

const routes: Routes = [
  {
    path: ':equipmentType/:equipmentId/:equipmentName',
    component: EquipmentDetailsComponent,
    data: { breadcrumb: 'Equipment List' },
    children: [
      {
        path: 'performance',
        component: PerformanceTargetComponent,
        data: { breadcrumb: 'Performance vs Target' },
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent,
        data: { breadcrumb: 'Maintenance' },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquipmentDetailsRoutingModule { }

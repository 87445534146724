import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { API } from '../constants/api.constant';


@Injectable({
  providedIn: 'root'
})
export class PortService {
  private serviceUrl = environment.basePath + API.PORTS;

  constructor(private http: HttpClient) { }

  getPort():Observable<ApiResponse | ErrorResponse | any>{
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.serviceUrl}/ports`)
  }
}

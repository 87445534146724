import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { PortAuthorityState } from "./port-authority.reducers";

export const selectPortAuthorityState = (state: AppState) => state.portauthority;

export const selectPortAuthorityMember = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthMember
);

export const selectPortAuthorityMemberDetails = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthMemberDetails
);

export const selectPortAuthorityMembersCoordinates = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthMemberCoordinates
);

export const selectPortAuthorityGeneralInfoAnalytics = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthGeneralInfoAnalytics
);


export const selectPortAuthorityUtilizationRateAnalytics = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthUtilizationRateAnalytics
);


export const selectPortAuthorityTrafficDwellTimeAnalytics = createSelector(
    selectPortAuthorityState,
    (state: PortAuthorityState) => state.portAuthTrafficDwellTime
);
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { CraneProductivityModel } from 'src/app/shared/models';
import { getCraneProductivity } from 'src/app/shared/state/operations/operations.action';
import { selectCraneProductivity } from 'src/app/shared/state/operations/operations.selectors';

export interface Legends {
  low_threshold: any;
  mid_threshold: any;
  high_threshold: any;
}

@Component({
  selector: 'app-crane-movement',
  templateUrl: './crane-movement.component.html',
  styleUrls: ['./crane-movement.component.scss']
})
export class CraneMovementComponent implements OnInit, AfterViewInit {
  @Input()
  portId: string;

  @Input()
  equipmentId: string;
  @Input()
  equipmentName: string;

  craneProductivity$: Observable<CraneProductivityModel> = this.store.select(selectCraneProductivity);

  private canvas = {
    colors: [],
    data: [],
    labels: [],
    options: {},
    legend: false,
    type: '',
    settings: {
      CraneProductivityLowThreshold: 10,
      CraneProductivityHighThreshold: 15,
    }
  };
  private settings = {
    CraneProductivityLowThreshold: 10,
    CraneProductivityHighThreshold: 15,
  }

  public legends: Legends = {
    low_threshold: `< ${this.settings.CraneProductivityLowThreshold}`,
    mid_threshold: `${this.settings.CraneProductivityLowThreshold} -
    ${this.settings.CraneProductivityHighThreshold}`,
    high_threshold: `> ${this.settings.CraneProductivityHighThreshold}`
  };

  public equipmentGMPH: any;
  public timePerContainer: any;
  public timeToMove: any;
  public chartIsReady = false;

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private roundPipe: RoundPipe
  ) { }

  ngOnInit() {
    this.store.dispatch(getCraneProductivity ({ portId: this.portId, equipmentId: this.equipmentId }));
  }

  ngAfterViewInit(): void {
    this.craneProductivity$.subscribe((craneProductivity) => {
      if (craneProductivity) {
        this.initializeCraneProductivityData(craneProductivity);
      }
    });
  }


  initializeCraneProductivityData(data: CraneProductivityModel) {
    this.equipmentGMPH = this.roundPipe.transform(data.bch);
    
    this.timePerContainer = this.roundPipe.transform(data.tpc);
    this.timeToMove = this.roundPipe.transform(data.ttm);
    this.initCanvas();
    this.rotateIndicator();
  }


  initCanvas() {
    this.canvas.colors = [{
      backgroundColor: [
        THRESHOLD.COLOR_VALUE.LOW,
        THRESHOLD.COLOR_VALUE.MID,
        THRESHOLD.COLOR_VALUE.HIGH
      ]
    }];
    const lowThresholdData = this.settings.CraneProductivityLowThreshold;
    const midThresholdData = this.settings.CraneProductivityHighThreshold -
      this.settings.CraneProductivityLowThreshold;
    const highThresholdData = 100 - (lowThresholdData + midThresholdData);
    this.canvas.data = [lowThresholdData, midThresholdData, highThresholdData];
    this.canvas.labels = [
      `< ${this.settings.CraneProductivityLowThreshold} Container/Hour`,
      `${this.settings.CraneProductivityLowThreshold} -
      ${this.settings.CraneProductivityHighThreshold} Container/Hour`,
      `> ${this.settings.CraneProductivityHighThreshold} Container/Hour`
    ];
    this.canvas.options = {
      circumference: Math.PI,
      rotation: 1.0 * Math.PI,
      cutoutPercentage: 80,
      elements: { arc: { borderWidth: 0 } },
      animation: false,
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      tooltips: { enabled: false }
    };
    this.canvas.legend = false;
    this.canvas.type = 'doughnut';

    this.legends = this.legends;

    this.chartIsReady = true;
  }


  rotateIndicator() {
    const rotate = ((this.equipmentGMPH * 180) / 100) - 90;
    this.renderer.setStyle(
      this.elementRef.nativeElement.parentNode.querySelector('.pointer-inner'),
      'transform', 'rotate(' + rotate + 'deg)'
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement.parentNode.querySelector('.pointer-inner'),
      '-webkit-transform', 'rotate(' + rotate + 'deg)'
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement.parentNode.querySelector('.pointer-inner'),
      '-moz-transform', 'rotate(' + rotate + 'deg)'
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement.parentNode.querySelector('.pointer-inner'),
      '-ms-transform', 'rotate(' + rotate + 'deg)'
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement.parentNode.querySelector('.pointer-inner'),
      '-o-transform', 'rotate(' + rotate + 'deg)'
    );
  }


  public craneMovementChartClicked(e: any): void { }
  public craneMovementChartHovered(e: any): void { }
}

import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PortProductivityKpiModel } from 'src/app/shared/models';
import { getPortProductivityKpi } from 'src/app/shared/state/operations/operations.action';
import { selectPortProductivityKpi } from 'src/app/shared/state/operations/operations.selectors';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
@Component({
  selector: 'app-port-productivity-kpi',
  templateUrl: './port-productivity-kpi.component.html',
  styleUrls: ['./port-productivity-kpi.component.scss']
})
export class PortProductivityKpiComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  selectedPortId: string;

  portProductivityKpi$: Observable<PortProductivityKpiModel> = this.store.select(selectPortProductivityKpi);
  public borTargetPercentValue = 30;
  public bshTargetValue = 104;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getPortProductivityKpi({ portId: this.selectedPortId }));

  }


  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getPortProductivityKpi({ portId: this.selectedPortId }));
      }
    }
  }

  ngAfterViewInit(): void {

  }

}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getEmissionFactors } from 'src/app/shared/state/sustainability/sustainability.actions';
import { selectEmissionFactors } from 'src/app/shared/state/sustainability/sustainability.selectors';
import { CarbonService } from 'src/app/shared/common-function/carbon.service';
import { Router } from '@angular/router';
import { EmissionFactorsModel } from 'src/app/shared/models';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-carbon-calculator',
  templateUrl: './carbon-calculator.component.html',
  styleUrls: ['./carbon-calculator.component.scss']
})
export class CarbonCalculatorComponent implements OnInit {
  emissionFactors$: Observable<EmissionFactorsModel[]> = this.store.select(selectEmissionFactors);

  carbonForm: carbonForm = {};
  accumulatedValues: carbonForm[] = [];
  isDropdownVisibleYear: boolean = false;
  isDropdownVisibleType: boolean = false;
  isDropdownVisibleLocation: boolean = false;
  selectedYear: string = 'Select Year';
  selectedType: string = 'Select Emission Type';
  selectedLocation: string = 'Select Location';
  emissionKwh: number;
  totalConsumption: number;
  totalFootprintScope1: number;
  totalFootprintScope2: number;
  selectedEmissionTypes: string[] = [];
  options = [
    { id: 'twentyFour', value: '2024', label: '2024' },
    { id: 'twentyThree', value: '2023', label: '2023' },
    { id: 'twentyTwo', value: '2022', label: '2022' }
  ];
  optionType = [
    { id: 'elect', valueType: 'Electricity', labelType: 'Electricity' },
    { id: 'natural', valueType: 'Natural Gas', labelType: 'Natural Gas' },
    { id: 'nuclear', valueType: 'Nuclear', labelType: 'Nuclear' }
  ];

  constructor(
    private store: Store,
    private carbonService: CarbonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getEmissionFactors());

    const savedData = localStorage.getItem('carbonData');
    if (savedData) {
      this.accumulatedValues = JSON.parse(savedData);
      this.updateTotalConsumption();
    }

    if (this.accumulatedValues.length > 0) {
      this.selectedYear = this.accumulatedValues[0].reportingYear.toString();
      this.selectedLocation = this.accumulatedValues[0].emissionFactor;
      this.emissionKwh = this.accumulatedValues[0].emissionFactorKwh;
    }

    this.selectedEmissionTypes = this.carbonService.getSelectedEmissionTypes();
  }

  addToTable() {
    if (this.accumulatedValues.length >= 3) {
      console.log("You can only add a maximum of three entries.");
      return;
    }

    const existingEntryIndex = this.accumulatedValues.findIndex(entry => entry.emissionType === this.carbonForm.emissionType);
    if (existingEntryIndex !== -1) {
      console.log("An entry with the same emission type already exists.");
      return;
    }

    this.carbonForm.reportingYear = parseInt(this.selectedYear);
    this.carbonForm.emissionFactor = this.selectedLocation;
    this.carbonForm.emissionFactorKwh = this.emissionKwh;
    this.carbonForm.amount = this.carbonForm.amount.toLocaleString();
    this.accumulatedValues.push(this.carbonForm);
    this.updateTotalConsumption();
    this.carbonForm = {};
    this.selectedType = 'Select Emission Type';
  }

  updateTotalConsumption() {
    this.totalConsumption = this.accumulatedValues.reduce((sum, value) => sum + parseFloat(value.amount.replace(/,/g, '')), 0);
  }

  onCalculateFootprint() {
    if (this.accumulatedValues.length > 0) {
      this.updateTotalConsumption();
      let emissionFactor = this.accumulatedValues[0].emissionFactorKwh || 1;
      let totalFootprint = this.totalConsumption * emissionFactor;
      this.carbonService.setTotalConsumption(this.totalConsumption);
      this.carbonService.setReportingYear(parseInt(this.selectedYear));

      this.carbonService.setTotalFootprint(totalFootprint);
      this.router.navigate(['/sustainability-dashboard/carbon/footprint']);

      let scope1Values = this.accumulatedValues.filter(entry => entry.emissionType !== 'Electricity');
      let scope2Values = this.accumulatedValues.filter(entry => entry.emissionType === 'Electricity');

      let totalScope1Consumption = scope1Values.reduce((sum, value) => sum + parseFloat(value.amount.replace(/,/g, '')), 0);
      let totalScope2Consumption = scope2Values.reduce((sum, value) => sum + parseFloat(value.amount.replace(/,/g, '')), 0);
      let emissionFactorScope1 = this.accumulatedValues[0].emissionFactorKwh || 1;


      let emissionFactorScope2 = scope2Values.length > 0 ? scope2Values[0].emissionFactorKwh || 1 : 1;
      let totalFootprintScope1 = totalScope1Consumption * emissionFactorScope1;
      let totalFootprintScope2 = totalScope2Consumption * emissionFactorScope2;
      this.carbonService.setTotalFootprintScope1(totalFootprintScope1);
      this.carbonService.setTotalFootprintScope2(totalFootprintScope2);
      this.carbonService.setTotalConsumption(this.totalConsumption);
      let totalFootprintPercentageScope1 = (totalFootprintScope1 / (totalFootprintScope1 + totalFootprintScope2)) * 100;
      let totalFootprintPercentageScope2 = (totalFootprintScope2 / (totalFootprintScope1 + totalFootprintScope2)) * 100;
      this.carbonService.setTotalFootprintPercentageScope1(totalFootprintPercentageScope1);
      this.carbonService.setTotalFootprintPercentageScope2(totalFootprintPercentageScope2);
      let totalConsumptionDividedBy22 = this.totalConsumption / 22;
      this.carbonService.setTotalConsumptionDividedBy22(totalConsumptionDividedBy22);
    }
  }

  isFormValid(): boolean {
    return !!this.carbonForm.reportingYear &&
      !!this.carbonForm.emissionType &&
      !!this.carbonForm.amount &&
      !!this.carbonForm.emissionFactor;
  }

  navigateToFootprint(): void {
    this.router.navigate(['/sustainability-dashboard/carbon/footprint']);
  }

  removeFromTable(index: number) {
    this.accumulatedValues.splice(index, 1);

    if (this.accumulatedValues.length == 0) {
      this.selectedYear = 'Select Year';
      this.selectedLocation = 'Select Location';
      this.emissionKwh = 0;
    }
  }

  selectOptionYear(value: string) {
    this.selectedYear = value;
    this.carbonForm.reportingYear = parseInt(value);
    this.carbonService.clearSelectedEmissionTypes();
    this.selectedEmissionTypes = [];
    this.carbonService.setReportingYear(parseInt(this.selectedYear));
  }

  handleMouseLeaveYear(): void {
    this.isDropdownVisibleYear = false;
  }

  toggleDropdownYear(): void {
    this.isDropdownVisibleYear = !this.isDropdownVisibleYear;
  }

  selectOptionType(valueType: string) {
    this.selectedType = valueType;
    this.carbonForm.emissionType = valueType;
    this.carbonService.addEmissionType(valueType);
    this.selectedEmissionTypes = this.carbonService.getSelectedEmissionTypes(); // Update local copy
  }

  handleMouseLeaveType(): void {
    this.isDropdownVisibleType = false;
  }

  toggleDropdownType(): void {
    this.isDropdownVisibleType = !this.isDropdownVisibleType;
  }

  selectOptionLocation(location: string, price: number) {
    this.selectedLocation = location;
    this.carbonForm.emissionFactor = location;
    this.carbonForm.emissionFactorKwh = price;
    this.carbonService.setSelectedEmissionFactorPrice(price);
  }

  handleMouseLeaveLocation(): void {
    this.isDropdownVisibleLocation = false;
  }

  toggleDropdownLocation(): void {
    this.isDropdownVisibleLocation = !this.isDropdownVisibleLocation;
  }

  enableButton() {
    this.carbonService.enableButton();
  }
}

interface carbonForm {
  reportingYear?: number;
  emissionFactor?: string;
  emissionFactorKwh?: number;
  emissionType?: string;
  amount?: string;
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ColorCodedBarPieComponent } from './color-coded-bar-pie.component';
import { ChartsModule } from 'ng2-charts';


@NgModule({
  declarations: [
    ColorCodedBarPieComponent
  ],
  exports: [
    ColorCodedBarPieComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    ChartsModule
  ],
  providers: []
})
export class ColorCodedBarPieModule {
  static forRoot(): ModuleWithProviders<ColorCodedBarPieModule> {
    return {
      ngModule: ColorCodedBarPieModule,
      providers: []
    };
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../../services/users.service";
import { createUserSessionFailure, createUserSessionSuccess, 
  createUserSession, getUserDetails, getUserDetailsSuccess, 
  getUserDetailsFailure } from "./user.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService
  ) { }

  //Run this code when a createUserSession is dispatched
  createUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserSession),
      switchMap(() =>
        //Call the createUserSession(), convert it into Observable
        this.userService.createUserSession().pipe(
          //Take the returned value and return a new success action containing the response
          map(() => createUserSessionSuccess({ isValidSession: true })),
          //Or if it returns an error, return a new failure action containing the error
          catchError((error) => of(createUserSessionFailure({ error })))
        )
      )
    )
  );

  getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetails),
      switchMap((action) =>
        this.userService.getUserDetailById(action.userId).pipe(
          map(res => res.data),
          map((data) => getUserDetailsSuccess({ userDetail: data })),
          catchError((error) => of(getUserDetailsFailure({ error })))
        )
      )
    )
  );
}
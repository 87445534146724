import { Component, OnInit, SimpleChanges, Input, OnChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ICON } from "src/app/shared/constants/assets.constant";
import { EngagementAndTrainingMetricsModel } from "src/app/shared/models/engagement-and-training-metrics.model";
import { getEngagementAndTrainingMetrics } from "src/app/shared/state/social-responsibility/social-responsibility.actions";
import { selectEngagementTrainingMetrics } from "src/app/shared/state/social-responsibility/social-responsibility.selectors";

@Component({
  selector: 'app-social-engagement',
  templateUrl: './social-engagement.component.html',
  styleUrls: ['./social-engagement.component.scss']
})
export class SocialEngagementComponent implements OnInit, OnChanges {
  @Input() selectedYear: string;

  engagementAndTrainingMetrics$: Observable<EngagementAndTrainingMetricsModel> = this.store.select(selectEngagementTrainingMetrics);

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getEngagementAndTrainingMetrics({}));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getEngagementAndTrainingMetrics({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getEngagementAndTrainingMetrics({}));
      }
    }
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SustainabilityNavComponent } from './sustainability-nav.component';
import { CarbonManagementComponent } from './carbon-management/carbon-management.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { WaterConservationComponent } from './water-conservation/water-conservation.component';
import { WasteManagementComponent } from './waste-management/waste-management.component';
import { BiodiversityComponent } from './biodiversity/biodiversity.component';
import { SocialResponsibilityComponent } from './social-responsibility/social-responsibility.component';

const routes: Routes = [
  {
    path: "",
    component: SustainabilityNavComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "kpi",
        pathMatch: "full"
      },
      {
        path: "kpi",
        component: SustainabilityComponent,
        data: { breadcrumb: "" }
      },
      {
        path: "carbon-management",
        component: CarbonManagementComponent,
        data: { breadcrumb: "Carbon Management" }
      },
      {
        path: "water-conservation",
        component: WaterConservationComponent,
        data: { breadcrumb: "Water Conservation" }
      },
      {
        path: "waste-management",
        component: WasteManagementComponent,
        data: { breadcrumb: "Waste Management" }
      },
      {
        path: "biodiversity",
        component: BiodiversityComponent,
        data: { breadcrumb: "Biodiversity" }
      },
      {
        path: "social-responsibility",
        component: SocialResponsibilityComponent,
        data: { breadcrumb: "Social Responsibility" }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SustainabilityNavRoutingModule { }

import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import { Observable } from 'rxjs';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { CarbonEmissionReduceSummaryModel, OverallCarbonIntensityModel } from 'src/app/shared/models';
import { getCarbonEmissionReduceSummary, getOverallCarbonIntensity } from 'src/app/shared/state/sustainability/sustainability.actions';
import { selectCarbonEmissionSummary, selectOverallCarbonIntensity } from 'src/app/shared/state/sustainability/sustainability.selectors';

@Component({
  selector: 'app-carbon-information',
  templateUrl: './carbon-information.component.html',
  styleUrls: ['./carbon-information.component.scss']
})
export class CarbonInformationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  carbonEmissionSummary$: Observable<CarbonEmissionReduceSummaryModel> = this.store.select(selectCarbonEmissionSummary);
  carbonInstensity$: Observable<OverallCarbonIntensityModel> = this.store.select(selectOverallCarbonIntensity);
  private carbonEmissionReductionBarChart: Chart;
  overallCarbonIntensity: number;
  yearOnYearTarget: number;
  carbonIntensity: number;
  carbonStatus: string;
  gaugeValue: string;
  gaugeOptions = {
    hasNeedle: false,
    arcColors: [CHART.COLOR.CARBON_INTENSITY.GREEN, CHART.COLOR.CARBON_INTENSITY.GRAY],
    arcDelimiters: [],
    rangeLabel: [],
    needleStartValue: 0,
  }
  reducedCarbonEmission: string;
  reducedCarbonEmissionIntensity: string;

  constructor(private store: Store, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.store.dispatch(getCarbonEmissionReduceSummary({}));
    this.store.dispatch(getOverallCarbonIntensity({}));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.carbonInstensity$.subscribe((data) => {
        if (data) {
          this.createGaugeChart(data);
        }
      });
      this.carbonEmissionSummary$.subscribe((historical_trends) => {
        if (historical_trends) {
          this.createCarbonBarLineChart(historical_trends);
        }
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getOverallCarbonIntensity({ years: this.selectedYear }));
        this.store.dispatch(getCarbonEmissionReduceSummary({ years: this.selectedYear }));
      } else {
        this.store.dispatch(getOverallCarbonIntensity({}));
        this.store.dispatch(getCarbonEmissionReduceSummary({}));

      }
    }
  }

  createGaugeChart(data: OverallCarbonIntensityModel) {
    this.gaugeValue = data.overall_co2_intensity + '%';
    this.overallCarbonIntensity = data.overall_co2_intensity;
    this.yearOnYearTarget = data.yoyTarget;
    this.carbonIntensity = data.co2_intensity;
    this.carbonStatus = data.status;
    const arcDelimiterValue = this.overallCarbonIntensity === 0 ? 1 : this.overallCarbonIntensity;
    this.gaugeOptions.arcDelimiters = [arcDelimiterValue];
  }

  createCarbonBarLineChart(data: CarbonEmissionReduceSummaryModel) {
    if (this.carbonEmissionReductionBarChart) {
      this.carbonEmissionReductionBarChart.destroy();
    }

    const historicalTrends = data.historical_trends;
    const quarterLabels = historicalTrends.map((source) => source.label);
    const carbonEmissionValues = historicalTrends.map((source) => source.co2_emission);
    const carbonIntensityValues = historicalTrends.map((source) => source.co2_emission_intensity);
    this.reducedCarbonEmission = data.reduced_co2_emission.toLocaleString();
    this.reducedCarbonEmissionIntensity = data.reduced_co2_emission_intensity.toLocaleString();

    const canvas: HTMLCanvasElement = this.elementRef.nativeElement.querySelector('#carbonBarLineChart');
    if (!canvas) {
      console.error('Canvas element with id "carbonBarLineChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const carbonBarChartPlugin = {
      id: 'carbonBarChartPlugin',
      beforeDraw(chart: Chart) {
        var ctx = chart.ctx;
        var chartArea = chart.chartArea;

        ctx.lineWidth = 1;
        ctx.strokeStyle = CHART.COLOR.CARBON_INTENSITY.DARK_GRAY;

        ctx.beginPath();
        ctx.moveTo(chartArea.left, chartArea.bottom);
        ctx.lineTo(chartArea.right, chartArea.bottom);
        ctx.stroke();
      },
      afterDraw(chart: Chart) {
        var ctx = chart.ctx;
        var config = chart.config;

        config.data.datasets.forEach(function (dataset: Chart.ChartDataSets, datasetIndex: number) {
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';

          const meta = chart.getDatasetMeta(datasetIndex);
          if (meta.type === 'line') {
            ctx.fillStyle = CHART.COLOR.CARBON_INTENSITY.DARK_BLUE;
            meta.data.forEach((point, index) => {
              const data = Number(dataset.data[index]);
              const xPos = point._model.x;
              const yPos = point._model.y - 5;
              ctx.font = "12px Montserrat";
              ctx.fillText(data.toFixed(3), xPos, yPos);
            });
          }
          else {
            ctx.fillStyle = CHART.COLOR.CARBON_INTENSITY.WHITE;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index].toLocaleString();
              const xPos = bar._model.x;
              const yPos = bar._model.base;
              ctx.font = "10px Montserrat";
              ctx.fillText(data, xPos, yPos);
            });
          }

          dataset.data.forEach(function (value, index) {
            ctx.fillStyle = CHART.COLOR.CARBON_INTENSITY.LIGHT_GRAY;
            var bottomLabelHeight = (chart as any).scales['bar-y-axis'].bottom;
            var x = (chart.getDatasetMeta(datasetIndex).data[index] as any)._model.x;
            var y = (chart.getDatasetMeta(datasetIndex).data[index] as any)._model.y;

            var barWidth = (chart.getDatasetMeta(datasetIndex).data[index] as any)._model.width;
            ctx.fillRect((x - barWidth / 2) - 8, 0, barWidth + 16, bottomLabelHeight);
          });
        });

        ctx.restore();
      }
    };

    this.carbonEmissionReductionBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: quarterLabels,
        datasets: [{
          label: 'Carbon Emission Reduction',
          data: carbonEmissionValues,
          backgroundColor: CHART.COLOR.CARBON_INTENSITY.DARK_GRAY,
          borderColor: CHART.COLOR.CARBON_INTENSITY.DARK_GRAY,
          borderWidth: 1,
          yAxisID: 'bar-y-axis',
          order: 2
        }, {
          label: 'Trend',
          data: carbonIntensityValues,
          type: 'line',
          borderColor: CHART.COLOR.CARBON_INTENSITY.YELLOW,
          borderWidth: 2,
          fill: false,
          yAxisID: 'line-y-axis',
          order: 1,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
          yAxes: [{
            id: 'bar-y-axis',
            position: 'left',
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }, {
            id: 'line-y-axis',
            position: 'right',
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        legend: {
          display: false,
          labels: {
            fontColor: "#111",
            fontSize: 12,
            fontFamily: 'Montserrat'
          },
        },
        plugins: {
          labels: {
            render: () => { },
            fontSize: 12,
            fontFamily: 'Montserrat'
          }
        }
      },
      plugins: [carbonBarChartPlugin]
    });
  }
}

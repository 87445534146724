import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { AUTHMAP } from "src/app/shared/constants/authmap.constant";
import { Router } from "@angular/router";
// import { AUTHMAP } from "src/app/shared/utils/constants";
// import { AppConfigService } from "src/app/shared/services/app-config.service";
// import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
// import { PortAuthorityService } from "src/network/api/api/portAuthority.service";
// import { FeatureModelGeoJSONDataFeatures } from "src/network/api/model/featureModelGeoJSONDataFeatures";
import { FeatureModelGeoJSONDataFeatures } from "src/app/shared/models/featureModelGeoJSONDataFeatures";
import * as atlas from "azure-maps-control";
import { Map, AuthenticationType } from "azure-maps-control";
import { environment } from "src/environments/environment";

import { data } from '../../../../../src/app/shared/mock-data/region-geofence.json'
import { FeatureModelGeoJSONDataGeometry } from "src/app/shared/models/featureModelGeoJSONDataGeometry";

@Component({
  selector: 'app-regional-map',
  templateUrl: './regional-map.component.html',
  styleUrls: ['./regional-map.component.scss']
})
export class RegionalMapComponent implements AfterViewInit, OnInit {
  @ViewChild("map", { static: true }) mapElement: ElementRef;
  public markers: atlas.HtmlMarker[] = [];
  public status: boolean = false;
  public map: atlas.Map;
  // private geoObs: Observable<any> = new Observable();
  // private geoSubs: Subscription = new Subscription();
  public zoom_multiport = AUTHMAP.MULTI_TERMINAL_ZOOM;
  //public start_end_mark: markerDetails[] = [];
  public latitude: number;
  public longitude: number;
  public poly: any;
  //Get Directions
  public lat: Number = 42.4413971;
  public lng: Number = 11.3276083;
  //google maps zoom
  zoom: Number = 30;
  dir = undefined;
  public latlong = [
    { lat: 38.4576, lng: 15.9084, port_name: "Gioia Tauro", status: false },
    { lat: 45.4379, lng: 12.31, port_name: "Venezia", status: false },
    { lat: 43.6253, lng: 13.5065, port_name: "Anconia", status: false },
    { lat: 37.2388, lng: 15.2136, port_name: "Augusta", status: false },
    { lat: 40.4173, lng: 17.1746, port_name: "Taranto", status: false },
    { lat: 38.1197, lng: 13.3641, port_name: "Palermo", status: false },
    { lat: 44.401, lng: 8.9435, port_name: "Genova", status: false },
    { lat: 44.0952, lng: 9.8097, port_name: "La Spezia", status: false },
    { lat: 43.5485, lng: 10.3028, port_name: "Livorno", status: false },
    { lat: 42.0477, lng: 11.8121, port_name: "Civitavechia", status: false },
    { lat: 40.814, lng: 14.2284, port_name: "Napoli", status: false },
    { lat: 41.1315, lng: 16.8454, port_name: "Bari", status: false },
    { lat: 44.4736, lng: 12.2512, port_name: "Ravenna", status: true },
    { lat: 39.2051, lng: 9.1125, port_name: "Cagliari", status: false },
    { lat: 45.63118956, lng: 13.76000055, port_name: "Trieste", status: false },
  ];

  public geofenceCoordinates: any[] = [];
  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.getPortAuthorityMapGeoJSON();
    this.azureMap();
  }
  azureMap() {
    this.map = new atlas.Map(this.mapElement.nativeElement, {
      center: [11.3276083, 42.4413971],
      zoom: 5,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      showFeedbackLink: false,
      showLogo: false,
      language: "en-US",
    });
    this.InitializeMap();
  }

  getPortAuthorityMapGeoJSON() {
    // this.geoObs = this.portAuthoritySevice.getPortAuthorityMapGeoJSON();
    // this.geoSubs = this.geoObs.subscribe((response) => {
    // const response = mockData;
    
    const geojson: FeatureModelGeoJSONDataFeatures[] = data?.geoJsonData?.features;
    // response.data.geoJsonData.features;

    let coordsArr = [];
    geojson.forEach((data) => {
      if (data.geometry.type === "Polygon") {

        const geoCoords = data.geometry.coordinates[0];
        let geoCoordsArr = geoCoords.map(coor => [coor[0], coor[1]]);
        coordsArr.push(geoCoordsArr);
      }
    });
    this.geofenceCoordinates = coordsArr;
    this.azureMap();
    // });
  }


  

  InitializeMap() {
    this.map.events.add("ready", () => {
      this.latlong.forEach((port) => {
        const markerPosition = { position: [port.lng, port.lat] };
        const latitude = markerPosition.position[0];
        const longitude = markerPosition.position[1];

        const popup = new atlas.Popup({
          content: '<div style="padding:10px">' + port.port_name + "</div>",
          pixelOffset: [0, -40],
          position: [latitude, longitude],
        });

        const marker = new atlas.HtmlMarker({
          position: [latitude, longitude],
          htmlContent: this.getMarkerIconUrl(port.status),
        });
        this.map.markers.add(marker);

        marker.getElement().addEventListener("mouseover", () => {
          marker.togglePopup();

          if (!popup.isOpen()) {
            popup.setOptions({
              position: [latitude, longitude],
            });
            popup.open(this.map);
          }

          this.map.getCanvasContainer().style.cursor = "pointer";

          marker.getElement().addEventListener("mouseleave", () => {
            popup.close();
            this.map.getCanvasContainer().style.cursor = "grab";
          });
        });

        marker.getElement().addEventListener("click", () => {
          if (port.port_name === "Ravenna") {
            this.router.navigate(["./port-authority/general-map"]);
          }
        });
      });

      const dataSource = new atlas.source.DataSource();
      this.map.sources.add(dataSource);
      this.geofenceCoordinates.forEach((coords) => {
        dataSource.add(new atlas.data.Polygon(coords));

        const polygonLayer = new atlas.layer.PolygonLayer(
          dataSource,
          "myPolygonLayer",
          {
            fillColor: "rgb(180, 180, 180)",
            fillOpacity: 0.5,
          }
        );

        const lineLayer = new atlas.layer.LineLayer(dataSource, "myLineLayer", {
          strokeColor: "grey",
          strokeWidth: 1,
        });

        this.map.layers.add([polygonLayer, lineLayer]);
      });
    });
  }



  // changeLang(language: string) {
  //   this.translate.use(language);
  // }

  ngAfterViewInit() { }



  getMarkerIconUrl(status: boolean): string {
    const iconSrc = status
      ? "assets/icons/agm-map-marker-active.svg"
      : "assets/icons/agm-map-marker-inactive.svg";

    return `
      <div class="equipment-status-icon" style="text-align: center; position: relative;">
        <img style="width: 50px; height: 50px; position: relative;" src="${iconSrc}" class="equipment-status-img"/>
      </div>`;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-nav',
  templateUrl: './setting-nav.component.html',
  styleUrls: ['./setting-nav.component.scss']
})
export class SettingNavComponent implements OnInit {
  public activeButton: number = 1;
  constructor() { }

  ngOnInit(): void {
  }
  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1]

    switch (activePath.toLocaleLowerCase()) {
      case 'dashboard-settings':
        this.activeButton = 2;
        break;
      case 'analytics-settings':
        this.activeButton = 3;
        break;
      case 'default':
        this.activeButton = 4;
        break;
      case 'map-opti':
        this.activeButton = 5;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PortProductivityModel } from 'src/app/shared/models';
import { getPortProductivity } from 'src/app/shared/state/operations/operations.action';
import { selectPortProductivity } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-port-productivity',
  templateUrl: './port-productivity.component.html',
  styleUrls: ['./port-productivity.component.scss']
})
export class PortProductivityComponent implements OnInit, OnChanges {
  @Input()
  selectedPortId: string;

  portProductivity: Observable<PortProductivityModel> = this.store.select(selectPortProductivity);

  constructor(private store: Store) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getPortProductivity({ portId: this.selectedPortId }));
      }
    }
  }
}

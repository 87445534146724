import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalyticsComponent } from './analytics.component';
import { OverviewComponent } from './overview/overview.component';
import { AlertsComponent } from './alerts/alerts.component';
import { KpiComponent } from './kpi/kpi.component';
import { YardComponent } from './yard/yard.component';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { CraneAndRTGStatusComponent } from './overview/crane-and-rtg-status/crane-and-rtg-status.component';

const routes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    data: { breadcrumb: '' },
    children: [
      {
        path: '',
        redirectTo: 'default',
        pathMatch: 'full'
      },
      {
        path: 'default',
        component: AnalyticsDashboardComponent,
        data: { breadcrumb: '' }
      },
      {
        path: 'overview',
        component: OverviewComponent,
        data: { breadcrumb: 'Overview' }
      },
      {
        path: 'alerts',
        component: AlertsComponent,
        data: { breadcrumb: 'Alerts' }
      },
      {
        path: 'kpi',
        component: KpiComponent,
        data: { breadcrumb: 'KPI' }
      },
      {
        path: 'yard',
        component: YardComponent,
        data: { breadcrumb: 'Yard' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalyticsRoutingModule { }

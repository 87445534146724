import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { getWaterConservation, getWaterConservationFailure, getWaterConservationSuccess, getWaterFootprintUsageEfficiency, getWaterFootprintUsageEfficiencyFailure, getWaterFootprintUsageEfficiencySuccess, getWaterQualityIndexCompliance, getWaterQualityIndexComplianceFailure, getWaterQualityIndexComplianceSuccess, getWaterQualityProfile, getWaterQualityProfileFailure, getWaterQualityProfileSuccess, getWaterRecyclingRateTreatEff, getWaterRecyclingRateTreatEffFailure, getWaterRecyclingRateTreatEffSuccess, getWaterSourceDiversity, getWaterSourceDiversityFailure, getWaterSourceDiversitySuccess, getWaterUsageShare, getWaterUsageShareFailure, getWaterUsageShareSuccess } from "./water-conservation.actions";
import { WaterConservationService } from "../../services/water-conservation.service";


@Injectable()
export class WaterConservationEffects {
  constructor(
    private actions$: Actions,
    private waterConservationService: WaterConservationService
  ) { }

  getWaterConservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterConservation),
      switchMap((action) =>
        this.waterConservationService.getWaterConservation(action.portsId ? action.portsId : null).pipe(
          map((response) => response.data),
          map((data) => getWaterConservationSuccess({ waterConservation: data })),
          catchError((error) => of(getWaterConservationFailure({ error })))
        )
      )
    )
  );

  getWaterQualityProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterQualityProfile),
      switchMap((action) =>
        this.waterConservationService.getWaterQualityProfile(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterQualityProfileSuccess({ waterQualityProfile: data })),
          catchError((error) => of(getWaterQualityProfileFailure({ error })))
        )
      )
    )
  );

  getWaterQualityIndexCompliance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterQualityIndexCompliance),
      switchMap((action) =>
        this.waterConservationService.getWaterQualityIndexCompliance(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterQualityIndexComplianceSuccess({ waterQualityIndexCompliance: data })),
          catchError((error) => of(getWaterQualityIndexComplianceFailure({ error })))
        )
      )
    )
  );

  getWaterUsageShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterUsageShare),
      switchMap((action) =>
        this.waterConservationService.getWaterUsageShare(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterUsageShareSuccess({ waterUsageShare: data })),
          catchError((error) => of(getWaterUsageShareFailure({ error })))
        )
      )
    )
  );

  getWaterFootprintUsageEfficiency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterFootprintUsageEfficiency),
      switchMap((action) =>
        this.waterConservationService.getWaterFootprintUsageEfficiency(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterFootprintUsageEfficiencySuccess({ waterFootprintUsageEfficiency: data })),
          catchError((error) => of(getWaterFootprintUsageEfficiencyFailure({ error })))
        )
      )
    )
  );

  getWaterSourceDiversity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterSourceDiversity),
      switchMap((action) =>
        this.waterConservationService.getWaterSourceDiversity(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterSourceDiversitySuccess({ waterSourceDiversity: data })),
          catchError((error) => of(getWaterSourceDiversityFailure({ error })))
        )
      )
    )
  );

  getWaterRecyclingRateTreatEff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWaterRecyclingRateTreatEff),
      switchMap((action) =>
        this.waterConservationService.getWaterRecyclingRateTreatEff(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWaterRecyclingRateTreatEffSuccess({ waterRecyclingRateTreatEff: data })),
          catchError((error) => of(getWaterRecyclingRateTreatEffFailure({ error })))
        )
      )
    )
  );



}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';


@Injectable({
  providedIn: 'root'
})
export class WaterConservationService {

  private waterConservation = environment.basePath + API.KPI;
  // private waterConservation = '/connPort' + API.KPI; //local 

  constructor(private http: HttpClient) { }

  getWaterConservation(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-conservation-quality`, { params: params });
  }

  getWaterQualityProfile(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-quality-profile`, { params: params });
  }

  getWaterQualityIndexCompliance(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-quality-index-compliance`, { params: params });
  }

  getWaterUsageShare(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-usage-share`, { params: params });
  }

  getWaterFootprintUsageEfficiency(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-footprint-usage-efficiency`, { params: params });
  }

  getWaterSourceDiversity(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-source-diversity`, { params: params });
  }

  getWaterRecyclingRateTreatEff(year: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set('year', year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.waterConservation}/water-recycling-rate-treat-efficiency`, { params: params });
  }
}







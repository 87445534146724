export const CHART = {
  TYPE: {
    BAR: 'bar',
    DOUGHNUT: 'doughnut',
    LINE: 'line',
    PIE: 'pie',
    SCATTER: 'scatter',
    HORIZONTAL_BAR: 'horizontalBar',
  },
  CHART_TYPE: {
    bar: 'bar',
    doughnut: 'doughnut',
    line: 'line',
    pie: 'pie',
    horizontalBar: 'horizontalBar',
    scatter: 'scatter',
  },
  COLOR_NAME: {
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
  },
  CHART_LABELS: {
    CONTAINER_HANDLED_PER_DAY: 'Container Handled Per Day',
    EQUIPMENT_UTILIZATION: 'Equipment Utilization (%)',
    MOVE_BETWEEN_FAILURE: 'Mean Move Between Failure (per day)',
    TIME_BETWEEN_FAILURE: 'Mean Time Between Failure (per day)',
    BERTH_UTILIZATION: 'Berth Utilization (%)',
    YARD_OCCUPANCY_RATIO: 'Yard Occupancy Ratio',
    FUEL_EFFICIENCY: ['Idle', 'Active'],
    CRANE_PROD: ['< 15 C/H', '15-27 C/H', '> 27 C/H'],
    CRANE_OPERATION: ['A', 'B', 'C', 'D', 'E'],
    ACTIVE_IDLE: ['Feb01', 'Feb02', 'Feb03', 'Feb04', 'Feb05',
      'Feb06', 'Feb07', 'Feb08', 'Feb09', 'Feb10',
      'Feb11', 'Feb12', 'Feb13', 'Feb14', 'Feb15',
      'Feb16', 'Feb17', 'Feb18', 'Feb19', 'Feb20',
      'Feb21', 'Feb22', 'Feb23', 'Feb24', 'Feb25',
      'Feb26', 'Feb27', 'Feb28', 'Feb29', 'Mar01'],
    ANALYTICS: {
      PORT: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      VESSEL: [],
      SENSOR: []
    },
    HOUR: 'hour'
  },
  CODES: {
    PORT_ELECTRIFICATION: {
      FUEL: 'Fuel',
      HYBRID: 'Hybrid',
      ELECTRIC: 'Electric'
    },
    PLANTED_TREES_LOCATION: {
      FOREST: 'FRS',
      RURAL: 'RRL',
      URBAN: 'UBN',
    },
    WATER_DIVERSITY: {
      RAINWATER: 'RAIN',
      SURFACE_WATER: 'SURF',
      SEAWATER: 'SEA',
      GROUNDWATER: 'GRND',
      DESALINATED_WATER: 'DES'
    },
    WATER_USAGE_SHARE: {
      DOCK_OPERATIONS: 'DO',
      DOMESTIC_USE: 'DU',
      INDUSTRIAL_PROCESSES: 'IP',
      IRRIGATION: 'IRR',
      NON_PORTABLE_USE: 'NPU',
      OTHER: 'OTHERS',
      VESSEL_SERVICES: 'VS'
    }
  },
  COLOR: {
    GREEN: '#27ae60',
    YELLOW: '#edb500',
    RED: '#e74c3c',
    WHITE: '#D3D3D3',
    GRAY: '#A9A9A9',
    BLUE: '#0077F7',
    LIGHT_BLUE_1: '#E2F5FF',
    ANALYTICS: {
      GREEN: '#089700',
      ORANGE: '#FFBD04',
      BLUE: '#2B6FB8',
      VIOLET: '#8e44ad',
      TORQUOISE: '#1abc9c',
    },
    CARBON_INTENSITY: {
      DARK_GRAY: '#7F7F7F',
      DARK_BLUE: '#2C3E50',
      GRAY: '#D9D9D9',
      GREEN: '#4A7942',
      LIGHT_GRAY: '#0000001A',
      WHITE: '#FFFFFF',
      YELLOW: '#F6C242'
    },
    DIVERSITY_BY_CLASS: {
      MALE: '#4E72BE',
      FEMALE: '#F997E4'
    },
    DIVERSITY_BY_DEPARTMENT: {
      MALE: '#4E72BE',
      FEMALE: '#F997E4'
    },
    EMISSIONS: {
      BLACK: '#000000',
      DARK_BLUE: '#375592',
      GREEN: '#7EAB55',
      WHITE: '#FFFFFF'
    },
    FAULT_MESSAGES: {
      LAN_BLUE: '#1e3799',
      AZRAQ_BLUE: '#4a69bd',
      LIVID: '#6a89cc'
    },
    PLANTED_TREES_LOCATION: {
      FRS: '#4A7942',
      RRL: '#4472C4',
      UBN: '#C55A11'
    },
    PORT_ELECTRIFICATION: {
      DARK_BLUE: '#324D73',
      DARK_GRAY: '#7F7F7F',
      DARK_GREEN: '#4A7942',
      LIGHT_GRAY: '#0000001A'
    },
    PORT_ENERGY_SHARE: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      DARK_GRAY: '#7F7F7F',
      DARK_GREEN: '#4A7942',
      GRAY: '#CCCCCC',
      WHITE: '#FFFFFF'
    },
    PROTECTED_AREA_SHARE: {
      IN_LANDS: '#7F7F7F',
      IN_WATERS: '#324D73'
    },
    SHARE_BY_CATEGORY: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      BLUE: '#5C8BD7',
      GREEN: '#4A7942',
      GRAY: '#7F7F7F',
      ORANGE: '#DF8244',
      WHITE: '#FFFFFF',
      YELLOW: '#CCA43F'
    },
    SHARE_BY_CLASSIFICATION: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      BLUE: '#5C8BD7',
      GREEN: '#4A7942',
      WHITE: '#FFFFFF'
    },
    STAFF_DIVERSITY: {
      MALE: '#4E72BE',
      FEMALE: '#F997E4'
    },
    WASTE_COLLECTED_BY_SEABINS: {
      BLUE: '#4E72BE',
      BLUE_1: '#699AD0',
      BLUE_2: '#2C4474',
      BROWN: '#934D20',
      DARK_BLUE: '#2C3E50',
      GRAY: '#A5A5A5',
      GREEN: '#7EAB55',
      ORANGE: '#DF8244',
      WHITE: '#FFFFFF',
      YELLOW: '#F6C242'
    },
    WASTE_UNDER_SPECIAL_DISPOSAL: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      BLUE: '#5C8BD7',
      DARK_BLUE: '#2C3E50',
      GREEN: '#4A7942',
      GRAY: '#7F7F7F',
      ORANGE: '#DF8244',
      WHITE: '#FFFFFF',
      YELLOW: '#CCA43F'
    },
    WATER_DIVERSITY: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      BLUE: '#8FA2D4',
      BLUE_1: '#BAC4E2',
      BLUE_2: '#315493',
      BLUE_3: '#3B64AD',
      BLUE_4: '#4472C4',
      WHITE: '#FFFFFF'
    },
    WATER_QUALITY_PROFILE: {
      BLACK: '#111111',
      GREEN: '#548235',
      RED: '#B73D2C',
      WHITE: '#FFFFFF'
    },
    WATER_USAGE_SHARE: {
      BLACK: '#000000',
      BLACK_1: '#111111',
      BLUE: '#4472C4',
      BLUE_1: '#5B9BD5',
      BLUE_2: '#264478',
      GRAY: '#A5A5A5',
      GREEN: '#75B04E',
      ORANGE: '#DF8244',
      WHITE: '#FFFFFF',
      YELLOW: '#FFC000'
    },
  },
  DATA: {
    YARD_OPERATIONS: {
      HAZARDOUS_MATERIAL: {
        COLORS: ['#0132d5', '#839fff', '#b2c2fd', '#5d41e3', '#917ef2', '#cfc6ff'],
        DATASET_MAIN: {
          'categ1': [],
          'categ2': [],
          'categ3': [],
          'categ4': [],
          'categ5': [],
          'categ6': [],
          'categ7': [],
          'categ8': [],
          'categ9': []
        },
        DATASET_SUB_DIVISION: {
          'sub1': [],
          'sub2': [],
          'sub3': [],
          'sub4': [],
          'sub5': [],
          'sub6': []
        },
        LABELS: ['Sub .1', 'Sub .2', 'Sub .3', 'Sub .4', 'Sub .5', 'Sub .6']
      },
      CONTAINER_DWELL_TIME: {
        COLORS: ['#2B6FB8', '#FFBD04'],
        DATASET_SET: {
          'import': [],
          'export': []
        },
        LABELS: ['Import', 'Export']
      },
      EMPTIES_IN_YARD: {
        COLORS: ['#0132d5', '#839fff', '#b2c2fd', '#5d41e3', '#917ef2'],
        DATASET_SET: {
          'container1': [],
          'container2': [],
          'container3': [],
          'container4': [],
          'container5': [],
        }
      }
    },
    TURN_TIME: {
      RANGE_LABEL: {
        start: 0,
        end: 120
      },
      DELIMITERS: [61, 91],
      ARC_COLORS: ['#009116', '#edb500', '#ed0010'],
      //ARC_COLORS: ['#ed0010', '#edb500', '#009116'],
      DUMMY_MODAL_DATA: {
        date: "16-Aug-2021",
        average: 35.39,
        inbound: "(207) 22.66",
        outbound: "(145) 39.86",
        double: "(200) 45.31"
      }
    },
    GATE_MOVES: {
      SETTINGS: {
        LABELS: [['Empty', 'In'], ['Empty', 'Out'], ['Full', 'In'], ['Full', 'Out']],
        CHART_DATA: [
          { id: 1, value: 0, text: 'emptyIn' },
          { id: 2, value: 0, text: 'emptyOut' },
          { id: 3, value: 0, text: 'fullIn' },
          { id: 4, value: 0, text: 'fullOut' }
        ],
        COLORS: ['#029a00', '#04028e', '#02cc9c', '#0696ff']
      }
    }
  },
  FONTS: {
    GOTHAM: 'Gotham'
  },
  FILTERS: {
    LAST_SEVEN_DAYS: 7,
    LAST_FOURTEEN_DAYS: 14,
    LAST_THIRTY_DAYS: 30
  },
  TOOLTIP_MODE: {
    NEAREST: 'nearest'
  }
};

export const CHARTv2 = {
  STACK_O: 'Stack 0',
  ROTATION_0: 0,
  ROTATION_90: 90,
  SCALE_0: 0,
  SCALE_100: 100,
  TOOLTIP_MODE_NEAREST: 'nearest',
  COLOR_NAME: {
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
  },

  COLOR: {
    GREEN: '#27ae60',
    YELLOW: '#edb500',
    RED: '#e74c3c',
    WHITE: '#D3D3D3',
    GRAY: '#A9A9A9',
    BLUE: '#0077F7',
    LIGHT_BLUE_1: '#E2F5FF',
    LIGHT_BLUE_2: '#D3F1FF',
    ANALYTICS: {
      green: '#089700',
      orange: '#FFBD04',
      blue: '#2B6FB8',
      violet: '#8e44ad',
      torquoise: '#1abc9c',
    },
    YARD_OPERATION: {
      dark_blue: '#0a36cd'
    },
    FAULT_MESSAGES: {
      lan_blue: '#1e3799',
      azraq_blue: '#4a69bd',
      livid: '#6a89cc'
    }
  },
  YARD_INVENTORY_CONTAINER: {
    COLORS: ['#ed0010', '#edb500', '#0032d6'],
    DATASET: {
      "empty": [],
      "full": [],
      "other": []
    },
    LABELS: ['Empty', 'Full', 'Other']
  },
  TYPE: {
    BAR: 'bar',
    PIE: 'pie',
    LINE: 'line'
  },

  // MEASUREMENT_UNIT: {
  //   celcius: '°C',
  //   fahrenheit: '°F',
  //   pressure: 'Psi'
  // },
  CHART_TYPE: {
    bar: 'bar',
    doughnut: 'doughnut',
    line: 'line',
    pie: 'pie',
    horizontalBar: 'horizontalBar',
    scatter: 'scatter',
  },
  CHART_LABELS: {
    CONTAINER_HANDLED_PER_DAY: 'Container Handled Per Day',
    EQUIPMENT_UTILIZATION: 'Equipment Utilization (%)',
    MOVE_BETWEEN_FAILURE: 'Mean Move Between Failure (per day)',
    TIME_BETWEEN_FAILURE: 'Mean Time Between Failure (per day)',
    BERTH_UTILIZATION: 'Berth Utilization (%)',
    YARD_OCCUPANCY_RATIO: 'Yard Occupancy Ratio',
    FUEL_EFFICIENCY: ['Idle', 'Active'],
    CRANE_PROD: ['< 15 C/H', '15-27 C/H', '> 27 C/H'],
    CRANE_OPERATION: ['A', 'B', 'C', 'D', 'E'],
    ACTIVE_IDLE: ['Feb01', 'Feb02', 'Feb03', 'Feb04', 'Feb05',
      'Feb06', 'Feb07', 'Feb08', 'Feb09', 'Feb10',
      'Feb11', 'Feb12', 'Feb13', 'Feb14', 'Feb15',
      'Feb16', 'Feb17', 'Feb18', 'Feb19', 'Feb20',
      'Feb21', 'Feb22', 'Feb23', 'Feb24', 'Feb25',
      'Feb26', 'Feb27', 'Feb28', 'Feb29', 'Mar01'],
    ANALYTICS: {
      PORT: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      VESSEL: [],
      SENSOR: []
    },
    HOUR: 'hour'
  },
  CHART_LEGENDS: {
    CRANE_PROD: {
      below: '< 15 C/H',
      average: '15 - 27',
      above: '> 27 C/H'
    },
    ACTIVE_IDLE: {
      active: 'Active',
      idle: 'Idle',
      non_utilized: 'Non-Utilized'
    }
  },
  CHART_CONFIGS: {
    PIE: {
      CHART_OPTIONS: {
        cutoutPercentage: 50,
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        animation: {
          duration: 0,
        },
        tooltips: {
          enabled: false
        },
        plugins: {
          labels: {

            fontColor: 'white',
            fontSize: 0,
          },
        },
      }
    }
  },
  CHART_FILTERS: {
    LAST_SEVEN_DAYS: 7,
    LAST_FOURTEEN_DAYS: 14,
    LAST_THIRTY_DAYS: 30
  }
};
export const FONTS = {
  FAMILY: {
    GOTHAM: 'Gotham'
  },
  SIZE: {
    TEN: 10,
    TWELVE: 12
  }
};

import { Component, OnInit, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import * as data from 'src/app/shared/mock-data/turnaround-data.json';
import { MESSAGE_DISPLAY } from "src/app/shared/constants/api.constant";
import { TurnTimeService } from "src/app/shared/services/turn-time.service";
import { TURN_TIME } from "src/app/shared/constants/turn-time.constant";
// import { OutboundTrucksService } from "src/app/shared/services/specs/outbound-truck-service";

@Component({
  selector: "app-turnaround-time",
  templateUrl: "./turnaround-time.component.html",
  styleUrls: ["./turnaround-time.component.scss"],
})
export class TurnaroundTimeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() outboundtrucksdata: any;
  @Input() turntimedata: any;
  displayedColumns: string[] = ['Scene ID', 'Transaction Type', 'Gate In', 'Gate Out', 'Transfer Time', 'Turnaround Time'];
  dataSource = new MatTableDataSource<any>(data.EmpData as any); 

  private data: any;
  public canvasWidth: number = 350;
  public needleValue: number = 0;
  public centralLabel: string = '';
  public name: string = 'Gauge chart';
  public bottomLabel: string | number = '910.50';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: TURN_TIME.ARC_COLORS,
    arcLabelFontSize: 16,
    arcDelimiters: TURN_TIME.DELIMITERS,
    rangeLabel: [`${TURN_TIME.RANGE_LABEL.start}`, `${TURN_TIME.RANGE_LABEL.end}`],
    needleStartValue: 0,
  };
  public arcLabel: number = TURN_TIME.DELIMITERS[1];
  public mnAvgTurnTime: number;
  public avgYellow: number;
  public avgRed: number;
  public noDataToDisplay: string = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData: boolean = true;

  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("paginatorPageSize") paginatorPageSize: MatPaginator;
  pageSizes = [5, 8, 10];

  constructor(private turnTimeService: TurnTimeService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.turntimedata && this.turntimedata) {
      this.noData = false;
      this.data = this.turntimedata;

      this.mnAvgTurnTime = this.data.avgTurnTime30Days;
      this.avgYellow = this.data.yellow;
      this.avgRed = this.data.red;

      this.needleValue = this.data.avgTurnTimeToday == null || this.data.avgTurnTimeToday < 1
        ? 0
        : (this.data.avgTurnTimeToday / 120) * 100;
      this.bottomLabel = this.data.avgTurnTimeToday == null || this.data.avgTurnTimeToday < 1
        ? 0
        : (this.data.avgTurnTimeToday).toFixed(2);

      this.options.arcDelimiters = this.turnTimeService.getDelimiters(TURN_TIME.DELIMITERS);
    }
  }
}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyNavRoutingModule } from './energy-nav-routing.module';
import { EnergyDashboardComponent } from './energy-dashboard/energy-dashboard.component';
import { IdleTimeTrackerComponent } from './idle-time-tracker/idle-time-tracker.component';
import { EnergySourceComponent } from './energy-source/energy-source.component';
import { EnergyEquipmentComponent } from './energy-equipment/energy-equipment.component';
import { RouterModule } from '@angular/router';
import { EnergyTableComponent } from './energy-dashboard/energy-table/energy-table.component';
import { CostComponent } from './energy-dashboard/cost/cost.component';
import { MatIconModule } from '@angular/material/icon';
import { ConsumpCardsComponent } from './energy-dashboard/consump-cards/consump-cards.component';



@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [EnergyTableComponent, CostComponent, EnergyDashboardComponent, IdleTimeTrackerComponent, EnergySourceComponent, EnergyEquipmentComponent, ConsumpCardsComponent],
  imports: [
    CommonModule,
    EnergyNavRoutingModule,
    RouterModule,
    MatIconModule,
  
  ]
})
export class EnergyNavModule { }

import {Directive, ElementRef, forwardRef, HostListener, Input, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: 'input[type=checkbox][appTrueFalseValue]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrueFalseValueDirective),
      multi: true
    }
  ]
})
export class TrueFalseValueDirective implements ControlValueAccessor {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input() trueValue;
  @Input() falseValue;
  private propagateChange = (_: any) => {};

  @HostListener('change', ['$event'])
  onHostChange(ev) {
    this.propagateChange(ev.target.checked ? this.trueValue : this.falseValue);
  }

  writeValue(obj: any): void {
    if (obj === this.trueValue && (obj !== null || obj !== undefined)) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', true);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.trueValue);
      this.propagateChange(this.trueValue);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', false);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.falseValue);
      this.propagateChange(this.falseValue);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
}

import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-biodiversity",
  templateUrl: "./biodiversity.component.html",
  styleUrls: ["./biodiversity.component.scss"],
})
export class BiodiversityComponent implements OnInit {
  @Input() selectedYear: string;

  isArrowUpVisible = false;
  isDropdownVisible: boolean = false;
  selectedOption = "2024";
  selectedValue = "Select Year";
  yearOptions = [
    { id: "twentyFour", value: "2024", label: "2024" },
    { id: "twentyThree", value: "2023", label: "2023" },
    { id: "twentyTwo", value: "2022", label: "2022" },
  ];

  constructor(private store: Store) { }

  ngOnInit(): void { }

  selectOption(value: string) {
    this.selectedOption = value;
    this.selectedValue = value;
  }

  handleMouseLeave(): void {
    this.isArrowUpVisible = false;
    this.isDropdownVisible = false;
  }

  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
}



import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART } from 'src/app/shared/constants/chart.constant';

@Component({
  selector: 'app-environmentally-friendly-disposal',
  templateUrl: './environmentally-friendly-disposal.component.html',
  styleUrls: ['./environmentally-friendly-disposal.component.scss']
})
export class EnvironmentallyFriendlyDisposalComponent implements OnInit {
  private disposalHorizontalBar: Chart;

  constructor() { }

  ngOnInit(): void {
    this.createHorizontalBar(); //Waste Materials Chart
  }

  createHorizontalBar() {
    if (this.disposalHorizontalBar) {
      this.disposalHorizontalBar.destroy();
    }

    const canvas = document.getElementById('wasteDisposalChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "wasteDisposalChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const COLOR = CHART.COLOR.WASTE_UNDER_SPECIAL_DISPOSAL;

    this.disposalHorizontalBar = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: [
          'Chemical waste',
          'Chemicals',
          'Oil and grease',
          'Batteries',
          'Food waste',
          'Green waste',
          'Construction debris',
          'Plastic',
          'Cardboard',
          'Paper',
        ],
        datasets: [
          {
            label: 'Chemical waste',
            data: [2139, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: COLOR.GRAY,
          },
          {
            label: 'Chemicals',
            data: [0, 1354, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: COLOR.GRAY,
          },
          {
            label: 'Oil and grease',
            data: [0, 0, 22563, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: COLOR.GRAY,
          },
          {
            label: 'Batteries',
            data: [0, 0, 0, 12432, 0, 0, 0, 0, 0, 0],
            backgroundColor: COLOR.GRAY,
          },
          {
            label: 'Food waste',
            data: [0, 0, 0, 0, 84563, 0, 0, 0, 0, 0],
            backgroundColor: COLOR.GREEN,
          },
          {
            label: 'Green waste',
            data: [0, 0, 0, 0, 0, 43742, 0, 0, 0, 0],
            backgroundColor: COLOR.GREEN,
          },
          {
            label: 'Construction debris',
            data: [0, 0, 0, 0, 0, 0, 75237, 0, 0, 0],
            backgroundColor: COLOR.YELLOW,
          },
          {
            label: 'Plastic',
            data: [0, 0, 0, 0, 0, 0, 0, 43842, 0, 0],
            backgroundColor: COLOR.ORANGE,
          },
          {
            label: 'Cardboard',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 11952, 0],
            backgroundColor: COLOR.ORANGE,
          },
          {
            label: 'Paper',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 8890],
            backgroundColor: COLOR.ORANGE,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'point',
          callbacks: {},
        },
        hover: {
          mode: 'point',
        },
        layout: {
          padding: {
            right: 20,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              display: false,
              ticks: {
                beginAtZero: true,
                stepSize: 10000,
                display: false
              },
            },
          ],
          yAxes: [{
            stacked: true,
            display: true,
            ticks: {
              fontColor: COLOR.BLACK,
              fontFamily: 'Montserrat',
              fontSize: 12,
              fontStyle: 'normal',
            }
          }],
          gridLines: { display: true },
        },
        plugins: {
          datalabels: {
            display: true,
            color: COLOR.DARK_BLUE,
            anchor: 'end',
            align: 'right',
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              style: 'normal',
              weight: 'bold',
              family: 'Montserrat',
              size: 12,
            },
            formatter: function (value, ctx) {
              if (value > 0) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(',');
                return value;
              } else {
                value = '';
                return value;
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: COLOR.BLACK_1,
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-turn-around',
  templateUrl: './turn-around.component.html',
  styleUrls: ['./turn-around.component.scss']
})
export class TurnAroundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Directive, Input, ElementRef, Renderer2, AfterContentInit, HostListener, OnChanges} from '@angular/core';

@Directive({
  selector: '[appProgressBarTarget]'
})
export class ProgressBarTarget implements AfterContentInit, OnChanges {

  @Input() appProgressBarTarget;
  @Input() target: string;
  private directiveLoaded = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges() {
    if (this.directiveLoaded) {
      this.updateTarget();
    }
  }

  ngAfterContentInit() {
    const div_line =  this.renderer.createElement('div');
    const div_caret = this.renderer.createElement('div');
    this.renderer.appendChild(this.el.nativeElement.parentNode, div_line);
    this.renderer.addClass(this.el.nativeElement.parentNode.lastChild, 'mat-custom-progress-target-line');
    this.renderer.appendChild(this.el.nativeElement.parentNode, div_caret);
    this.renderer.addClass(this.el.nativeElement.parentNode.lastChild, 'mat-custom-progress-target-caret');
    this.updateTarget();
    this.directiveLoaded = true;;
  }

  private updateTarget() {
    this.renderer.setStyle(
      this.el.nativeElement.parentNode.querySelector('.mat-custom-progress-target-line'),
      'margin-left',
      this.target + '%'
    );
    this.renderer.setStyle(
      this.el.nativeElement.parentNode.querySelector('.mat-custom-progress-target-caret'),
      'margin-left',
      this.target + '%'
    );
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { BiodiversityService } from "../../services/biodiversity.service";
import {
  getBiodiversity,
  getBiodiversityFailure,
  getBiodiversitySuccess,
  getQuarterlyShare,
  getQuarterlyShareFailure,
  getQuarterlyShareSuccess,
  getTreePlantingMetrics,
  getTreePlantingMetricsSuccess,
  getCommunityEducation,
  getCommunityEducationFailure,
  getCommunityEducationSuccess,
  getProtectionWaters,
  getProtectionWatersFailure,
  getProtectionWatersSuccess
} from "./biodiversity.actions";

@Injectable()
export class BiodiversityEffects {
  constructor(
    private actions$: Actions,
    private biodiversityService: BiodiversityService
  ) { }

  getBiodiversity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBiodiversity),
      switchMap((action) =>
        this.biodiversityService.getBiodiversity(action.portsId ? action.portsId : null).pipe(
          map((response) => response.data),
          map((data) => getBiodiversitySuccess({ biodiversity: data })),
          catchError((error) => of(getBiodiversityFailure({ error })))
        )
      )
    )
  );

  getQuarterlyShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQuarterlyShare),
      switchMap((action) =>
        this.biodiversityService.getQuarterlyShare(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getQuarterlyShareSuccess({ quarterlyShare: data })),
          catchError((error) => of(getQuarterlyShareFailure({ error })))
        )
      )
    )
  );

  getTreePlantingMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTreePlantingMetrics),
      switchMap((action) =>
        this.biodiversityService.getTreePlantingMetrics(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getTreePlantingMetricsSuccess({ TreePlantingMetrics: data })),
          catchError((error) => of(getQuarterlyShareFailure({ error })))
        )
      )
    )
  );

  getProtectionWaters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProtectionWaters),
      switchMap((action) =>
        this.biodiversityService.getProtectionWaters(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getProtectionWatersSuccess({ protectionWaters: data })),
          catchError((error) => of(getProtectionWatersFailure({ error })))
        )
      )
    )
  );

  getCommunityEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCommunityEducation),
      switchMap((action) =>
        this.biodiversityService.getCommunityEducation(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getCommunityEducationSuccess({ communityEducation: data })),
          catchError((error) => of(getCommunityEducationFailure({ error })))
        )
      )
    )
  );
}
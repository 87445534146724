import { createReducer, on } from '@ngrx/store';
import { ApiStatus } from '../../enum/api-status.enum';
import {
  getOperation,
  getOperationSuccess,
  getOperationFailure,
  resetOperationMngmt,
  getPortProductivity,
  getPortProductivitySuccess,
  getPortProductivityFailure,
  getPortMap,
  getPortMapSuccess,
  getPortMapFailure,
  getBerthingStatus,
  getBerthingStatusSuccess,
  getBerthingStatusFailure,
  getOperatingStatus,
  getOperatingStatusSuccess,
  getOperatingStatusFailure,
  getEquipmentStatus,
  getEquipmentStatusSuccess,
  getEquipmentStatusFailure,
  getEquipmentJobOrder,
  getEquipmentJobOrderSuccess,
  getEquipmentJobOrderFailure,
  getEquipmentList,
  getEquipmentListSuccess,
  getEquipmentListFailure,
  getEquipmentDetails,
  getEquipmentDetailsSuccess,
  getEquipmentDetailsFailure,
  getCraneProductivity,
  getCraneProductivitySuccess,
  getCraneProductivityFailure,
  getCraneMovement,
  getCraneMovementSuccess,
  getCraneMovementFailure,
  getCraneUtilizationHistory,
  getCraneUtilizationHistorySuccess,
  getCraneUtilizationHistoryFailure,
  getMovementTimeOverview,
  getMovementTimeOverviewSuccess,
  getMovementTimeOverviewFailure,
  getEquipmentMaintenanceSummary,
  getEquipmentMaintenanceSummarySuccess,
  getEquipmentMaintenanceSummaryFailure,
  getEquipmentPartsInspection,
  getEquipmentPartsInspectionSuccess,
  getEquipmentPartsInspectionFailure,
  getHistoricalMaintenance,
  getHistoricalMaintenanceSuccess,
  getHistoricalMaintenanceFailure,
  getEquipmentAlert,
  getEquipmentAlertSuccess,
  getEquipmentAlertFailure,
  setAnalyticsSelectedPortId,
  setkpiSelectedPortId,
  getEquipmentFailureChart,
  getEquipmentFailureChartSuccess,
  getEquipmentFailureChartFailure,
  setEquipmentSelectedPortId,
  getEquipmentAlarmAnalysis,
  getEquipmentAlarmAnalysisSuccess,
  getEquipmentAlarmAnalysisFailure,
  getTotalContainersMovement,
  getTotalContainersMovementSuccess,
  getTotalContainersMovementFailure,
  getContainersMovementTypes,
  getContainersMovementTypesSuccess,
  getContainersMovementTypesFailure,
  getCraneUtilizationAnalytics,
  getCraneUtilizationAnalyticsSuccess,
  getCraneUtilizationAnalyticsFailure,
  getPortProductivityEquipmentBerthUtilization,
  getPortProductivityEquipmentBerthUtilizationFailure,
  getPortProductivityEquipmentBerthUtilizationSuccess,
  getCraneSensorEvaluation,
  getCraneSensorEvaluationSuccess,
  getCraneSensorEvaluationFailure,
  getTopVesselDelaySuccess,
  getTopVesselDelayFailure,
  getTopVesselDelay,
  getBerthingStatusOverview,
  getBerthingStatusOverviewSuccess,
  getBerthingStatusOverviewFailure,
  getQuayYardStatus,
  getQuayYardStatusSuccess,
  getQuayYardStatusFailure,
  getTugboatStatus,
  getTugboatStatusSuccess,
  getTugboatStatusFailure,
  getEquipmentAlertAnalytics,
  getEquipmentAlertAnalyticsSuccess,
  getEquipmentAlertAnalyticsFailure,
  getAlertsLogData,
  getAlertsLogDataSuccess,
  getAlertsLogDataFailure,
  getPortProductivityKpi,
  getPortProductivityKpiFailure,
  getPortProductivityKpiSuccess,
  getCraneKPI,
  getCraneKPISuccess,
  getCraneKPIFailure,
  getTugboatKpi,
  getTugboatKpiSuccess,
  getTugboatKpiFailure,
  getAverageTurnTime,
  getAverageTurnTimeSuccess,
  getAverageTurnTimeFailure,
  getContainerGateMoves,
  getContainerGateMovesSuccess,
  getContainerGateMovesFailure,
  getOverstayingTrucks,
  getOverstayingTrucksSuccess,
  getOverstayingTrucksFailure,
  getAverageContainerDwellTime,
  getAverageContainerDwellTimeSuccess,
  getAverageContainerDwellTimeFailure,
  getYardContainerSizeType,
  getYardContainerSizeTypeSuccess,
  getYardContainerSizeTypeFailure,
  getHazardousMaterial,
  getHazardousMaterialSuccess,
  getHazardousMaterialFailure,

} from './operations.action';
import {
  ErrorResponse,
  OperationModel,
  PortProductivityModel,
  BerthingStatusModel,
  PortMapModel,
  OperatingStatusModel,
  EquipmentStatusModel,
  EquipmentJobOrderModel,
  EquipmentDetailsModel,
  CraneProductivityModel,
  EquipmentAlertsModel,
  AlertsAnalyticsModel,
  BerthingStatusOverviewModel,
  PortProductivityKpiModel
} from '../../models';
import { CraneUtilizationHistoryModel } from '../../models/crane-utilization.model';
import { MovementTimeOverviewModel } from '../../models/movement-time-overview.model';
import { EquipmentMaitenanceSummaryModel } from '../../models/equipment-maintenance-status.model';
import { EquipmentPartsInspectionModel } from '../../models/equipment-part-inspection.model';
import { HistoricalMaintenanceModel } from '../../models/historical-maintenance.model';
import { EquipmentFailureChartModel } from '../../models/failure-chart.model';
import { EquipmentAlarmAnalysisModel } from '../../models/alarm-analysis.model';;
import { TotalContainersMovementModel } from '../../models/container-moved-by-type.model';
import { CraneMovementModel } from '../../models/crane-movement-steps.model';
import { CraneSensorEvaluationModel } from '../../models/crane-sensor-eval';
import { ContainersMovementTypesModel } from '../../models/craner-container-moves.model';
import { TopVesselDelay } from '../../models/top-vessel-delay';
import { PortProductivityEquipmentBerthUtilizationModel } from '../../models/port-productivity-equipment-berth-utilization.model';
import { QuayYardStatusModel } from '../../models/crane-list.model';
import { TugboatList, TugboatStatusModel } from '../../models/tugboat-list.model';
import { LogsDataModel } from '../../models/logs';
import { CraneListKPIModel } from '../../models/craneKpi';
import { AverageTurnTimeModel } from '../../models/average-turn-time.model';
import { ContainerGateMovesModel } from '../../models/container-gate-moves.model';
import { OverstayingTrucksModel } from '../../models/overstaying-trucks.model';
import { AverageContainerDwellTimeModel } from '../../models/average-container-dwell-time.model';
import { YardContainerSizeModel } from '../../models/yard-container-size.model';
import { HazardousMaterialModel } from '../../models/hazardous-material.model';



export interface OperationState {
  error: ErrorResponse,
  status: ApiStatus,
  operation: OperationModel,
  portProductivity: PortProductivityModel,
  portMap: Array<PortMapModel>,
  berthingStatus: BerthingStatusModel,
  operatingStatus: Array<OperatingStatusModel>,
  equipmentStatus: Array<EquipmentStatusModel>,
  equipmentJobOrder: EquipmentJobOrderModel,
  equipmentList: Array<EquipmentDetailsModel>,
  equipmentSelectedPortId: string,
  equipmentDetails: EquipmentDetailsModel,
  craneProductivity: CraneProductivityModel,
  craneMovement: CraneMovementModel,
  craneUtilizationHistory: Array<CraneUtilizationHistoryModel>,
  movementTimeOverview: MovementTimeOverviewModel,
  equipmentMaintenanceSummary: EquipmentMaitenanceSummaryModel,
  equipmentPartsInspection: Array<EquipmentPartsInspectionModel>,
  historicalMaintenance: Array<HistoricalMaintenanceModel>,
  equipmentAlert: EquipmentAlertsModel,
  analyticsSelectedPortId: string,
  kpiSelectedPortId: string,
  equipmentFailureChart: Array<EquipmentFailureChartModel>,
  equipmentAlarmAnalysis: Array<EquipmentAlarmAnalysisModel>,
  totalContainersMovement: Array<TotalContainersMovementModel>,
  containersMovementTypes: Array<ContainersMovementTypesModel>,
  craneUtilizationAnalytics: Array<CraneUtilizationHistoryModel>,
  portEquipmentBerth: Array<PortProductivityEquipmentBerthUtilizationModel>,
  craneSensorEvaluation: Array<CraneSensorEvaluationModel>,
  topVesselDelay: Array<TopVesselDelay>,
  berthingStatusOverview: BerthingStatusOverviewModel,
  quayYardStatus: QuayYardStatusModel,
  tugboatStatus: TugboatStatusModel,
  equipmentAlertsAnalytics: Array<AlertsAnalyticsModel>
  logData: Array<LogsDataModel>,
  portProductivityKpi: PortProductivityKpiModel,
  craneKpi: CraneListKPIModel,
  tugboatKpi: Array<TugboatList>,
  averageTurnTime: AverageTurnTimeModel,
  containerGateMoves: Array<ContainerGateMovesModel>,
  overstayingTrucks: Array<OverstayingTrucksModel>,
  averageContainerDwellTime: Array<AverageContainerDwellTimeModel>,
  yardContainerSizeType: Array<YardContainerSizeModel>,
  hazardousMaterial: Array<HazardousMaterialModel>
}

export const initialState: OperationState = {
  error: null,
  status: ApiStatus.Pending,
  operation: null,
  portProductivity: null,
  portMap: null,
  berthingStatus: null,
  operatingStatus: null,
  equipmentStatus: null,
  equipmentJobOrder: null,
  equipmentList: null,
  equipmentSelectedPortId: null,
  equipmentDetails: null,
  craneProductivity: null,
  craneMovement: null,
  craneUtilizationHistory: null,
  movementTimeOverview: null,
  equipmentMaintenanceSummary: null,
  equipmentPartsInspection: null,
  historicalMaintenance: null,
  equipmentAlert: null,
  analyticsSelectedPortId: null,
  kpiSelectedPortId: null,
  equipmentFailureChart: null,
  equipmentAlarmAnalysis: null,
  totalContainersMovement: null,
  containersMovementTypes: null,
  craneUtilizationAnalytics: null,
  portEquipmentBerth: null,
  craneSensorEvaluation: null,
  topVesselDelay: null,
  berthingStatusOverview: null,
  quayYardStatus: null,
  tugboatStatus: null,
  equipmentAlertsAnalytics: null,
  logData: null,
  portProductivityKpi: null,
  craneKpi: null,
  tugboatKpi: null,
  averageTurnTime: null,
  containerGateMoves: null,
  overstayingTrucks: null,
  averageContainerDwellTime: null,
  yardContainerSizeType: null,
  hazardousMaterial: null
}

export const operationReducer = createReducer(
  initialState,
  on(getOperation, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getOperationSuccess, (state, { operation }) => ({
    ...state,
    operation: operation,
    error: null,
    status: ApiStatus.Success
  })),
  on(getOperationFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error
  })),
  on(resetOperationMngmt, (state) => ({
    ...state,
    operation: null,
    error: null,
    status: ApiStatus.Pending
  })),
  on(getPortProductivity, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getPortProductivitySuccess, (state, { portProductivity }) => ({
    ...state,
    portProductivity: portProductivity,
    error: null,
    status: ApiStatus.Success
  })),
  on(getPortProductivityFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error
  })),
  on(getPortMap, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getPortMapSuccess, (state, { portMap }) => ({
    ...state,
    portMap: portMap,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getPortMapFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getBerthingStatus, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getBerthingStatusSuccess, (state, { berthingStatus }) => ({
    ...state,
    berthingStatus: berthingStatus,
    status: ApiStatus.Success,
    error: null,
  })),
  on(getBerthingStatusFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getOperatingStatus, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getOperatingStatusSuccess, (state, { operatingStatus }) => ({
    ...state,
    operatingStatus: operatingStatus,
    error: null,
    status: ApiStatus.Success
  })),
  on(getOperatingStatusFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error
  })),
  on(getEquipmentStatus, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentStatusSuccess, (state, { equipmentStatus }) => ({
    ...state,
    equipmentStatus: equipmentStatus,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentStatusFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentJobOrder, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentJobOrderSuccess, (state, { equipmentJobOrder }) => ({
    ...state,
    equipmentJobOrder: equipmentJobOrder,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentJobOrderFailure, (state, { error }) => ({
    ...state,
    equipmentJobOrder: null,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentList, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentListSuccess, (state, { equipmentList }) => ({
    ...state,
    equipmentList: equipmentList,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentListFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(setEquipmentSelectedPortId, (state, { portId }) => ({
    ...state,
    equipmentSelectedPortId: portId,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentDetails, (state, { equipmentId }) => ({
    ...state,
    equipmentDetails: state.equipmentList?.find((equipment) => equipment.equipment_name == equipmentId),
    status: ApiStatus.Success,
    error: null,
  })),
  on(getEquipmentDetailsSuccess, (state, { equipmentDetails }) => ({
    ...state,
    equipmentDetails: equipmentDetails,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentDetailsFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneProductivity, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneProductivitySuccess, (state, { craneProductivity }) => ({
    ...state,
    craneProductivity: craneProductivity,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneProductivityFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneMovement, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneMovementSuccess, (state, { craneMovement }) => ({
    ...state,
    craneMovement: craneMovement,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneMovementFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneUtilizationHistory, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneUtilizationHistorySuccess, (state, { craneUtilizationHistory }) => ({
    ...state,
    craneUtilizationHistory: craneUtilizationHistory,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneUtilizationHistoryFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getMovementTimeOverview, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getMovementTimeOverviewSuccess, (state, { movementTimeOverview }) => ({
    ...state,
    movementTimeOverview: movementTimeOverview,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getMovementTimeOverviewFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentMaintenanceSummary, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentMaintenanceSummarySuccess, (state, { equipmentMaintenanceSummary }) => ({
    ...state,
    equipmentMaintenanceSummary: equipmentMaintenanceSummary,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentMaintenanceSummaryFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentPartsInspection, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentPartsInspectionSuccess, (state, { equipmentPartsInspection }) => ({
    ...state,
    equipmentPartsInspection: equipmentPartsInspection,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentPartsInspectionFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getHistoricalMaintenance, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getHistoricalMaintenanceSuccess, (state, { historicalMaintenance }) => ({
    ...state,
    historicalMaintenance: historicalMaintenance,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getHistoricalMaintenanceFailure, (state, { error }) => ({
    ...state,
    error: error,
    historicalMaintenance: [],
    status: ApiStatus.Error
  })),
  on(getEquipmentAlert, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentAlertSuccess, (state, { equipmentAlert }) => ({
    ...state,
    equipmentAlert: equipmentAlert,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentAlertFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),


  on(setAnalyticsSelectedPortId, (state, { portId }) => ({
    ...state,
    analyticsSelectedPortId: portId,
    error: null,
    status: ApiStatus.Success,
  })),

  on(setkpiSelectedPortId, (state, { portId }) => ({
    ...state,
    kpiSelectedPortId: portId,
    error: null,
    status: ApiStatus.Success,
  })),


  on(getEquipmentFailureChart, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentFailureChartSuccess, (state, { equipmentFailureChart }) => ({
    ...state,
    equipmentFailureChart: equipmentFailureChart,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentFailureChartFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentAlarmAnalysis, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentAlarmAnalysisSuccess, (state, { equipmentAlarmAnalysis }) => ({
    ...state,
    equipmentAlarmAnalysis: equipmentAlarmAnalysis,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentAlarmAnalysisFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getTotalContainersMovement, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getTotalContainersMovementSuccess, (state, { totalContainersMovement }) => ({
    ...state,
    totalContainersMovement: totalContainersMovement,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getTotalContainersMovementFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getContainersMovementTypes, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getContainersMovementTypesSuccess, (state, { containersMovementTypes }) => ({
    ...state,
    containersMovementTypes: containersMovementTypes,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getContainersMovementTypesFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneUtilizationAnalytics, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneUtilizationAnalyticsSuccess, (state, { craneUtilizationAnalytics }) => ({
    ...state,
    craneUtilizationAnalytics: craneUtilizationAnalytics,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneUtilizationAnalyticsFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getPortProductivityEquipmentBerthUtilization, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getPortProductivityEquipmentBerthUtilizationSuccess, (state, { portEquipmentBerth }) => ({
    ...state,
    portEquipmentBerth: portEquipmentBerth,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getPortProductivityEquipmentBerthUtilizationFailure, (state, { error }) => ({
    ...state,
    portEquipmentBerth: [],
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneSensorEvaluation, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneSensorEvaluationSuccess, (state, { craneSensorEvaluation }) => ({
    ...state,
    craneSensorEvaluation: craneSensorEvaluation,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneSensorEvaluationFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getTopVesselDelay, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getTopVesselDelaySuccess, (state, { topVesselDelay }) => ({
    ...state,
    topVesselDelay: topVesselDelay,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getTopVesselDelayFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getBerthingStatusOverview, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getBerthingStatusOverviewSuccess, (state, { berthingStatusOverview }) => ({
    ...state,
    berthingStatusOverview: berthingStatusOverview,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getBerthingStatusOverviewFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getQuayYardStatus, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getQuayYardStatusSuccess, (state, { quayYardStatus }) => ({
    ...state,
    quayYardStatus: quayYardStatus,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getQuayYardStatusFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getTugboatStatus, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getTugboatStatusSuccess, (state, { tugboatStatus }) => ({
    ...state,
    tugboatStatus: tugboatStatus,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getTugboatStatusFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getEquipmentAlertAnalytics, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getEquipmentAlertAnalyticsSuccess, (state, { equipmentAlertsAnalytics }) => ({
    ...state,
    equipmentAlertsAnalytics: equipmentAlertsAnalytics,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getEquipmentAlertAnalyticsFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getAlertsLogData, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getAlertsLogDataSuccess, (state, { logData }) => ({
    ...state,
    logData: logData,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getAlertsLogDataFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getPortProductivityKpi, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getPortProductivityKpiSuccess, (state, { portProductivityKpi }) => ({
    ...state,
    portProductivityKpi: portProductivityKpi,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getPortProductivityKpiFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getCraneKPI, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getCraneKPISuccess, (state, { craneKpi }) => ({
    ...state,
    craneKpi: craneKpi,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getCraneKPIFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getTugboatKpi, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getTugboatKpiSuccess, (state, { tugboatKpi }) => ({
    ...state,
    tugboatKpi: tugboatKpi,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getTugboatKpiFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getAverageTurnTime, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getAverageTurnTimeSuccess, (state, { averageTurnTime }) => ({
    ...state,
    averageTurnTime: averageTurnTime,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getAverageTurnTimeFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getContainerGateMoves, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getContainerGateMovesSuccess, (state, { containerGateMoves }) => ({
    ...state,
    containerGateMoves: containerGateMoves,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getContainerGateMovesFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getOverstayingTrucks, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getOverstayingTrucksSuccess, (state, { overstayingTrucks }) => ({
    ...state,
    overstayingTrucks: overstayingTrucks,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getOverstayingTrucksFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getAverageContainerDwellTime, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getAverageContainerDwellTimeSuccess, (state, { averageContainerDwellTime }) => ({
    ...state,
    averageContainerDwellTime: averageContainerDwellTime,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getAverageContainerDwellTimeFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getYardContainerSizeType, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getYardContainerSizeTypeSuccess, (state, { yardContainerSizeType }) => ({
    ...state,
    yardContainerSizeType: yardContainerSizeType,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getYardContainerSizeTypeFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  })),
  on(getHazardousMaterial, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getHazardousMaterialSuccess, (state, { hazardousMaterial }) => ({
    ...state,
    hazardousMaterial: hazardousMaterial,
    error: null,
    status: ApiStatus.Success,
  })),
  on(getHazardousMaterialFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error,
  }))
);

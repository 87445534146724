import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegionalMapComponent } from './regional-map.component';
// import { GeneralMapModule } from '../general-map/general-map.module';
// import { GeneralMapComponent } from '../general-map/general-map.component';

const routes: Routes = [{
  path: "",
  component: RegionalMapComponent,
  data: { breadcrumb: "" },

  children: [
    


  ]


}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegionalMapRoutingModule { }

import { createAction, props } from '@ngrx/store';
import {
  ErrorResponse,
  OperationModel,
  PortProductivityModel,
  BerthingStatusModel,
  PortMapModel,
  OperatingStatusModel,
  EquipmentStatusModel,
  EquipmentJobOrderModel,
  EquipmentDetailsModel,
  CraneProductivityModel,
  EquipmentAlertsModel,
  AlertsAnalyticsModel,
  BerthingStatusOverviewModel,
  PortProductivityKpiModel
} from '../../models';
import { CraneUtilizationAnalyticsModel, CraneUtilizationHistoryModel } from '../../models/crane-utilization.model';
import { MovementTimeOverviewModel } from '../../models/movement-time-overview.model';
import { EquipmentMaitenanceSummaryModel } from '../../models/equipment-maintenance-status.model';
import { EquipmentPartsInspectionModel } from '../../models/equipment-part-inspection.model';
import { HistoricalMaintenanceModel } from '../../models/historical-maintenance.model';
import { EquipmentFailureChartModel } from '../../models/failure-chart.model';
import { EquipmentAlarmAnalysisModel } from '../../models/alarm-analysis.model';
import { TotalContainersMovementModel } from '../../models/container-moved-by-type.model';
import { CraneMovementModel } from '../../models/crane-movement-steps.model';
import { CraneSensorEvaluationModel } from '../../models/crane-sensor-eval';
import { ContainersMovementTypesModel } from '../../models/craner-container-moves.model';
import { TopVesselDelay } from '../../models/top-vessel-delay';
import { PortProductivityEquipmentBerthUtilizationModel } from '../../models/port-productivity-equipment-berth-utilization.model';
import { QuayYardStatusModel } from '../../models/crane-list.model';
import { TugboatKpiModel, TugboatList, TugboatStatusModel } from '../../models/tugboat-list.model';
import { LogsDataModel } from '../../models/logs';
import { CraneListKPIModel } from '../../models/craneKpi';
import { AverageTurnTimeModel } from '../../models/average-turn-time.model';
import { ContainerGateMovesModel } from '../../models/container-gate-moves.model';
import { OverstayingTrucksModel } from '../../models/overstaying-trucks.model';
import { AverageContainerDwellTimeModel } from '../../models/average-container-dwell-time.model';
import { YardContainerSizeModel } from '../../models/yard-container-size.model';
import { HazardousMaterialModel } from '../../models/hazardous-material.model';


export const getOperation = createAction(
  '[Dashboard Page] Get Operation',
  props<{ portsId?: string }>()
);

export const getOperationSuccess = createAction(
  '[Get Operation API] Get Operation Successful',
  props<{ operation: OperationModel }>()
);

export const getOperationFailure = createAction(
  '[Get Operation API] Get Operation Failed',
  props<{ error: ErrorResponse }>()
);

export const resetOperationMngmt = createAction(
  '[Dashboard Page] Reset Get Operation',
)

export const getPortProductivity = createAction(
  '[Operations Dashboard Page] Get Port Productivity',
  props<{ portId?: string }>()
);

export const getPortProductivitySuccess = createAction(
  '[Get Port Productivity API] Get Port Productivity Successful',
  props<{ portProductivity: PortProductivityModel }>()
);

export const getPortProductivityFailure = createAction(
  '[Get Port Productivity API] Get Port Productivity Failed',
  props<{ error: ErrorResponse }>()
);

export const getPortMap = createAction(
  '[Dashboard]  Get Port Map',
  props<{ portId?: string }>()
);

export const getPortMapSuccess = createAction(
  '[Get Port Map API] Get Port Map Successful',
  props<{ portMap: Array<PortMapModel> }>()
);

export const getPortMapFailure = createAction(
  '[Get Port Map API] Get Port Map Failed',
  props<{ error: ErrorResponse }>()
);

export const getBerthingStatus = createAction(
  '[Operations Dashboard]  Get Berthing Status',
  props<{ portId?: string }>()
);

export const getBerthingStatusSuccess = createAction(
  '[Get Berthing Status API] Get Berthing Status Successful',
  props<{ berthingStatus: BerthingStatusModel }>()
);

export const getBerthingStatusFailure = createAction(
  '[Get Berthing Status API] Get Berthing Status Failed',
  props<{ error: ErrorResponse }>()
);

export const getOperatingStatus = createAction(
  '[Operations Dashboard Page]  Get Operating Status',
  props<{ portId?: string }>()
);

export const getOperatingStatusSuccess = createAction(
  '[Get Operating Status API] Get Operating Status Successful',
  props<{ operatingStatus: Array<OperatingStatusModel> }>()
);

export const getOperatingStatusFailure = createAction(
  '[Get Operating Status API] Get Operating Status Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentStatus = createAction(
  '[Dashboard] Get Equipment Status',
  props<{ portsId?: string }>()
);

export const getEquipmentStatusSuccess = createAction(
  '[Get Equipment Status API] Get Equipment Status Successful',
  props<{ equipmentStatus: Array<EquipmentStatusModel> }>()
);

export const getEquipmentStatusFailure = createAction(
  '[Get Equipment Status API] Get Equipment Status Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentJobOrder = createAction(
  '[Operations Dashboard Page]  Get Equipment Job Order',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getEquipmentJobOrderSuccess = createAction(
  '[Get Equipment Job Order API] Get Equipment Job Order Successful',
  props<{ equipmentJobOrder: EquipmentJobOrderModel }>()
);

export const getEquipmentJobOrderFailure = createAction(
  '[Get Equipment Job Order API] Get Equipment Job Order Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentList = createAction(
  '[Equipment List Page] Get Equipment List',
  props<{ portId?: string }>()
);

export const getEquipmentListSuccess = createAction(
  '[Get Equipment List API] Get Equipment List Successful',
  props<{ equipmentList: Array<EquipmentDetailsModel> }>()
);

export const getEquipmentListFailure = createAction(
  '[Get Equipment List API] Get Equipment List Failed',
  props<{ error: ErrorResponse }>()
);

export const setEquipmentSelectedPortId = createAction(
  '[Equipment Details Page - Performance] Set Selected Port Id',
  props<{ portId?: string }>()
);

export const getEquipmentDetails = createAction(
  '[Equipment Details Page] Get Equipment Details',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getEquipmentDetailsSuccess = createAction(
  '[Get Equipment Details API] Get Equipment Details Successful',
  props<{ equipmentDetails: EquipmentDetailsModel }>()
);

export const getEquipmentDetailsFailure = createAction(
  '[Get Equipment Details API] Get Equipment Details Failed',
  props<{ error: ErrorResponse }>()
);

export const getCraneProductivity = createAction(
  '[Equipment Details Page - Performace] Get Crane Productivity Details',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getCraneProductivitySuccess = createAction(
  '[Get Crane Productivity API] Get Crane Productivity Successful',
  props<{ craneProductivity: CraneProductivityModel }>()
);

export const getCraneProductivityFailure = createAction(
  '[Get Crane Productivity API] Get Crane Productivity Failed',
  props<{ error: ErrorResponse }>()
);

export const getCraneMovement = createAction(
  '[Equipment Details Page - Performance] Get Crane Productivity Details',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getCraneMovementSuccess = createAction(
  '[Get Crane Movement API] Get Crane Movement Successful',
  props<{ craneMovement: CraneMovementModel }>()
);

export const getCraneMovementFailure = createAction(
  '[Get Crane Movement API] Get Crane Movement Failed',
  props<{ error: ErrorResponse }>()
);

export const getMovementTimeOverview = createAction(
  '[Equipment Details Page - Performance] Get Movement Time Overview',
  props<{ portId?: string, equipmentId?: string, startDate?: string, endDate?: string }>()
);

export const getCraneUtilizationHistory = createAction(
  '[Equipment Details Page - Performance] Get Crane Utilization History',
  props<{ portId?: string, equipmentId?: string, startDate?: string, endDate?: string }>()
);

export const getCraneUtilizationHistorySuccess = createAction(
  '[Get Crane Utilization History API] Get Crane Utilization History Successful',
  props<{ craneUtilizationHistory: Array<CraneUtilizationHistoryModel> }>()
);

export const getCraneUtilizationHistoryFailure = createAction(
  '[Get Crane Utilization History API] Get Crane Utilization History Failed',
  props<{ error: ErrorResponse }>()
);

export const getMovementTimeOverviewSuccess = createAction(
  '[Get Movement Time Overview API] Get Movement Time Overview Successful',
  props<{ movementTimeOverview: MovementTimeOverviewModel }>()
);

export const getMovementTimeOverviewFailure = createAction(
  '[Get Movement Time Overview API] Get Movement Time Overview Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentMaintenanceSummary = createAction(
  '[Equipment Details Page - Maintenance] Get Equipment Maintenance Summary',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getEquipmentMaintenanceSummarySuccess = createAction(
  '[Get Equipment Maintenance Summary API] Get Equipment Maintenance Summary Successful',
  props<{ equipmentMaintenanceSummary: EquipmentMaitenanceSummaryModel }>()
);

export const getEquipmentMaintenanceSummaryFailure = createAction(
  '[Get Equipment Maintenance Summary API] Get Equipment Maintenance Summary Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentPartsInspection = createAction(
  '[Equipment Details Page - Maintenance] GetEquipment Parts Inspection ',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getEquipmentPartsInspectionSuccess = createAction(
  '[Get Equipment Parts Inspection API] Get Equipment Parts Inspection Successful',
  props<{ equipmentPartsInspection: Array<EquipmentPartsInspectionModel> }>()
);

export const getEquipmentPartsInspectionFailure = createAction(
  '[Get  Equipment Parts Inspection API] Get Equipment Parts Inspection Failed',
  props<{ error: ErrorResponse }>()
);

export const getHistoricalMaintenance = createAction(
  '[Equipment Details Page - Maitenance] Get Historical Maintenance',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getHistoricalMaintenanceSuccess = createAction(
  '[Get Historical Maintenance API] Get Historical Maintenance Successful',
  props<{ historicalMaintenance: Array<HistoricalMaintenanceModel> }>()
);

export const getHistoricalMaintenanceFailure = createAction(
  '[Get Historical Maintenance API] Get Historical Maintenance Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentAlert = createAction(
  '[Equipment Details Page - Maintenance] Get Equipment Alert ',
  props<{ portId?: string, equipmentId?: string }>()
);

export const getEquipmentAlertSuccess = createAction(
  '[Get Equipment Alert API] Get Equipment Alert Successful',
  props<{ equipmentAlert: EquipmentAlertsModel }>()
);

export const getEquipmentAlertFailure = createAction(
  '[Get  EEquipment Alert API] Get Equipment Alert Failed',
  props<{ error: ErrorResponse }>()
);

export const setAnalyticsSelectedPortId = createAction(
  '[Operations Analytics Page] Set Selected Port Id',
  props<{ portId?: string }>()
);

export const setkpiSelectedPortId = createAction(
  '[Port Productivity KPI ] Set Selected Port Id',
  props<{ portId?: string }>()
);

export const getEquipmentFailureChart = createAction(
  '[Operations Analytics Page] Get Equipment Failure Chart',
  props<{ portId?: string, startDate?: string, endDate?: string }>()
);

export const getEquipmentFailureChartSuccess = createAction(
  '[Get Equipment Failure Chart API] Get Equipment Failure Chart  Successful',
  props<{ equipmentFailureChart: Array<EquipmentFailureChartModel> }>()
);

export const getEquipmentFailureChartFailure = createAction(
  '[Get  Equipment Failure Chart  API] Get Equipment Failure Chart Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentAlarmAnalysis = createAction(
  '[Operations Analytics Page] Get Equipment Alarm Analysis Alert',
  props<{ portId?: string, startDate?: string, endDate?: string }>()
);

export const getEquipmentAlarmAnalysisSuccess = createAction(
  '[Get Equipment Alarm Analysis API] Get Equipment Alarm Analysis Successful',
  props<{ equipmentAlarmAnalysis: Array<EquipmentAlarmAnalysisModel> }>()
);

export const getEquipmentAlarmAnalysisFailure = createAction(
  '[Get Equipment Alarm Analysis API] Get Equipment Alarm Analysis Failed',
  props<{ error: ErrorResponse }>()
);

export const getTotalContainersMovement = createAction(
  '[Operations Analytics Page] Get Total Containers Movement',
  props<{ portId?: string, startDate?: string, endDate?: string }>()
);

export const getTotalContainersMovementSuccess = createAction(
  '[Get Total Containers Movement API] Get Total Containers Movement Successful',
  props<{ totalContainersMovement: Array<TotalContainersMovementModel> }>()
);

export const getTotalContainersMovementFailure = createAction(
  '[Get Total Containers Movement API] Get Total Containers Movement Failed',
  props<{ error: ErrorResponse }>()
);

export const getContainersMovementTypes = createAction(
  '[Operations Analytics Page] Get Containers Movement Types',
  props<{ portId?: string, startDate?: string, endDate?: string }>()
);

export const getContainersMovementTypesSuccess = createAction(
  '[Get Containers Movement Types API] Get Containers Movement Types Successful',
  props<{ containersMovementTypes: Array<ContainersMovementTypesModel> }>()
);

export const getContainersMovementTypesFailure = createAction(
  '[Get Containers Movement Types API] Get Containers Movement Types Failed',
  props<{ error: ErrorResponse }>()
);

export const getCraneUtilizationAnalytics = createAction(
  '[Operations Analytics Page] Get Crane Utilization Analytics',
  props<{ portId?: string, startDate?: string, endDate?: string }>()
);
export const getCraneUtilizationAnalyticsSuccess = createAction(
  '[Get Crane Utilization Analytics API] Get Crane Utilization Analytics Successful',
  props<{ craneUtilizationAnalytics: Array<CraneUtilizationAnalyticsModel> }>()
);

export const getCraneUtilizationAnalyticsFailure = createAction(
  '[Get Crane Utilization Analytics API] Get Crane Utilization Analytics Failed',
  props<{ error: ErrorResponse }>()
);

export const getPortProductivityEquipmentBerthUtilization = createAction(
  '[Operations Analytics Page] Get Port Productivity Equipment Berth Utilization',
  props<{ portId?: string, filter?: number }>()
);

export const getPortProductivityEquipmentBerthUtilizationSuccess = createAction(
  '[Get Port Productivity Equipment Berth Utilization API] Get Port Productivity Equipment Berth Utilization Successful',
  props<{ portEquipmentBerth: Array<PortProductivityEquipmentBerthUtilizationModel> }>()
);

export const getPortProductivityEquipmentBerthUtilizationFailure = createAction(
  '[Get Port Productivity Equipment Berth Utilization API] Get Port Productivity Equipment Berth Utilization Failed',
  props<{ error: ErrorResponse }>()
);

export const getCraneSensorEvaluation = createAction(
  '[Operations Analytics Page] Get Crane Sensor Evaluation',
  props<{ portId?: string }>()
);

export const getCraneSensorEvaluationSuccess = createAction(
  '[Get Crane Sensor Evalution API] Get Crane Sensor Evaluation Successful',
  props<{ craneSensorEvaluation: Array<CraneSensorEvaluationModel> }>()
);

export const getCraneSensorEvaluationFailure = createAction(
  '[Get Crane Sensor Evalution  API] Get Crane Sensor Evaluation Failed',
  props<{ error: ErrorResponse }>()
);

export const getTopVesselDelay = createAction(
  '[Operations Analytics Page] Get Top Vessel Delay',
  props<{ portId?: string, filter?: number }>()
);

export const getTopVesselDelaySuccess = createAction(
  '[Get Top Vessel Delay API] Get Top Vessel Delay Successful',
  props<{ topVesselDelay: Array<TopVesselDelay> }>()
);

export const getTopVesselDelayFailure = createAction(
  '[Get Top Vessel Delay API] Get Top Vessel Delay Failed',
  props<{ error: ErrorResponse }>()
);

export const getBerthingStatusOverview = createAction(
  '[Operations Overview Page] Get Berthing Status Overview',
  props<{ portId?: string }>()
);

export const getBerthingStatusOverviewSuccess = createAction(
  '[Get Berthing Status Overview API] Get Berthing Status Overview Successful',
  props<{ berthingStatusOverview: BerthingStatusOverviewModel }>()
);

export const getBerthingStatusOverviewFailure = createAction(
  '[Get Berthing Status Overview API] Get Berthing Status Overview Failed',
  props<{ error: ErrorResponse }>()
);

export const getQuayYardStatus = createAction(
  '[Operations Overview Page] Get Quay & Yard Status',
  props<{ portId?: string }>()
);

export const getQuayYardStatusSuccess = createAction(
  '[Get Quay & Yard Status API] Get Quay & Yard Status Successful',
  props<{ quayYardStatus: QuayYardStatusModel }>()
);

export const getQuayYardStatusFailure = createAction(
  '[Get Quay & Yard Status API] Get Quay & Yard StatusFailed',
  props<{ error: ErrorResponse }>()
);

export const getTugboatStatus = createAction(
  '[Operations Overview Page] Get Tugboat Status',
  props<{ portId?: string }>()
);

export const getTugboatStatusSuccess = createAction(
  '[Get Tugboat  Status API] Get Tugboat  Successful',
  props<{ tugboatStatus: TugboatStatusModel }>()
);

export const getTugboatStatusFailure = createAction(
  '[Get Tugboat Status API] Get Quay & Tugboat Failed',
  props<{ error: ErrorResponse }>()
);

export const getEquipmentAlertAnalytics = createAction(
  '[Operations Alerts Page] Get Equipment Alert Analytics',
  props<{ portId?: string }>()
);

export const getEquipmentAlertAnalyticsSuccess = createAction(
  '[Get Equipment Alert Analytics API] Get Equipment Alert Analytics Successful',
  props<{ equipmentAlertsAnalytics: Array<AlertsAnalyticsModel> }>()
);

export const getEquipmentAlertAnalyticsFailure = createAction(
  '[Get Equipment Alert Analytics API] Get Equipment Alert Analytics  Failed',
  props<{ error: ErrorResponse }>()
);

export const getAlertsLogData = createAction(
  '[Operations Alerts Page] Get Alert Data Logs',
  props<{ portId?: string }>()
);

export const getAlertsLogDataSuccess = createAction(
  '[Get Alert Data Logs API] Get Alert Data Logs Successful',
  props<{ logData: Array<LogsDataModel> }>()
);

export const getAlertsLogDataFailure = createAction(
  '[Get Alert Data Logs API] Get Alert Data Logs Failed',
  props<{ error: ErrorResponse }>()
);

export const getPortProductivityKpi = createAction(
  '[Operations KPI Page] Get Port Productivity KPI',
  props<{ portId?: string }>()
);

export const getPortProductivityKpiSuccess = createAction(
  '[Get Port Productivity KPI] Get Port Productivity KPI Successful',
  props<{ portProductivityKpi: PortProductivityKpiModel }>()
);

export const getPortProductivityKpiFailure = createAction(
  '[Get Port Productivity KPI]Get Port Productivity KPI Failed',
  props<{ error: ErrorResponse }>()
);

export const getCraneKPI = createAction(
  '[Operations KPI Page] Get Crane KPI Logs',
  props<{ portId?: string }>()
);

export const getCraneKPISuccess = createAction(
  '[Get Crane KPI API] Get Crane KPI Successful',
  props<{ craneKpi: CraneListKPIModel }>()
);

export const getCraneKPIFailure = createAction(
  '[Get Crane KPI API] Get Crane KPI Failed',
  props<{ error: ErrorResponse }>()
);

export const getTugboatKpi = createAction(
  '[Operations KPI Page] Get Tugboat KPI',
  props<{ portId?: string }>()
);

export const getTugboatKpiSuccess = createAction(
  '[Get Tugboat KPI] Get Tugboat KPI Successful',
  props<{ tugboatKpi: Array<TugboatList> }>()
);

export const getTugboatKpiFailure = createAction(
  '[Get Tugboat KPI]Get Tugboat KPI Failed',
  props<{ error: ErrorResponse }>()
);

export const getAverageTurnTime = createAction(
  '[Operations Yard Page] Get Average Turn Time',
  props<{ portId?: string }>()
);

export const getAverageTurnTimeSuccess = createAction(
  '[Get Average Turn Time] Get Average Turn Time Successful',
  props<{ averageTurnTime: AverageTurnTimeModel }>()
);

export const getAverageTurnTimeFailure = createAction(
  '[Get Average Turn Time] Get Average Turn Time Failed',
  props<{ error: ErrorResponse }>()
);

export const getContainerGateMoves = createAction(
  '[Operations Yard Page] Get Container Gate Moves',
  props<{ portId?: string }>()
);

export const getContainerGateMovesSuccess = createAction(
  '[Get Container Gate Moves] Get Container Gate Moves Successful',
  props<{ containerGateMoves: Array<ContainerGateMovesModel> }>()
);

export const getContainerGateMovesFailure = createAction(
  '[Get Container Gate Moves] Get Container Gate Moves Failed',
  props<{ error: ErrorResponse }>()
);

export const getOverstayingTrucks = createAction(
  '[Operations Yard Page] Get Overstaying Trucks',
  props<{ portId?: string }>()
);

export const getOverstayingTrucksSuccess = createAction(
  '[Get Overstaying Trucks] Get Overstaying Trucks Successful',
  props<{ overstayingTrucks: Array<OverstayingTrucksModel> }>()
);

export const getOverstayingTrucksFailure = createAction(
  '[Get Overstaying Trucks] Get Overstaying Trucks Failed',
  props<{ error: ErrorResponse }>()
);

export const getAverageContainerDwellTime = createAction(
  '[Operations Yard Page] Get Average Container Dwell Time',
  props<{ portId?: string }>()
);

export const getAverageContainerDwellTimeSuccess = createAction(
  '[Get Average Container Dwell Time] Get Average Container Dwell Time Successful',
  props<{ averageContainerDwellTime: Array<AverageContainerDwellTimeModel> }>()
);

export const getAverageContainerDwellTimeFailure = createAction(
  '[Get Average Container Dwell Time] Get Average Container Dwell Time Failed',
  props<{ error: ErrorResponse }>()
);

export const getYardContainerSizeType = createAction(
  '[Operations Yard Page] Get Yard Container Size Type',
  props<{ portId?: string }>()
);

export const getYardContainerSizeTypeSuccess = createAction(
  '[Get Yard Container Size Type] Get Yard Container Size Type Successful',
  props<{ yardContainerSizeType: Array<YardContainerSizeModel> }>()
);

export const getYardContainerSizeTypeFailure = createAction(
  '[Get Yard Container Size Type] Get Yard Container Size Type Failed',
  props<{ error: ErrorResponse }>()
);

export const getHazardousMaterial = createAction(
  '[Operations Yard Page] Get Hazardous Material',
  props<{ portId?: string }>()
);

export const getHazardousMaterialSuccess = createAction(
  '[Get Hazardous Material] Get Hazardous Material Successful',
  props<{ hazardousMaterial: Array<HazardousMaterialModel> }>()
);

export const getHazardousMaterialFailure = createAction(
  '[Get Hazardous Material] Get Hazardous Material Failed',
  props<{ error: ErrorResponse }>()
);

import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MsalService } from "@azure/msal-angular";
import { SessionManager } from "../common-function/session-manager";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: MsalService) { }

  canActivate() {
    // role based implementation
    // const accessToken = sessionStorage.getItem("accessToken");

    // if (accessToken !== null) {
    //   const helper = new JwtHelperService();
    //   const decodedToken = helper.decodeToken(accessToken);
    //   const userName = decodedToken.username;
    //   const role = decodedToken.roleId;

    //   if (userName !== null) {
    //     if( role == 5){
    //       this.router.navigate(["/port-authority"]);
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     this.router.navigate(["/multi-port"]);
    //     sessionStorage.clear();
    //     return false;
    //   }
    // } else {
    //   this.router.navigate(["/login"]);
    //   sessionStorage.clear();
    //   return false;
    // }
    // const accessToken = this.getToken();
    const sessionAccessToken = sessionStorage.getItem("accessToken");

    if (sessionAccessToken !== null) {
      if (SessionManager.isValid()) {
        return true;
      } else {
        sessionStorage.clear();
        this.router.navigate(["/login"]);
        return false;
      }
    } else {
      this.router.navigate(["/login"]);
      return false;
    }

    // if (accessToken !== null) {
    //   console.log('inside not null accesstoken');
    //   if (localAccessToken !== null) {
    //     console.log('inside sessionToken not null');
    //     return true;
    //   } else {
    //     console.log('inside sessionToken null');
    //     this.router.navigate(["/login"]);
    //     return false;
    //   }
    // } else {
    //   console.log('inside null accesstoken');
    //   this.router.navigate(["/login"]);
    //   return false;
    // }
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sustainability-dashboard',
  templateUrl: './sustainability-dashboard.component.html',
  styleUrls: ['./sustainability-dashboard.component.scss']
})
export class SustainabilityDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingNavRoutingModule } from './setting-nav-routing.module';
import { DashboardSettingsComponent } from './dashboard-settings/dashboard-settings.component';
import { AnalyticsSettingsComponent } from './analytics-settings/analytics-settings.component';
import { PortSettingsComponent } from './port-settings/port-settings.component';
import { RefreshRateSettingsComponent } from './refresh-rate-settings/refresh-rate-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    DashboardSettingsComponent, 
    AnalyticsSettingsComponent, 
    PortSettingsComponent, 
    RefreshRateSettingsComponent
  ],
  imports: [
    CommonModule,
    SettingNavRoutingModule, 
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressBarModule
  ]
})
export class SettingNavModule { }

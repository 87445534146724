import { Component, OnInit } from '@angular/core';
import * as carbonData from '../../../shared/mock-data/carbon.json'


@Component({
  selector: 'app-carbon-mgnt-page',
  templateUrl: './carbon-mgnt-page.component.html',
  styleUrls: ['./carbon-mgnt-page.component.scss']
})
export class CarbonMgntPageComponent implements OnInit {
  public dataSourceCarbonMgnt;
  constructor() { }

  ngOnInit(): void {
    this.dataSourceCarbonMgnt = carbonData.carbon;
  }

}

export const ICON: any = {
  ARROW_GREEN_DOWN: 'assets/icons/arrow-down-green.svg',
  ARROW_GREEN_UP: 'assets/icons/kpi-up.svg',
  ARROW_RED_DOWN: 'assets/icons/kpi-red-down.svg',
  ARROW_RED_UP: 'assets/icons/arrow-up-red.svg',
  EQUIPMENT_STATUS: {
    CRANE: {
      ACTIVE: '/assets/icons/Crane-Active.svg',
      IDLE: '/assets/icons/Crane-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/Crane-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/Crane-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/Crane-NonProductive.svg'
    },
    QUAYCRANE: {
      ACTIVE: '/assets/icons/Crane-Active.svg',
      IDLE: '/assets/icons/Crane-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/Crane-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/Crane-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/Crane-NonProductive.svg'
    },
    RTG: {
      ACTIVE: '/assets/icons/RTG-Active.svg',
      IDLE: '/assets/icons/RTG-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/RTG-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/RTG-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/RTG-NonProductive.svg'
    },
    TUGBOAT: {
      ACTIVE: '/assets/icons/tugboat_active.svg',
      IDLE: '/assets/icons/tugboat_idling.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/tugboat_inactive.svg', // Engine is off,
      INACTIVE: '/assets/icons/tugboat_inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/tugboat_non_productive.svg'
    },
    MAINTENANCE: '/assets/icons/Alert-Amber.svg'
  },
  IPC_LOGO: 'assets/icons/ipc-logo.svg',
  EQUIPMENT_DETAILS: {
    PERFORMANCE: {
      active: '/assets/icons/Efficiency-Blue.svg',
      inactive: '/assets/icons/Efficiency-Gray.svg'
    },
    MAINTENANCE: {
      active: '/assets/icons/Repair-Blue.svg',
      inactive: '/assets/icons/Repair-Gray.svg'
    }
  }
}

export const AZUREMAP_ICONS = {
  active: "assets/icons/green_marker.png",
  ship: "assets/icons/ship_red.svg",
  inactive: "assets/icons/grey_marker.png",
  port: "assets/icons/red_marker.png",
};


export const IMG: any = {
  DIGITAL_TWIN_BLACK: 'assets/images/digital-twin-black.png',
  DIGITAL_TWIN_WHITE: 'assets/images/digital-twin-white.png',
  OPERATIONS_BLACK: 'assets/images/operations-black.png',
  OPERATIONS_WHITE: 'assets/images/operations-white.png',
  PORT_AUTHORITY_BLACK: 'assets/images/port-authority-black.png',
  PORT_AUTHORITY_WHITE: 'assets/images/port-authority-white.png',
  SETTINGS_BLACK: 'assets/images/settings-black.png',
  SETTINGS_WHITE: 'assets/images/settings-white.png',
  SUSTAINABILITY_BLACK: 'assets/images/sustainability-black.png',
  SUSTAINABILITY_WHITE: 'assets/images/sustainability-white.png',
  YARD_MNGMT_BLACK: 'assets/images/yard-mngmt-black.png',
  YARD_MNGMT_WHITE: 'assets/images/yard-mngmt-white.png',
  ENERGY_MGT_BLACK: 'assets/images/energy-black.png',
  ENERGY_MGT_WHITE: 'assets/images/energy-white.png'
}


export const BUOY = {
  SB1: {
    lat: 38.44478976472635,
    long: 15.897922326732186,
    name: "SB1",
    status: "Active",
  },

  SB2: {
    lat: 38.44092663938534,
    long: 15.895700566021901,
    name: "SB2",
    status: "Active",
  },
  SB3: {
    lat: 38.4438,
    long: 15.8901,
    name: "SB3",
    status: "Active",
  },
  SB4: {
    lat: 38.4444,
    long: 15.8909,
    name: "SB4",
    status: "Active",
  },
  SB5: {
    lat: 38.4397,
    long: 15.8799,
    name: "SB5",
    status: "Active",
  },

  SB6: {
    lat: 38.463,
    long: 15.8937,
    name: "SB6",
    status: "Active",
  },
  SB7: {
    lat: 38.4542,
    long: 15.8833,
    name: "SB7",
    status: "Active",
  },
  SB8: {
    lat: 38.4587,
    long: 15.8881,
    name: "SB8",
    status: "Active",
  },
  SB10: {
    lat: 38.47241672791836,
    long: 15.894930401224428,
    name: "SB10",
    status: "Inactive",
  },
  SB9: {
    lat: 38.4497,
    long: 15.8798,
    name: "SB9",
    status: "Active",
  },
};

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { API } from "../constants/api.constant";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ErrorResponse } from "../models/error-response.model";
import { ApiResponse } from "../models/api-response.model";

@Injectable({
  providedIn: "root",
})
export class SocialResponsibilityService {
  private socialResponsibility = environment.basePath + API.KPI;
  //private socialResponsibility = "/connPort" + API.KPI; //local

  constructor(private http: HttpClient) { }

  getSocialResponsibility(
    portsId?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set("port_ids", portsId) : null;

    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.socialResponsibility}/social-workplace-safety`,
      { params: params }
    );
  }

  getWorkforceDiversity(
    year?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set("year", year) : null;

    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.socialResponsibility}/workforce-diversity`,
      { params: params }
    );
  }

  getEngagementAndTrainingMetrics(
    year?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set("year", year) : null;

    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.socialResponsibility}/engagement-and-training-metrics`,
      { params: params }
    );
  }

  getSafetyAndHealthMetrics(
    year?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set("year", year) : null;

    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.socialResponsibility}/safety-health-metrics`,
      { params: params }
    );
  }

  getLostTimeInjuryFrequency(
    year?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = year ? new HttpParams().set("year", year) : null;

    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.socialResponsibility}/lost-time-injury-frequency`,
      { params: params }
    );
  }
}

import { NgModule } from '@angular/core';
import {  DatePipe } from '@angular/common'
import { PortOptimizationRoutingModule } from './port-optimization-routing.module';

import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { MatTabsModule } from '@angular/material/tabs'
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [

 
    ],
  imports: [
    CommonModule,
    PortOptimizationRoutingModule, MatPaginatorModule, MatTableModule, MatCardModule ,MatIconModule,MatMenuModule ,
    MatToolbarModule, FormsModule ,HttpClientModule,MatTabsModule ,
  ],
  providers: [DatePipe]
})
export class PortOptimizationModule { }

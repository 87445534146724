import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { routerTransition } from 'src/app/shared/router.animations';
import { selectAnalyticsPortId } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss'],
  animations: [routerTransition()]
})
export class AnalyticsDashboardComponent implements OnInit {
  analyticsPortId$: Observable<string> = this.store.select(selectAnalyticsPortId);

  constructor(private store: Store) { }

  ngOnInit(): void { }

}

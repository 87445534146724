import { Component, Input, OnInit } from '@angular/core';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EquipmentMaitenanceSummaryModel } from 'src/app/shared/models/equipment-maintenance-status.model';
import { selectEquipmentMaintenanceSummary } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentMaintenanceSummary } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-maintenance-summary',
  templateUrl: './maintenance-summary.component.html',
  styleUrls: ['./maintenance-summary.component.scss']
})
export class MaintenanceSummaryComponent implements OnInit {
  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  equipmentMaintenanceSummary$: Observable<EquipmentMaitenanceSummaryModel> = this.store.select(selectEquipmentMaintenanceSummary);
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getEquipmentMaintenanceSummary({ portId: this.portId, equipmentId: this.equipmentId }));
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { OverstayingTrucksModel } from 'src/app/shared/models/overstaying-trucks.model';

@Component({
  selector: 'app-obt-details',
  templateUrl: './obt-details.component.html',
  styleUrls: ['./obt-details.component.scss']
})
export class ObtDetailsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData,
    private dialog: MatDialog
  ) { }

  public displaydata: OverstayingTrucksModel[] = []
  public dataSource;
  public columnHeaders = ['Truck', 'Chassis No.', 'Genset No.', 'Gate In'];
  // public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;

  ngOnInit() {
    if (this.modalData) {
      this.noData = false
      this.displaydata.push(this.modalData)
      this.dataSource = this.displaydata
    }
  }

  dialogClose() {
    this.dialog.closeAll()
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthMembersRoutingModule } from './auth-members-routing.module';
import { AuthMembersComponent } from './auth-members.component';
import { OperationsNavModule } from 'src/app/operations-dashboard/operations-nav/operations-nav.module';
import { EquipmentIconModule } from 'src/app/operations-dashboard/components/equipment-icon/equipment-icon.module';
import { VirtualAssetComponent } from './virtual-asset/virtual-asset.component';
import { DigitalTwinComponent } from './digital-twin/digital-twin.component';
import { RavennaMapIconComponent } from './ravenna-map-icon/ravenna-map-icon.component';
import { ThreeDViewComponent } from './three-d-view/three-d-view.component';


@NgModule({
  declarations: [
    AuthMembersComponent,
    VirtualAssetComponent,
    DigitalTwinComponent,
    RavennaMapIconComponent,
    ThreeDViewComponent,

  ],
  imports: [
    CommonModule,
    EquipmentIconModule,
    AuthMembersRoutingModule,
    OperationsNavModule
  ]
})
export class AuthMembersModule { }

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { CraneSensorEvaluationModel } from 'src/app/shared/models/crane-sensor-eval';
import { getCraneSensorEvaluation } from 'src/app/shared/state/operations/operations.action';
import { selectCraneSensorEvaluation } from 'src/app/shared/state/operations/operations.selectors';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';

@Component({
  selector: 'app-sensor-evaluation',
  templateUrl: './sensor-evaluation.component.html',
  styleUrls: ['./sensor-evaluation.component.scss']
})
export class SensorEvaluationComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  selectedPortId: string;

  craneSensorEvaluation$: Observable<CraneSensorEvaluationModel[]> = this.store.select(selectCraneSensorEvaluation);
  dataSource = new MatTableDataSource<CraneSensorEvaluationModel>();
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  noData: boolean = true;
  pageIndex = PAGINATOR.DASHBOARD.SENSOR_EVALUATION.pageIndex;
  pageSize = PAGINATOR.DASHBOARD.SENSOR_EVALUATION.pageSize;
  displayedColumns: string[] = ['equipment', 'sensor', 'min', 'max', 'average', 'uom', 'minThreshold', 'maxThreshold', 'time'];

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getCraneSensorEvaluation({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.craneSensorEvaluation$.subscribe((data) => {
        if (data?.length > 0) {
          this.createTable(data);
        } else {
          this.noData = true;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getCraneSensorEvaluation({ portId: this.selectedPortId }));
      }
    }
  }

  createTable(data: CraneSensorEvaluationModel[]) {
    this.dataSource.data = data;
    this.noData = false;
  }
}

import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { BiodiversityState } from "./biodiversity.reducer";

export const selectBiodiversityState = (state: AppState) => state.biodiversity;

export const selectBiodiversity= createSelector(
    selectBiodiversityState,
  (state: BiodiversityState) => state.biodiversity
);

export const selectQuarterlyShare = createSelector(
  selectBiodiversityState,
  (state: BiodiversityState) => state.quarterlyShare
);

export const selectTreePlantingMetrics = createSelector(
  selectBiodiversityState,
  (state: BiodiversityState) => state.treePlantingMetrics
);

export const selectProtectionWaters = createSelector(
  selectBiodiversityState,
  (state: BiodiversityState) => state.protectionWaters
);

export const selectCommunityEducation = createSelector(
  selectBiodiversityState,
  (state: BiodiversityState) => state.communityEducation
);
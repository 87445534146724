export const ICONS = {
    IPC_LOGO: 'assets/icons/ipc-logo.svg',

    EQUIPMENT_STATUS: {
        CRANE: {
            ACTIVE: '/assets/icons/Crane-Active.svg',
            IDLE: '/assets/icons/Crane-Idle.svg', // Turned on but there is no movement
            NON_UTILIZED: '/assets/icons/Crane-Inactive.svg', // Engine is off
            INACTIVE: '/assets/icons/Crane-Inactive.svg', // Engine is off
            NON_PRODUCTIVE: '/assets/icons/Crane-NonProductive.svg'
        },
        RTG: {
            ACTIVE: '/assets/icons/RTG-Active.svg',
            IDLE: '/assets/icons/RTG-Idle.svg', // Turned on but there is no movement
            NON_UTILIZED: '/assets/icons/RTG-Inactive.svg', // Engine is off
            INACTIVE: '/assets/icons/RTG-Inactive.svg', // Engine is off
            NON_PRODUCTIVE: '/assets/icons/RTG-NonProductive.svg'
        },
        TUGBOAT: {
            ACTIVE: '/assets/icons/tugboat_active.svg',
            IDLE: '/assets/icons/tugboat_idling.svg', // Turned on but there is no movement
            NON_UTILIZED: '/assets/icons/tugboat_inactive.svg', // Engine is off,
            INACTIVE: '/assets/icons/tugboat_inactive.svg', // Engine is off
            NON_PRODUCTIVE: '/assets/icons/tugboat_non_productive.svg'
        },
        MAINTENANCE: '/assets/icons/Alert-Amber.svg'
    },

    EQUIPMENT_DETAILS: {
        PERFORMANCE: {
            active: '/assets/icons/Efficiency-Blue.svg',
            inactive: '/assets/icons/Efficiency-Gray.svg'
        },
        MAINTENANCE: {
            active: '/assets/icons/Repair-Blue.svg',
            inactive: '/assets/icons/Repair-Gray.svg'
        }
    }
};
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ICON } from "src/app/shared/constants/assets.constant";
import {
  getWaterQualityIndexCompliance,
  getWaterQualityProfile,
} from "src/app/shared/state/water-conservation/water-conservation.actions";
import {
  selectWaterQualityIndexCompliance,
  selectWaterQualityProfile,
} from "src/app/shared/state/water-conservation/water-conservation.selectors";
import "chartjs-plugin-labels";
import { WaterQualityIndexComplianceModel, WaterQualityProfileModel } from "src/app/shared/models";
import { Observable } from "rxjs";
import { CHART } from "src/app/shared/constants/chart.constant";
@Component({
  selector: "app-water-quality",
  templateUrl: "./water-quality.component.html",
  styleUrls: ["./water-quality.component.scss"],
})
export class WaterQualityComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  waterQualityProfile$: Observable<WaterQualityProfileModel> = this.store.select(selectWaterQualityProfile);
  waterQualityIndexCompliance$: Observable<WaterQualityIndexComplianceModel> = this.store.select(selectWaterQualityIndexCompliance);
  private stackedBarChart: Chart;

  constructor(private store: Store, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.store.dispatch(getWaterQualityProfile({}));
    this.store.dispatch(getWaterQualityIndexCompliance({}));
  }

  ngAfterViewInit(): void {
    this.waterQualityProfile$.subscribe((water_profiles) => {
      if (water_profiles) {
        this.stackedBar(water_profiles);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getWaterQualityProfile({ year: this.selectedYear }));
        this.store.dispatch(getWaterQualityIndexCompliance({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getWaterQualityProfile({}));
        this.store.dispatch(getWaterQualityIndexCompliance({}));
      }
    }
  }

  stackedBar(data: WaterQualityProfileModel) {
    if (this.stackedBarChart) {
      this.stackedBarChart.destroy();
    }

    const canvas: HTMLCanvasElement = this.elementRef.nativeElement.querySelector("#waterQualityChart");
    if (!canvas) {
      console.error('Canvas element with id "waterQualityChart" not found');
      return;
    }

    const ctx = canvas.getContext("2d");
    const longlabels = data.water_profiles.map((source) => source.parameter);
    const labeladjusted = longlabels.map((label) => label.split(" /n"));
    const labelTarget = data.water_profiles.map((source) => source.onTarget);
    const dataTarget = data.water_profiles.map((source) => source.normalize);
    const backgroundColors = dataTarget.map((value: number, index: number) =>
      labelTarget[index] ? CHART.COLOR.WATER_QUALITY_PROFILE.GREEN : CHART.COLOR.WATER_QUALITY_PROFILE.RED
    );

    this.stackedBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labeladjusted,
        datasets: [
          {
            data: dataTarget,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                callback: function (value: any) {
                  const words = value.toString().split(" ");
                  return words;
                },
              },
            },
          ],
          yAxes: [{ stacked: true, display: false }],
          gridLines: { display: false },
        },
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10,
          intersect: false,
          callbacks: {
            title: function (tooltipItem, data) {
              const labelTooltip = data.labels[tooltipItem[0].index];
              return String(labelTooltip);
            },
            label: function (tooltipItem, item) {
              const labelTargetValue =
                data.water_profiles[tooltipItem.index].onTarget;
              const dataTargetValue =
                item.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return ` ${labelTargetValue}:${dataTargetValue} `;
            },
          }
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: CHART.COLOR.WATER_QUALITY_PROFILE.BLACK,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            color: CHART.COLOR.WATER_QUALITY_PROFILE.WHITE,
            align: "bottom",
            textAlign: "center",
            font: {
              lineHeight: 1.6,
              size: 14,
            },
            formatter: function (value, context) {
              if (value > 0) {
                value = value.toString().split(/(?=(?:...)*$)/).join(",");
                return value + "%";
              } else {
                return '';
              }
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

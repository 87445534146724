import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
  export class CarbonService {
    private totalFootprint: number = 0;
    private reportingYear: number = 0;
    private totalConsumption: number = 0;
    private totalFootprintScope1: number = 0;
    private totalFootprintScope2: number = 0;
    private totalFootprintPercentageScope1: number = 0;
    private totalFootprintPercentageScope2: number = 0;
    private totalConsumptionDividedBy22: number = 0;
    private selectedEmissionTypes: string[] = [];
    private selectedEmissionFactorPrice: number = 0;
    private isButtonDisabled: boolean = true;
    
  
    enableButton() {
      this.isButtonDisabled = false;
    }

    getIsButtonDisabled(): boolean {
      return this.isButtonDisabled;
    }

    setTotalFootprint(value: number) {
      this.totalFootprint = value;
    }
  
    getTotalFootprint(): number {
      return this.totalFootprint;
    }

    setReportingYear(year: number): void { 
      this.reportingYear = year;
    }
  
    getReportingYear(): number { 
      return this.reportingYear;
    }

    setTotalConsumption(value: number) {
      this.totalConsumption = value;
    }
  
    getTotalConsumption(): number {
      return this.totalConsumption;
    }

    setTotalFootprintScope1(value: number) {
      this.totalFootprintScope1 = value;
    }
  
    getTotalFootprintScope1(): number {
      return this.totalFootprintScope1;
    }

    setTotalFootprintScope2(value: number) {
      this.totalFootprintScope2 = value;
    }
  
    getTotalFootprintScope2(): number {
      return this.totalFootprintScope2;
    }

    setTotalFootprintPercentageScope1(value: number) {
      this.totalFootprintPercentageScope1 = value;
    }
  
    getTotalFootprintPercentageScope1(): number {
      return this.totalFootprintPercentageScope1;
    }

    setTotalFootprintPercentageScope2(value: number) {
      this.totalFootprintPercentageScope2 = value;
    }
  
    getTotalFootprintPercentageScope2(): number {
      return this.totalFootprintPercentageScope2;
    }

    setTotalConsumptionDividedBy22(value: number) {
      this.totalConsumptionDividedBy22 = value;
    }
  
    getTotalConsumptionDividedBy22(): number {
      return this.totalConsumptionDividedBy22;
    }

    getSelectedEmissionTypes(): string[] {
      return this.selectedEmissionTypes;
    }
  
    setSelectedEmissionTypes(emissionTypes: string[]): void {
      this.selectedEmissionTypes = emissionTypes;
    }
  
    addEmissionType(emissionType: string): void {
      if (!this.selectedEmissionTypes.includes(emissionType)) {
        this.selectedEmissionTypes.push(emissionType);
      }
    }
  
    clearSelectedEmissionTypes(): void {
      this.selectedEmissionTypes = [];
    }

    setSelectedEmissionFactorPrice(price: number): void {
      this.selectedEmissionFactorPrice = price;
    }
  
    getSelectedEmissionFactorPrice(): number { 
      return this.selectedEmissionFactorPrice;
    }
    
  }
export const AUTHMAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 20,
  PORT_MINI_MAP_ZOOM: 16,
  MULTI_TERMINAL_ZOOM: 6,
};

export const GENMAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 20,
  PORT_MINI_MAP_ZOOM: 16,
  MULTI_TERMINAL_ZOOM: 10,
};

export const VIRTUALASSET = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 5,
  PORT_MINI_MAP_ZOOM: 10,
  MULTI_TERMINAL_ZOOM: 9,
};

export const RAVENNAMAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 6,
  PORT_MINI_MAP_ZOOM: 10,
  MULTI_TERMINAL_ZOOM: 10,
};

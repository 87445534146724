import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit {
  public activeButton: number = 1;
  constructor() { }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }



  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;

  }



  AfterViewInit() {
    //piechart




    var barOptions_stacked = {
      tooltips: {
        enabled: false
      },
      hover: {
        animationDuration: 0
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 11
          },
          scaleLabel: {
            display: false
          },
          gridLines: {
            display: false // Remove grid lines
          },
          stacked: true
        }],
        yAxes: [{
          gridLines: {
            display: false,
            color: "#fff",
            zeroLineColor: "#fff",
            zeroLineWidth: 0
          },
          ticks: {
            // fontFamily: "'Open Sans Bold', sans-serif",
            // fontSize: 11
          },
          stacked: true
        }]
      },
      legend: {
        display: true, // Display legend
        labels: {
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11
        }
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "13 px Open Sans";
          ctx.fillStyle = "#fff";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            Chart.helpers.each(meta.data.forEach(function (bar, index) {
              let data = dataset.data[index]; // Fix: Add let to avoid global variable leakage
              if (i == 0) {
                ctx.fillText(data, 50, bar._model.y + 4);
              } else {
                ctx.fillText(data, bar._model.x - 50, bar._model.y + 4);
              }
            }), this)
          }), this);
        }
      },
      pointLabelFontFamily: "Quadon Extra Bold",
      scaleFontFamily: "Quadon Extra Bold",
    };

    var ctx = document.getElementById("Chart1") as HTMLCanvasElement;
    var myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: ["TUG 12", "TUG 09", "TUG 01",],
        datasets: [{
          label: 'Baseline Hours',
          data: [3, 1.2],
          backgroundColor: "#4472C4",

        }, {
          label: 'Extended Hours',
          data: [3, 1.5, 2.8,],
          backgroundColor: "#DF8548",

        },]
      },
      options: barOptions_stacked,
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToggleSettings } from 'src/app/shared/models';
import { ModalLoaderComponent } from 'src/app/operations-dashboard/components/modal-loader/modal-loader.component';
import * as response from 'src/app/shared/mock-data/dashboard-settings.json'

export interface DashboardFeatures {
  title: string;
  description: string;
  status: boolean;
  formName: string;
}

@Component({
  selector: 'app-dashboard-setting',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss']
})

export class DashboardSettingsComponent implements OnInit {
  public isChecked: boolean = true;
  public dashboardFeature: DashboardFeatures[] = [];
  public description = [
    { title: "Port Productivity", description: "GMPH, Port Average, Port Average", status: true },
    { title: "Berthing Status", description: "King Solomon, Queen ELI, Berth Productivity", status: false },
    { title: "Port Map", description: "Current port map", status: true },
    { title: "Operating Status", description: "List of the Operating Status", status: false },
    { title: "Equipment Status", description: "Active, Idling, Inactive", status: false },
  ]
  public observable: Observable<any>;
  public subscription: Subscription;
  noData: boolean;
  public isSuccess: boolean = false;
  public savingOverlay: string;
  constructor(private _formBuilder: FormBuilder, private dialog: MatDialog) { }

  formGroup = this._formBuilder.group({
    port_productivity: '',
    berthing_status: '',
    port_map: '',
    operating_status: '',
    equipment_status: '',
  });

  ngOnInit(): void {
    this.getOperationSettings();
  }


  getOperationSettings() {
    const responseList: ToggleSettings[] = response.data.data;
    if (!responseList || responseList === undefined || responseList === null || responseList.length === 0) {
      this.noData = true;
    } else {
      console.log("EI", responseList);
      responseList.map((data: ToggleSettings, index) => {

        var newName = data.name.replace(/_/g, " ");
        this.dashboardFeature.push(
          { title: newName, description: data.description, status: data.isEnabled, formName: data.name }
        )
      });
    }
  }

  patchDashboardSettings(formGroup: FormGroup) {
    const entries = Object.entries(formGroup.value);
    const values = Object.values(formGroup.value);
    const keys = Object.keys(formGroup.value);

    for (let i = 0; i <= values.length; i++) {
      let name: string, isEnabled: boolean;
      let key: unknown = keys[i];
      let val: unknown = values[i];
      if (typeof val === 'boolean' && typeof key === 'string') {
        isEnabled = val; name = key;
        this.subscription = this.observable.subscribe((response) => {

        });
      }
    }
    this.dialog.open(ModalLoaderComponent, { disableClose: true });
  }
}






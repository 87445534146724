import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { SocialResponsibilityService } from "../../services/social-responsibility.service";
import {
  getSocialResponsibility,
  getSocialResponsibilityFailure,
  getSocialResponsibilitySuccess,
  getWorkforceDiversity,
  getWorkforceDiversitySuccess,
  getWorkforceDiversityFailure,
  getEngagementAndTrainingMetrics,
  getEngagementAndTrainingMetricsSuccess,
  getEngagementAndTrainingMetricsFailure,
  getSafetyAndHealthMetrics,
  getSafetyAndHealthMetricsSuccess,
  getSafetyAndHealthMetricsFailure,
  getLostTimeInjuryFrequency,
  getLostTimeInjuryFrequencySuccess,
  getLostTimeInjuryFrequencyFailure
} from "./social-responsibility.actions";

@Injectable()
export class SocialResponsibilityEffects {
  constructor(
    private actions$: Actions,
    private socialResponsibilityService: SocialResponsibilityService
  ) { }

  getSocialResponsibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSocialResponsibility),
      switchMap((action) =>
        this.socialResponsibilityService.getSocialResponsibility(action.portsId ? action.portsId : null).pipe(
          map((response) => response.data),
          map((data) => getSocialResponsibilitySuccess({ socialResponsibility: data })),
          catchError((error) => of(getSocialResponsibilityFailure({ error })))
        )
      )
    )
  );

  getWorkforceDiversity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkforceDiversity),
      switchMap((action) =>
        this.socialResponsibilityService.getWorkforceDiversity(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getWorkforceDiversitySuccess({ workforceDiversity: data })),
          catchError((error) => of(getWorkforceDiversityFailure({ error })))
        )
      )
    )
  );

  getEngagementAndTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEngagementAndTrainingMetrics),
      switchMap((action) =>
        this.socialResponsibilityService.getEngagementAndTrainingMetrics(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getEngagementAndTrainingMetricsSuccess({ engagementTrainingMetrics: data })),
          catchError((error) => of(getEngagementAndTrainingMetricsFailure({ error })))
        )
      )
    )
  );

  getSafetyAndHealthMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSafetyAndHealthMetrics),
      switchMap((action) =>
        this.socialResponsibilityService.getSafetyAndHealthMetrics(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getSafetyAndHealthMetricsSuccess({ safetyHealthMetrics: data })),
          catchError((error) => of(getSafetyAndHealthMetricsFailure({ error })))
        )
      )
    )
  );

  getLostTimeInjuryFrequency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLostTimeInjuryFrequency),
      switchMap((action) =>
        this.socialResponsibilityService.getLostTimeInjuryFrequency(action.year ? action.year : null).pipe(
          map((response) => response.data),
          map((data) => getLostTimeInjuryFrequencySuccess({ lostTimeInjuryFrequency: data })),
          catchError((error) => of(getLostTimeInjuryFrequencyFailure({ error })))
        )
      )
    )
  );
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { AlertsComponent } from './alerts/alerts.component';
import { KpiComponent } from './kpi/kpi.component';
import { YardComponent } from './yard/yard.component';
import { AnalyticsComponent } from './analytics.component';
import { FailureChartComponent } from './analytics-dashboard/failure-chart/failure-chart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import { FailureTableComponent } from './analytics-dashboard/failure-chart/failure-table/failure-table.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { DataTablesModule } from 'angular-datatables';
import { AlarmAnalysisComponent } from './analytics-dashboard/alarm-analysis/alarm-analysis.component';
import { ChartLegendComponent } from './analytics-dashboard/alarm-analysis/chart-legend/chart-legend.component';
import { CustomPipesModule } from 'src/app/shared/custom-pipes/custom-pipes.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { CraneStatusChartComponent } from './analytics-dashboard/crane-status-chart/crane-status-chart.component';
import { TabularViewComponent } from './analytics-dashboard/alarm-analysis/tabular-view/tabular-view.component';
import { SummaryComponent } from './analytics-dashboard/alarm-analysis/summary/summary.component';
import { SensorEvaluationComponent } from './analytics-dashboard/sensor-evaluation/sensor-evaluation.component';
import { ContainersNumberMovementComponent } from './analytics-dashboard/containers-number-movement/containers-number-movement.component';
import { VesselDelayComponent } from './analytics-dashboard/vessel-delay/vessel-delay.component';
import { EquipmentIconModule } from 'src/app/operations-dashboard/components/equipment-icon/equipment-icon.module';
import { TugboatStatusComponent } from './overview/tugboat-status/tugboat-status.component';
import { ColorCodedBarPieModule } from '../../components/color-coded-bar-pie/color-coded-bar-pie.module';
import { PortProductivityComponent } from './analytics-dashboard/port-productivity/port-productivity.component';
import { CraneAndRTGStatusComponent } from './overview/crane-and-rtg-status/crane-and-rtg-status.component';
import { LogsComponent } from './alerts/logs-n-alert/logs.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { EquipmentAlertsComponent } from './alerts/equipment-alerts/equipment-alerts.component';
import { EquipmentRaiseCaseComponent } from './alerts/equipment-alerts/equipment-raise-case/equipment-raise-case.component';
import { BerthContainerComponent } from './overview/berthing-status-overview/berth-container/berth-container.component';
import { BerthCranesGmphComponent } from './overview/berthing-status-overview/berth-cranes-gmph/berth-cranes-gmph.component';
import { BerthUtilizationComponent } from './overview/berthing-status-overview/berth-utilization/berth-utilization.component';
import { BerthingTimeRemainingComponent } from './overview/berthing-status-overview/berthing-time-remaining/berthing-time-remaining.component';
import { BerthingStatusOverviewComponent } from './overview/berthing-status-overview/berthing-status-overview.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ObtDetailsComponent } from '../../components/obt-details/obt-details.component';
import { CraneKpiComponent } from './kpi/crane-kpi/crane-kpi.component';
import { ContainerDwellTimeComponent } from './yard/container-dwell-time/container-dwell-time.component';
import { PortProductivityKpiComponent } from './kpi/port-productivity-kpi/port-productivity-kpi.component';
import { TugboatKpiComponent } from './kpi/tugboat-kpi/tugboat-kpi.component';
import { AvgTurnTimeComponent } from './yard/avg-turn-time/avg-turn-time.component';
import { ContainerGateMovesComponent } from './yard/container-gate-moves/container-gate-moves.component';
import { GaugeChartModule } from 'angular-gauge-chart'
import { HazardousMaterialComponent } from './yard/hazardous-material/hazardous-material.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { YardInventoryComponent } from './yard/yard-inventory/yard-inventory.component';
import { OverstayTruckComponent } from './yard/overstay-truck/overstay-truck.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AnalyticsComponent,
    AnalyticsDashboardComponent,
    OverviewComponent,
    AlertsComponent,
    KpiComponent,
    YardComponent,
    FailureChartComponent,
    FailureTableComponent,
    AlarmAnalysisComponent,
    ChartLegendComponent,
    TabularViewComponent,
    SummaryComponent,
    CraneStatusChartComponent,
    SensorEvaluationComponent,
    ContainersNumberMovementComponent,
    VesselDelayComponent,
    TugboatStatusComponent,
    PortProductivityComponent,
    CraneAndRTGStatusComponent,
    LogsComponent,
    OverstayTruckComponent,
    
    ObtDetailsComponent,
    EquipmentAlertsComponent,
    EquipmentRaiseCaseComponent,
    BerthContainerComponent,
    BerthCranesGmphComponent,
    BerthUtilizationComponent,
    BerthingTimeRemainingComponent,
    BerthingStatusOverviewComponent,
    PortProductivityKpiComponent,
    CraneKpiComponent,
    ContainerDwellTimeComponent,
    TugboatKpiComponent,
    AvgTurnTimeComponent,
    ContainerGateMovesComponent,
    HazardousMaterialComponent,
    YardInventoryComponent
  ],
  imports: [
    CommonModule,
    CustomPipesModule,
    Ng2Charts,
    GaugeChartModule,
    FormsModule,
    EquipmentIconModule,
    ColorCodedBarPieModule,
    MatProgressBarModule,
    MatCardModule,
    MatSliderModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatTooltipModule,
    DataTablesModule,
    EquipmentIconModule,
    IconSpriteModule,
    DirectivesModule,
    AnalyticsRoutingModule,
    MatSortModule,
    MatDialogModule
  ]
})
export class AnalyticsModule { }

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAnalyticsPortId } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  analyticsPortId$: Observable<string> = this.store.select(selectAnalyticsPortId);

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-map',
  templateUrl: './equipment-map.component.html',
  styleUrls: ['./equipment-map.component.scss']
})
export class EquipmentMapComponent implements OnInit {
  @Input()
  selectedPortId: string;
  constructor() { }

  ngOnInit(): void {
  }

}

import { createReducer, on } from '@ngrx/store';
import { PortModel } from '../../models/port.models';
import { getPort, getPortFailure, getPortSuccess } from './port.actions';
import { ErrorResponse } from '../../models/error-response.model';



export interface PortState {
  ports: Array<PortModel>,
  error: ErrorResponse,
  status: 'pending' | 'loading' | 'error' | 'success'
}

export const initialState: PortState = {
  ports: [],
  error: null,
  status: 'pending'
}

export const portReducer = createReducer(
  //supply initial state
  initialState,
  //Handle create user session
  on(getPort, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  //Handle successfully loaded create session
  on(getPortSuccess, (state, { ports }) => ({
    ...state,
    ports: ports,
    error: null,
    status: 'success'
  })),
  //Handle failed create session
  on(getPortFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: 'error'
  })),
);
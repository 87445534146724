import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingNavComponent } from './setting-nav.component';
import { DashboardSettingsComponent } from './dashboard-settings/dashboard-settings.component';
import { AnalyticsSettingsComponent } from './analytics-settings/analytics-settings.component';
import { PortSettingsComponent } from './port-settings/port-settings.component';
import { RefreshRateSettingsComponent } from './refresh-rate-settings/refresh-rate-settings.component';

const routes: Routes = [
  {
    path: "",
    component: SettingNavComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "dashboard-settings",
        pathMatch: "full"
      },
    

      {
        path: "dashboard-settings",
        component: DashboardSettingsComponent,
        data: { breadcrumb: "Dashboard Settings" }
      },
      {
        path: "analytics-settings",
        component: AnalyticsSettingsComponent,
        data: { breadcrumb: "Analytics Settings" }
      },
      {
        path: "port-settings",
        component: PortSettingsComponent,
        data: { breadcrumb: "Port Settings" }
      },
      {
        path: "refresh-rate-settings",
        component: RefreshRateSettingsComponent,
        data: { breadcrumb: "Refresh Rate Settings" }
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingNavRoutingModule { }

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseChartDirective } from 'ng2-charts';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { AlarmAnalysisData, EquipmentAlarmAnalysisModel } from 'src/app/shared/models/alarm-analysis.model';
import { Store } from '@ngrx/store';
import { getEquipmentAlarmAnalysis } from 'src/app/shared/state/operations/operations.action';
import { Observable } from 'rxjs';
import { selectEquipmentAlarmAnalysis } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-alarm-analysis',
  templateUrl: './alarm-analysis.component.html',
  styleUrls: ['./alarm-analysis.component.scss']
})
export class AlarmAnalysisComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: false })
  public chart: BaseChartDirective;

  @Input()
  selectedPortId: string;

  alarmAnalysis$: Observable<EquipmentAlarmAnalysisModel[]> = this.store.select(selectEquipmentAlarmAnalysis);
  alarmAnalysisData: Array<EquipmentAlarmAnalysisModel> = []
  public isZoom: boolean;
  public barLineChartData: any[];
  public barLineChartLabels: string[] = [];
  public barLineChartOptions: any;
  public barLineChartColors: any[];
  public barLineChartLegend: boolean;
  public barLineChartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public chartIsReady = false;
  public filters = { ...CHART.FILTERS };
  public faultNumberFilter = "10";
  public selectedCrane: string = "";
  public selectedFault: number = 10;
  public currentDate = new Date();
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public ngDateFrom = new Date('2022-1-1');
  public ngDateTo = new Date('2022-1-7');
  public initialDF = '2022-01-01';
  public initialDT = '2022-01-07';
  public dateRangeError: boolean;
  public tableHeader: string[] = [];
  public equipmentIdList: string[] = [];
  public itemData: AlarmAnalysisData[][] = [];
  public septemberData: AlarmAnalysisData[][] = [];
  public threeMonths: AlarmAnalysisData[][] = [];
  public TopTenFaults: AlarmAnalysisData[][] = [];
  public isTableVisible: boolean = false;
  public faultNumber: number = 10;
  public lineGraphData: number[] = [];
  public chartColorLegends: string[] = [
    CHART.COLOR.FAULT_MESSAGES.LAN_BLUE, CHART.COLOR.FAULT_MESSAGES.AZRAQ_BLUE,
    CHART.COLOR.FAULT_MESSAGES.LIVID, '#00b894', '#00cec9', '#55efc4', '#81ecec',
    CHART.COLOR.FAULT_MESSAGES.LAN_BLUE, CHART.COLOR.FAULT_MESSAGES.AZRAQ_BLUE,
    CHART.COLOR.FAULT_MESSAGES.LIVID
  ];

  constructor(
    private datePipe: DatePipe,
    private store: Store
  ) {
    datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 60);
    this.rangeFrom = this.initialDF;
    this.rangeTo = this.initialDT;

    this.store.dispatch(getEquipmentAlarmAnalysis({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  ngAfterViewInit(): void {
    this.alarmAnalysis$.subscribe((alarmAnalysis) => {
      if (alarmAnalysis?.length) {
        this.alarmAnalysisData = alarmAnalysis;
        this.initializeFaultKPI(this.alarmAnalysisData);
      } else {
        this.noData = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEquipmentAlarmAnalysis({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
      }
    }
  }

  initializeFaultKPI(dataList: EquipmentAlarmAnalysisModel[]) {
    const dateFormat2 = 'dd/MM';
    let durationCollection = [];
    const barLineChartDataFaultId = [];
    let barData = [];
    let tempItemObject: any = [];
    let tempDuration: number[] = [];
    let durationData: number[][] = [];
    let durationGraph: any[][] = [];
    let totalFault: number[][] = [];


    dataList.map((data: EquipmentAlarmAnalysisModel) => {
      this.equipmentIdList.push(data.equipment_name);

      if (this.selectedCrane == "") {
        this.selectedCrane = this.equipmentIdList[0].toString();
      }
      // format dateAcquired
      if (data.equipment_name === this.selectedCrane) {
        let tempDateAquired: any;
        data.alarm_data.map((item: AlarmAnalysisData, index) => {
          item.date_acquired = this.datePipe.transform(item.date_acquired, dateFormat2);

          if (!this.barLineChartLabels.includes(item.date_acquired)) {
            this.barLineChartLabels.push(item.date_acquired);
          }
          if (!barLineChartDataFaultId.includes(item.fault_id)) {
            barLineChartDataFaultId.push(item.fault_id);
          }

          //durationCopy for tracking the top Faults
          durationCollection.push(item.daily_duration);

          //Transforming data of FauldData to arrayobject groupby dates
          if (index == 0) {
            tempDateAquired = item.date_acquired;
          }
          if (tempDateAquired !== item.date_acquired) {
            tempDateAquired = item.date_acquired;
            this.itemData.push(tempItemObject);
            tempItemObject = [];
          }
          //push the item object to arrayObject
          tempItemObject.push(item);
          //getting the last data of item object
          if (index == data.alarm_data.length - 1) {
            this.itemData.push(tempItemObject);
          }
        });

        for (const element of this.itemData) {
          let tTen = element.sort(this.compareDuration).slice(0, this.faultNumber);
          this.TopTenFaults.push(tTen);
        }

        //Transforming the duration data based on the arrayObject to be on the graph
        for (const iterator of this.TopTenFaults) {
          iterator.map((item: AlarmAnalysisData, index) => {
            let n = item.daily_duration;
            // n = (Math.round(n * 100) / 100)
            tempDuration.push(n);
          });

          durationData.push(tempDuration);
          tempDuration = [];
        }
      }
    });

    //Check if the durationData has 1 array data
    if (durationData.length == 1) {
      let tempDurationGraph = []; let total = 0;
      for (let row = 0; row < durationData[0].length; row++) {
        let n = durationData[0][row];
        tempDurationGraph.push(n);
        total += durationData[0][row];
        durationGraph.push(tempDurationGraph);
        tempDurationGraph = []; //removing the data for tempDurationGraph
      }
      totalFault.push([total]);
    } else {


      let xcount = durationData.length;
      let ycount = durationData[0].length;
      durationGraph = Array.from(Array(ycount), () => Array(xcount).fill(0));
      //processing multiple array data in durationData
      for (let row = 0; row < durationData.length; row++) {
        //looping to the durationData and push to graphArray
        for (let col = 0; col < durationData[row].length; col++) {
          let n = durationData[row][col];
          //tempDurationGraph.push(n);
          durationGraph[col][row] = n;
        }

        //durationGraph.push(tempDurationGraph);
        //tempDurationGraph =[];
      }

    }

    //getting the overall for linegraph
    for (let row = 0; row < durationData.length; row++) {
      let sum = 0;
      for (let col = 0; col < durationData[row].length; col++) {
        sum = sum + durationData[row][col];
        sum = (Math.round(sum * 100) / 100)
      }
      this.lineGraphData.push(sum);
    }

    for (let i = 0; i < durationGraph.length; i++) {
      barData.push({
        type: 'bar',
        //data:durationGraph[i],
        data: durationGraph[i],
        //label:'Fault',

      });
    }

    //plotting the line graph
    barData.push({
      type: 'line',
      label: 'Total',
      data: this.lineGraphData,
      fill: false,
      borderColor: 'rgb(54, 162, 235)',
      tension: 0,
    },
    );

    this.barLineChartData = barData;
    this.barLineChartLabels;
    this.initializeChart();
    this.noData = false;
    this.chartIsReady = true;
  }

  initializeChart() {
    this.barLineChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: { titleFontFamily: CHART.FONTS.GOTHAM, bodyFontFamily: CHART.FONTS.GOTHAM },
      scales: {
        yAxes: [{
          id: 'container',
          position: 'left',
          ticks: { min: 0, fontFamily: CHART.FONTS.GOTHAM, fontSize: 12 },
          gridLines: { display: true }
        },],
        xAxes: [{
          ticks: { autoSkip: false, maxRotation: 90, minRotation: 0, fontFamily: CHART.FONTS.GOTHAM, fontSize: 12 },
          gridLines: { display: true }
        }]
      },
      pan: {
        enabled: this.isZoom,
        threshold: 10,
        mode: 'x',
      },

      zoom: {
        mode: 'xy',
        enabled: this.isZoom,


      },
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
    };
    this.barLineChartColors = [
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.LAN_BLUE },
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.AZRAQ_BLUE },
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.LIVID },
      { backgroundColor: '#00b894' },
      { backgroundColor: '#00cec9' },
      { backgroundColor: '#55efc4' },
      { backgroundColor: '#81ecec' },
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.LAN_BLUE },
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.AZRAQ_BLUE },
      { backgroundColor: CHART.COLOR.FAULT_MESSAGES.LIVID },
    ];
    this.barLineChartLegend = false;
    this.barLineChartType = CHART.TYPE.BAR;
  }

  onFilterFault(faultNumber: number) {
    this.chartIsReady = false;
    this.barLineChartData = [''];
    this.barLineChartLabels = [];
    this.tableHeader = [];
    this.equipmentIdList = [];
    this.itemData = [];
    this.TopTenFaults = [];
    this.lineGraphData = []
    this.faultNumber = faultNumber;

    this.onFilterCrane(this.selectedCrane);
  }

  onFilterCrane(selectedCrane: string) {
    this.selectedCrane = selectedCrane;
    this.chartIsReady = false;
    this.barLineChartData = [''];
    this.barLineChartLabels = [];
    this.tableHeader = [];
    this.equipmentIdList = [];
    this.itemData = [];
    this.TopTenFaults = [];
    this.lineGraphData = [];
    this.threeMonths = [];
    this.septemberData = []

    if (this.rangeFrom !== null && this.rangeTo !== null) {
      this.initializeFaultKPI(this.alarmAnalysisData);
    } else {
      this.initializeFaultKPI(this.alarmAnalysisData);
    }
  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate
    } else {
      this.rangeTo = selectedDate
    }

    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;
      this.store.dispatch(getEquipmentAlarmAnalysis({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    }
  }

  onViewTable(type: string) {
    if (type === 'open') {
      this.isTableVisible = true;
    } else {
      this.isTableVisible = false;
    }
  }

  compareDuration(a, b) {
    if (a.dailyDuration < b.dailyDuration) {
      return 1;
    }
    if (a.dailyDuration > b.dailyDuration) {
      return -1;
    }
    return 0;
  }

  onResetChart() {
    // @ts-ignore
    this.chart.chart.resetZoom();
  }

  onZoom() {
    if (!this.isZoom) {
      this.isZoom = true;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = true
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = true
    } else {
      this.isZoom = false;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = false
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = false
      // @ts-ignore
      this.chart.chart.resetZoom();
    }
    this.chart.chart.update();
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }
}

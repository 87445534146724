import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { API } from "../constants/api.constant";
import { Observable } from "rxjs";
import { ApiResponse } from "../models/api-response.model";
import { ErrorResponse } from "../models/error-response.model";

@Injectable({
  providedIn: "root",
})
export class BiodiversityService {
 private biodiversity = environment.basePath + API.KPI;
  // private biodiversity = "/connPort" + API.KPI; //local

 

  constructor(private http: HttpClient) {}

  getBiodiversity(
    portsId?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set("port_ids", portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.biodiversity}/biodiversity-environmental-education`,
      { params: params }
    );
  }

  getQuarterlyShare(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.biodiversity}/quarterly-share-planted-trees`,
      { params: params }
    );
  }

  getTreePlantingMetrics(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.biodiversity}/tree-planting-metrics`,
      { params: params }
    );
  }

  getProtectionWaters(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.biodiversity}/land-sea-protection-metrics`,
      { params: params }
    );
  }

  getCommunityEducation(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.biodiversity}/community-education-metrics`,
      { params: params }
    );
  }
}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutRoutingModule } from "./layout-routing.module";
import { LayoutComponent } from "./layout.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from './multi-app-components/header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SideBarComponent } from './multi-app-components/side-bar/side-bar.component';
import { BreadCrumbComponent } from './multi-app-components/bread-crumb/bread-crumb.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from "@ngx-translate/core";
import { PortAuthorityLayoutComponent } from './port-authority-layout/port-authority-layout.component';
import { EnergyMgtLayoutComponent } from "../energy-mgt-layout/energy-mgt-layout.component";



@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    TranslateModule,
    LayoutRoutingModule
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SideBarComponent,
    BreadCrumbComponent,
    PortAuthorityLayoutComponent,
    EnergyMgtLayoutComponent

  ],
  providers: [DatePipe],
})
export class LayoutModule { }

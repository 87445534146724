import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseChartDirective } from 'ng2-charts';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { Store } from '@ngrx/store';
import { selectContainersMovementTypes, selectTotalContainersMovement } from 'src/app/shared/state/operations/operations.selectors';
import { DailyMovementsData, TotalContainersMovementModel, TotalDailyMovementsData } from 'src/app/shared/models/container-moved-by-type.model';
import { ContainersMovementTypesModel, DailyContainersMovedData } from 'src/app/shared/models/craner-container-moves.model';
import { getContainersMovementTypes, getTotalContainersMovement } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-containers-number-movement',
  templateUrl: './containers-number-movement.component.html',
  styleUrls: ['./containers-number-movement.component.scss']
})
export class ContainersNumberMovementComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: false })
  public chart: BaseChartDirective;

  @Input()
  selectedPortId: string;

  totalContainersMovement$: Observable<TotalContainersMovementModel[]> = this.store.select(selectTotalContainersMovement);
  containersMovementTypes$: Observable<ContainersMovementTypesModel[]> = this.store.select(selectContainersMovementTypes);
  totalContainersMovementData: Array<TotalContainersMovementModel> = [];
  containersMovementTypeData: Array<ContainersMovementTypesModel> = [];
  public isZoom: boolean = false;
  public barLineChartData: any[];
  public barLineChartLabels: string[] = [];
  public barLineChartOptions: any;
  public barLineChartColors: any[];
  public barLineChartLegend: boolean;
  public barLineChartType: string;
  public lineGraphData: number[] = [];
  public pieChartData: any[];
  public pieChartLabels: string[] = [];
  public pieChartEquipmentId: string[] = [];
  public pieChartOptions: any;
  public pieChartColors: any[];
  public pieChartLegend: boolean;
  public pieChartType: string;
  public pieDataSet: number[] = [];
  public noDataToDisplay = '';
  public noData = true;
  public noDataPie = true;
  public chartIsReady = false;
  public pieChartIsReady = false;
  public filters = { ...CHART.FILTERS };
  public initialFilter = 0;
  public selectedcraneID = "ALL";
  public selectedequipmentID: "ALL";
  public selectedCrane: string = "ALL";
  public currentDate = new Date();
  public initialDateFilter = new Date();
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public equipmentIdList: string[] = [];
  public tableHeader: string[] = [];
  public dateRangeError: boolean;
  public isTableVisible: boolean = false;
  public initialDF = '2022-01-01';
  public initialDT = '2022-01-07';
  limit: string = "";
  public ngDateFrom = new Date('2022-1-1');
  public ngDateTo = new Date('2022-1-7');
  public showPieChart: boolean = true;
  noResultsMessage = '';
  constructor(
    private store: Store,
    private datePipe: DatePipe
  ) {
    datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 60);
    const initialDate = new Date(this.initialDateFilter);
    initialDate.setDate(initialDate.getDate() - 7);
    this.initialDateFilter = initialDate;
    this.rangeFrom = this.initialDF;
    this.rangeTo = this.initialDT;
    this.selectedCrane = "ALL";
    this.limit = "0";
    this.store.dispatch(getTotalContainersMovement({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    this.store.dispatch(getContainersMovementTypes({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  ngAfterViewInit(): void {
    this.totalContainersMovement$.subscribe((totalContainersMovement) => {
      if (totalContainersMovement?.length) {
        this.totalContainersMovementData = totalContainersMovement;
        this.initializeTotalContainersMovementData(totalContainersMovement);
      } else {
        this.noData = true;
      }
    })
    this.containersMovementTypes$.subscribe((containersMovementType) => {
      if (containersMovementType?.length) {
        this.containersMovementTypeData = containersMovementType;
        this.initializeContainersMovementTypeData(containersMovementType);
      } else {
        this.noDataPie = true;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getTotalContainersMovement({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
        this.store.dispatch(getContainersMovementTypes({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
      }
    }
  }


  getContainersMovementKPI(startDate: string, endDate: string) {
    startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');

    this.store.dispatch(getTotalContainersMovement({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    this.store.dispatch(getContainersMovementTypes({ portId: this.selectedPortId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  initializeTotalContainersMovementData(data: TotalContainersMovementModel[]) {
    const dateFormat2 = 'dd/MM';
    const craneMovementCount: number[][] = [];
    const containersMovedCount: number[][] = [];
    this.barLineChartData = [];
    this.barLineChartLabels = [];
    let barData = [];
    const totalMovedContainer = [];


    data?.sort((a, b) => (a.equipment_name > b.equipment_name) ? 1 : ((b.equipment_name > a.equipment_name) ? -1 : 0));
    data.map((data: TotalContainersMovementModel) => {
      let tempCraneMovementCount = [];
      let tempContainersMovedCount = [];
      this.equipmentIdList.push(data.equipment_name);





      if (data.equipment_name !== "ALL") {
        data.daily_movements.map((item: DailyMovementsData) => {
          item.date_acquired = this.datePipe.transform(item.date_acquired, dateFormat2);
          if (!this.barLineChartLabels.includes(item.date_acquired)) {
            this.barLineChartLabels.push(item.date_acquired);
          }
          tempCraneMovementCount.push(item.crane_movement_count);
          tempContainersMovedCount.push(item.crane_containers_moved_count);
        });
        craneMovementCount.push(tempCraneMovementCount);
        containersMovedCount.push(tempContainersMovedCount);
      } else {
        // getting the data of EquipmentId All
        data.total_daily_movements.map((item: TotalDailyMovementsData) => {
          totalMovedContainer.push(item.crane_total_containers_moved_count);
        })
      }
    });

    const selectedCrane = parseInt(this.limit);
    if (selectedCrane === 0) {
      for (let row = 0; row < craneMovementCount.length; row++) {
        barData.push(
          {
            type: 'bar',
            label: this.equipmentIdList[row + 1],
            data: craneMovementCount[row],
            stack: 'Stack 0',
          }
        );
      }

      barData.push(
        {
          type: 'line',
          label: "Total containers moved count",
          data: totalMovedContainer,
          borderColor: '#10ac84',
          fill: false,
          tension: 0,
        }
      );
    } else {
      barData.push(
        {
          type: 'bar',
          label: this.equipmentIdList[selectedCrane],
          data: craneMovementCount[selectedCrane - 1],
          stack: 'Stack 0',
        },
        {
          type: 'line',
          label: "Total containers moved count",
          data: containersMovedCount[selectedCrane - 1],
          borderColor: '#10ac84',
          fill: false,
          tension: 0,
        }
      );
    }

    this.barLineChartData = barData;
    this.barLineChartLabels;
    this.initializeBarChart();
    this.noData = false;
    this.chartIsReady = true;
  }

  initializeContainersMovementTypeData(data: ContainersMovementTypesModel[]) {
    this.pieDataSet = [];
    this.pieChartEquipmentId = [];

    data.sort((a, b) => (a.equipment_name > b.equipment_name) ? 1 : ((b.equipment_name > a.equipment_name) ? -1 : 0));
    let temp2020 = 0, temp45 = 0, temp40 = 0, temp20 = 0, temp2020percent = 0, temp45percent = 0, temp40percent = 0, temp20percent = 0; let crane: string; let totalSum;
    data.map((data: ContainersMovementTypesModel) => {
      if (!this.pieChartEquipmentId.includes(data.equipment_name)) {
        this.pieChartEquipmentId.push(data.equipment_name);
      }
      if (data.equipment_name === this.pieChartEquipmentId[this.limit]) {
        data.daily_containers_moved.map((item: DailyContainersMovedData) => {
          temp2020 += item.daily_containers_moved_count_2020;
          temp45 += item.daily_containers_moved_count_45;
          temp40 += item.daily_containers_moved_count_40;
          temp20 += item.daily_containers_moved_count_20;
        });

        totalSum = temp2020 + temp20 + temp45 + temp40;
        temp2020percent = parseFloat((((temp2020 / totalSum) * 100)).toFixed(2));
        temp20percent = parseFloat((((temp20 / totalSum) * 100)).toFixed(2));
        temp45percent = parseFloat((((temp45 / totalSum) * 100)).toFixed(2));
        temp40percent = parseFloat((((temp40 / totalSum) * 100)).toFixed(2));
        temp2020 = (Math.round(temp2020 * 100) / 100); //get decimal
        temp20 = (Math.round(temp20 * 100) / 100); //get decimal
        temp45 = (Math.round(temp45 * 100) / 100); //get decimal
        temp40 = (Math.round(temp40 * 100) / 100); //get decimal


        this.pieDataSet.push(temp2020, temp45, temp40, temp20);
      }
    })
    this.pieChartData = [
      {
        data: this.pieDataSet,
        label: this.pieChartEquipmentId,
        borderColor: '#fff'
      }
    ];

    this.pieChartLabels = ['Type 2020', 'Type 45', 'Type 40', 'Type 20'];
    this.initializePieChart();
    this.noDataPie = false;
    this.pieChartIsReady = true;
  }

  initializePieChart() {
    ///PIE CHART
    this.pieChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: {
        titleFontFamily: CHART.FONTS.GOTHAM,
        bodyFontFamily: CHART.FONTS.GOTHAM
      },
      legend: {
        position: 'right'
      },
      label: {
        display: true
      },
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
      //added
      toolTipContent: "this.pieDataSet , #percent %"
    };
    this.pieChartColors = [
      { backgroundColor: ['#079992', '#38ada9', '#82ccdd', '#81ecec'] },
    ];
    this.pieChartLegend = true;
    this.pieChartType = CHART.TYPE.PIE;
  }

  initializeBarChart() {
    this.barLineChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: {
        titleFontFamily: CHART.FONTS.GOTHAM,
        bodyFontFamily: CHART.FONTS.GOTHAM
      },
      scales: {
        yAxes: [{
          id: 'container',
          position: 'left',
          ticks: {
            min: 0,
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 12
          },
          gridLines: {
            display: true
          }
        }],
        xAxes: [{
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 0,
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 12
          },
          gridLines: {
            display: true
          }
        }]
      },
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: 'white',
          fontSize: 0,
        },
      },
      pan: {
        enabled: this.isZoom,
        threshold: 10,
        mode: 'x',
      },
      zoom: {
        mode: 'xy',
        enabled: this.isZoom,
      }
    };
    this.barLineChartColors = [
      { backgroundColor: '#079992' },
      { backgroundColor: '#38ada9' },
      { backgroundColor: '#82ccdd' }
    ];
    this.barLineChartLegend = true;
    this.barLineChartType = CHART.TYPE.BAR;
  }


  onFilterCrane(selectedCrane: number) {
    this.limit = selectedCrane.toString();
    this.selectedCrane = this.equipmentIdList[selectedCrane];
    this.chartIsReady = false;
    this.barLineChartData = [''];
    this.pieDataSet = [];
    this.barLineChartLabels = [];
    this.tableHeader = [];
    this.equipmentIdList = [];
    this.lineGraphData = [];

    this.initializeTotalContainersMovementData(this.totalContainersMovementData);
    this.initializeContainersMovementTypeData(this.containersMovementTypeData);

  
    this.showPieChart = false; 
    this.noDataPie = false;
    this.noDataToDisplay = '';

    if (this.pieChartEquipmentId.includes(this.selectedCrane)) {
      this.showPieChart = true;
      this.noDataPie = false; 
    } else {
      this.showPieChart = false;
      this.noDataPie = true; 
      this.noDataToDisplay = 'The search parameters return no results for Crane container data.';
    }

  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate
    } else {
      this.rangeTo = selectedDate
    }

    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;

      if (this.rangeFrom !== null && this.rangeTo !== null) {
        this.getContainersMovementKPI(this.rangeFrom, this.rangeTo);
      } else {
        this.getContainersMovementKPI(this.initialDateFilter.toLocaleDateString(), this.currentDate.toLocaleDateString());
      }
    }
  }

  onViewTable(type: string) {
    if (type === 'open') {
      this.isTableVisible = true;
    } else {
      this.isTableVisible = false;
    }
  }

  onResetChart() {
    // @ts-ignore
    this.chart.chart.resetZoom();
  }

  onZoom() {
    if (!this.isZoom) {
      this.isZoom = true;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = true
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = true
    } else {
      this.isZoom = false;
      // @ts-ignore
      this.chart.chart.options.pan.enabled = false
      // @ts-ignore
      this.chart.chart.options.zoom.enabled = false
      // @ts-ignore
      this.chart.chart.resetZoom();
    }
    this.chart.chart.update();
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { };
}






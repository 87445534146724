import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EquipmentKPI } from 'src/app/shared/models/equipment-kpi-response.model';

@Component({
  selector: 'app-failure-table',
  templateUrl: './failure-table.component.html',
  styleUrls: ['./failure-table.component.scss']
})
export class FailureTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @Input()
  displayedColumns: any;

  @Input()
  noData: any;

  @Input()
  noDataToDisplay: any;

  @Input()
  tableDataMMBF: Array<EquipmentKPI> = [];

  public dateAcquired: string[] = [];
  public mmbfData: number[][] = [];
  public mtbfData: number[][] = [];
  public MMBFdataSource: MatTableDataSource<any>;
  public MTBFdataSource: MatTableDataSource<any>;
  public MMBFTableData: TableData[] = [];
  public MTBFTableData: TableData[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) { }

  ngOnInit() { }

  getTableData() {

    let mm = 0, mt = 0;
    for (let z = 0; z < this.dateAcquired.length; z++) {
      this.MMBFTableData.push({
        Date: this.dateAcquired[z],
        QC01: this.mmbfData[mm][z],
        QC02: this.mmbfData[mm += 1][z],
        QC03: this.mmbfData[mm += 1][z],
        All: this.mmbfData[mm += 1][z].toFixed(2),
      });

      this.MTBFTableData.push({
        Date: this.dateAcquired[z],
        QC01: this.mtbfData[mt][z],
        QC02: this.mtbfData[mt += 1][z],
        QC03: this.mtbfData[mt += 1][z],
        All: this.mtbfData[mt += 1][z].toFixed(2),
      });

      mm = 0;
      mt = 0;
    }

    this.MMBFdataSource = new MatTableDataSource<TableData>(this.MMBFTableData);
    this.MMBFdataSource.paginator = this.paginator;
    this.MTBFdataSource = new MatTableDataSource<TableData>(this.MTBFTableData);
    this.MTBFdataSource.paginator = this.paginator;
  }


  getHeaders() {
    this.displayedColumns.push('Date');
    this.tableDataMMBF.forEach((item) => {
      this.displayedColumns.push(item.equipmentId);
    });

  }

  getDataMMBF() {
    this.tableDataMMBF.forEach(element => {
      let tempArr = [];
      element.kpi.map((item) => {
        tempArr.push(item.mmbf);
      })
      this.mmbfData.push(tempArr);
    });

  }


  getDataMTBF() {
    this.tableDataMMBF.forEach(element => {
      let tempArr = [];
      element.kpi.map((item) => {
        tempArr.push(item.mtbf);
      })
      this.mtbfData.push(tempArr);
    });

  }

  getDateAcquired() {
    this.tableDataMMBF.forEach(element => {
      if (element.equipmentId === 'All') {
        element.kpi.map((item) => {
          this.dateAcquired.push(item.dateAcquired);
        });
      }
    });
  }
}

export interface TableData {
  Date: string,
  QC01: number,
  QC02: number,
  QC03: number,
  All: number | string,
}


import { createAction, props } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import { UserModel } from '../../models/user.model';

export const createUserSession = createAction('[Login Page] Create User Session');

export const createUserSessionSuccess = createAction(
  '[Create User Session API] Create User Session Successful',
  props<{isValidSession: boolean}>()
);

export const createUserSessionFailure = createAction(
  '[Create User Session API] Create User Session Failed',
  props<{error: ErrorResponse}>()
  );

  export const getUserDetails = createAction('[Login Page] Get User details', props<{userId: string}>());
  
  export const getUserDetailsSuccess = createAction(
    '[Get User Details API] Get User Details Successful',
    props<{userDetail: UserModel}>()
  );
  
  export const getUserDetailsFailure = createAction(
    '[Get User Details API] Get User Details Failed',
    props<{error: ErrorResponse}>()
  );
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { API } from "../constants/api.constant";
import { Observable } from "rxjs";
import { ApiResponse } from "../models/api-response.model";
import { ErrorResponse } from "../models/error-response.model";

@Injectable({
  providedIn: "root",
})
export class SustainabilityService {
  private serviceUrl = environment.basePath + API.KPI;
  private serviceUrl2 = environment.basePath + API.SUSTAINABILITY;

  constructor(private http: HttpClient) { }

  getCarbonMngmtAndEmissionReduction(
    portIds?: string
  ): Observable<ApiResponse | ErrorResponse | any> {
    let params = portIds ? new HttpParams().set("port_ids", portIds) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/carbon-emission-reduction`,
      { params: params }
    );
  }

  getOverallCarbonIntensity(years?: string) {
    let params = years ? new HttpParams().set("year", years) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/carbon-information-overview`,
      { params: params }
    );
  }

  getCarbonReducedEmissionSummary(years?: string) {
    let params = years ? new HttpParams().set("year", years) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/carbon-reduced-emission-summary`,
      { params: params }
    );
  }

  getPortElectrificationOverview(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/port-electrification-overview`,
      { params: params }
    );
  }

  getPortElectrificationInsight(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/port-electrification-insight`,
      { params: params }
    );
  }

  getCarbonCreditTradingSequestration(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl}/carbon-credit-trading-sequestration`,
      { params: params }
    );
  }

  getEmissionFactors() {
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl2}/emission-factors`
    );
  }

  getRenewableEnergyShare(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl2}/kpi/renewable-energy-share`,
      { params: params }
    );
  }

  getRenewableEnergyGraph(year: string) {
    let params = year ? new HttpParams().set("year", year) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(
      `${this.serviceUrl2}/kpi/renewable-energy-graph`,
      { params: params }
    );
  }

}

import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import * as atlas from "azure-maps-control";
import { environment } from "src/environments/environment";
import { VIRTUALASSET } from "src/app/shared/constants/authmap.constant";
import { Store } from '@ngrx/store';
//to be removed
import { data } from "src/app/shared/mock-data/ravenna-coordinates.json";
import { geojsonModel, MapMemberCoordinatesModel } from "src/app/shared/models/port-authority-models/map-region-list.model";
import { getportAuthMembersCoordinates } from "src/app/shared/state/port-authority/port-authority.action";
import { selectPortAuthorityMembersCoordinates } from "src/app/shared/state/port-authority/port-authority.selectors";

@Component({
  selector: "app-virtual-asset",
  templateUrl: "./virtual-asset.component.html",
  styleUrls: ["./virtual-asset.component.scss"],
})
export class VirtualAssetComponent implements OnInit, AfterViewInit {
  @ViewChild("mapvirtual", { static: false }) mapElement: ElementRef;
  portMemberCoordinates$: Observable<MapMemberCoordinatesModel> = this.store.select(selectPortAuthorityMembersCoordinates)
  RegionCode = environment.regionCode;
  selectedPortId = environment.ravennaPortId;
  public zoom_multiport = VIRTUALASSET.MULTI_TERMINAL_ZOOM;
  public latlng: any[] = [];
  public longitudeV = -347.788;
  public portRegionCode: number;
  public portCode: string;
  public latitudeV = 44.45;

  lat: Number = this.latitudeV;
  lng: Number = this.longitudeV;
  hideData: boolean = false;
  public start_end_mark: markerDetail[] = [];

  constructor(private store: Store) { }
  ngAfterViewInit(): void {


    this.portMemberCoordinates$.subscribe((data) => {
      if (data) {
      
        this.getPortAuthorityMemberGeoCoordinates(data);
      }
    });



  }

  ngOnInit(): void {
    this.store.dispatch(getportAuthMembersCoordinates({ portsId: this.selectedPortId, portRegionCode: this.RegionCode }));
    this.azureMap();
   

  }
  getPortAuthorityMemberGeoCoordinates(data: MapMemberCoordinatesModel) {

    if (data.ports) {
   
      data.ports.forEach((port) => {
        if (port.geojson) {
          const latitude = port.geojson.latitude;
          const longitude = port.geojson.longitude;
          this.start_end_mark.push({
            latitude: latitude,
            longitude: longitude,
            port: port.port_name,
            flag: false
          });
        }
      });
    }


  


 

    this.azureMap();






  }

  

  azureMap() {
    const map = new atlas.Map(this.mapElement.nativeElement, {
      center: [this.longitudeV, this.latitudeV],
      zoom: 9,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      // showFeedbackLink: false,
      // showLogo: false,
      language: "en-US",
    });


    map.events.add("load", () => {
      this.start_end_mark.forEach((port) => {
        const popup = new atlas.Popup({
          content: `<div style="padding:10px">${port.port}</div>`,
          pixelOffset: [0, -30],
        });

        const marker = new atlas.HtmlMarker({
          color: "red",
          position: [port.longitude, port.latitude],
          popup: popup,
        });

        map.markers.add(marker);

        marker.getElement().addEventListener("mouseover", () => {
          marker.togglePopup();
        });

       
        marker.getElement().addEventListener("mouseleave", () => {
          popup.close();
        });
      });
    });

  }
}
export interface markerDetail {
  latitude: number;
  longitude: number;
  port: string;
  flag: boolean;
}

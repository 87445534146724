import { Component, Input, OnChanges, OnInit, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import { EquipmentAlertsModel } from 'src/app/shared/models';
import { WorkOrderComponent } from '../../../components/work-order/work-order.component';
import { Store } from "@ngrx/store";
import { getOperatingStatus } from 'src/app/shared/state/operations/operations.action';
import { selectOperatingStatus } from 'src/app/shared/state/operations/operations.selectors';
import { OperatingStatusModel } from 'src/app/shared/models/operating-status.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-operating-status',
  templateUrl: './operating-status.component.html',
  styleUrls: ['./operating-status.component.scss']
})
export class OperatingStatusComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  selectedPortId: string;

  operatingStatus$: Observable<OperatingStatusModel[]> = this.store.select(selectOperatingStatus);
  dataSource = new MatTableDataSource<OperatingStatusModel>();
  displayedColumns = ['description', 'equipment', 'raiseCase', 'time'];
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  pageIndex = PAGINATOR.DASHBOARD.OPERATING_STATUS.pageIndex;
  pageSize = PAGINATOR.DASHBOARD.OPERATING_STATUS.pageSize;

  constructor(private dialog: MatDialog, private store: Store) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.operatingStatus$.subscribe((data) => {
        if (data) {
          this.createTable(data);
        }
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;
    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getOperatingStatus({ portId: this.selectedPortId }));
      }
    }
  }

  createTable(data: OperatingStatusModel[]) {
    this.dataSource.data = data;
  }

  popUpAlert(alert: EquipmentAlertsModel) {
    const dialogRef = this.dialog.open(WorkOrderComponent, {
      data: { ...alert }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        alert.raised = result.raised;
      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EquipmentAlertsModel } from 'src/app/shared/models';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AlertDialogComponent } from '../modal-loader/alert-dialog/alert-dialog.component';

import { ModalLoaderComponent } from '../modal-loader/modal-loader.component';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { EmailRequest } from 'src/app/shared/models/emailRequest.model';


@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit {
  noData: boolean;
  public isSuccess: boolean = false;
  public savingOverlay: string;
  public workOrderRequest: EmailRequest = {} as EmailRequest;
  private dialogWidth = '500px';
  private observable: Observable<any>;
  private subscription: Subscription;
  public submitted = false;
  public loading = false;
  public hasError = false;
  public workOrderTypes = [
    'Breakdown',
    'Preventive',
    'Corrective',
    'Accident'
  ];


  constructor(
    private dialogRef: MatDialogRef<WorkOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentAlertsModel,
    private datePipe: DatePipe,
    private dialog: MatDialog,


  ) { }

  ngOnInit() {
    this.dialogRef.updateSize(this.dialogWidth);
    this.workOrderRequest.alertId = this.data.alert_id;
    this.workOrderRequest.equipmentId = this.data.equipment_name;
    this.workOrderRequest.equipmentType = this.data.equipment_type;
    this.workOrderRequest.maintenanceDesc = this.data.description;
    if (this.data.current !== null && this.data.current !== undefined) {
      this.workOrderRequest.sensorData = `${this.data.current}/${this.data.threshold} ${this.data.uom}`;
    } else {
      this.workOrderRequest.sensorData = '-';
    }
    if (this.data.data_point !== null && this.data.data_point !== undefined) {
      this.workOrderRequest.sensorId = this.data.data_point;
    } else {
      this.workOrderRequest.sensorId = '-';
    }
    if (this.data.time !== null && this.data.time !== undefined) {
      // this.workOrderRequest.alertTimeStamp = this.datePipe.transform(this.data.time.replace('.', ':'), environment.emailDateTimeFormat);
    } else {
      this.workOrderRequest.alertTimeStamp = '-';
    }
    this.workOrderRequest.username = sessionStorage.getItem('userName');
    // this.workOrderRequest.workReqestTimeStamp = this.datePipe.transform(new Date(), environment.emailDateTimeFormat, environment.timeZone);
    // this.workOrderRequest.requestDate = this.datePipe.transform(new Date(), environment.emailDateFormat, environment.timeZone);
  }

  close() {
    this.dialogRef.close();
  }

  sendEmail(form: NgForm) {
    this.submitted = true;
    if (form.valid && this.workOrderRequest.username !== null) {
      this.loading = true;
      // this.observable = this.emailService.sendWorkOrder(this.workOrderRequest);
      this.subscription = this.observable.subscribe(data => {
        this.loading = false;
        this.data.raised = true;
        this.dialog.open(AlertDialogComponent, {
          data: { title: 'Message', message: MESSAGE_DISPLAY.SUCCESSFULLY_RAISED }
        });
        this.dialogRef.close(
          {
            ...this.data,
            ...this.workOrderRequest
          }
        );
      }, (error) => {
        this.loading = false;
        this.hasError = true;
      });
    }
    this.dialog.closeAll();

    const formModal = this.dialog.open(ModalLoaderComponent, { disableClose: true, data: { response: 200 } });

    formModal.componentInstance.action = "Processing";

    formModal.componentInstance.task = "Raised Successfully";

  }

}

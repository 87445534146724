import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OptiServiceComponent } from './opti-service.component';
import { InwardComponent } from './inward/inward.component';
import { OutwardComponent } from './outward/outward.component';
import { HistoricalComponent } from './historical/historical.component';




const routes: Routes = [
  {
    path: '',
    component: OptiServiceComponent,
    data: { breadcrumb: '' },
    children: [

      { path: '', redirectTo: 'inward', pathMatch: 'full' },

      {
        path: 'inward',
        component: InwardComponent,
        data: { breadcrumb: 'Inward' }
      },
      {
        path: 'outward',
        component: OutwardComponent,
        data: { breadcrumb: 'Outward' }
      },
      {
        path: 'historical',
        component: HistoricalComponent,
        data: { breadcrumb: 'Historical Booking' }
      },

   


    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OptiServiceRoutingModule { }

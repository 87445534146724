import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import Chart from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Observable } from 'rxjs';
import { ICON } from 'src/app/shared/constants/assets.constant';
import { LTIFModel, SafetyAndHealthMetricsModel } from 'src/app/shared/models';
import { getLostTimeInjuryFrequency, getSafetyAndHealthMetrics } from 'src/app/shared/state/social-responsibility/social-responsibility.actions';
import { selectLostTimeInjuryFrequency, selectSafetyAndHealthMetrics } from 'src/app/shared/state/social-responsibility/social-responsibility.selectors';

@Component({
  selector: 'app-health-and-safety',
  templateUrl: './health-and-safety.component.html',
  styleUrls: ['./health-and-safety.component.scss']
})
export class HealthAndSafetyComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  safetyHealthMetrics$: Observable<SafetyAndHealthMetricsModel> = this.store.select(selectSafetyAndHealthMetrics);
  lostTimeInjuryFrequency$: Observable<LTIFModel> = this.store.select(selectLostTimeInjuryFrequency);
  private ltifBarChart: Chart;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getSafetyAndHealthMetrics({}));
    this.store.dispatch(getLostTimeInjuryFrequency({}));
  }

  ngAfterViewInit(): void {
    this.lostTimeInjuryFrequency$.subscribe((lostTimeInjuryFrequency) => {
      if (lostTimeInjuryFrequency) {
        this.barChartMonthlyLTIF(lostTimeInjuryFrequency);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getLostTimeInjuryFrequency({ year: this.selectedYear }));
        this.store.dispatch(getSafetyAndHealthMetrics({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getLostTimeInjuryFrequency({}));
        this.store.dispatch(getSafetyAndHealthMetrics({}));
      }
    }
  }

  barChartMonthlyLTIF(data: LTIFModel) {
    if (!data.lost_time_injury_frequency.length) {
      this.ltifBarChart?.destroy();
      return;
    }

    if (this.ltifBarChart) {
      this.ltifBarChart.destroy();
    }

    const canvas = document.getElementById("LTIFChart") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d"); // Get the 2D context

    const ltifValues = data.lost_time_injury_frequency.map((data) => data.value);

    this.ltifBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "LTIF",
            data: ltifValues,
            backgroundColor: "#DF8244",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
          text: "Lost Time Injury Frequency",
          position: "top",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#111",
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: { beginAtZero: true },
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: "#000",
            textAlign: "center",
            //offset: 1,
            font: {
              lineHeight: 1.6,
              size: 10,
            },
            formatter: function (value, ctx) {
              return value.toLocaleString();
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }

}

import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

import { WaterConservationState } from "./water-conservation.reducer";


export const selectWaterConservationState = (state: AppState) => state.waterConservation;

export const selectWaterConservation= createSelector(
    selectWaterConservationState,
  (state: WaterConservationState) => state.waterConservation
);

export const selectWaterQualityProfile = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterQualityProfile
);

export const selectWaterQualityIndexCompliance = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterQualityIndexCompliance
);

export const selectWaterUsageShare = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterUsageShare
);

export const selectWaterFootprintUsageEfficiency = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterFootprintUsageEfficiency
);

export const selectWaterSourceDiversity = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterSourceDiversity
);

export const selectWaterRecyclingRateTreatEff = createSelector(
  selectWaterConservationState,
(state: WaterConservationState) => state.waterRecyclingRateTreatEff
);
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';


@Injectable({
  providedIn: 'root'
})
export class PortAuthorityService {


  private portAuthority = environment.basePath + API.PORTAUTHORITY;
  private mockUrl = "https://f7a72141-81a7-4efe-98b5-8bce222718bd.mock.pstmn.io" + API.PORTAUTHORITY;
  constructor(private http: HttpClient) { }

  getportAuthMembers(portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portRegionCode ? new HttpParams().set('port_region_code', portRegionCode.toString()) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/map-region-list-and-details`, { params: params });

  }

  getportAuthMembersDetails(portsId?: string, portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : new HttpParams();
    if (portRegionCode !== undefined && portRegionCode !== null) {
      params = params.append('port_region_code', portRegionCode.toString());
    }
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/member-and-details`, { params });
  }


  getportAuthMembersCoordinates(portsId?: string, portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : new HttpParams();
    if (portRegionCode !== undefined && portRegionCode !== null) {
      params = params.append('port_region_code', portRegionCode.toString());
    }
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/member-coordinates`, { params });
  }


  getportAuthGeneralInfoAnalytics(portsId?: string, portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : new HttpParams();
    if (portRegionCode !== undefined && portRegionCode !== null) {
      params = params.append('port_region_code', portRegionCode.toString());
    }
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/general-information-analytics`, { params });
  }

  getportAuthUtilizationRateAnalytics(portsId?: string, portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : new HttpParams();
    if (portRegionCode !== undefined && portRegionCode !== null) {
      params = params.append('port_region_code', portRegionCode.toString());
    }
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/utilization-rates-analytics`, { params });
  }


  getportAuthTrafficDwellTimeAnalytics(portsId?: string, portRegionCode?: number): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_id', portsId) : new HttpParams();
    if (portRegionCode !== undefined && portRegionCode !== null) {
      params = params.append('port_region_code', portRegionCode.toString());
    }
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.portAuthority}/traffic-and-dwell-time-analytics`, { params });
  }







}

export const TURN_TIME = {
    RANGE_LABEL: {
      start: 0,
      end: 120
    },
    DELIMITERS: [61,91],
     ARC_COLORS: ['#009116', '#edb500', '#ed0010'],
    //ARC_COLORS: ['#ed0010', '#edb500', '#009116'],
    DUMMY_MODAL_DATA: {
      date: "16-Aug-2021",
      average: 35.39,
      inbound: "(207) 22.66",
      outbound: "(145) 39.86",
      double: "(200) 45.31"
    }
  }
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class WasteManagementService {
  private wasteManagement = environment.basePath + API.KPI;
  /// private wasteManagement = '/connPort' + API.KPI; //local 
  
  constructor(private http: HttpClient) { }

  getWasteManagement(portsId?: string): Observable<ApiResponse | ErrorResponse | any> {
    let params = portsId ? new HttpParams().set('port_ids', portsId) : null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.wasteManagement}/waste-recycling-efficiency`, { params: params });
  }
}


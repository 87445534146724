import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OptiServiceRoutingModule } from './opti-service-routing.module';
import { InwardComponent } from './inward/inward.component';

import { OutwardComponent } from './outward/outward.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { HistoricalComponent } from './historical/historical.component';
import { ServicesInfoComponent } from './services-info/services-info.component';


@NgModule({
  declarations: [InwardComponent,
   
    OutwardComponent,
    HistoricalComponent,
    ServicesInfoComponent,
    ],
  imports: [
    CommonModule,
    OptiServiceRoutingModule,MatPaginatorModule, MatTableModule
  ]
})
export class OptiServiceModule { }

import { Component, Input, OnInit, OnChanges, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHARTv2 } from 'src/app/shared/constants/chart.constant';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import { TugboatKpiModel, TugboatList } from 'src/app/shared/models/tugboat-list.model';
import { getTugboatKpi } from 'src/app/shared/state/operations/operations.action';
import { selectTugboatKpi } from 'src/app/shared/state/operations/operations.selectors';


@Component({
  selector: 'app-tugboat-kpi',
  templateUrl: './tugboat-kpi.component.html',
  styleUrls: ['./tugboat-kpi.component.scss']
})
export class TugboatKpiComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) { this.dataSource.paginator = paginator; }
  @Input() selectedPortId: string;

  tugboatKpi$: Observable<TugboatList[]> = this.store.select(selectTugboatKpi);
  public displayedColumns = ['tugboatId', 'idlingRate', 'utilizationRate', 'availability', 'reliability', 'emptyColumn'];
  dataSource = new MatTableDataSource<TugboatList>();
  public chartTypes = { ...CHARTv2.TYPE };
  pageIndex = PAGINATOR.DASHBOARD.SENSOR_EVALUATION.pageIndex;
  pageSize = PAGINATOR.DASHBOARD.SENSOR_EVALUATION.pageSize;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;


  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getTugboatKpi({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tugboatKpi$.subscribe((data) => {
        if (data?.length > 0) {
          this.dataSource.data = data;
          this.noData = false;
          // data = this.roundToTwoDecimal(data);
        }
      });
    });
  }

  //FOR FURTURE REQUIREMENT OF 2 DECIMAL POINT
  // roundToTwoDecimal(dataArray: any[]): any[] {
  //   return dataArray.map(item => {
  //     return {
  //       ...item,
  //       // // Ensure status is not empty and round any numerical status
  //       // status: item.status ? item.status : 'Inactive', // Set default to 'Inactive' if empty
  //       idlingRate: Math.round(item.idling_rate * 100) / 100,
  //       utilizationRate: Math.round(item.utilization_rate * 100) / 100,
  //       availability: Math.round(item.availability * 100) / 100,
  //       reliability: Math.round(item.reliability * 100) / 100
  //     };
  //   });
  // }



  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getTugboatKpi({ portId: this.selectedPortId }));
      }
    }
  }


}

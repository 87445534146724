import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import { EquipmentIconComponent } from './equipment-icon.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [EquipmentIconComponent],
  exports: [
    EquipmentIconComponent
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    MatTooltipModule
  ],
  providers: [
    TitleCasePipe
  ]
})
export class EquipmentIconModule {
  static forRoot(): ModuleWithProviders<EquipmentIconModule> {
    return {
      ngModule: EquipmentIconModule,
      providers: []
    };
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-equipment-raise-case',
  templateUrl: './equipment-raise-case.component.html',
  styleUrls: ['./equipment-raise-case.component.scss']
})
export class EquipmentRaiseCaseComponent implements OnInit {
  @Input() raisedDescription: string;
  @Input() raisedEquipment: string;

  constructor() { }

  ngOnInit(): void {
  }

}

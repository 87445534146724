import { createReducer, on } from '@ngrx/store';
import { CarbonMngmtAndEmissionReductionModel } from '../../models/sustainability.model';
import { ErrorResponse } from '../../models/error-response.model';
import { ApiStatus } from '../../enum/api-status.enum';
import { OverallCarbonIntensityModel } from '../../models/overallcarbonint.model';
import {
  getCarbonCreditTradingSequestration,
  getCarbonCreditTradingSequestrationFailure,
  getCarbonCreditTradingSequestrationSuccess,
  getCarbonEmissionReduceSummary,
  getCarbonEmissionReduceSummaryFailure,
  getCarbonEmissionReduceSummarySuccess,
  getCarbonMngmtAndEmissionReduction,
  getCarbonMngmtAndEmissionReductionFailure,
  getCarbonMngmtAndEmissionReductionSuccess,
  getEmissionFactors,
  getEmissionFactorsFailure,
  getEmissionFactorsSuccess,
  getOverallCarbonIntensity,
  getOverallCarbonIntensityFailure,
  getOverallCarbonIntensitySuccess,
  getPortElectrificationInsight,
  getPortElectrificationInsightFailure,
  getPortElectrificationInsightSuccess,
  getPortElectrificationOverview,
  getPortElectrificationOverviewSuccess,
  // getQuarterlyShare,
  // getQuarterlyShareFailure,
  // getQuarterlyShareSuccess,
  getRenewableEnergyGraph,
  getRenewableEnergyGraphFailure,
  getRenewableEnergyGraphSuccess,
  getRenewableEnergyShare,
  getRenewableEnergyShareFailure,
  getRenewableEnergyShareSuccess
} from './sustainability.actions';
import { CarbonEmissionReduceSummaryModel } from '../../models/carbon-emission-reduce-summary.model';
import { PortElectrificationInsightModel } from '../../models/port-electrification-insight.model';
import { CarbonCreditTradingSequestrationModel } from '../../models/carbon-credit-trading-sequestration.model';
import { PortElectrificationOverviewModel } from '../../models/port-electrification-overview.model';
import { EmissionFactorsModel } from '../../models/emission-factors.model';
import { RenewableEnergyShareModel } from '../../models/renewable-energy-share.model';
import { RenewableEnergyGraphModel } from '../../models/renewable-energy-graph.model';
import { QuarterlyPlantShareModel } from '../../models/quarterly-share.model';



export interface SustainabilityState {
  carbonMngmtEmission: CarbonMngmtAndEmissionReductionModel,
  overallCarbonIntensity: OverallCarbonIntensityModel,
  carbonEmissionSummary: CarbonEmissionReduceSummaryModel,
  portElectrificationOverview: PortElectrificationOverviewModel,
  portElectrificationInsight: PortElectrificationInsightModel,
  carbonCreditTradingSequestration: CarbonCreditTradingSequestrationModel,
  emissionFactors: Array<EmissionFactorsModel>,
  renewableEnergyShare: RenewableEnergyShareModel,
  renewableEnergyGraph: RenewableEnergyGraphModel,
  quarterlyShare:QuarterlyPlantShareModel,
  error: ErrorResponse,
  status: ApiStatus
}

export const initialState: SustainabilityState = {
  carbonMngmtEmission: null,
  overallCarbonIntensity: null,
  carbonEmissionSummary: null,
  portElectrificationOverview: null,
  portElectrificationInsight: null,
  carbonCreditTradingSequestration: null,
  emissionFactors: [],
  renewableEnergyShare: null,
  renewableEnergyGraph: null,
  quarterlyShare:null,
  error: null,
  status: ApiStatus.Pending

}

export const sustainabilityReducer = createReducer(
  initialState,
  on(getCarbonMngmtAndEmissionReduction, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getCarbonMngmtAndEmissionReductionSuccess, (state, { carbonMngmtEmission }) => ({
    ...state,
    carbonMngmtEmission: carbonMngmtEmission,
    status: ApiStatus.Success,
    error: null
  })),
  on(getCarbonMngmtAndEmissionReductionFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getOverallCarbonIntensity, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getOverallCarbonIntensitySuccess, (state, { overallCarbonIntensity }) => ({
    ...state,
    overallCarbonIntensity: overallCarbonIntensity,
    status: ApiStatus.Success,
    error: null
  })),
  on(getOverallCarbonIntensityFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getCarbonEmissionReduceSummary, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getCarbonEmissionReduceSummarySuccess, (state, { carbonEmissionSummary }) => ({
    ...state,
    carbonEmissionSummary: carbonEmissionSummary,
    status: ApiStatus.Success,
    error: null
  })),
  on(getCarbonEmissionReduceSummaryFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getPortElectrificationOverview, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getPortElectrificationOverviewSuccess, (state, { portElectrificationOverview }) => ({
    ...state,
    portElectrificationOverview: portElectrificationOverview,
    status: ApiStatus.Success,
    error: null
  })),
  on(getPortElectrificationInsightFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getPortElectrificationInsight, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getPortElectrificationInsightSuccess, (state, { portElectrificationInsight }) => ({
    ...state,
    portElectrificationInsight: portElectrificationInsight,
    status: ApiStatus.Success,
    error: null
  })),
  on(getPortElectrificationInsightFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getCarbonCreditTradingSequestration, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getCarbonCreditTradingSequestrationSuccess, (state, { carbonCreditTradingSequestration }) => ({
    ...state,
    carbonCreditTradingSequestration: carbonCreditTradingSequestration,
    status: ApiStatus.Success,
    error: null
  })),
  on(getCarbonCreditTradingSequestrationFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getEmissionFactors, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getEmissionFactorsSuccess, (state, { emissionFactors }) => ({
    ...state,
    emissionFactors: emissionFactors,
    status: ApiStatus.Success,
    error: null
  })),
  on(getEmissionFactorsFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getRenewableEnergyShare, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getRenewableEnergyShareSuccess, (state, { renewableEnergyShare }) => ({
    ...state,
    renewableEnergyShare: renewableEnergyShare,
    status: ApiStatus.Success,
    error: null
  })),
  on(getRenewableEnergyShareFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getRenewableEnergyGraph, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getRenewableEnergyGraphSuccess, (state, { renewableEnergyGraph }) => ({
    ...state,
    renewableEnergyGraph: renewableEnergyGraph,
    status: ApiStatus.Success,
    error: null
  })),
  on(getRenewableEnergyGraphFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),





);
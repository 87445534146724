import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OutboundTrucksService {

  constructor() { }

  private newTime;
  public newDate;
  public monthsAll = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  formatDateTime(datetime) {
    const date = datetime.transaction_date.slice(0, 10);
    const time = datetime.transaction_date.slice(11, 19);

    this.retOBTDateTime(date, time)

    const tmphr = this.newTime.slice(0, -6);
    const tmpmn = this.newTime.slice(-5, -3);

    datetime.transaction_date = `${this.newDate} ${time}`;
    datetime.time = this.newTime.length <= 5 ? null : `${tmphr} hr ${tmpmn} min`;

    return datetime;
  }

  retOBTDateTime(date, time) {
    const [dataHours, dataMinutes, dateSeconds] = time.split(':');
    const dataDate = new Date(date);
    const currDate = new Date();

    dataDate.setHours(dataHours);
    dataDate.setMinutes(dataMinutes);
    dataDate.setSeconds(dateSeconds);

    const diff = currDate.getTime() - dataDate.getTime();

    let day = (dataDate.getDate()).toString(), year = (dataDate.getFullYear()).toString();

    if (day.length < 2) day = '0' + day;

    this.newDate = [day, this.monthsAll[dataDate.getMonth()], year].join('-');
    this.newTime = this.formatMilliseconds(diff, true);
  }

  formatMilliseconds(milliseconds, padStart) {
    function pad(num) {
      return `${num}`.padStart(2, '0');
    }
    let asSeconds = milliseconds / 1000;

    let hours = undefined;
    let minutes = Math.floor(asSeconds / 60);
    let seconds = Math.floor(asSeconds % 60);

    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }

    return hours
      ? `${padStart ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`
      : `${padStart ? pad(minutes) : minutes}:${pad(seconds)}`;
  }
}

import { createReducer, on } from '@ngrx/store';
import { ApiStatus } from '../../enum/api-status.enum';
import { ErrorResponse } from '../../models';
import { getportAuthGeneralInfoAnalytics, getportAuthGeneralInfoAnalyticsFailure, getportAuthGeneralInfoAnalyticsSuccess, getportAuthMembers, getportAuthMembersCoordinates, getportAuthMembersCoordinatesFailure, getportAuthMembersCoordinatesSuccess, getportAuthMembersDetails, getportAuthMembersDetailsFailure, getportAuthMembersDetailsSuccess, getportAuthMembersFailure, getportAuthMembersSuccess, getportAuthTrafficDwellTimeAnalytics, getportAuthTrafficDwellTimeAnalyticsFailure, getportAuthTrafficDwellTimeAnalyticsSuccess, getportAuthUtilizationRateAnalytics, getportAuthUtilizationRateAnalyticsFailure, getportAuthUtilizationRateAnalyticsSuccess } from './port-authority.action';
import { MapMemberCoordinatesModel, MapRegionListModel, PortMemberDetailsModel } from '../../models/port-authority-models/map-region-list.model';
import { PortMemberGeneralDetailsModel, PortMemberUtlizationRateModel, TraffifDwellTimeModel } from '../../models/port-authority-models/analytics-details.model';

export interface PortAuthorityState {

    error: ErrorResponse,
    status: ApiStatus,
    portAuthMember: Array<MapRegionListModel>,
    portAuthMemberDetails: PortMemberDetailsModel,
    portAuthMemberCoordinates: MapMemberCoordinatesModel,
    portAuthGeneralInfoAnalytics: PortMemberGeneralDetailsModel,
    portAuthUtilizationRateAnalytics: PortMemberUtlizationRateModel,
    portAuthTrafficDwellTime: TraffifDwellTimeModel
}




export const initialState: PortAuthorityState = {

    error: null,
    status: ApiStatus.Pending,
    portAuthMember:
        [
            {
                "port_region_name": "Central-Northern Adriatic Sea Ravenna",
                "port_name": "Port of Ravenna",
                "port_code": "PORT04",
                "location": "Located on the northeastern coast of Italy, along Adriatic Sea. Via Baiona, 192, 48123 Ravenna RA, Italy.",
                "port_description": "The main and largest port under the authority's jurisdiction, which handles a variety of cargo types, including bulk goods, containers and oil products.",
                "geojson": [
                    {
                        "longitude": 12.2611,
                        "latitude": 44.4677
                    }
                ]
            },
            {
                "port_region_name": "Central-Northern Adriatic Sea Ravenna",
                "port_name": "Port of Forli",
                "port_code": "PORT05",
                "location": "Forlì is an inland city in northern Italy, without direct access to the sea. It is part of the Emilia-Romagna region, situated on the Montone River.",
                "port_description": "A smaller port within the Ravenna Port Authority's territory, primarily focused on industrial and bulk cargo.",
                "geojson": [
                    {
                        "longitude": 12.05,
                        "latitude": 44.2333
                    }
                ]
            },
            {
                "port_region_name": "Central-Northern Adriatic Sea Ravenna",
                "port_name": "Port of Cesenatico",
                "port_code": "PORT06",
                "location": "Located on the Adriatic coast of Italy. Situated in the Emilia-Romagna region, near the city of Cesena.",
                "port_description": "A smaller coastal port mainly used fo fishing and tourism activities.",
                "geojson": [
                    {
                        "longitude": 12.4052,
                        "latitude": 44.2008
                    }
                ]
            },
            {
                "port_region_name": "Central-Northern Adriatic Sea Ravenna",
                "port_name": "Port of Cervia",
                "port_code": "PORT07",
                "location": "Cervia is a town on the Adriatic coast in the Emilia-Romagna region of Italy. It is known for its historical connection to salt production.",
                "port_description": "A saltwater port located near the town of Cervia, primarily used for salt production and related activities.",
                "geojson": [
                    {
                        "longitude": 12.3477,
                        "latitude": 44.2635
                    }
                ]
            }
        ],

    portAuthMemberDetails:null, 
    // {
    //     "port_region_name": "Central-Northern Adriatic Sea Ravenna",
    //     "port_name": "Ravenna",
    //     "location": "Located on the northeastern coast of Italy. Situated in the Emilia-Romagna region, on the Adriatic Sea.",
    //     "cargo_type": "Handles a diverse range of cargo, including chemicals, energy products, and general cargo. Significantly involved in the import and export of millions of tons of goods annually.",
    //     "weather_conditions": "Mediterranean climate with hot, dry summers and mild winters. Average summer temperatures around 25-30°C (77-86°F) and winter temperatures around 5-10°C (41-50°F).",
    //     "facilities_available": "Well-equipped commercial and industrial port facilities. Features infrastructure supporting the efficient transport and handling of various types of cargo."
    // },

    portAuthMemberCoordinates: null,
    portAuthGeneralInfoAnalytics: null,
    portAuthUtilizationRateAnalytics: null,
    portAuthTrafficDwellTime: null
}


export const portauthorityReducer = createReducer(
    initialState,

    on(getportAuthMembers, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthMembersSuccess, (state, { portAuthMember }) => ({
        ...state,
        portAuthMember: portAuthMember,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthMembersFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),

    on(getportAuthMembersDetails, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthMembersDetailsSuccess, (state, { portAuthMemberDetails }) => ({
        ...state,
        portAuthMemberDetails: portAuthMemberDetails,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthMembersDetailsFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),

    on(getportAuthMembersCoordinates, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthMembersCoordinatesSuccess, (state, { portAuthMemberCoordinates }) => ({
        ...state,
        portAuthMemberCoordinates: portAuthMemberCoordinates,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthMembersCoordinatesFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),

    on(getportAuthGeneralInfoAnalytics, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthGeneralInfoAnalyticsSuccess, (state, { portAuthGeneralInfoAnalytics }) => ({
        ...state,
        portAuthGeneralInfoAnalytics: portAuthGeneralInfoAnalytics,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthGeneralInfoAnalyticsFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),

    on(getportAuthUtilizationRateAnalytics, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthUtilizationRateAnalyticsSuccess, (state, { portAuthUtilizationRateAnalytics }) => ({
        ...state,
        portAuthUtilizationRateAnalytics: portAuthUtilizationRateAnalytics,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthUtilizationRateAnalyticsFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),


    on(getportAuthTrafficDwellTimeAnalytics, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getportAuthTrafficDwellTimeAnalyticsSuccess, (state, { portAuthTrafficDwellTime }) => ({
        ...state,
        portAuthTrafficDwellTime: portAuthTrafficDwellTime,
        error: null,
        status: ApiStatus.Success
    })),
    on(getportAuthTrafficDwellTimeAnalyticsFailure, (state, { error }) => ({
        ...state,
        error: error,
        status: ApiStatus.Error
    })),

)
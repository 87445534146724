import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { ICON } from 'src/app/shared/constants/assets.constant';

@Component({
  selector: 'app-equipment-icon',
  templateUrl: './equipment-icon.component.html',
  styleUrls: ['./equipment-icon.component.scss']
})
export class EquipmentIconComponent implements OnInit, OnChanges {

  @Input() equipmentStatus = EQUIPMENT.EQUIPMENT_STATUS.ACTIVE;
  @Input() equipmentType = EQUIPMENT.EQUIPMENT_TYPE.CRANE;
  @Input() workStatus = false;
  @Input() tooltipPosition = 'right';
  @Input() tooltipMaintenancePosition = 'right';
  @Input() tooltipEnabled = false;
  public equipmentIcon = '';
  public equipmentStatusTooltip = '';
  public maintenanceStatusTooltip = '';
  private equipmentTypes = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public equipmentStatuses = { ...EQUIPMENT.EQUIPMENT_STATUS, MAINTENANCE: EQUIPMENT.EQUIPMENT_STATUS.MAINTENANCE };
  public equipmentIcons = { ...ICON.EQUIPMENT_STATUS, MAINTENANCE: ICON.EQUIPMENT_STATUS.MAINTENANCE };
  constructor(private titlecasePipe: TitleCasePipe) { }

  ngOnInit() {

  }

  ngOnChanges(): void {
    this.getIcon();
    this.getTooltip();
  }

  getIcon() {
    this.equipmentStatus = this.titlecasePipe.transform(this.equipmentStatus);
    switch (this.equipmentStatus) {
      case EQUIPMENT.EQUIPMENT_STATUS.ACTIVE:
        this.equipmentIcon = this.equipmentIcons[this.equipmentType.toUpperCase()].ACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.INACTIVE:
        this.equipmentIcon = this.equipmentIcons[this.equipmentType.toUpperCase()].INACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.IDLE:
        this.equipmentIcon = this.equipmentIcons[this.equipmentType.toUpperCase()].IDLE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.NON_PRODUCTIVE:
        this.equipmentIcon = this.equipmentIcons[this.equipmentType.toUpperCase()].NON_PRODUCTIVE;
        break;
      default:
        this.equipmentIcon = this.equipmentIcons[this.equipmentType.toUpperCase()].INACTIVE;
        break;
    }
  }

  getTooltip() {
    this.equipmentStatus = this.titlecasePipe.transform(this.equipmentStatus);
    if (this.tooltipEnabled) {

      if (this.workStatus) {
        this.maintenanceStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.MAINTENANCE;
      }
      //
      if (this.workStatus && this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.ACTIVE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.ACTIVE_STATUS_TRUE;
      } else if (this.workStatus && this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.INACTIVE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.INACTIVE_STATUS_TRUE;
      } else if (this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.ACTIVE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.ACTIVE;
      } else if (this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.IDLE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.IDLE;
      } else if (this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.NON_PRODUCTIVE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.NON_PRODUCTIVE;
      } else if (this.titlecasePipe.transform(this.equipmentStatus) === EQUIPMENT.EQUIPMENT_STATUS.INACTIVE) {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.INACTIVE;
      } else {
        this.equipmentStatusTooltip = EQUIPMENT.EQUIPMENT_STATUS_TOOLTIP.INACTIVE;
      }
    }
  }

}

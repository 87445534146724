import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable } from 'rxjs';
import { ICON } from 'src/app/shared/constants/assets.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { WaterFootprintUsageEfficiencyModel, WaterUsageShareModel } from 'src/app/shared/models';
import { getWaterFootprintUsageEfficiency, getWaterUsageShare } from 'src/app/shared/state/water-conservation/water-conservation.actions';
import { selectWaterFootprintUsageEfficiency, selectWaterUsageShare } from 'src/app/shared/state/water-conservation/water-conservation.selectors';

@Component({
  selector: 'app-water-usage',
  templateUrl: './water-usage.component.html',
  styleUrls: ['./water-usage.component.scss']
})
export class WaterUsageComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  waterUsageShare$: Observable<WaterUsageShareModel> = this.store.select(selectWaterUsageShare);
  waterFootprintUsageEfficiency$: Observable<WaterFootprintUsageEfficiencyModel> = this.store.select(selectWaterFootprintUsageEfficiency);
  private waterUsageDoughnutChart: Chart;

  constructor(private store: Store, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.store.dispatch(getWaterUsageShare({}));
    this.store.dispatch(getWaterFootprintUsageEfficiency({}));
  }

  ngAfterViewInit(): void {
    this.waterUsageShare$.subscribe((water_shares) => {
      if (water_shares) {
        this.createDonutChart(water_shares);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getWaterUsageShare({ year: this.selectedYear }));
        this.store.dispatch(getWaterFootprintUsageEfficiency({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getWaterUsageShare({}));
        this.store.dispatch(getWaterFootprintUsageEfficiency({}));
      }
    }
  }

  createDonutChart(data: WaterUsageShareModel) {
    if (this.waterUsageDoughnutChart) {
      this.waterUsageDoughnutChart.destroy();
    }

    const canvas: HTMLCanvasElement = this.elementRef.nativeElement.querySelector('#waterUsageChart');
    if (!canvas) {
      console.error('Canvas element with id "waterUsageChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const CODES = CHART.CODES.WATER_USAGE_SHARE;
    const COLORS = CHART.COLOR.WATER_USAGE_SHARE;
    const colorMapping = {
      [CODES.INDUSTRIAL_PROCESSES]: COLORS.BLUE,
      [CODES.NON_PORTABLE_USE]: COLORS.ORANGE,
      [CODES.VESSEL_SERVICES]: COLORS.GRAY,
      [CODES.IRRIGATION]: COLORS.YELLOW,
      [CODES.DOCK_OPERATIONS]: COLORS.BLUE_1,
      [CODES.DOMESTIC_USE]: COLORS.GREEN,
      [CODES.OTHER]: COLORS.BLUE_2,
    };
    const legendOrder = Object.keys(colorMapping);
    const sortedWaterShares = data.water_shares.slice().sort((a, b) => legendOrder?.indexOf(a.code) - legendOrder?.indexOf(b.code));
    const waterShareCodes = sortedWaterShares.map((share) => share.code);
    const waterShareLabel = sortedWaterShares.map((share) => share.parameter);
    const waterShareData = sortedWaterShares.map((share) => Math.round(share.percentage_share));
    const backgroundColors = waterShareCodes.map(code => colorMapping[code] || COLORS.BLACK);

    this.waterUsageDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: waterShareLabel,
        datasets: [{
          data: waterShareData,
          backgroundColor: backgroundColors
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        title: {
          display: false,
          text: 'Water Usage Share',
          position: 'top',
          fontSize: 16,
          fontColor: COLORS.BLACK_1,
          padding: 20
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            fontColor: COLORS.BLACK_1,
            fontSize: 12,
            boxWidth: 10,
            fontFamily: 'Montserrat',
            padding: 18,
            filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: COLORS.WHITE,
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              size: 14,
            },
            formatter: function (value, context) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value + '%';
            }
          }
        }
      },
      plugins: [ChartDataLabels]
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? 'percent-red' : 'percent-green';
  }
}

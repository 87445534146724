import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { PortAuthorityService } from "../../services/port-authority.service";
import { getportAuthGeneralInfoAnalytics, getportAuthGeneralInfoAnalyticsFailure, getportAuthGeneralInfoAnalyticsSuccess, getportAuthMembers, getportAuthMembersCoordinatesFailure, getportAuthMembersCoordinatesSuccess, getportAuthMembersDetails, getportAuthMembersDetailsFailure, getportAuthMembersDetailsSuccess, getportAuthMembersFailure, getportAuthMembersSuccess, getportAuthTrafficDwellTimeAnalytics, getportAuthTrafficDwellTimeAnalyticsFailure, getportAuthTrafficDwellTimeAnalyticsSuccess, getportAuthUtilizationRateAnalytics, getportAuthUtilizationRateAnalyticsFailure, getportAuthUtilizationRateAnalyticsSuccess } from "./port-authority.action";


@Injectable()
export class PortAuthorityEffects {

    constructor(
        private actions$: Actions,
        private portauthorityService: PortAuthorityService
    ) { }

    //***TO UNCOMMENT WHEN API DATA AVAILABLE***
    // getportAuthMembers$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(getportAuthMembers),
    //         switchMap((action) =>
    //             this.portauthorityService.getportAuthMembers(action.portRegionCode ? action.portRegionCode : null)
    //                 .pipe(
    //                     map((response) => response.data),
    //                     map((data) => getportAuthMembersSuccess({ portAuthMember: data })),
    //                     catchError((error) => of(getportAuthMembersFailure({ error })))
    //                 )
    //         )
    //     )
    // );


    getportAuthMembersDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getportAuthMembersDetails),
            switchMap((action) =>
                this.portauthorityService.getportAuthMembersDetails(action.portsId ? action.portsId : null, action.portRegionCode ? action.portRegionCode : null)
                    .pipe(
                        map((response) => response.data),
                        map((data) => getportAuthMembersDetailsSuccess({ portAuthMemberDetails: data })),
                        catchError((error) => of(getportAuthMembersDetailsFailure({ error })))
                    )
            )
        )
    );


    getportAuthMembersCoordinates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getportAuthMembersDetails),
            switchMap((action) =>
                this.portauthorityService.getportAuthMembersCoordinates(action.portsId ? action.portsId : null, action.portRegionCode ? action.portRegionCode : null)
                    .pipe(
                        map((response) => response.data),
                        map((data) => getportAuthMembersCoordinatesSuccess({ portAuthMemberCoordinates: data })),
                        catchError((error) => of(getportAuthMembersCoordinatesFailure({ error })))
                    )
            )
        )
    );

    getportAuthGeneralInfoAnalytics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getportAuthGeneralInfoAnalytics),
            switchMap((action) =>
                this.portauthorityService.getportAuthGeneralInfoAnalytics(action.portsId ? action.portsId : null, action.portRegionCode ? action.portRegionCode : null)
                    .pipe(
                        map((response) => response.data),
                        map((data) => getportAuthGeneralInfoAnalyticsSuccess({ portAuthGeneralInfoAnalytics: data })),
                        catchError((error) => of(getportAuthGeneralInfoAnalyticsFailure({ error })))
                    )
            )
        )
    );

    getportAuthUtilizationRateAnalytics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getportAuthUtilizationRateAnalytics),
            switchMap((action) =>
                this.portauthorityService.getportAuthUtilizationRateAnalytics(action.portsId ? action.portsId : null, action.portRegionCode ? action.portRegionCode : null)
                    .pipe(
                        map((response) => response.data),
                        map((data) => getportAuthUtilizationRateAnalyticsSuccess({ portAuthUtilizationRateAnalytics: data })),
                        catchError((error) => of(getportAuthUtilizationRateAnalyticsFailure({ error })))
                    )
            )
        )
    );



    getportAuthTrafficDwellTimeAnalytics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getportAuthTrafficDwellTimeAnalytics),
            switchMap((action) =>
                this.portauthorityService.getportAuthTrafficDwellTimeAnalytics(action.portsId ? action.portsId : null, action.portRegionCode ? action.portRegionCode : null)
                    .pipe(
                        map((response) => response.data),
                        map((data) => getportAuthTrafficDwellTimeAnalyticsSuccess({ portAuthTrafficDwellTime: data })),
                        catchError((error) => of(getportAuthTrafficDwellTimeAnalyticsFailure({ error })))
                    )
            )
        )
    );

}
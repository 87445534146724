import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICON } from 'src/app/shared/constants/assets.constant';
import {
  CarbonMngmtAndEmissionReductionModel,
  WaterConservationModel,
  WasteMgntModel,
  BiodiversityModel,
  SocialResponsibilityModel
} from 'src/app/shared/models';
import { } from 'src/app/shared/models/sustainability.model';
import { getBiodiversity } from 'src/app/shared/state/biodiversity/biodiversity.actions';
import { selectBiodiversity } from 'src/app/shared/state/biodiversity/biodiversity.selectors';
import { getSocialResponsibility } from 'src/app/shared/state/social-responsibility/social-responsibility.actions';
import { selectSocialResponsibility } from 'src/app/shared/state/social-responsibility/social-responsibility.selectors';
import { getCarbonMngmtAndEmissionReduction } from 'src/app/shared/state/sustainability/sustainability.actions';
import { selectCarbonMngmtEmission } from 'src/app/shared/state/sustainability/sustainability.selectors';
import { getWasteManagement } from 'src/app/shared/state/waste-management/waste-management.action';
import { selectWasteManagement } from 'src/app/shared/state/waste-management/waste-management.selectors';
import { getWaterConservation } from 'src/app/shared/state/water-conservation/water-conservation.actions';
import { selectWaterConservation } from 'src/app/shared/state/water-conservation/water-conservation.selectors';

@Component({
  selector: 'app-sustainability-kpi',
  templateUrl: './sustainability-kpi.component.html',
  styleUrls: ['./sustainability-kpi.component.scss']
})
export class SustainabilityKpiComponent implements OnInit, OnChanges {
  @Input() selectedPortId: string;

  public carbonMngmtAndEmissionKpi$: Observable<CarbonMngmtAndEmissionReductionModel> = this.store.select(
    selectCarbonMngmtEmission
  );
  public waterConserve$: Observable<WaterConservationModel> = this.store.select(selectWaterConservation);
  public wasteMngt$: Observable<WasteMgntModel> = this.store.select(selectWasteManagement);
  public biodiversity$: Observable<BiodiversityModel> = this.store.select(selectBiodiversity);
  public socialRespon$: Observable<SocialResponsibilityModel> = this.store.select(selectSocialResponsibility);

  constructor(private store: Store) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(
          getCarbonMngmtAndEmissionReduction({ portsId: this.selectedPortId })
        );
        this.store.dispatch(getWaterConservation({ portsId: this.selectedPortId }));
        this.store.dispatch(getWasteManagement({ portsId: this.selectedPortId }));
        this.store.dispatch(getBiodiversity({ portsId: this.selectedPortId }));
        this.store.dispatch(getSocialResponsibility({ portsId: this.selectedPortId }));
      }
    }
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

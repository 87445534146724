import { Component, OnInit } from '@angular/core';
import 'babylonjs-loaders';



@Component({
  selector: 'app-three-d-view',
  templateUrl: './three-d-view.component.html',
  styleUrls: ['./three-d-view.component.scss']
})
export class ThreeDViewComponent implements OnInit {



  constructor() { }




  ngOnInit() {}



}


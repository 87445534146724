import { Component, OnInit, Input } from '@angular/core';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';

//to be removed
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';

@Component({
  selector: 'app-berth-utilization',
  templateUrl: './berth-utilization.component.html',
  styleUrls: ['./berth-utilization.component.scss'],
})
export class BerthUtilizationComponent implements OnInit {
  @Input() berthRatio: number;
  @Input() berthStatusReady = false;
  public borTargetPercentValue = this.roundPipe.transform(feConfig.BORTargetPercent);


  constructor(private roundPipe: RoundPipe) { }

  ngOnInit() { }
}

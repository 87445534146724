import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import * as data from 'src/app/shared/mock-data/opti-ward.json'

@Component({
  selector: 'app-inward',
  templateUrl: './inward.component.html',
  styleUrls: ['./inward.component.scss']
})
export class InwardComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) 
  public paginator: MatPaginator;
  public dataSource;
  public pageIndex = PAGINATOR.EQUIPMENT.LIST.pageIndex;
  public pageSize = PAGINATOR.EQUIPMENT.LIST.pageSize;
  public pageSizeOptions = PAGINATOR.EQUIPMENT.LIST.pageSizeOptions;
  public columnHeaders = [
    'terminalId', 
    'vesselName',
    'vesselType', 
    'imoNum', 
    'location', 
    'arrival', 
    'status',
    'buoyId', 
    'bookingId'
  ];

  constructor() { }

  ngOnInit(): void {
    
    this.dataSource = new MatTableDataSource<any>(data.vessels);
  }

}

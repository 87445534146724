import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SustainabilityDashboardComponent } from './sustainability-dashboard.component';
import { SustainabilityNavModule } from './sustainability-nav/sustainability-nav.module';
import { CorporateGoalModule } from './corporate-goal/corporate-goal.module';
import { CarbonManagementModule } from './carbon-management/carbon-management.module';

const routes: Routes = [
  {
    path: "",
    component: SustainabilityDashboardComponent,
    data: { breadcrumb: "Sustainability Dashboard" },
    children: [
      {
        path: "",
        redirectTo: "all",
        pathMatch: "full"
      },
      {
        path: "all",
        loadChildren: () => SustainabilityNavModule,
        data: { breadcrumb: "" },
      },
      {
        path: "corporate-goal",
        loadChildren: () => CorporateGoalModule,
        data: { breadcrumb: "" },
      },
      {
        path: "carbon",
        loadChildren: () => CarbonManagementModule,
        data: { breadcrumb: "" },
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SustainabilityDashboardRoutingModule { }

import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ICON } from "src/app/shared/constants/assets.constant";
import { EngagementAndTrainingMetricsModel } from "src/app/shared/models/engagement-and-training-metrics.model";
import { selectEngagementTrainingMetrics } from "src/app/shared/state/social-responsibility/social-responsibility.selectors";

@Component({
  selector: 'app-social-training',
  templateUrl: './social-training.component.html',
  styleUrls: ['./social-training.component.scss']
})
export class SocialTrainingComponent implements OnInit {
  @Input() selectedYear: string;

  engagementAndTrainingMetrics$: Observable<EngagementAndTrainingMetricsModel> = this.store.select(selectEngagementTrainingMetrics);

  constructor(private store: Store) { }

  ngOnInit(): void { }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(0));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

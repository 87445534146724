import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-twin-nav',
  templateUrl: './digital-twin-nav.component.html',
  styleUrls: ['./digital-twin-nav.component.scss']
})
export class DigitalTwinNavComponent implements OnInit {
  public activeButton: number = 1;

  constructor() { }

  ngOnInit(): void {
  }
  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
}
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operations-nav',
  templateUrl: './operations-nav.component.html',
  styleUrls: ['./operations-nav.component.scss']
})
export class OperationsNavComponent implements OnInit {
  public activeButton: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.checkActivatedRoute();
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1]

    switch (activePath.toLocaleLowerCase()) {
      case 'port-map':
        this.activeButton = 2;
        break;
      case 'equipment-list':
        this.activeButton = 3;
        break;
      case 'default':
        this.activeButton = 4;
        break;
      case 'map-opti':
        this.activeButton = 5;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationsNavComponent } from './operations-nav.component';
import { OperationsComponent } from './operations/operations.component';
import { PortMapComponent } from './port-map/port-map.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { PortOptimizationModule } from './port-optimization/port-optimization.module';
import { AnalyticsModule } from './analytics/analytics.module';


const routes: Routes = [
  {
    path: "",
    component: OperationsNavComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "overview",
        pathMatch: "full"
      },
      {
        path: "overview",
        component: OperationsComponent,
        data: { breadcrumb: "" }
      },
      {
        path: "port-map",
        component: PortMapComponent,
        data: { breadcrumb: "Port Map" }
      },
      {
        path: "equipment-list",
        component: EquipmentListComponent,
        data: { breadcrumb: "Equipment List" }
      },
      {
        path: "analytics",
        loadChildren: () => AnalyticsModule,
        data: { breadcrumb: "Analytics" }
      },
      {
        path: "port-optimization",
        loadChildren: () => PortOptimizationModule,
        data: { breadcrumb: "Port Optimization" },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsNavRoutingModule { }

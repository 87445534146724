import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { EnergyManagementService } from "../../services/energy-management.service";
import { getEnergyMngt, getEnergyMngtFailure, getEnergyMngtSuccess } from "./energy-management.actions";

@Injectable()
export class EnergyManagementEffects { 
    constructor(
    private actions$: Actions,
    private energyManagementService: EnergyManagementService
  ) { }

  getEnergyMngt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEnergyMngt),
      switchMap((action) =>
        this.energyManagementService.getEnergyMngt(action.portsId ? action.portsId : null).pipe(
          map((response) => response.data),
          map((data) => getEnergyMngtSuccess({ energymanagement: data.consumption })),
          catchError((error) => of(getEnergyMngtFailure({ error })))
        )
      )
    )
  );
  
}
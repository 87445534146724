import {environment} from '../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';

export class CommonFunction {
  static getStreamEndPoint(subPath: string, queryParams: {}): string {

    const api: string = environment.basePath;
    const params: string = new HttpParams({ fromObject: queryParams }).toString();
    return api + subPath + '?' + params;

  }

  static checkWorkOrderPermission(equipmentType: string): boolean {
    const helper = new JwtHelperService();
    if (sessionStorage.getItem('accessToken') !== undefined) {
      const decodedToken = helper.decodeToken(sessionStorage.getItem('accessToken'));
      const workOrderAccess = decodedToken.workOrderAccess;
      if (workOrderAccess !== undefined && workOrderAccess !== null) {
        const access = workOrderAccess.split(',');
        if (access.includes(equipmentType)) {
          return true;
        }
      }
    }
    return false;
  }
}

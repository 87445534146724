import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DigitalTwinNavRoutingModule } from './digital-twin-nav-routing.module';
import { SceneSimulatorComponent } from './scene-simulator/scene-simulator.component';
import { SimulationInformationComponent } from './simulation-information/simulation-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TurnaroundTimeComponent } from './simulation-information/turnaround-time/turnaround-time.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GaugeChartModule } from 'angular-gauge-chart';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ColorCodedBarPieModule } from 'src/app/operations-dashboard/components/color-coded-bar-pie/color-coded-bar-pie.module';
import { PortParkingComponent } from './simulation-information/port-parking/port-parking.component';
import { MtbfComponent } from './simulation-information/mtbf/mtbf.component';
import { TurnAroundComponent } from './simulation-information/turn-around/turn-around.component';
import { ChartsModule } from 'ng2-charts';
import { ScenarioSimulationComponent } from './scene-simulator/scenario-simulation/scenario-simulation.component';
import { OverstayTruckComponent } from './simulation-information/overstay-truck/overstay-truck.component';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    SceneSimulatorComponent,
    SimulationInformationComponent,
    TurnaroundTimeComponent,
    PortParkingComponent,
    MtbfComponent,
    TurnAroundComponent,
    ScenarioSimulationComponent,
    OverstayTruckComponent
  ],
  imports: [
    CommonModule,
    DigitalTwinNavRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    MatPaginatorModule,
    GaugeChartModule,
    MatSortModule,
    MatButtonModule,
    MatTooltipModule,
    IconSpriteModule,
    MatSlideToggleModule,
    ColorCodedBarPieModule,
    MatSlideToggleModule,
    ChartsModule,
  ]
})
export class DigitalTwinNavModule { }

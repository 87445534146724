
export const MAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 15,
  PORT_MINI_MAP_ZOOM: 16,
  MULTI_TERMINAL_ZOOM: 2.5,
};

export const MARKER_DETAILS: any = {
  VIEW_MAP: {
    latitude: 39.461044888828326,
    longitude: -0.33032428179620865
  },
  VIEWMAP_MARK1: {
    latitude: 39.461061198313445,
    longitude: -0.330327240750222
  },
  VIEWMAP_MARK2: {
    latitude: 39.460591376331934,
    longitude: -0.3265543488271305
  },
  VIEWMAP_MARK3: {
    latitude: 39.45933223778451,
    longitude: -0.3257267467279519
  },
  VIEWMAP_MARK4: {
    latitude: 39.46091085562449,
    longitude: -0.32253804452113854
  },
  VIEWMAP_MARK5: {
    latitude: 39.461061198313445,
    longitude: -0.3195197309825062
  },
  VIEWMAP_MARK6: {
    latitude: 39.46032393637407,
    longitude: -0.3096730528069429
  },
  VIEWMAP_MARK7: {
    latitude: 39.4656716846618,
    longitude: -0.3099709805138673
  },
  VIEWMAP_MARK8: {
    latitude: 39.209532808644155,
    longitude: 2.1493343673221545
  },
  VIEWMAP_MARK9: {
    latitude: 38.70942315747871,
    longitude: 3.377180327634562
  },
  VIEWMAP_MARK10: {
    latitude: 38.276902563496094,
    longitude: 9.117407919422732
  },
  VIEWMAP_MARK11: {
    latitude: 38.71646780795004,
    longitude: 14.865315353344727
  },
  VIEWMAP_MARK12: {
    latitude: 38.68680623086462,
    longitude: 15.246411346999935
  },
  VIEWMAP_MARK13: {
    latitude: 38.44136065561622,
    longitude: 15.899225380093753
  },
  VIEWMAP_MARK14: {
    latitude: 38.46097405567875,
    longitude: 15.90667667037863
  },
  VIEWMAP_MARK15: {
    latitude: 38.47016691518871,
    longitude: 15.914060152693793
  },

  START_LINE: {
    latitude: 38.44136065561622,
    longitude: 15.899225380093753
  },
  STARTLINE_MARK1: {
    latitude: 38.47025488080891,
    longitude: 15.913883561838134
  },
  STARTLINE_MARK2: {
    latitude: 38.45749224734905,
    longitude: 15.904991668841006
  },
  STARTLINE_MARK3: {
    latitude: 38.44243799173765,
    longitude: 15.898418731016164
  },
  STARTLINE_MARK4: {
    latitude: 38.44599736301794,
    longitude: 15.880216154521747
  },
  STARTLINE_MARK5: {
    latitude: 38.25678731207273,
    longitude: 15.666465276697636
  },
  STARTLINE_MARK6: {
    latitude: 38.227144984353544,
    longitude: 15.599068378270601
  },
  STARTLINE_MARK7: {
    latitude: 38.0993192560899,
    longitude: 15.574839531042102
  },
  STARTLINE_MARK8: {
    latitude: 38.00897663957022,
    longitude: 15.539363454412808
  },
  STARTLINE_MARK9: {
    latitude: 37.781578316670135,
    longitude: 15.709969465243205
  },
  STARTLINE_MARK10: {
    latitude: 37.770470530824184,
    longitude: 16.16644122241101
  },
  STARTLINE_MARK11: {
    latitude: 39.729161142064726,
    longitude: 19.18527568247876
  },
  STARTLINE_MARK12: {
    latitude: 41.059207017805846,
    longitude: 18.21099179912423
  },
  STARTLINE_MARK13: {
    latitude: 41.719064787814176,
    longitude: 17.32524569357571
  },
  STARTLINE_MARK14: {
    latitude: 42.46902388689432,
    longitude: 16.325675144741098
  },
  STARTLINE_MARK15: {
    latitude: 44.87469152538105,
    longitude: 13.152705952862277
  },
  STARTLINE_MARK16: {
    latitude: 45.55895628623364,
    longitude: 13.450537822690848
  },
  END_LINE: {
    latitude: 45.63118956,
    longitude: 13.76000055
  }
};

export const AZURE_MAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },

  PORT_MAP_ZOOM: 15,
  PORT_MINI_MAP_ZOOM: 16,
  MULTI_TERMINAL_ZOOM: 2.5,
};

export const GENMAP = {
  LATITUDE_CENTER: 54.978638,
  LONGITUDE_CENTER: -1.446235,
  DEFAULT_MAP_TYPE_ID: 'roadmap',
  MAP_TYPE_IDS: {
    ROAD_MAP: 'roadmap',
    HYBRID: 'hybrid'
  },
  PORT_MAP_ZOOM: 20,
  PORT_MINI_MAP_ZOOM: 16,
  MULTI_TERMINAL_ZOOM: 10,
};

import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { EquipmentDetail } from 'src/app/shared/models';
import { ROUTER_LINK } from 'src/app/shared/constants/router-link.constant';
import { EquipmentJobOrderModel } from 'src/app/shared/models/job-order.model';
import { selectEquipmentJobOrder } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentJobOrder } from 'src/app/shared/state/operations/operations.action';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';


@Component({
  selector: 'app-job-order',
  templateUrl: './job-order.component.html',
  styleUrls: ['./job-order.component.scss']
})
export class JobOrderComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator', { static: true })
  public paginator: MatPaginator;

  jobOrder$: Observable<EquipmentJobOrderModel> = this.store.select(selectEquipmentJobOrder);
  public workOrderList = [];
  public dataSource = new MatTableDataSource<EquipmentJobOrderModel>();
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noWorkOrderMessage: string;
  public routerLinkEquipment = `/${ROUTER_LINK.EQUIPMENT}/`;
  public routerLinkMaitenance = `/${ROUTER_LINK.MAINTENANCE}/`;
  public chartTypes = { ...CHART.TYPE };
  public craneStatus = { ...EQUIPMENT.EQUIPMENT_STATUS };
  public equipmentTypes = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public craneColumns = ['containerId', 'activity', 'pod', 'pol', 'status', 'completionTime'];
  public rtgColumns = ['containerId', 'activity', 'pod', 'pol', 'status', 'completionTime'];
  public tugboatColumns = ['vesselName', 'time', 'activity', 'completionTime', 'status'];
  private dateTimeFormat = environment.dateTimeFormat;
  private dateFormat = environment.dateFormat;
  private timezone = environment.timeZone;
  private dateToday = this.datePipe.transform(new Date(), this.dateTimeFormat, this.timezone);
  portId: string;
  public equipmentId: string;
  public equipmentName: string;
  public equipmentType: string;
  public equipmentStatus: string;
  public maintenanceStatus: boolean;
  public jobOrderDate: any;
  public operatorOrPilotName = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public utilizationRate = 0;
  public availability = 0;
  public lastMaintenanceDate = MESSAGE_DISPLAY.N_A;
  public lastMaintenanceDaysAgo = '';
  public maintenanceDescription: string;
  public thresholdStatus = { ...THRESHOLD.STATUS };
  public thresholdValues = { ...THRESHOLD.VALUE };
  public utilizationRateThresholdStatus = this.thresholdStatus.LOW;
  public availabilityThresholdStatus = this.thresholdStatus.LOW;
  public pageIndex = 0;
  public pageSize = 5;
  public noWorkOrder = true;
  public tabindex = -1;
  statusMessage: string = '';
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private dialogRef: MatDialog,
    private roundPipe: RoundPipe,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentDetail,
  ) {
    this.portId = this.data.port_id;
    this.equipmentId = this.data.equipment_id;
    this.equipmentName = this.data.equipment_name;
    this.equipmentType = this.data.equipment_type;
    this.equipmentStatus = this.data.status;
    this.maintenanceStatus = this.data.maintenance_status;
  }

  ngOnInit() {

    this.store.dispatch(getEquipmentJobOrder({ portId: this.portId, equipmentId: this.equipmentId }));

    switch (this.equipmentType) {
      case this.equipmentTypes.TUGBOAT: {
        this.noWorkOrderMessage = MESSAGE_DISPLAY.NO_WORK_ORDER_TUGBOAT;
        break;
      }
      case this.equipmentTypes.CRANE: {
        this.noWorkOrderMessage = MESSAGE_DISPLAY.NO_WORK_ORDER_CRANE;
        break;
      }
      case this.equipmentTypes.RTG: {
        this.noWorkOrderMessage = MESSAGE_DISPLAY.NO_WORK_ORDER_RTG;
        break;
      }
      default: {
        this.noWorkOrderMessage = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
        break;
      }
    }
  }



  ngAfterViewInit(): void {
    this.jobOrder$.subscribe((jobOrder) => {
      if (jobOrder) {
        this.initializeJobOrder(jobOrder);
      } else {
        this.operatorOrPilotName = this.noDataToDisplay;
        this.jobOrderDate = null;
        this.noWorkOrder = true;
        this.lastMaintenanceDaysAgo = this.noDataToDisplay;
        this.utilizationRate = 0;
        this.availability = 0;
      }
    })
  }

  initializeJobOrder(data: EquipmentJobOrderModel) {
    const jobOrderObj = data.job_order;
    this.jobOrderDate = jobOrderObj.job_order_date;

    // IDP-76 > IDP-12
    // Work Order Table and Operator's Name in Tugboat Job Order Pop-up from Overview Page
    if (jobOrderObj.operator_name === undefined) {
      this.operatorOrPilotName = this.noDataToDisplay;
    } else if (jobOrderObj.operator_name === null) {
      this.operatorOrPilotName = this.noDataToDisplay;
    } else if (jobOrderObj.operator_name === '') {
      this.operatorOrPilotName = this.noDataToDisplay;
    } else {
      this.operatorOrPilotName = jobOrderObj.operator_name;
    }

    this.workOrderList = jobOrderObj.work_order_list;


    const allStatusesAreTrue = jobOrderObj.work_order_list.every((hit) => hit.status);

   

    if (this.workOrderList.length !== 0) {
      this.dataSource.data = this.workOrderList;
     

      this.dataSource.paginator = this.paginator;
      this.noWorkOrder = false;
    } else {
      this.noWorkOrder = true;
    }

    // IDP-73 > IDP-61
    // Utilization and Availability Progress Bars in Crane Job Order Pop-up from Dashboard Page
    this.utilizationRate = jobOrderObj.utilization_rate;
    this.availability = jobOrderObj.availability;

    // IDP-73 > IDP-62
    // Maintenance Brief Section in Crane Job Order Pop-up from Dashboard PageJO
    const maintenanceBrief = jobOrderObj.maintenance_brief;
    this.lastMaintenanceDate = maintenanceBrief.last_maintenance;
    this.maintenanceDescription = maintenanceBrief.maintenance_description;
    // validate if last maintenance date has value
    if (this.lastMaintenanceDate) {
      const dateToday = new Date(this.dateToday);
      const lastMaintenanceDay = new Date(this.lastMaintenanceDate);
      const daysAgo = dateToday.getTime() - lastMaintenanceDay.getTime();
      this.lastMaintenanceDaysAgo = `(${this.msToDaysConverterTool(daysAgo)} days ago)`;
    } else {
      this.lastMaintenanceDaysAgo = this.noDataToDisplay;
    }
  }

  msToDaysConverterTool(ms: number) {
    if (isNaN(ms)) {
      return 0;
    }

    const sec = Math.floor(ms / 1000);
    const min = Math.floor(sec / 60);
    const hr = Math.floor(min / 60);
    const day = Math.floor(hr / 24);
    return day;
  }

  closeDialog() {
    this.dialogRef.closeAll();
  }
}

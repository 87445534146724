import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { OperationModel } from "../../shared/models/operations.model";
import {
  getOperation,
  resetOperationMngmt,
} from "../../shared/state/operations/operations.action";
import { selectOperation } from "../../shared/state/operations/operations.selectors";
import * as atlas from "azure-maps-control";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.scss"],
})
export class OperationsComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild("map", { static: true }) mapElement: ElementRef;
  @Input() selectedPortId: string;

  public operation$: Observable<OperationModel> =
    this.store.select(selectOperation);
  public map: atlas.Map;
  public markers: atlas.HtmlMarker[] = [];
  public canvasWidth = 170;
  public needleValue = 75;
  public centralLabel = "";
  public name = "Total Movement Per Hour";
  public bottomLabel = "750 MPH";
  public options = {
    hasNeedle: true,
    needleColor: "gray",
    needleUpdateSpeed: 1000,
    arcColors: ["#e74c3c", "orange", "#27ae60"],
    arcDelimiters: [25, 35],
    rangeLabel: ["0", "100000"],
    needleStartValue: 61,
  };
  total_movement_per_hour = 750;
  public latlng = [
    { name: "Valencia", lat: 39.4413971, lng: -0.3276083 },
    { name: "Gioaia Tauro", lat: 38.4576, lng: 15.9084 },
    { name: "Trieste", lat: 45.63118956, lng: 13.76000055 },
  ];

  constructor(private store: Store) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.map = new atlas.Map(this.mapElement.nativeElement, {
      center: [5.4076083, 43.4413971],
      zoom: 2,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapSubcriptionKey,
      },
      showFeedbackLink: false,
      showLogo: false,
      language: "en-US",
    });
    this.updateMap()
    //
    // this.operation$.subscribe((data) => {
    //   if (data) {
    //     this.updateMap(data.ports);
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (isValidChange) {
      if (this.selectedPortId && this.selectedPortId !== "Select Port") {
        this.store.dispatch(getOperation({ portsId: this.selectedPortId }));
      } else {
        this.clearMap();
      }
    }
  }

  ngOnDestroy(): void {
    this.clearMap();
    this.store.dispatch(resetOperationMngmt());
  }

  clearMap() {
    this.map.markers.clear();
    this.markers = [];
  }

  //API READY TO RETURN WHEN DATA AVAILABLE
  updateMap() {
    this.clearMap();

    const imageUrl1 = "assets/icons/red_marker.png";

    this.latlng.forEach((port) => {
      const marker = new atlas.HtmlMarker({
        position: [port.lng, port.lat],
        htmlContent: `<img src="${imageUrl1}" alt="Marker">`,
      });

      const popup = new atlas.Popup({
        content: `<div style="padding-left:8px;padding-right:8px;margin:10px; font-weight:bold; font-size: 13px">${port.name}</div>`,
        position: [port.lng, port.lat],
        pixelOffset: [0, -30],
        closeButton: false,
      });

      this.map.markers.add(marker);
      this.markers.push(marker);

      marker.getElement().addEventListener("mouseover", () => {
        marker.togglePopup();
        popup.open(this.map);
        this.map.getCanvasContainer().style.cursor = "pointer";
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.close();
        this.map.getCanvasContainer().style.cursor = "grab";
      });
    });
  }

  //API READY TO RETURN WHEN DATA AVAILABLE
  // updateMap(ports: any[]) {
  //   this.clearMap();

  //   const imageUrl1 = "assets/icons/red_marker.png";

  //   ports.forEach((port) => {
  //     const marker = new atlas.HtmlMarker({
  //       position: [port.longitude, port.latitude],
  //       htmlContent: `<img src="${imageUrl1}" alt="Marker">`,
  //     });

  //     const popup = new atlas.Popup({
  //       content: `<div style="padding-left:8px;padding-right:8px;margin:10px; font-weight:bold; font-size: 13px">${port.port_name}</div>`,
  //       position: [port.longitude, port.latitude],
  //       pixelOffset: [0, -30],
  //       closeButton: false,
  //     });

  //     this.map.markers.add(marker);
  //     this.markers.push(marker);

  //     marker.getElement().addEventListener("mouseover", () => {
  //       marker.togglePopup();
  //       popup.open(this.map);
  //       this.map.getCanvasContainer().style.cursor = "pointer";
  //     });

  //     marker.getElement().addEventListener("mouseleave", () => {
  //       popup.close();
  //       this.map.getCanvasContainer().style.cursor = "grab";
  //     });
  //   });
  // }
}

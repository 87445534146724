import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { AverageTurnTimeModel } from 'src/app/shared/models/average-turn-time.model';
import { getAverageTurnTime } from 'src/app/shared/state/operations/operations.action';
import { selectAverageTurnTime } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-avg-turn-time',
  templateUrl: './avg-turn-time.component.html',
  styleUrls: ['./avg-turn-time.component.scss']
})
export class AvgTurnTimeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  averageTurnTime$: Observable<AverageTurnTimeModel> = this.store.select(selectAverageTurnTime);
  public canvasWidth = 300
  public needleValue = 0
  public centralLabel = ''
  public name = 'Gauge chart'
  public bottomLabel
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: CHART.DATA.TURN_TIME.ARC_COLORS,
    arcLabelFontSize: 16,
    arcDelimiters: CHART.DATA.TURN_TIME.DELIMITERS,
    rangeLabel: [`${CHART.DATA.TURN_TIME.RANGE_LABEL.start}`, `${CHART.DATA.TURN_TIME.RANGE_LABEL.end}`],
    needleStartValue: 0,
  }
  public arcLabel = CHART.DATA.TURN_TIME.DELIMITERS[1]
  public mnAvgTurnTime;
  public avgYellow;
  public avgRed;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getAverageTurnTime({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.averageTurnTime$.subscribe((data) => {
      if (data) {
        this.initializeAvgTurnTimeData(data);
        this.noData = false;
      } else {
        this.noData = true;
      }
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getAverageTurnTime({ portId: this.selectedPortId }));
      }
    }
  }

  initializeAvgTurnTimeData(data: AverageTurnTimeModel) {
    // const ms = parseInt(this.data.avgTurnTime30Days) * 60 * 1000
    // const converted = this.obtService.formatMilliseconds(ms, true)
    // const tmphr = converted.slice(0, -6)
    // const tmpmn = converted.slice(-5, -3)

    // this.mnAvgTurnTime = converted.length <= 5 ? 0 : `${tmphr} hr ${tmpmn} min`
    this.mnAvgTurnTime = data.avg_turn_time_30_days
    this.avgYellow = data.yellow;
    this.avgRed = data.red;

    this.needleValue = data.avg_turn_time_today == null || data.avg_turn_time_today < 1 ? 0 : (data.avg_turn_time_today / 120) * 100;
    this.bottomLabel = data.avg_turn_time_today == null || data.avg_turn_time_today < 1 ? 0 : (data.avg_turn_time_today).toFixed(2);

    this.options.arcDelimiters = this.getDelimiters(CHART.DATA.TURN_TIME.DELIMITERS);
  }

  getDelimiters(data) {
    const endval = CHART.DATA.TURN_TIME.RANGE_LABEL.end;
    let rawValues = [];

    data.map(item => {
      // rawValues.push((item / endval) * 100)
      const comp = (item / endval) * 100
      if (comp > 100) {
        rawValues.push(99.99)
      } else {
        rawValues.push(comp)
      }
    });

    return rawValues;
  }
}

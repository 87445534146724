import { Component, OnInit, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { EquipmentDetailsModel } from 'src/app/shared/models';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { ROUTER_LINK } from 'src/app/shared/constants/router-link.constant';
import { PAGINATOR } from 'src/app/shared/constants/paginator.constant';
import { Store } from '@ngrx/store';
import { selectEquipmentList } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentList } from 'src/app/shared/state/operations/operations.action';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { ICON } from 'src/assets/icons/assets.constant';


@Component({
  selector: 'app-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.scss']
})
export class EquipmentTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  selectedPortId: string;

  equipmentList$: Observable<EquipmentDetailsModel[]> = this.store.select(selectEquipmentList);
  dataSource = new MatTableDataSource<EquipmentDetailsModel>();
  columnHeaders = ['craneId', 'craneLocation', 'craneLocationName', 'operator', 'craneBreakStatus'];
  public pageIndex = PAGINATOR.EQUIPMENT.LIST.pageIndex;
  public pageSize = PAGINATOR.EQUIPMENT.LIST.pageSize;
  public pageSizeOptions = PAGINATOR.EQUIPMENT.LIST.pageSizeOptions;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public equipmentType = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public equipmentStatus = { ...EQUIPMENT.EQUIPMENT_STATUS };
  public equipmentStatusIcon = { ...ICON.EQUIPMENT_STATUS };
  public routerLink = ROUTER_LINK.EQUIPMENT;
  public routerLinkPerformance = `/${ROUTER_LINK.PERFORMANCE}/`;
  public routerLinkEfficiency = `/${ROUTER_LINK.EFFICIENCY}/`;
  public routerLinkelEctrification = `/${ROUTER_LINK.ELECTRIFICATION}/`;
  public envPage: string;
  public energy: boolean;

  constructor(private store: Store) { }

  ngOnInit() {
    this.envPage = 'dashboard';
    this.energy = false;
  }

  ngAfterViewInit() {
    this.equipmentList$.subscribe((equipmentList) => {
      if (equipmentList?.length) {
        this.createTable(equipmentList);
        this.getSortedEquipment(equipmentList);
      }
    })
  }
  getSortedEquipment(craneUtilization: EquipmentDetailsModel[]): EquipmentDetailsModel[] {
    const sortedData = craneUtilization.sort((a, b) => a.equipment_name.localeCompare(b.equipment_name));
    return sortedData;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEquipmentList({ portId: this.selectedPortId }));
      }
    }
  }

  createTable(data: EquipmentDetailsModel[]) {
    this.dataSource.data = data;
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PortModel, ToggleSettings } from 'src/app/shared/models';
import { getPort } from 'src/app/shared/state/port/port.actions';
import { selectPorts } from 'src/app/shared/state/port/port.selectors';

//to be removed
import * as operationsSettings from 'src/app/shared/mock-data/operations-settings.json';
import { getEquipmentList, setEquipmentSelectedPortId } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, AfterViewInit {
  public ports$ = this.store.select(selectPorts);
  public selectedPortId: string;
  showOptions: boolean = false;
  isArrowUpVisible: boolean = false;
  public selectedOption: string = "Select Port";
  public isProductivity: boolean;
  public isBerthing: boolean;
  public isMap: boolean;
  public isOperating: boolean;
  public isEquipment: boolean;
  public isFirstDiv: boolean;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getPort());
    this.getOperationSettings();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 1) {
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }

  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;
    this.store.dispatch(setEquipmentSelectedPortId({ portId: this.selectedPortId }));
    this.store.dispatch(getEquipmentList({ portId: this.selectedPortId }));
  }

  showCheckboxes() {
    if ((this.showOptions = true)) {
      this.isArrowUpVisible = true;
    } else if ((this.showOptions = false)) {
      this.isArrowUpVisible = false;
    }
  }

  handleCheckboxClick(port: any): void {
    this.selectedOption = port.port_name;
    this.selectedPortId = port.port_id;
    this.store.dispatch(getEquipmentList({ portId: this.selectedPortId }));
  }

  handleMouseLeave() {
    this.showOptions = false;
    this.isArrowUpVisible = false;
  }

  getOperationSettings() {
    const responseList: ToggleSettings[] = operationsSettings.data.data;
    responseList.map((data: ToggleSettings, index) => {
      switch (data.name) {
        case 'port_productivity':
          this.isProductivity = data.isEnabled;
          break;
        case 'berthing_status':
          this.isBerthing = data.isEnabled;
          break;
        case 'port_map':
          this.isMap = data.isEnabled;
          break;
        case 'operating_status':
          this.isOperating = data.isEnabled;
          break;
        case 'equipment_status':
          this.isEquipment = data.isEnabled;
          break;
      }
      if (this.isProductivity == false && this.isBerthing == false) {
        this.isFirstDiv = false;
      } else {
        this.isFirstDiv = true;
      }
    });
  }
}

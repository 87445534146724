import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { HistoricalMaintenanceModel } from 'src/app/shared/models/historical-maintenance.model';
import { getHistoricalMaintenance } from 'src/app/shared/state/operations/operations.action';
import { selectHistoricalMaintenance } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-historical-maintenance',
  templateUrl: './historical-maintenance.component.html',
  styleUrls: ['./historical-maintenance.component.scss']
})
export class HistoricalMaintenanceComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  historicalMaintenance$: Observable<HistoricalMaintenanceModel[]> = this.store.select(selectHistoricalMaintenance);
  public dataSource = new MatTableDataSource<HistoricalMaintenanceModel>();
  public columnHeaders = ['woNumber', 'description', 'owner', 'workOrderType', 'timeStart', 'timeFinished'];
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  noData = false;
  public pageIndex = 0;
  public pageSize = 10;;

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getHistoricalMaintenance({ portId: this.portId, equipmentId: this.equipmentId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.historicalMaintenance$.subscribe((historicalMaintenance) => {
        if (historicalMaintenance?.length) {
          this.initializeHistoricalMaitenanceTable(historicalMaintenance);
        }
      });
    }, 0);
  }

  initializeHistoricalMaitenanceTable(data: HistoricalMaintenanceModel[]) {
    this.dataSource.data = data;
  }
}

import { Component, OnInit ,ComponentFactoryResolver } from '@angular/core';

@Component({
  selector: 'app-corporate-goal',
  templateUrl: './corporate-goal.component.html',
  styleUrls: ['./corporate-goal.component.scss']
})
export class CorporateGoalComponent implements OnInit {

  isArrowUpVisible = false;
  isDropdownVisible: boolean = false;
  selectedOption = 'Carbon Management';
  activeSelector: string;

  

  constructor(private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
  }

  showCheckboxes(): void {
    this.isArrowUpVisible = !this.isArrowUpVisible;
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  handleMouseLeave(): void {
    this.isArrowUpVisible = false;
    this.isDropdownVisible = false;
  }

  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;7
  }

  hideDropdown(): void {
    this.isDropdownVisible = false;
  }
  onActivated(component) {
    this.activeSelector =    this.resolver.resolveComponentFactory(component.constructor).selector;    
  }
  

  


  selectOption(option: string) {
    this.selectedOption = option;
}
}

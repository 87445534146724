import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomMatPaginatorIntl } from '../app/shared/custom-mat-paginator-intl';
import {
  MsalModule,
  MsalGuard,
  MsalService,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE
} from "@azure/msal-angular";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel
} from "@azure/msal-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { AuthGuard } from './shared/guard/auth.guard';
import { LoginModule } from './login/login.module';
import { HttpClientInterceptor } from './shared/services/http-client-interceptor.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { portReducer } from './shared/state/port/port.reducer';
import { PortEffects } from './shared/state/port/port.effects';
import { userReducer } from './shared/state/user/user.reducer';
import { UserEffects } from './shared/state/user/user.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { operationReducer } from './shared/state/operations/operations.reducers';
import { OperationsEffects } from './shared/state/operations/operations.effects';
import { energyManagementReducer } from './shared/state/energy-management/energy-management.reducer';
import { EnergyManagementEffects } from './shared/state/energy-management/energy-management.effects';
import { environment } from 'src/environments/environment';
import { sustainabilityReducer } from './shared/state/sustainability/sustainability.reducer';
import { SustainabilityEffects } from './shared/state/sustainability/sustainability.effects';
import { WaterConservationEffects } from './shared/state/water-conservation/water-conservation.effects';
import { waterConservationReducer } from './shared/state/water-conservation/water-conservation.reducer';
import { WasteManagementEffects } from './shared/state/waste-management/waste-management.effects';
import { wasteManagementReducer } from './shared/state/waste-management/waste-management.reducer';
import { biodiverisityReducer } from './shared/state/biodiversity/biodiversity.reducer';
import { BiodiversityEffects } from './shared/state/biodiversity/biodiversity.effects';
import { socialResponsibilityReducer } from './shared/state/social-responsibility/social-responsibility.reducer';
import { SocialResponsibilityEffects } from './shared/state/social-responsibility/social-responsibility.effects';
import { CustomPipesModule } from './shared/custom-pipes/custom-pipes.module';
import { EquipmentIconModule } from './operations-dashboard/components/equipment-icon/equipment-icon.module';
import { ColorCodedBarPieModule } from './operations-dashboard/components/color-coded-bar-pie/color-coded-bar-pie.module';
import { PortAuthorityEffects } from './shared/state/port-authority/port-authority.effects';
import { portauthorityReducer } from './shared/state/port-authority/port-authority.reducers';



const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  /* for development
  return new TranslateHttpLoader(
    http,
    '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
    '.json'
  );  */
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // ConnPorts Entra
      clientId: 'ab9ac9f9-6074-4162-a65c-8e0267f5cafc',
      authority: 'https://login.microsoftonline.com/5cb5ffd6-bd10-4f9b-841a-943d8b6709c7',
      // for local env
      //redirectUri: 'http://localhost:4200',
       redirectUri: environment.REDIRECT_URI,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    StoreModule.forRoot({
      user: userReducer,
      ports: portReducer,
      operations: operationReducer,
      energy: energyManagementReducer,
      sustainability: sustainabilityReducer,
      waterConservation: waterConservationReducer,
      wasteManagement: wasteManagementReducer,
      biodiversity: biodiverisityReducer,
      socialResponsibility: socialResponsibilityReducer,
      portauthority: portauthorityReducer

    }),
    EffectsModule.forRoot([
      UserEffects,
      PortEffects,
      OperationsEffects,
      EnergyManagementEffects,
      SustainabilityEffects,
      WaterConservationEffects,
      WasteManagementEffects,
      BiodiversityEffects,
      SocialResponsibilityEffects,
      PortAuthorityEffects

    ]),
    //for debugging NgRX
    //test
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: !isDevMode(), // Restrict extension to log-only mode
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDgRfHv2rctz8ZwUUG9ArN40cjHlil2WYg' as string,
    } as LazyMapsAPILoaderConfigLiteral),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    AppRoutingModule,
    MsalModule,
    LoginModule,
    CustomPipesModule.forRoot(),
    EquipmentIconModule.forRoot(),
    ColorCodedBarPieModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    MsalService,
    AuthGuard,
    MsalGuard,
    MsalBroadcastService
  ],
  exports: [
    MatIconModule,
    MatListModule
  ],
  declarations: [
    AppComponent,
    
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PortModel } from 'src/app/shared/models';
import { routerTransition } from 'src/app/shared/router.animations';
import { setAnalyticsSelectedPortId } from 'src/app/shared/state/operations/operations.action';
import { getPort } from 'src/app/shared/state/port/port.actions';
import { selectPorts } from 'src/app/shared/state/port/port.selectors';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  animations: [routerTransition()]
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  ports$: Observable<PortModel[]> = this.store.select(selectPorts);
  selectedPortId: string;
  showOptions: boolean = false;
  isArrowUpVisible: boolean = false;
  selectedOption: string = "Select Port";
  activeButton: number = 0;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.checkActivatedRoute();
    this.store.dispatch(getPort());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 1) {
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }

  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;
    this.store.dispatch(setAnalyticsSelectedPortId({ portId: this.selectedPortId }));
  }

  showCheckboxes() {
    if ((this.showOptions = true)) {
      this.isArrowUpVisible = true;
    } else if ((this.showOptions = false)) {
      this.isArrowUpVisible = false;
    }
  }

  handleCheckboxClick(port: any): void {
    this.selectedOption = port.port_name;
    this.selectedPortId = port.port_id;

    this.store.dispatch(setAnalyticsSelectedPortId({ portId: this.selectedPortId }));
  }

  handleMouseLeave() {
    this.showOptions = false;
    this.isArrowUpVisible = false;
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  checkActivatedRoute() {
    const urlPaths = window.location.pathname.split('/');
    const activePath = urlPaths[urlPaths.length - 1];

    switch (activePath.toLocaleLowerCase()) {
      case 'overview':
        this.activeButton = 1;
        break;
      case 'alerts':
        this.activeButton = 2;
        break;
      case 'kpi':
        this.activeButton = 3;
        break;
      case 'yard':
        this.activeButton = 4;
        break;
      default:
        this.activeButton = 0;
        break;
    }
  }

}

import { createReducer, on } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import { ApiStatus } from '../../enum/api-status.enum';
import { WasteMgntModel } from '../../models/waste-management.model';
import { getWasteManagement, getWasteManagementFailure, getWasteManagementSuccess } from './waste-management.action';



export interface WasteManagementState {
    wasteManagement: WasteMgntModel,
    error: ErrorResponse,
    status: 'pending' | 'loading' | 'error' | 'success'
}

export const initialState: WasteManagementState  = {
    wasteManagement: null,
    error: null,
    status: ApiStatus.Pending
}

export const wasteManagementReducer = createReducer(
    initialState,
    on(getWasteManagement, (state) => ({
        ...state,
        status: ApiStatus.Loading,
        error: null
    })),
    on(getWasteManagementSuccess, (state, { wasteManagement }) => ({
        ...state,
        wasteManagement: wasteManagement ,
        status: ApiStatus.Success,
        error: null
    })),
    on(getWasteManagementFailure, (state, { error }) => ({
        ...state,
        status: ApiStatus.Error,
        error: error,
    })),
);
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalTwinNavComponent } from './digital-twin-nav.component';
import { SceneSimulatorComponent } from './scene-simulator/scene-simulator.component';
import { SimulationInformationComponent } from './simulation-information/simulation-information.component';

const routes: Routes = [
  {
    path: "",
    component: DigitalTwinNavComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "scene-simulator",
        pathMatch: "full"
      },
      {
        path: "scene-simulator",
        component: SceneSimulatorComponent,
        data: { breadcrumb: "Scene Simulator" }
      },
      {
        path: "simulation-information",
        component: SimulationInformationComponent,
        data: { breadcrumb: "Simulation Information" }
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DigitalTwinNavRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vessel-info',
  templateUrl: './vessel-info.component.html',
  styleUrls: ['./vessel-info.component.scss']
})
export class VesselInfoComponent implements OnInit {
  public activeButton: number = 1;
  constructor() { }

  ngOnInit(): void {
  }
  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }
}

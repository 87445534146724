import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as atlas from "azure-maps-control";
import { environment } from "src/environments/environment";
import { TitleCasePipe } from "@angular/common";
import { MAP } from 'src/app/shared/constants/map.constant';
import { RAVENNAMAP } from 'src/app/shared/constants/authmap.constant';
import { EquipmentDetail } from 'src/app/shared/models';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { ICONS } from 'src/app/shared/constants/icons.contant';
import { ROUTER_LINK } from 'src/app/shared/constants/router-link.constant';
import { JobOrderComponent } from 'src/app/operations-dashboard/components/job-order/job-order.component';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';


//to be removed
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';
import { data } from "src/app/shared/mock-data/ravenna-coordinates.json";

@Component({
  selector: 'app-ravenna-map-icon',
  templateUrl: './ravenna-map-icon.component.html',
  styleUrls: ['./ravenna-map-icon.component.scss']
})
export class RavennaMapIconComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("map", { static: true })
  mapElement: ElementRef;

  @ViewChild("markerContainer", { static: false })
  markerContainer: ElementRef;

  @ViewChild("icon", { static: true })
  icon: ElementRef;

  @Input()
  public zoom: number = RAVENNAMAP.MULTI_TERMINAL_ZOOM;

  @Input()
  public operatorNameEnabled = false;

  @Input()
  public equipmentList: any[] = [];

  public markers: atlas.HtmlMarker[] = [];
  public map: atlas.Map;

  public latitude: number = feConfig.PortMapLatCenter;
  //public longitude: number =  MAP.LONGITUDE_CENTER;
  public longitude: number = feConfig.PortMapLonCenter;
  public mapTypeIds = { ...MAP.MAP_TYPE_IDS };
  public mapTypeId: string = MAP.DEFAULT_MAP_TYPE_ID;
  private assetObs: Observable<any> = new Observable();
  private assetSubs: Subscription = new Subscription();
  // public zoom_multiport = RAVENNAMAP.MULTI_TERMINAL_ZOOM;
  public latlng: any[] = [];
  public longitudeV = 12.2525499;
  public portRegionCode: number;
  public portCode: string;
  public latitudeV = 44.4724593;
  lat: Number = this.latitudeV;
  lng: Number = this.longitudeV;
  public mapTypeControlOptions = {
    style: 'default',
    position: 'top-right'
  };
  public equipmentDataSelected = {};
  private _map: any;
  public mapIsReady = false;
  public equipmentType = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public equipmentStatus = { ...EQUIPMENT.EQUIPMENT_STATUS };
  public equipmentStatusIcon = { ...ICONS.EQUIPMENT_STATUS };
  public routerLink = ROUTER_LINK.EQUIPMENT;
  public portId: string;
  public start_end_mark: markerDetail[] = [];
  public equipmentIcons = {
    ...ICONS.EQUIPMENT_STATUS,
    MAINTENANCE: ICONS.EQUIPMENT_STATUS.MAINTENANCE,
  };

  hideData: boolean = false;

  // mapStyles: any[] = [
  //   {
  //     featureType: 'poi.business',
  //     stylers: [{ visibility: 'off' }]
  //   },
  //   {
  //     featureType: 'transit',
  //     stylers: [{ visibility: 'off' }]
  //   },
  //   {
  //     featureType: 'water',
  //     stylers: [{ visibility: 'on' }]
  //   },
  //   {
  //     featureType: 'road',
  //     stylers: [{ visibility: 'on' }]
  //   },
  //   {
  //     featureType: 'landscape',
  //     stylers: [{ visibility: 'on' }]
  //   }
  // ];
  constructor(
    private dialog: MatDialog,
    private titlecasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getPortAuthorityMemberGeoCoordinates(this.portCode, this.portRegionCode);
  }

  ngOnChanges() {
    if (this.equipmentList) {
      this.azureMap();
      this.mapIsReady = true;
    }
  }

  getPortAuthorityMemberGeoCoordinates(portCode: string, portRegionCode: number) {
    this.start_end_mark.push({
      latitude: data[0].geojson[0].latitude,
      longitude: data[0].geojson[0].longitude,
      port: data[0].portName,
      flag: false
    });

    this.azureMap();
  };

  azureMap() {
    if (this.mapElement) {
      const map = new atlas.Map(this.mapElement.nativeElement, {
        center: [this.lng, this.lat],
        zoom: 13,
        style: "road_shaded_relief",

        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: environment.azureMapSubcriptionKey,
        },
        showFeedbackLink: false,
        showLogo: false,
        language: "en-US",
      });

      const markerSB = [];

      this.equipmentList.forEach((port) => {
        const markerPosition = { position: [port.longitude, port.latitude] };
        const latitude = markerPosition.position[0];
        const longitude = markerPosition.position[1];

        const marker = new atlas.HtmlMarker({
          position: [latitude, longitude],
          htmlContent: this.getHTMLContent(
            port.status,
            port.equipmentType,
            port.maintenanceStatus,
            port.equipmentId,
            port.currentOperator
          ),
        });

        const popup = new atlas.Popup();

        marker.getElement().addEventListener("click", () => {
          // popup.setOptions({
          //     // content:
          //     //     '<div style="padding:10px;font-weight: bold"> ' +
          //     //     port.equipmentId +
          //     //     '<div style="padding-top: 2px; font-weight: bold"> ' +
          //     //     port.currentOperator +
          //     //     "</div></div>",

          //     position: [latitude, longitude],
          //     pixelOffset: [0, -30],
          // });

          // popup.open(map);
          this.clickViewDetails(port); // Call the clickViewDetails function when marker is clicked
        });

        markerSB.push(marker);
        map.markers.add(marker);
      });

      map.events.add("load", () => {
        map.getCanvasContainer().style.cursor = "grab";
      });
    }
  }

  getHTMLContent(
    equipmentStatus: string,
    equipmentType: string,
    workStatus: boolean,
    equipmentId: string,
    currentOperator: string
  ): string {
    const equipmentIcon = this.getIconSrc(equipmentStatus, equipmentType);

    if (workStatus) {
      if (this.operatorNameEnabled) {
        return `
          <div class="equipment-status-icon" style="text-align: center; position: relative;">
            <img style="width: 50px; height: 50px; position: relative;" src="${equipmentIcon}" class="equipment-status-img"/>
            <img style="width: 50px; height: 50px; padding-top: 30px; padding-right: 25px; z-index: 5;" src="${this.equipmentIcons.MAINTENANCE}" class="equipment-status-maintenance" />
          </div>
          <div class="marker-details" style="text-align: center;">
            <div class="marker-equipment">${equipmentId}</div>
            <div class="marker-operator">${currentOperator ? currentOperator : ""}</div>
          </div>`;
      } else {
        return `
          <div class="equipment-status-icon" style="text-align: center;">
            <img style="width: 30px; height: 30px;" src="${equipmentIcon}" class="equipment-status-img"/>
            <img style="width: 30px!important; height: 30px!important;" src="${this.equipmentIcons.MAINTENANCE}" class="equipment-status-maintenance" />
          </div>
          <div class="marker-details" style="text-align: center;">
            <div class="marker-equipment">${equipmentId}</div>
          </div>`;
      }
    } else {
      if (this.operatorNameEnabled) {
        return `
          <div class="equipment-status-icon" style="text-align: center;">
            <img style="width: 50px; height: 50px;" src="${equipmentIcon}" class="equipment-status-img"/>
          </div>
          <div class="marker-details" style="text-align: center;">
            <div class="marker-equipment">${equipmentId}</div>
            <div class="marker-operator1">${currentOperator ? currentOperator : ""}</div>


          </div>`;
      } else {
        return `
          <div class="equipment-status-icon" style="text-align: center;">
            <img style="width: 50px; height: 50px;" src="${equipmentIcon}" class="equipment-status-img"/>
            <span class="marker-equipment" style="text-align: center;">${equipmentId}</span>
          </div>
          <div class="marker-details" style="text-align: center;">

          </div>`;
      }
    }
  }

  getIconSrc(equipmentStatus: string, equipmentType: string) {
    equipmentStatus = this.titlecasePipe.transform(equipmentStatus);

    let equipmentIcon;
    switch (equipmentStatus) {
      case EQUIPMENT.EQUIPMENT_STATUS.ACTIVE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].ACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.INACTIVE:
        equipmentIcon =
          this.equipmentIcons[equipmentType.toUpperCase()].INACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.IDLE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].IDLE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.NON_PRODUCTIVE:
        equipmentIcon =
          this.equipmentIcons[equipmentType.toUpperCase()].NON_PRODUCTIVE;
        break;
      default:
        equipmentIcon =
          this.equipmentIcons[equipmentType.toUpperCase()].INACTIVE;
        break;
    }

    return equipmentIcon;
  }

  getIcon() {
    this.equipmentList.push({
      status: "active",
      equipmentType: "crane",
      //  workStatus: true
    });
  }

  getEquipmentList() {
    this.equipmentList = this.equipmentList.map((data) => {
      if (data.currentOperator === undefined || data.currentOperator === '') {
        data.currentOperator = MESSAGE_DISPLAY.NO_OPERATOR_OR_PILOT.replace('.', '');
      }
      if (Number.isNaN(data.latitude)) {
        data.latitude = null;
      }
      if (Number.isNaN(data.longitude)) {
        data.longitude = null;
      }

      return data;
    });
  }


  changeTilt(map: any) {
    this._map = map;
    this._map.setTilt(40);
  }

  clickViewDetails(equipmentDetail: EquipmentDetail) {
    this.dialog.open(JobOrderComponent, {
      data: {
        ...equipmentDetail
      }
    });
  }


  onMapTypeIdChange(type) {
    this.mapTypeId = type;
  }
}


export interface markerDetail {
  latitude: number;
  longitude: number;
  port: string;
  flag: boolean;
}

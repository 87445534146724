import { Component, OnInit, ElementRef, ViewChild, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionManager } from "src/app/shared";
import { Route, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { GENMAP } from "src/app/shared/constants/authmap.constant";
import * as atlas from "azure-maps-control";
import { Map, AuthenticationType } from "azure-maps-control";
import { environment } from "src/environments/environment";
import { Store } from '@ngrx/store';
import { selectPortAuthorityMember, selectPortAuthorityMemberDetails } from "src/app/shared/state/port-authority/port-authority.selectors";
import { MapRegionListModel, PortMemberDetailsModel } from "src/app/shared/models/port-authority-models/map-region-list.model";
import { getportAuthMembers, getportAuthMembersDetails } from "src/app/shared/state/port-authority/port-authority.action";

@Component({
  selector: 'app-general-map',
  templateUrl: './general-map.component.html',
  styleUrls: ['./general-map.component.scss']
})
export class GeneralMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("map", { static: true }) mapElement!: ElementRef;
  portMember$: Observable<MapRegionListModel[]> = this.store.select(selectPortAuthorityMember)

  RegionCode = environment.regionCode;
  selectedPortId = environment.ravennaPortId;
  public zoom_multiport = GENMAP.MULTI_TERMINAL_ZOOM;
  public longitudeV = -347.788;
  public latitudeV = 44.32;
  // public longitudeT = 12.2686145;
  // public latitudeT = 45.4576;
  // public longitudeForli = 12.0416;
  // public latitudeForli = 44.2226;
  // public longitudeCesenatico = 12.39697400049701;
  // public latitudeCesenatico = 44.1999375247251;
  // public longitudeCervia = 12.351032140543964;
  // public latitudeCervia = 44.26325828467796;
  // public longitudeRavenna = 12.2512;
  // public latitudeRavenna = 44.4736;
  public latitude: number;
  public longitude: number;
  public geolatitude: number;
  public geolongitude: number;
  lat: Number = this.latitudeV;
  lng: Number = this.longitudeV;
  public portLng: any;
  public portLat: any;
  zoom: Number = 40;
  dir = undefined;
  public start_end_marks: markerDetail[] = [];
  public portRegionName: string;
  public portname: string;
  public location: string;
  public portDescription: string;
  public ravenna: string;
  public forli: string;
  public cesenatico: string;
  public cervia: string;
  public portKey: any[];
  public latlng: any[] = [];
  public portRavenna: string;
  public portForli: string;
  public portCesenatico: string;
  public portCervia: string;
  public portlocRavenna: string;
  public portlocForli: string;
  public portlocCesenatico: string;
  public portlocCervia: string;
  public portDesRavenna: string;
  public portDesForli: string;
  public portDesCesenatico: string;
  public portDesCervia: string;
  hideData: boolean = false;
  public portRegionCode: number = 4;
  public mapRes: any;
  private subscription: Subscription = new Subscription();

  constructor(private router: Router, private store: Store) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {

    this.portMember$.subscribe((data) => {
      if (data?.length > 0) {
        this.getPortAuthorityMapRegionMembers(data);

      }
    });
  }


  getGeoJson(data: MapRegionListModel[]) {

    if (data.length > 0) {
      this.portRegionName = data[0].port_region_name;

      this.portRavenna = data[0].port_name;
      this.portlocRavenna = data[0].location;
      this.portDesRavenna = data[0].port_description;
      
    } else {
      console.error('Data array is empty or not initialized');
    }

    if (data.length > 1) {
      this.portForli = data[1].port_name;
      this.portlocForli = data[1].location;
      this.portDesForli = data[1].port_description;
    } else {
      console.error('Data array Index 1 Empty');
    }

    if (data.length > 2) {
      this.portCesenatico = data[2].port_name;
      this.portlocCesenatico = data[2].location;
      this.portDesCesenatico = data[2].port_description;
    } else {
      console.error('Data array Index 2 Empty');
    }

    if (data.length > 2) {
      this.portCervia = data[3].port_name;
      this.portlocCervia = data[3].location;
      this.portDesCervia = data[3].port_description;
    } else {
      console.error('Data array Index 3 Empty');
    }

  }


  getPortAuthorityMapRegionMembers(data: MapRegionListModel[]) {
    data.forEach((port) => {

      let geojsonArray: Array<any> = [];
      if (Array.isArray(port.geojson)) {
        geojsonArray = port.geojson;
      } else if (port.geojson && typeof port.geojson === 'object') {

        geojsonArray = [port.geojson];
      } else {
        console.error('geojson is neither an array nor an object');
        return;
      }

      geojsonArray.forEach((geo) => {
        if (geo.latitude !== undefined && geo.longitude !== undefined) {
          this.latlng.push({
            latitude: geo.latitude,
            longitude: geo.longitude,
            port: port.port_name,
            flag: false,
          });
        } else {
          console.error('Invalid geo object:', geo);
        }
      });
    });

    this.azureMap();
    this.getGeoJson(data);



  }





  azureMap() {
    if (this.mapElement) {
      const map = new atlas.Map(this.mapElement.nativeElement, {
        center: [this.longitudeV, this.latitudeV],
        zoom: 9,
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: environment.azureMapSubcriptionKey,
        },
        language: "en-US",
      });

      map.events.add("ready", () => {
        this.latlng.forEach((port) => {
          const popup = new atlas.Popup({
            content: `<div style="padding:10px">${port.port}</div>`,
            pixelOffset: [0, -30],
          });

          const marker = new atlas.HtmlMarker({
            color: "red",
            position: [port.longitude, port.latitude],
            popup: popup,
          });

          map.markers.add(marker);

          marker.getElement().addEventListener("mouseover", () => {
            marker.togglePopup();
          });


          marker.getElement().addEventListener("mouseleave", () => {
            popup.close();
          });
        });
      });
    }
  }
  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

  onMouseOut(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.close();
  }

  ngOnInit() {
    this.store.dispatch(getportAuthMembers({ portRegionCode: this.RegionCode }));//for implemetation  api not available
    this.azureMap();

  }
}
export interface markerDetail {
  latitude: number;
  longitude: number;
  port: string;
  flag: boolean;
}
export interface latlng {
  lat: number;
  lng: number;
}
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
// import { routerTransition } from 'src/app/router.animations';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GateMovesData } from 'src/app/shared/models/yard-operations/gate-moves.model';
import { YardInventory } from 'src/app/shared/models/yard-operations/yard-inventory.model';
import { TruckData } from 'src/app/shared/models/yard-operations/outbound-trucks.model';
import { HazardousMaterial } from 'src/app/shared/models/yard-operations/hazardous-material.model';
import { YardInventoryChassis } from 'src/app/shared/models/yard-operations/yard-inventory-chassis.model';
import { YardInventoryAccessories } from 'src/app/shared/models/yard-operations/yard-inventory-accessories.model';
import { EmptiesInYard } from 'src/app/shared/models/yard-operations/empties-in-yard.model';
import { ContainerDwellTime } from 'src/app/shared/models/yard-operations/container-dwell-time.model';
import { APIs } from 'src/app/shared/constants/api.constant';
import { routerTransition } from 'src/app/shared/router.animations';
import { Store } from '@ngrx/store';
import { selectAnalyticsPortId } from 'src/app/shared/state/operations/operations.selectors';
import { Observable } from 'rxjs';


//to be removed
import { averageTurnTime } from 'src/app/shared/mock-data/avg-turn-time.json';
import { containerGateMoves } from 'src/app/shared/mock-data/container-gate-moves.json';
import { avgDwellTime } from 'src/app/shared/mock-data/avg-dwell-time.json';
import { materialDetails } from 'src/app/shared/mock-data/hazardous-material.json'


@Component({
  selector: 'app-yard',
  templateUrl: './yard.component.html',
  styleUrls: ['./yard.component.scss'],
  animations: [routerTransition()]
})
export class YardComponent implements OnInit, OnDestroy {

  analyticsPortId$: Observable<string> = this.store.select(selectAnalyticsPortId);
  visible = true
  public yardopsdata: any = []
  public gatemoves: GateMovesData[];
  public yardinventory_containers: YardInventory[];
  public outboundtrucks: TruckData[];
  public turntime;
  public scantime = 0;
  public vehicleInspectionStatus = {
    totalVehicles: 105,
    completed: 52,
    inProgress: 15,
    inQueue: 38
  };
  public hazardousmaterial: HazardousMaterial[];
  public yardinventory_chassis: YardInventoryChassis[];
  public yardinventory_accessories: YardInventoryAccessories[];
  public emptiesinyard: EmptiesInYard[];
  public containerdwelltime: ContainerDwellTime[];
  private intrvl;

  constructor(private store: Store) { }

  ngOnInit() {
    this.getData()

    this.scantime = 65;
    // this.intrvl = setInterval(() => {
    //   this.scantime = this.randomInteger(0, 180);
    // }, 5000);
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnDestroy() {
    // this.yardopsSubs.unsubscribe();
    if (this.intrvl) clearInterval(this.intrvl);
  }

  // fetchFBData(): Observable<any> {
  //   return this.http.get<any>(YARD_OPS.PATH)
  // }

  getData() {
    Object.entries(APIs.YARD_OPERATIONS).forEach(path => {
      // const queryParams = {'portId' : environment.portId};
      // console.log(path);
      // const api = CommonFunction.getStreamEndPoint(path[1], queryParams);
      // this.yardopsObs = this.myseService.getServerSentEvent(api, STREAM_EVENT.YARD_OPERATIONS);
      // this.yardopsObs =  this.http.get<any>(api)
      // this.yardopsSubs = this.yardopsObs.subscribe(data => {
      // console.log("Yard Data",data);
      // if (data.code === RESPONSE_CODE.OK) {
      //   if (data.message === 'Success') {
      //     if (data.tag === STREAM_TAG.GATE_MOVES) {
      this.gatemoves = containerGateMoves
      //     } else if (data.tag === STREAM_TAG.OUTBOUND_TRUCKS) {
      //       this.outboundtrucks = data.truckList
      //     } else if (data.tag === STREAM_TAG.YARD_INVENTORY_CONTAINERS) {
      //       this.yardinventory_containers = data.yardContainersTypeSize
      //     } else if (data.tag === STREAM_TAG.HAZARDOUS_MATERIAL) {
      this.hazardousmaterial = materialDetails
      //     }else if (data.tag === STREAM_TAG.YARD_INVENTORY_CHASSIS) {
      //       this.yardinventory_chassis = data.chassisList
      //     }else if (data.tag === STREAM_TAG.YARD_INVENTORY_ACCESSORIES) {
      //       this.yardinventory_accessories = data.yardAccessoriesType;
      //     } else if (data.tag === STREAM_TAG.EMPTIES_IN_YARD) {
      //       this.emptiesinyard = data.emptiesInYard
      //     } else if (data.tag === STREAM_TAG.TURN_TIME) {
      this.turntime = averageTurnTime;
      //     } else if (data.tag === STREAM_TAG.CONTAINER_DWELL_TIME) {
      this.containerdwelltime = avgDwellTime;
      //     }
      //   }
      // }
      // }, (error) => {
      //   this.yardopsSubs.unsubscribe()
      //   // this.getData()
      // })
    });
  }
}


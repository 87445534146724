import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { API } from '../constants/api.constant';


@Injectable({
  providedIn: 'root'
})
export class EnergyManagementService {
 private serviceUrl = environment.basePath + API.DASHBOARD;
  
  constructor(private http: HttpClient) { }
  
  getEnergyMngt(portsId?  : string):Observable<ApiResponse | ErrorResponse | any>{
    console.log('environment', environment.production);
    let params = portsId ? new HttpParams().set('port_ids', portsId): null;
    return this.http.get<ApiResponse | ErrorResponse | any>(`${this.serviceUrl}/energy-management`, { params: params })
  }
}

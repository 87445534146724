import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { CraneUtilizationHistoryModel, CraneUtilizationData } from 'src/app/shared/models/crane-utilization.model';
import { TugboatUtilization, TugboatUtilizationData } from 'src/app/shared/models/tugbout-utilization.model';
import { getCraneUtilizationHistory } from 'src/app/shared/state/operations/operations.action';
import { selectCraneUtilization } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-crane-utilization',
  templateUrl: './crane-utilization.component.html',
  styleUrls: ['./crane-utilization.component.scss']
})
export class CraneUtilizationComponent implements OnInit, AfterViewInit {
  @Input()
  portId: string;

  @Input()
  equipmentName: string;

  @Input()
  title: string;

  craneUtilization$: Observable<CraneUtilizationHistoryModel[]> = this.store.select(selectCraneUtilization);
  public chartData: any[];
  public chartLabels: string[] = [];
  public chartOptions: any;
  public chartColors: any[];
  public chartLegend: boolean;
  public chartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public noDataPie = true;
  public chartIsReady = false;
  public initialFilter = 0;
  public currentDate = new Date();
  public initialDateFilter = new Date();
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public equipmentIdList: string[] = [];
  public dateRangeError: boolean;
  public ArrActive: number[] = [];
  public ArrNonActive: number[] = [];
  public ArrIdle: number[] = [];
  public dropdownData: string[] = [];


  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private roundPipe: RoundPipe
  ) {
    datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 60);
    this.rangeFrom = this.datePipe.transform(this.initialDateFilter.setDate(this.initialDateFilter.getDate() - 7), 'yyyy-MM-dd');
    this.rangeTo = this.datePipe.transform(this.currentDate.setDate(this.currentDate.getDate() - 1), 'yyyy-MM-dd');

    this.getCraneUtilization();
  }

  ngAfterViewInit(): void {
    this.craneUtilization$.subscribe((craneUtilization) => {
      if (craneUtilization?.length) {
        this.initializeCraneUtilization(craneUtilization);
      }
    })
  }

  getCraneUtilization() {
    const startDate = this.datePipe.transform(this.rangeFrom, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.rangeTo, 'yyyy-MM-dd');

    this.store.dispatch(getCraneUtilizationHistory({ portId: this.portId, equipmentId: this.equipmentName, startDate: startDate, endDate: endDate }));
  }

  initializeCraneUtilization(data: CraneUtilizationHistoryModel[]) {
    const dateFormat2 = 'dd/MM';

    const responseList: CraneUtilizationHistoryModel[] = data;
    if (!responseList || responseList === undefined || responseList === null || responseList.length === 0) {
      this.noData = true;
    } else {
      const data = responseList.find((a) => a.equipment_name === this.equipmentName);

      if (data) {
        this.equipmentIdList.push(this.equipmentName);
        data?.daily_utilization_history.map((item: CraneUtilizationData) => {
          item.date_acquired = this.datePipe.transform(item.date_acquired, dateFormat2);

          if (!this.chartLabels.includes(item.date_acquired)) {
            this.chartLabels.push(item.date_acquired);
          }

          if (item.crane_idling) {
            this.ArrIdle.push(
              this.roundPipe.transform(item.crane_idling, '1.0-2')
            );
          } else {
            this.ArrIdle.push(0);
          }

          if (item.crane_unutilized) {
            this.ArrNonActive.push(
              this.roundPipe.transform(item.crane_unutilized, '1.0-2')
            );
          } else {
            this.ArrNonActive.push(0);
          }

          if (item.crane_utilization) {
            this.ArrActive.push(
              this.roundPipe.transform(item.crane_utilization, '1.0-2')
            );
          } else {
            this.ArrActive.push(0);
          }
        });

        this.chartData = [
          {
            type: 'bar',
            label: 'Active',
            data: this.ArrActive,
            stack: 'Stack 0',
          },
          {
            type: 'bar',
            label: 'Idle',
            data: this.ArrIdle,
            stack: 'Stack 0',
          },
          {
            type: 'bar',
            label: 'Non-Active',
            data: this.ArrNonActive,
            stack: 'Stack 0',
          },
        ];

        this.initializeChart();
        this.noData = false;
        this.chartIsReady = true;
      } else {
        this.noData = true;
      }
    }
  }


  initializeChart() {
    this.chartOptions = {
      scaleShowVerticalLines: true,
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      plugins: {
        labels: {
          fontColor: 'white',
          fontSize: 0,
        },
      },
      tooltips: {
        mode: 'nearest',
        callbacks: {
          label: (context, value) => {
            let val = context.value;
            return value.datasets[context.datasetIndex].label + ": " + val + '%'
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: 100,
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 10,
            callback: function (value) {
              return value + "%"
            }
          }
        }],
        xAxes: [{
          ticks: {
            fontFamily: CHART.FONTS.GOTHAM,
            fontSize: 10,
            callback: function (label: any) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            }
          }
        }]
      },
      options: {
        legend: {
          display: false
        }
      }
    };
    this.chartColors = [
      { backgroundColor: CHART.COLOR.BLUE },
      { backgroundColor: CHART.COLOR.RED },
      { backgroundColor: CHART.COLOR.GRAY },

    ];
    this.chartLegend = true;
    this.chartType = CHART.TYPE.BAR;
  }


  onFilterCrane(selected: string) {
    this.equipmentName = selected;
    this.chartIsReady = false;
    this.chartData = [''];
    this.ArrActive = [];
    this.ArrIdle = [];
    this.ArrNonActive = [];
    this.chartLabels = [];
    this.equipmentIdList = [];
  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate
    } else {
      this.rangeTo = selectedDate
    }

    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;


      this.getCraneUtilization();
    }
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }
}

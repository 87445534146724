import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-carbon-management',
  templateUrl: './carbon-management.component.html',
  styleUrls: ['./carbon-management.component.scss']
})
export class CarbonManagementComponent implements OnInit, AfterViewInit {
  public urlPaths = window.location.pathname.split('/');
  public activeButton: number = this.urlPaths[this.urlPaths.length - 1] === 'calculator' ? 1 : 2;
  public isButtonDisabled: boolean = this.activeButton === 2 ? false: true;


  constructor(private router: Router) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkActivatedRoute(event.urlAfterRedirects);
    });
  }

  toggleButton(buttonNumber: number) {
      this.activeButton = buttonNumber;
  }

  checkActivatedRoute(url: string) {
    const urlPaths = url.split('/');
    const activePath = urlPaths[urlPaths.length - 1];

    switch (activePath.toLocaleLowerCase()) {
      case 'calculator':
        this.activeButton = 1;
        break;
      case 'footprint':
        this.activeButton = 2;
        this.isButtonDisabled = false;
        break;
      default:
        this.activeButton = 1;
        break;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import 'chartjs-plugin-labels';


@Component({
  selector: 'app-usage-info',
  templateUrl: './usage-info.component.html',
  styleUrls: ['./usage-info.component.scss']
})
export class UsageInfoComponent implements OnInit {

  public pieChartData: number[] = [44, 56];
  public pieChartLabel: string[] = ['Idle', 'Active'];

  public pieChartType: string = 'pie';

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public pieChartColors: any[] = [
    { backgroundColor: ['#4472C4', '#EC7E31',] } 
  ];

  public pieChartOptions: any = {
    legend: {
      display: false,
      position: 'right',
      labels: {
        fontColor: 'white',
        fontSize: 20,
        text: ''
      }
    },
    plugins: {
      labels: {
        fontColor: 'white',
        // render: 'percentage', 
        fontSize: 16,
        precision: 2,
        render: function (args) {
          return args.label + '\n' + args.value + '%';
        }
      }
    }
  };



  constructor() { }

  ngOnInit(): void {

    var barOptions_stacked = {
      tooltips: {
        enabled: false
      },
      hover: {
        animationDuration: 0
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 0,

          },
          scaleLabel: {
            display: true
          },
          gridLines: {
            display: false 
          },
          stacked: true
        }],
        yAxes: [{
          gridLines: {
            display: false,
            color: "#fff",
            zeroLineColor: "#fff",
            zeroLineWidth: 0
          },
          ticks: {
            // fontFamily: "'Open Sans Bold', sans-serif",
            // fontSize: 11
          },
          stacked: true
        }]
      },
      legend: {
        display: true, // Display legend
        labels: {
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11
        }
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "13 px Open Sans";
          ctx.fillStyle = "#fff";
          ctx.text = '  Hours'
          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            Chart.helpers.each(meta.data.forEach(function (bar, index) {
              let data = dataset.data[index]; 
              if (i == 0) {
                ctx.fillText(data + ctx.text,  180, bar._model.y + 2);
              } else {
                ctx.fillText(data + ctx.text,  bar._model.x - 60, bar._model.y +2);
              }
            }), this)
          }), this);
        }
      },
      pointLabelFontFamily: "Quadon Extra Bold",
      scaleFontFamily: "Quadon Extra Bold",
    };

    var ctx = document.getElementById("Chart1") as HTMLCanvasElement;
    var myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: ["TUG 12", "TUG 09", "TUG 01",],
        datasets: [{
          label: 'Estimated Hours',
          data: [3, 3, 2.8],
          backgroundColor: "#4472C4",

        }, {
          label: 'Extended Hours',
          data: [1.2, 1.5,],
          backgroundColor: "#DF8548",

        },]
      },


      options: barOptions_stacked,
    });
  }

}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { SettingsLayoutRoutingModule } from './settings-layout-routing.module';
import { SettingsLayoutComponent } from './settings-layout.component';
import { SettingNavComponent } from './setting-nav/setting-nav.component';
import { MatButtonModule } from '@angular/material/button';
import { SubsSettingsComponent } from './setting-nav/dashboard-settings/subs-settings/subs-settings.component';

@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [SettingsLayoutComponent, SettingNavComponent,SubsSettingsComponent],
  imports: [
    CommonModule,
    SettingsLayoutRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    MatTabsModule,
    MatButtonModule

  ]
})
export class SettingsLayoutModule { }

import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-berth-regist',
  templateUrl: './berth-regist.component.html',
  styleUrls: ['./berth-regist.component.scss']
})
export class BerthRegistComponent implements OnInit {
  displayedColumns = ['position', 'name', 'weight', 'symbol', 'action'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
export interface Element {
  etd: any;
  eta: any;
  dateSub: any;
  symbol: any;

}

const ELEMENT_DATA: Element[] = [
  { eta: '3/15/2024 8:00', etd: '3/17/2024 18:00', dateSub: '3/13/2024 8:00', symbol: 'Submitted', },
  { eta: '3/20/2024 14:00', etd: '3/22/2024 20:00', dateSub: '3/18/2024 14:00', symbol: 'Submitted' },
  { eta: '3/18/2024 7:30', etd: '3/20/2024 16:45', dateSub: '3/16/2024 7:30', symbol: 'Confirmed' },
  { eta: '3/22/2024 6:00', etd: '3/24/2024 12:00', dateSub: '3/20/2024 6:00​', symbol: 'Confirmed' },
  { eta: '3/25/2024 9:00', etd: '3/27/2024 19:00', dateSub: '3/22/2024 9:00​', symbol: 'Confirmed' },
  { eta: '3/17/2024 10:15', etd: '3/19/2024 22:00​', dateSub: '3/15/2024 10:15​', symbol: 'Confirmed' },
  { eta: '3/23/2024 8:30', etd: '3/25/2024 17:00', dateSub: '3/22/2024 8:30', symbol: 'Confirmed' }
];

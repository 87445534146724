import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { feConfig } from '../../../shared/mock-data/fe-config.json'

@Component({
  selector: 'app-color-coded-bar-pie',
  templateUrl: './color-coded-bar-pie.component.html',
  styleUrls: ['./color-coded-bar-pie.component.scss']
})
export class ColorCodedBarPieComponent implements OnInit, OnChanges {

  @Input() public chartType: string;
  @Input() public idlingRate?: number;
  @Input() public utilizationRate?: number;
  @Input() public availability?: number;
  @Input() public reliability?: number;
  @Input() public pieData: any;
  @Input() public pieLabels: any;

  public pieColors = {
    RED: [{ backgroundColor: [CHART.COLOR.RED, CHART.COLOR.WHITE] }],
    YELLOW: [{ backgroundColor: [CHART.COLOR.YELLOW, CHART.COLOR.WHITE] }],
    GREEN: [{ backgroundColor: [CHART.COLOR.GREEN, CHART.COLOR.WHITE] }],
    DEFAULT: [{ backgroundColor: [CHART.COLOR.WHITE, CHART.COLOR.WHITE] }]
  };
  public pieOptions = {
    cutoutPercentage: 50,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      labels: {

        fontColor: 'white',
        fontSize: 0,
      },
    },
  };
  public pieLegend = false;
  private indicator: any;
  private indicatorSuffix = '%';
  private chartTypes = { ...CHART.TYPE };
  private thresholdColors = { ...THRESHOLD.COLOR };
  private thresholdColor: string;
  public showBarPie = false;
  private showPieChart = false;

  constructor() { }


  ngOnInit() {
    this.validateData();
  }

  ngOnChanges() {
    this.validateData();
  }

  validateData() {
    if (this.idlingRate !== undefined) {
      this.thresholdColor = this.idlingRateThresholdCheckerTool(this.idlingRate);
      this.indicator = this.idlingRate;
      this.showBarPie = true;
    } else if (this.utilizationRate !== undefined) {
      this.thresholdColor = this.utilizationRateThresholdCheckerTool(this.utilizationRate);
      this.indicator = this.utilizationRate;
      this.showBarPie = true;
    } else if (this.availability !== undefined) {
      this.thresholdColor = this.availabilityThresholdCheckerTool(this.availability);
      this.indicator = this.availability;
      this.showBarPie = true;
    } else if (this.reliability !== undefined) {
      this.thresholdColor = this.reliabilityThresholdCheckerTool(this.reliability);
      this.indicator = this.reliability;
      this.showBarPie = true;
    } else {
      this.showBarPie = false;
    }

    if (this.chartType === this.chartTypes.PIE) {
      this.pieData = this.setPieChartData(this.indicator);
      this.showPieChart = true;
    }
  }


  // Idling Rate - Low Threshold is Green; High Threshold is Red
  idlingRateThresholdCheckerTool(idlingRate: number): string {
    let thresholdColor: string;
    if (idlingRate < feConfig.IdlingRateLowThreshold) {
      thresholdColor = this.thresholdColors.GREEN;
    } else if (idlingRate >= feConfig.IdlingRateLowThreshold &&
      idlingRate <= feConfig.IdlingRateHighThreshold) {
      thresholdColor = this.thresholdColors.YELLOW;
    } else if (idlingRate > feConfig.IdlingRateHighThreshold) {
      thresholdColor = this.thresholdColors.RED;
    } else {
      thresholdColor = '';
    }
    return thresholdColor;
  }


  // Utilization Rate - Low Threshold is Red; High Threshold is Green
  utilizationRateThresholdCheckerTool(utilizationRate: number): string {
    let thresholdColor: string;
    if (utilizationRate < feConfig.UtilizationRateLowThreshold) {
      thresholdColor = this.thresholdColors.RED;
    } else if (utilizationRate >= feConfig.UtilizationRateLowThreshold &&
      utilizationRate <= feConfig.UtilizationRateHighThreshold) {
      thresholdColor = this.thresholdColors.YELLOW;
    } else if (utilizationRate > feConfig.UtilizationRateHighThreshold) {
      thresholdColor = this.thresholdColors.GREEN;
    } else {
      thresholdColor = '';
    }
    return thresholdColor;
  }


  availabilityThresholdCheckerTool(availability: number): string {
    let thresholdColor: string;
    if (availability < feConfig.AvailabilityLowThreshold) {
      thresholdColor = this.thresholdColors.RED;
    } else if (availability >= feConfig.AvailabilityLowThreshold &&
      availability <= feConfig.AvailabilityHighThreshold) {
      thresholdColor = this.thresholdColors.YELLOW;
    } else if (availability > feConfig.AvailabilityHighThreshold) {
      thresholdColor = this.thresholdColors.GREEN;
    } else {
      thresholdColor = '';
    }
    return thresholdColor;
  }


  reliabilityThresholdCheckerTool(reliability: number): string {
    let thresholdColor: string;
    if (reliability < feConfig.ReliabilityLowThreshold) {
      thresholdColor = this.thresholdColors.RED;
    } else if (reliability >= feConfig.ReliabilityLowThreshold &&
      reliability <= feConfig.ReliabilityHighThreshold) {
      thresholdColor = this.thresholdColors.YELLOW;
    } else if (reliability > feConfig.ReliabilityHighThreshold) {
      thresholdColor = this.thresholdColors.GREEN;
    } else {
      thresholdColor = '';
    }
    return thresholdColor;
  }


  setPieChartData(pieChartFill: number) {
    const pieChartBuffer = 100 - pieChartFill;
    const pieChartData = [pieChartFill, pieChartBuffer];
    return pieChartData;
  }
}

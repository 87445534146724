import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectEquipmentSelectedPortId } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  equipmentSelectedPortId$ = this.store.select(selectEquipmentSelectedPortId);
  portId: string;
  equipmentType: string;
  equipmentId: string;
  equipmentName: string;

  constructor(private store: Store, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.parent.paramMap.subscribe(params => {
      this.equipmentType = params.get('equipmentType');
      this.equipmentId = params.get('equipmentId');
      this.equipmentName = params.get('equipmentName');
    });
  }

  ngOnInit(): void {
    this.equipmentSelectedPortId$.subscribe((portId) => {
      if (portId) {
        this.portId = portId;
      }
    })
  }

}

import { createAction, props } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import {  WasteMgntModel } from '../../models/waste-management.model';

export const getWasteManagement = createAction(
    '[Sustainability Dashboard Page] Get Waste Management',
    props<{ portsId?: string }>()
);

export const getWasteManagementSuccess = createAction(
    '[Sustainability Dashboard Page] Get Waste ManagementSuccess',
    props<{ wasteManagement: WasteMgntModel }>()
);

export const getWasteManagementFailure = createAction(
    '[Sustainability Dashboard Page] Get Waste Management Failure',
    props<{ error: ErrorResponse }>()
);
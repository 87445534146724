import { Component, OnInit, Input, OnChanges, AfterViewInit, SimpleChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { JobOrderComponent } from 'src/app/operations-dashboard/components/job-order/job-order.component';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { EquipmentDetail } from 'src/app/shared/models';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { MatTableDataSource } from '@angular/material/table';
import { ICON } from 'src/app/shared/constants/assets.constant';
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';
import { QuayYardStatusModel } from 'src/app/shared/models/crane-list.model';
import { selectQuayYardStatus } from 'src/app/shared/state/operations/operations.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getQuayYardStatus } from 'src/app/shared/state/operations/operations.action';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-crane-and-rtg-status',
  templateUrl: './crane-and-rtg-status.component.html',
  styleUrls: ['./crane-and-rtg-status.component.scss']
})
export class CraneAndRTGStatusComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  selectedPortId: string;
  @Input() public craneList: QuayYardStatusModel[];
  quayYard$: Observable<QuayYardStatusModel> = this.store.select(selectQuayYardStatus);
  public newCraneList = [];
  public thresholdStatus = { ...THRESHOLD.STATUS };
  public displayedColumns = ['craneId', 'gmph', 'idlingRate', 'utilizationRate', 'viewWorkOrder'];
  public dataSource = new MatTableDataSource<QuayYardStatusModel>();
  public equipmentType = { ...EQUIPMENT.EQUIPMENT_TYPE };
  public craneStatus = { ...EQUIPMENT.EQUIPMENT_STATUS, MAINTENANCE: EQUIPMENT.EQUIPMENT_STATUS.MAINTENANCE };
  public craneIcons = { ...ICON.EQUIPMENT_STATUS, MAINTENANCE: ICON.EQUIPMENT_STATUS.MAINTENANCE };
  public pieChartColorNames = { ...CHART.COLOR_NAME };
  public utilPieChartType: string = CHART.TYPE.PIE;
  public utilPieChartLegend = true;
  public utilPieChartLabels: string[] = [];
  public showIdlingRate: boolean = false;
  public utilPieChartOptions: any = {
    cutoutPercentage: 50,
    elements: { arc: { borderWidth: 0 } },
    animation: { duration: 0 },
    tooltips: { enabled: false },
  };
  public utilPieChartColorsGreen = [{ backgroundColor: [CHART.COLOR.GREEN, CHART.COLOR.WHITE] }];
  public utilPieChartColorsRed = [{ backgroundColor: [CHART.COLOR.RED, CHART.COLOR.WHITE] }];
  public utilPieChartColorsYellow = [{ backgroundColor: [CHART.COLOR.YELLOW, CHART.COLOR.WHITE] }];
  public idlingPieChartColorsGreen = [{ backgroundColor: [CHART.COLOR.GREEN, CHART.COLOR.WHITE] }];
  public idlingPieChartColorsRed = [{ backgroundColor: [CHART.COLOR.RED, CHART.COLOR.WHITE] }];
  public idlingPieChartColorsYellow = [{ backgroundColor: [CHART.COLOR.YELLOW, CHART.COLOR.WHITE] }];
  public utilRateLineChartColors: Array<any> = [{
    backgroundColor: CHART.COLOR.LIGHT_BLUE_1,
    borderColor: CHART.COLOR.LIGHT_BLUE_1,
    pointBackgroundColor: CHART.COLOR.LIGHT_BLUE_1
  }];
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public showIdlingChart = false;
  public showUtilizationChart = false;
  private subscription: Subscription = new Subscription();
  data: QuayYardStatusModel
  crane_target = 30;
  craneId: string;
  constructor(
    public dialog: MatDialog,
    private roundPipe: RoundPipe,
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(getQuayYardStatus({ portId: this.selectedPortId }));

  }



  ngAfterViewInit(): void {
    this.subscription.add(
      this.quayYard$.subscribe((data) => {
        if (data?.crane_list?.length > 0) {
          this.initializeQuayCraneList(data);
          this.dataSource.data = this.newCraneList;
          this.noData = false;
        } else {
          this.noData = true;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getQuayYardStatus({ portId: this.selectedPortId }));
      }
    }
  }

  initializeQuayCraneList(data: QuayYardStatusModel) {

    if (Array.isArray(data.crane_list)) {


      data.crane_list.sort((a, b) => {
        return a.equipment_name.localeCompare(b.equipment_name);
      });


      this.newCraneList = data.crane_list.map((crane) => {
        this.craneId = crane.equipment_name;
        const craneTarget = this.getPphTarget(crane.equipment_id);
        crane.crane_pph = this.roundPipe.transform(crane.crane_pph);
        let craneFill = this.roundPipe.transform((crane.crane_pph / this.crane_target) * 100);
        craneFill = craneFill > 100 ? 100 : craneFill;
        const craneBuffer = 100 - craneFill;
        const thresholdStatus = this.pphThresholdChecker(crane.equipment_id, crane.crane_pph);
        const equipmentStatus = crane.status;
        const equipmentStatusDesc = `${MESSAGE_DISPLAY.LAST_UPDATED} ${crane.last_updated}`;
        const idlingRate = this.roundPipe.transform(crane.idling_rate);
        const utilizationRate = this.roundPipe.transform(crane.utilization_rate);

        return {
          ...crane,
          crane_target: this.crane_target,
          equipmentStatus,
          equipmentStatusDesc,
          craneBuffer,
          craneFill,
          craneTarget,
          thresholdStatus,
          idling_rate: idlingRate,
          utilization_rate: utilizationRate,

        };
      });
    } else {
      console.error('Invalid crane_list data:', data.crane_list);
    }
  }


  getPphTarget(craneId: string): number {
    const envHighhreshold = feConfig[craneId + 'BCHActualHighThreshold'];
    if (envHighhreshold !== undefined) {
      return Number(envHighhreshold);
    } else {
      return 0;
    }
  }

  pphThresholdChecker(equipment_id: string, aggValue: number) {
    let thresholdStatus: string;
    this.craneId
    const envLowThreshold = feConfig[this.craneId + 'BCHActualLowThreshold'];
    const envHighhreshold = feConfig[this.craneId + 'BCHActualHighThreshold'];


    if (envLowThreshold !== undefined && envHighhreshold !== undefined) {
      if (aggValue < envLowThreshold) {
        thresholdStatus = this.thresholdStatus.LOW;
      } else if (aggValue >= envLowThreshold && aggValue <= envHighhreshold) {
        thresholdStatus = this.thresholdStatus.MID;
      } else if (aggValue > envHighhreshold) {
        thresholdStatus = this.thresholdStatus.HIGH;
      }
    } else {
      thresholdStatus = this.thresholdStatus.LOW;
    }
    return thresholdStatus;
  }

  clickViewDetails(craneList: EquipmentDetail) {
    this.dialog.open(JobOrderComponent, {
      data: {
        port_id: this.selectedPortId,
        ...craneList
      }
    });
  }
}

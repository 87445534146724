import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarbonManagementComponent } from './carbon-management.component';
import { CarbonCalculatorComponent } from './carbon-calculator/carbon-calculator.component';
import { CarbonFootprintComponent } from './carbon-footprint/carbon-footprint.component';

const routes: Routes = [
  {
    path: '',
    component: CarbonManagementComponent,
    data: { breadcrumb: 'Carbon Management' },
    children: [
      {
        path: 'calculator',
        component: CarbonCalculatorComponent,
        data: { breadcrumb: 'Carbon Calculator' },
      },
      {
        path: 'footprint',
        component: CarbonFootprintComponent,
        data: { breadcrumb: 'Carbon Footprint' },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarbonManagementRoutingModule { }

import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit {
  public loading:boolean = false;
  action: string = 'Saving';
  task: string = 'Saved';
  constructor() { }

  ngOnInit(): void {
  }

}

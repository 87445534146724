import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnergyNavComponent } from './energy-nav.component';
import { EnergyDashboardComponent } from './energy-dashboard/energy-dashboard.component';
import { IdleTimeTrackerComponent } from './idle-time-tracker/idle-time-tracker.component';
import { EnergySourceComponent } from './energy-source/energy-source.component';
import { EnergyEquipmentComponent } from './energy-equipment/energy-equipment.component';
import { EnergyDashboardModule } from './energy-dashboard/energy-dashboard.module';

const routes: Routes = [

  {
    path: "",
    component: EnergyNavComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "energy-dashboard",
        pathMatch: "full"
      },

      {
        path: "energy-dashboard",
        component: EnergyDashboardComponent,
        data: { breadcrumb: "" }
      },
      {
        path: "idle-time-tracker",
        component: IdleTimeTrackerComponent,
        data: { breadcrumb: "Idle-Time-Tracker" }
      },
      {
        path: "energy-source",
        component: EnergySourceComponent,
        data: { breadcrumb: "Energy-Source" }
      },
      {
        path: "energy-equipment",
        component: EnergyEquipmentComponent,
        data: { breadcrumb: "Energy Equipment" }
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnergyNavRoutingModule { }

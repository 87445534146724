import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-worker-safety-management',
  templateUrl: './worker-safety-management.component.html',
  styleUrls: ['./worker-safety-management.component.scss']
})
export class WorkerSafetyManagementComponent implements OnInit {

 
public WorkerData = [
  {id:1,value:312, label:"People at Site", icon:"group"},
  {id:2,value:2, label:"Alarms", icon:"notifications"},
  {id:3,value:1, label:"Near Miss", icon:"group"},
  {id:4,value:2, label:"Lost Time Injury", icon:"group"},
  
 
]
public schedData =[
  {value:520, label:"Monthly Manhours", icon:"schedule",color:'grey'},
  {value:15, label:"WO In Progress", icon:"trending_up",color:'grey'},
  {value:295, label:"Timesheet", icon:"description",color:'grey'},
]

public incidentData = [
  {id:1,value:"-17%", label:"Land Incidents",icon:"warning"},
  {id:2,value:"8", label:"Injuries Work Related",icon:"personal_injury"},
]
  constructor() { }

  ngOnInit() {
  }

  
}

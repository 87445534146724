import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent implements OnInit {
  @Input() noData: boolean;
  @Input() noDataToDisplay: string;
  @Input() selectedCrane: string;
  @Input() itemData: any = [];
  @Input() totalDuration: any = [];
  @Input() selectedDates: any = [];
  @Input() colors: any = [];

  public dummyData: any[] = [];
  public dateIndex: number = 0;

  constructor() { }

  ngOnInit() { }

  onFilterSelected(selected: number) {
    this.dateIndex = selected;
  }
}

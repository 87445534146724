import { Component, OnInit, ViewChild, OnChanges, OnDestroy, Input, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { LogsDataModel } from 'src/app/shared/models/logs';
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { selectAlertsLogData } from 'src/app/shared/state/operations/operations.selectors';
import { getAlertsLogData } from 'src/app/shared/state/operations/operations.action';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  @Input()
  selectedPortId: string;

  logsAlerts$: Observable<LogsDataModel[]> = this.store.select(selectAlertsLogData);
  public dataSource = new MatTableDataSource<LogsDataModel>();
  public columnHeaders = ['description', 'operator', 'time'];
  public pageIndex = 0;
  public pageSize = 5;
  public noLogs = true;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getAlertsLogData({ portId: this.selectedPortId }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getAlertsLogData({ portId: this.selectedPortId }));;
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.logsAlerts$.subscribe((data) => {
        if (data?.length) {
          this.createTable(data);
        }
      });
    }, 0);
  }

  createTable(logsList: LogsDataModel[]) {
    if (logsList !== undefined) {
      if (logsList.length === 0) {
        this.noLogs = true;
      } else {
        this.dataSource.data = logsList;
        this.dataSource.paginator = this.paginator;
        this.noLogs = false;
      }
    }
  }
}



import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { CorporateGoalRoutingModule } from './corporate-goal-routing.module';
import { CorporateGoalComponent } from "./corporate-goal.component";
import { CarbonMgntPageComponent } from "./carbon-mgnt-page/carbon-mgnt-page.component";
import { BiodiversityPageComponent } from "./biodiversity-page/biodiversity-page.component";
import { WaterConservationPageComponent } from "./water-conservation-page/water-conservation-page.component";
import { WaterMngtPageComponent } from "./water-mngt-page/water-mngt-page.component";
import { SocialResponPageComponent } from "./social-respon-page/social-respon-page.component";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CorporateGoalRoutingModule,
    FormsModule,
    MatTableModule
  ],
  declarations: [
    CorporateGoalComponent,
    CarbonMgntPageComponent,
    BiodiversityPageComponent,
    WaterConservationPageComponent,
    WaterMngtPageComponent, 
    SocialResponPageComponent
  ],
})
export class CorporateGoalModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarbonManagementRoutingModule } from './carbon-management-routing.module';
import { CarbonManagementComponent } from './carbon-management.component';
import { CarbonCalculatorComponent } from './carbon-calculator/carbon-calculator.component';
import { CarbonFootprintComponent } from './carbon-footprint/carbon-footprint.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CarbonManagementComponent, 
    CarbonCalculatorComponent, 
    CarbonFootprintComponent
  ],
  imports: [
    CommonModule,
    CarbonManagementRoutingModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule
  ]
})
export class CarbonManagementModule { }

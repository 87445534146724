import { createSelector, createFeatureSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { UserState } from '../user/user.reducer';

export const selectUserState = (state: AppState) => state.user;
export const selectIsValidSession = createSelector(
  selectUserState,
  (state: UserState) => state.isValidSession
);

export const selectCreateSessionStatus = createSelector(
  selectUserState,
  (state: UserState) => state.createSessionStatus
);

export const selectUserDetails = createSelector(
  selectUserState,
  (state: UserState) => state.user
);

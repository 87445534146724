import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-berth-container',
  templateUrl: './berth-container.component.html',
  styleUrls: ['./berth-container.component.scss']
})
export class BerthContainerComponent implements OnInit {

  /**@Input() chartTitle:string;

   public chartarrow:string[]= ["&uarr","&darr"];
   public chartColors:string[] = ["#54D454","#ff3f3f"];
   public chartData:column[] = [
   {Name:'Loading',Value:40},
   {Name:'Unloading',Value:50},

 ]

 public allChartData:column[] = [
 { Name:'All Users',Value:50},
 { Name:'Mail Boxes',Value:73},

 ]

   circleArray: circle[] = [{ vector: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831", circumferance: 100 },
   { vector: "M18 5.26 a 12.738 12.738 0 0 1 0 25.477 a 12.738 12.738 0 0 1 0 -25.477", circumferance: 80 },
   { vector: "M18 8.44 a 9.55 9.55 0 0 1 0 19.108 a 9.55 9.55 0 0 1 0 -19.108", circumferance: 60 }];

   dashArray: Array<number[]>;
   totalValue: number;

   constructor() { }

   ngOnInit() {
     this.totalValue = this.getTotalValue();
     this.dashArray = this.getStrokeDashArray();
   }

   getStrokeDashArray(): Array<number[]> {
     let result: Array<number[]> = [];
     for (let i = 0; i < this.chartData.length; i++) {
       let percent = Math.round((this.chartData[i].Value / this.allChartData[i].Value) * 100);
       this.chartData[i].percentage = percent;
       let percentageValue = (percent * this.circleArray[i].circumferance) / 100;
       let resultArray = [percentageValue, this.circleArray[i].circumferance];
       result.push(resultArray);
     }
     console.log(result);
     return result;
   }

   getTotalValue(): number {
     let result = 0;
     for (let i = 0; i < this.chartData.length; i++) {
       result = result + this.chartData[i].Value;
     }
     return result;
   }

 }

 export class column {
   Name: string;
   Value: number;
   percentage?: number;
   chartarrow?:string;
 }

 export class circle {
   vector: string;
   circumferance: number;
 }*/













































  //ORIGINAL
  @Input() containerFillColor: any;
  @Input() containerFillLoading: any;
  @Input() containerBufferLoading: any;
  @Input() containerFillUnloading: any;
  @Input() containerBufferUnloading: any;
  @Input() containerCompletedLoading: any;
  @Input() containerAssignedLoading: any;
  @Input() containerCompletedUnloading: any;
  @Input() containerAssignedUnloading: any;
  @Input() index: any;
  public isReady = false;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(): void {
    if (this.containerFillLoading !== undefined &&
      this.containerFillLoading !== null &&
      this.containerBufferLoading !== undefined &&
      this.containerFillLoading !== null &&
      this.containerFillUnloading !== undefined &&
      this.containerFillUnloading !== null &&
      this.containerBufferUnloading !== undefined &&
      this.containerFillUnloading !== null) {
      this.isReady = true;
    } else {
      this.isReady = false;
    }
  }
}

import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { PortModel } from "src/app/shared/models/port.models";
import { getPort } from "src/app/shared/state/port/port.actions";
import { selectPorts } from "src/app/shared/state/port/port.selectors";

@Component({
  selector: "app-sustainability",
  templateUrl: "./sustainability.component.html",
  styleUrls: ["./sustainability.component.scss"],
})
export class SustainabilityComponent implements OnInit, AfterViewInit {
  ports$: Observable<PortModel[]> = this.store.select(selectPorts);

  showOptions: boolean = false;
  isArrowUpVisible: boolean = false;
  selectedOption: string = "Select Port";
  selectedPortId: string;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getPort());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 1) {
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }

  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;
  }

  handleCheckboxClick(port: PortModel): void {
    this.selectedOption = port.port_name;
    this.selectedPortId = port.port_id;
  }

  showCheckboxes() {
    if ((this.showOptions = true)) {
      this.isArrowUpVisible = true;
    } else if ((this.showOptions = false)) {
      this.isArrowUpVisible = false;
    }
  }

  handleMouseLeave() {
    this.showOptions = false;
    this.isArrowUpVisible = false;
  }
}

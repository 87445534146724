import { Component, OnInit, ViewChild, OnChanges, Input, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { EquipmentAlertsModel } from 'src/app/shared/models';
import { WorkOrderComponent } from 'src/app/operations-dashboard/components/work-order/work-order.component';
import { Store } from "@ngrx/store";
import { selectEquipmentMaintenanceAlert } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentAlert } from 'src/app/shared/state/operations/operations.action';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  equipmentMaintenanceAlert$: Observable<EquipmentAlertsModel> = this.store.select(selectEquipmentMaintenanceAlert);
  dataSource = new MatTableDataSource<EquipmentAlertsModel>();
  columnHeaders = ['dataPoint', 'description', 'equipment', 'current', 'workRequest', 'time'];
  pageIndex = 0;
  pageSize = 5;
  noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;

  constructor(private dialog: MatDialog, private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getEquipmentAlert({ portId: this.portId, equipmentId: this.equipmentId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.equipmentMaintenanceAlert$.subscribe((alerts) => {
        if (alerts) {
          this.createTable(alerts);
        }
      });
    }, 0);
  }

  ngOnChanges() { }

  createTable(data: EquipmentAlertsModel) {
    this.dataSource.data = [data];
  }

  popUpAlert(alert: EquipmentAlertsModel) {
    const dialogRef = this.dialog.open(WorkOrderComponent, {
      data: { ...alert }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        alert.raised = result.raised;
      }
    });
  }
}

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Observable } from "rxjs";
import { CHART } from "src/app/shared/constants/chart.constant";
import { RenewableEnergyGraphModel, RenewableEnergyShareModel } from "src/app/shared/models";
import { getRenewableEnergyGraph, getRenewableEnergyShare } from "src/app/shared/state/sustainability/sustainability.actions";
import { selectRenewableEnergyGraph, selectRenewableEnergyShare } from "src/app/shared/state/sustainability/sustainability.selectors";

@Component({
  selector: "app-renewable-share",
  templateUrl: "./renewable-share.component.html",
  styleUrls: ["./renewable-share.component.scss"],
})
export class RenewableShareComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectedYear: string;

  renewableEnergyShare$: Observable<RenewableEnergyShareModel> = this.store.select(selectRenewableEnergyShare);
  renewableEnergyGraph$: Observable<RenewableEnergyGraphModel> = this.store.select(selectRenewableEnergyGraph);
  private energySharePieChart: Chart;
  private energyMonthlyShareLineChart: Chart;
  private energyShareByEquipmentBarChart: Chart;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getRenewableEnergyShare({}));
    this.store.dispatch(getRenewableEnergyGraph({}));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renewableEnergyShare$.subscribe((renewableEnergyShare) => {
        if (renewableEnergyShare) {
          this.createPieChart(renewableEnergyShare);
          this.createBarChart(renewableEnergyShare);
        }
      });
      this.renewableEnergyGraph$.subscribe((monthlyRenewable) => {
        if (monthlyRenewable) {
          this.createLineChart(monthlyRenewable);
        }
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getRenewableEnergyShare({ year: this.selectedYear }));
        this.store.dispatch(getRenewableEnergyGraph({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getRenewableEnergyShare({}));
        this.store.dispatch(getRenewableEnergyGraph({}));
      }
    }
  }

  createPieChart(data: RenewableEnergyShareModel) {
    if (this.energySharePieChart) {
      this.energySharePieChart.destroy();
    }

    const canvas = document.getElementById('energySharePieChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "energySharePieChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const renewSharelabel = Math.round((data.shares.renewable / (data.shares.renewable + data.shares.non_renewable)) * 100);
    const nonRenewSharelabel = Math.round((data.shares.non_renewable / (data.shares.renewable + data.shares.non_renewable)) * 100);

    this.energySharePieChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Non-Renewable', 'Renewable'],
        datasets: [{
          data: [renewSharelabel, nonRenewSharelabel],
          backgroundColor: [CHART.COLOR.PORT_ENERGY_SHARE.DARK_GRAY, CHART.COLOR.PORT_ENERGY_SHARE.DARK_GREEN],
        }]
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          titleFontSize: 12,
          bodyFontSize: 12,
          caretPadding: 20,
          yPadding: 10
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            color: CHART.COLOR.PORT_ENERGY_SHARE.WHITE,
            textAlign: 'center',
            formatter: (value, ctx) => {
              if (value === 0) {
                return null;
              }
              const label = ctx.chart.data.labels[ctx.dataIndex];
              const data = ctx.chart.data.datasets[0].data[ctx.dataIndex];
              return `${label}\n${data}%`;
            },
            font: {
              size: 15,
              weight: 'bold',
              family: 'Montserrat'
            },
          }
        }
      },
      plugins: [ChartDataLabels]
    });
  }

  createLineChart(data: RenewableEnergyGraphModel) {
    if (this.energyMonthlyShareLineChart) {
      this.energyMonthlyShareLineChart.destroy();
    }

    const canvas = document.getElementById("energyMonthlyShareLineChart") as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "energyMonthlyShareLineChart" not found');
      return;
    }

    const ctx = canvas.getContext("2d");
    const renewableData = data.renewable.map((data) => data.value);
    const nonRenewableData = data.non_renewable.map((data) => data.value);
    const rSLineChartPlugin = {
      id: "rSLineChartPlugin",
      beforeDraw(chart: Chart) {
        var ctx = chart.ctx;
        var chartArea = chart.chartArea;

        ctx.lineWidth = 1;
        ctx.strokeStyle = CHART.COLOR.PORT_ENERGY_SHARE.GRAY;

        ctx.beginPath();
        ctx.moveTo(chartArea.left, chartArea.top);
        ctx.lineTo(chartArea.right, chartArea.top);
        ctx.stroke();
      },
    };

    this.energyMonthlyShareLineChart = new Chart(ctx, {
      type: "bar",
      data: {
        datasets: [
          {
            label: "Renewable Share",
            type: "line",
            data: renewableData,
            borderColor: CHART.COLOR.PORT_ENERGY_SHARE.DARK_GREEN,
            lineTension: 0,
            pointStyle: "circle",
            pointRadius: 5,
            pointHoverRadius: 5,
            pointBackgroundColor: CHART.COLOR.PORT_ENERGY_SHARE.WHITE,
            borderWidth: 2,
            order: 1,
            fill: false,
          },
          {
            label: "Non-Renewable Share",
            type: "line",
            data: nonRenewableData,
            borderColor: CHART.COLOR.PORT_ENERGY_SHARE.DARK_GRAY,
            lineTension: 0,
            pointStyle: "circle",
            pointRadius: 5,
            pointHoverRadius: 5,
            pointBackgroundColor: CHART.COLOR.PORT_ENERGY_SHARE.WHITE,
            borderWidth: 2,
            order: 2,
            fill: false,
          },
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          titleFontSize: 12,
          bodyFontSize: 12,
          caretPadding: 20,
          yPadding: 10
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              stacked: false,
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "k";
                },
              },
            },
          ],
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: 10,
            align: "bottom",
            color: CHART.COLOR.PORT_ENERGY_SHARE.BLACK,
            textAlign: "center",
            font: {
              lineHeight: 1.6,
              size: 12,
              weight: "bold",
              family: "Montserrat",
            },
            formatter: function (value, ctx) {
              return Math.round(value / 1000) + "k";
            },
          },
        },
      },
      plugins: [ChartDataLabels, rSLineChartPlugin]
    });
  }

  createBarChart(data: RenewableEnergyShareModel) {
    if (this.energyShareByEquipmentBarChart) {
      this.energyShareByEquipmentBarChart.destroy();
    }

    const canvas = document.getElementById("energyShareByEquipmentBarChart") as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "energyShareByEquipmentBarChart" not found');
      return;
    }

    const ctx = canvas.getContext("2d");
    const renewableEquipments = data?.renewable?.map((energy) => energy.equipment);
    const nonRenewableEquipments = data?.non_renewable?.map((energy) => energy.equipment);
    const labels = [...nonRenewableEquipments, ...renewableEquipments];
    const renewableValues = data?.renewable?.map((energy) => energy.value);
    const nonRenewableValues = data?.non_renewable?.map((energy) => energy.value);
    const dataset = [...nonRenewableValues, ...renewableValues];
    const topLineChartPlugin = {
      id: "topLineChartPlugin",
      beforeDraw(chart: Chart) {
        var ctx = chart.ctx;
        var chartArea = chart.chartArea;

        ctx.lineWidth = 1;
        ctx.strokeStyle = CHART.COLOR.PORT_ENERGY_SHARE.GRAY;

        ctx.beginPath();
        ctx.moveTo(chartArea.left, chartArea.top);
        ctx.lineTo(chartArea.right, chartArea.top);
        ctx.stroke();
      },
    };

    this.energyShareByEquipmentBarChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "LTIF",
            data: dataset,
            backgroundColor: [
              ...Array(nonRenewableEquipments?.length).fill(CHART.COLOR.PORT_ENERGY_SHARE.DARK_GRAY),
              ...Array(renewableEquipments?.length).fill(CHART.COLOR.PORT_ENERGY_SHARE.DARK_GREEN),
            ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 12,
          bodyFontSize: 12,
          caretPadding: 20,
          yPadding: 10
        },
        title: {
          display: false,
          text: "Energy Share By Equipment Type",
          position: "top",
          fontSize: 16,
          fontColor: CHART.COLOR.PORT_ENERGY_SHARE.BLACK_1,
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: CHART.COLOR.PORT_ENERGY_SHARE.BLACK_1,
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
                max: 1500000
              },
            },
          ],
          gridLines: {
            display: false,
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            anchor: "end",
            offset: -5,
            align: "top",
            color: CHART.COLOR.PORT_ENERGY_SHARE.BLACK,
            textAlign: "center",
            font: {
              lineHeight: 1.6,
              size: 12,
              weight: "bold",
              family: "Montserrat",
            },
            formatter: function (value, ctx) {
              return Math.round(value / 1000) + "k";
            },
          },
        },
      },
      plugins: [ChartDataLabels, topLineChartPlugin]
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalTwinLayoutComponent } from './digital-twin-layout.component';
import { DigitalTwinNavModule } from './digital-twin-nav/digital-twin-nav.module';

const routes: Routes = [{
  path: "",
  component: DigitalTwinLayoutComponent,
  data: { breadcrumb: "Digital Twin" },

  children: [

    {
      path: "",
      loadChildren: () => DigitalTwinNavModule,
      data: { breadcrumb: "" },
    },

  ],

}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DigitalTwinLayoutRoutingModule { }

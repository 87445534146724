import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsLayoutComponent } from './settings-layout.component';
import { SettingNavModule } from './setting-nav/setting-nav.module';

const routes: Routes = [
  {
    path: "",
    component: SettingsLayoutComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        redirectTo: "all",
        pathMatch: "full"
      },
      {
        path: "all",
        loadChildren: () => SettingNavModule,
        data: { breadcrumb: "Settings" },
      },


    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsLayoutRoutingModule { }

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Store } from "@ngrx/store";
import { ICON } from "src/app/shared/constants/assets.constant";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { selectProtectionWaters } from 'src/app/shared/state/biodiversity/biodiversity.selectors';
import { getProtectionWaters } from 'src/app/shared/state/biodiversity/biodiversity.actions';
import { ProtectionWatersModel } from 'src/app/shared/models/protection-waters.model';
import { CHART } from 'src/app/shared/constants/chart.constant';


@Component({
  selector: 'app-land-sea-protection',
  templateUrl: './land-sea-protection.component.html',
  styleUrls: ['./land-sea-protection.component.scss']
})
export class LandSeaProtectionComponent implements OnInit {
  @Input() selectedYear: string;

  landAndWaterChart: Chart;

  protectionWaters$ = this.store.select(selectProtectionWaters);

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getProtectionWaters({}));
  }

  ngAfterViewInit(): void {
    this.protectionWaters$.subscribe((protectionWaters) => {
      if (protectionWaters) {
        this.stackedBar(protectionWaters);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean =
      changes.selectedYear && !changes.selectedYear.firstChange;
    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getProtectionWaters({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getProtectionWaters({}));
      }
    }
  }

  stackedBar(protectionWaters: ProtectionWatersModel) {
    if (this.landAndWaterChart) {
      this.landAndWaterChart.destroy();
    }

    const canvas = document.getElementById(
      "protectedAreaShareChart"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d"); // Get the 2D context
    const protectionWaterlabels = protectionWaters.protected_area_share.in_water.name;
    const protectionWaterValue = protectionWaters.protected_area_share.in_water.value;
    const protectionLandlabels = protectionWaters.protected_area_share.in_land.name;
    const protectionLandValue = protectionWaters.protected_area_share.in_land.value;

    this.landAndWaterChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [''],
        datasets: [
          {
            label: protectionWaterlabels,
            data: [protectionWaterValue],
            backgroundColor: CHART.COLOR.PROTECTED_AREA_SHARE.IN_WATERS,
          },
          {
            label: protectionLandlabels,
            data: [protectionLandValue],
            borderSkipped: 'left',
            backgroundColor: CHART.COLOR.PROTECTED_AREA_SHARE.IN_LANDS,
            borderColor: 'white',
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 1,
              left: 0
            }
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
          }
        },
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          yPadding: 10
        },
        scales: {
          xAxes: [{ stacked: true, display: false }],
          yAxes: [{ stacked: true, display: false, ticks: { max: 100 } }],
          gridLines: { display: false },
        },
        title: {
          display: false,
          text: "Protected Area Share",
          position: "chartArea",
          fontSize: 16,
          fontColor: "#111",
          padding: 20,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#111",
          },
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: "#fff",
            align: "center",
            textAlign: "center",
            font: {
              lineHeight: 1.6,
              size: 14,
              weight: 'bold',
              family: 'Montserrat',
            },
            formatter: function (value, ctx) {
              return ctx.dataset.label + "\n" + value + "%";
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? "percent-red" : "percent-green";
  }
}

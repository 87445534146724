import { createReducer, on } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import { EnergyManagementModel } from '../../models/energy-management.model';
import { getEnergyMngt, getEnergyMngtFailure, getEnergyMngtSuccess, resetEnergyMngmt } from './energy-management.actions';
import { ApiStatus } from '../../enum/api-status.enum';



export interface EnergyManagementState {
  energy: EnergyManagementModel,
  error: ErrorResponse,
  status: ApiStatus
}

export const initialState: EnergyManagementState = {
  energy : null,
  error: null,
  status: ApiStatus.Pending
}

export const energyManagementReducer = createReducer(
  initialState,
  on(getEnergyMngt, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getEnergyMngtSuccess, (state, { energymanagement }) => ({
    ...state,
    energy: energymanagement,
    error: null,
    status: ApiStatus.Success
  })),
  on(getEnergyMngtFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: ApiStatus.Error
  })),
  on(resetEnergyMngmt, (state) => ({
    ...state,
    energy: null,
    error: null,
    status: ApiStatus.Pending
  }))
);
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SustainabilityNavRoutingModule } from './sustainability-nav-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SustainabilityNavComponent } from './sustainability-nav.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { CarbonManagementComponent } from './carbon-management/carbon-management.component';
import { WaterConservationComponent } from './water-conservation/water-conservation.component';
import { WasteManagementComponent } from './waste-management/waste-management.component';
import { BiodiversityComponent } from './biodiversity/biodiversity.component';
import { SocialResponsibilityComponent } from './social-responsibility/social-responsibility.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { GaugeChartModule } from 'angular-gauge-chart';
import { PortEquipmentElectrificationComponent } from './carbon-management/port-equipment-electrification/port-equipment-electrification.component';
import { CarbonInformationComponent } from './carbon-management/carbon-information/carbon-information.component';
import { WaterQualityComponent } from './water-conservation/water-quality/water-quality.component';
import { WaterUsageComponent } from './water-conservation/water-usage/water-usage.component';
import { WaterDiversityComponent } from './water-conservation/water-diversity/water-diversity.component';
import { RenewableShareComponent } from './carbon-management/renewable-share/renewable-share.component';
import { SustainabilityKpiComponent } from './sustainability/sustainability-kpi/sustainability-kpi.component';
import { GreenerEnvironmentComponent } from './biodiversity/greener-environment/greener-environment.component';
import { LandSeaProtectionComponent } from './biodiversity/land-sea-protection/land-sea-protection.component';
import { CommunityEducationComponent } from './biodiversity/community-education/community-education.component';
import { WorkforceDiversityComponent } from './social-responsibility/workforce-diversity/workforce-diversity.component';
import { SocialEngagementComponent } from './social-responsibility/social-engagement/social-engagement.component';
import { SocialTrainingComponent } from './social-responsibility/social-training/social-training.component';
import { HealthAndSafetyComponent } from './social-responsibility/health-and-safety/health-and-safety.component';
import { WasteDiversionComponent } from './waste-management/waste-diversion/waste-diversion.component';
import { EnvironmentallyFriendlyDisposalComponent } from './waste-management/environmentally-friendly-disposal/environmentally-friendly-disposal.component';
import { SeabinsCollectionComponent } from './waste-management/seabins-collection/seabins-collection.component';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatTableModule,
    MatIconModule,
    GaugeChartModule,
    SustainabilityNavRoutingModule
  ],
  declarations: [
    SustainabilityNavComponent,
    SustainabilityComponent,
    SustainabilityKpiComponent,
    CarbonManagementComponent,
    WaterConservationComponent,
    WasteManagementComponent,
    BiodiversityComponent,
    SocialResponsibilityComponent,
    CarbonInformationComponent,
    PortEquipmentElectrificationComponent,
    RenewableShareComponent,
    WaterQualityComponent,
    WaterUsageComponent,
    WaterDiversityComponent,
    GreenerEnvironmentComponent,
    LandSeaProtectionComponent,
    CommunityEducationComponent,
    WorkforceDiversityComponent,
    SocialEngagementComponent,
    SocialTrainingComponent,
    HealthAndSafetyComponent,
    WasteDiversionComponent,
    EnvironmentallyFriendlyDisposalComponent,
    SeabinsCollectionComponent
  ],
  providers: []
})
export class SustainabilityNavModule { }

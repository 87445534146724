import { Component, Input, OnInit } from '@angular/core';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { ComponentsInDueForCheckup } from 'src/app/shared/models/componentsInDueForCheckup';
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { selectEquipmentPartInspection } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentPartsInspection } from 'src/app/shared/state/operations/operations.action';
import { EquipmentPartsInspectionModel } from 'src/app/shared/models/equipment-part-inspection.model';


@Component({
  selector: 'app-due-for-inspection',
  templateUrl: './due-for-inspection.component.html',
  styleUrls: ['./due-for-inspection.component.scss']
})
export class DueForInspectionComponent implements OnInit {
  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  equipmentpartsInspection$: Observable<EquipmentPartsInspectionModel[]> = this.store.select(selectEquipmentPartInspection);
  public componentsInDueForCheckup: ComponentsInDueForCheckup[] = [];
  public columnHeaders = ['component', 'checkupDate'];
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = false;

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(getEquipmentPartsInspection({ portId: this.portId, equipmentId: this.equipmentId }));
  }
}

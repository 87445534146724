import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicesInfoComponent } from './services-info.component';
import { VesselInfoComponent } from './vessel-info/vessel-info.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';


const routes: Routes = [
  {
    path: '',
    component: ServicesInfoComponent,
    data: { breadcrumb: '' },
    children: [
      { path: '', redirectTo: 'vessel', pathMatch: 'full' },
      {
        path: 'vessel',
        component: VesselInfoComponent,
        data: { breadcrumb: 'Vessel Information' }
      },
      {
        path: 'booking',
        component: BookingInfoComponent,
        data: { breadcrumb: 'Booking Information' }
      },



    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicesInfoRoutingModule { }

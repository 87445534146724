import { Component, OnInit, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from '@angular/material/sort';
import { MatDialog } from "@angular/material/dialog";
import { ObtDetailsComponent } from "src/app/operations-dashboard/components/obt-details/obt-details.component";
import { Store } from "@ngrx/store";
import { selectOverstayingTrucks } from "src/app/shared/state/operations/operations.selectors";
import { Observable } from "rxjs";
import { OverstayingTrucksModel } from "src/app/shared/models/overstaying-trucks.model";
import { getOverstayingTrucks } from "src/app/shared/state/operations/operations.action";
import { OutboundTrucksService } from "src/app/shared/services/specs/outbound-truck-service";
import { OUTBOUND_TRUCKS } from "src/app/shared/constants/paginator.constant";
import { MESSAGE_DISPLAY } from "src/app/shared/constants/api.constant";

@Component({
  selector: "app-overstay-truck",
  templateUrl: "./overstay-truck.component.html",
  styleUrls: ["./overstay-truck.component.scss"],
})
export class OverstayTruckComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator
  }

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms
    this.dataSource.sort = this.sort;
  }

  @Input()
  selectedPortId: string;

  overstayingTrucks$: Observable<OverstayingTrucksModel[]> = this.store.select(selectOverstayingTrucks);
  public dataSource = new MatTableDataSource<OverstayingTrucksModel>();
  public columnHeaders = ['vehicleLicense', 'containers', 'status', 'time', 'trouble_ticket', 'View details'];
  public pageIndex = OUTBOUND_TRUCKS.PAGINATOR.PAGE_INDEX;
  public pageSize = OUTBOUND_TRUCKS.PAGINATOR.PAGE_SIZE;
  public pageSizeOptions = OUTBOUND_TRUCKS.PAGINATOR.PAGE_SIZE_OPTIONS;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public tooltipval
  public noData = true;
  private paginator: MatPaginator
  private sort: MatSort;

  constructor(
    private store: Store,
    private outboundTrucksService: OutboundTrucksService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.store.dispatch(getOverstayingTrucks({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.overstayingTrucks$.subscribe((data) => {
      if (data?.length > 0) {
        this.displayOBTData(data);
      } else {
        this.noData = true;
      }
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getOverstayingTrucks({ portId: this.selectedPortId }));
      }
    }

  }

  displayOBTData(data: OverstayingTrucksModel[]) {
    const dateToday = new Date();
    const c = [`${(dateToday.getDate()) < 10 ? 0 : ''}${(dateToday.getDate())}`, this.outboundTrucksService.monthsAll[dateToday.getMonth()], (dateToday.getFullYear()).toString()].join('-');
    let dataForToday = [];
    let allData = [];

    data.map((el: OverstayingTrucksModel) => {
      if (new Date()) {//temporary substitute for el.transaction_date
        el.transaction_date = new Date().toISOString(); // hardcoded transaction_date
        const newArr = this.outboundTrucksService.formatDateTime(el);
        const d = el.transaction_date.slice(0, 11);

        if (el.containers?.length > 1) this.tooltipval = el.containers.join('\n');
        if (d == c && el.time) dataForToday.push(el); // check if current date and date of data is equal || check if time is less than an hour
        // if (el.time) dataForToday.push(el)
        allData.push(newArr);
      }
    });

    if (dataForToday?.length !== 0) {
      this.noData = false;
      this.dataSource.data = dataForToday;
    }
  }

  openModal(element) {
    this.dialog.open(ObtDetailsComponent, {
      data: {
        ...element
      },
      maxWidth: '800px',
      width: '720px',
      panelClass: 'custom_dialog',
      autoFocus: false
    })
  }
}

import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-tabular-view',
  templateUrl: './tabular-view.component.html',
  styleUrls: ['./tabular-view.component.scss']
})
export class TabularViewComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @Input() selectedCrane: string;
  @Input() itemData: any = [];
  @Input() totalDuration: any = [];
  @Input() selectedDates: any = [];
  public dateIndex: number = 0;
  public tableData: any[] = [];
  displayedColumns: string[] = ['dateAcquired', 'faultId', 'dailyDuration', 'description', 'category'];
  public dataSource: MatTableDataSource<any>;
  public ELEMENT_DATA: tableData[] = [];
  public totalDuration2: number[] = [];


  constructor() { }


  getTotalDuration() {
    let total = this.ELEMENT_DATA.map(t => t.dailyDuration).reduce((acc, value) => acc + value, 0);
    total = Math.round(total * 100);
    return total / 100;
  }

  getData() {
    for (let j = 0; j < this.itemData[this.dateIndex].length; j++) {

      let dailyDuration = this.itemData[this.dateIndex][j].dailyDuration / 3600;
      dailyDuration = (Math.round(dailyDuration * 100) / 100);
      if (dailyDuration !== 0) { //<-- added to check the 0
        this.ELEMENT_DATA.push({
          dateAcquired: this.itemData[this.dateIndex][j].dateAcquired,
          faultId: this.itemData[this.dateIndex][j].faultId,
          dailyDuration: dailyDuration,
          description: this.itemData[this.dateIndex][j].description,
          category: this.itemData[this.dateIndex][j].category,
        })
      } else {
        //console.log("not pushed");
      }



    }
    this.dataSource = new MatTableDataSource<tableData>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.getData();
    //this.getTotalDuration();
  }


  onFilterSelected(selected: number) {
    this.dateIndex = selected;
    this.ELEMENT_DATA = [];
    this.getData();
  }

}

export interface tableData {
  dateAcquired: string;
  faultId: string;
  dailyDuration: number;
  description: string;
  category: string
}

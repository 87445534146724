
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Router } from '@angular/router';
import 'chartjs-plugin-labels';
import { Store } from '@ngrx/store';
import { selectEnergyManagement } from '../../shared/state/energy-management/energy-management.selectors';
import { getEnergyMngt, resetEnergyMngmt } from '../../shared/state/energy-management/energy-management.actions';

@Component({
  selector: 'app-energy-management',
  templateUrl: './energy-management.component.html',
  styleUrls: ['./energy-management.component.scss']
})
export class EnergyManagementComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedPortId: string;
  @ViewChild('myChartCanvas') myChartCanvas!: ElementRef;
  consumption: any;
  energy$ = this.store.select(selectEnergyManagement);
  energyConsumptionChart: Chart;
  fuel_equipment_operation = -15;
  electricity_intensity_per_TEU = -6;
  fuel_intensity_per_tugboat = +2;
  doughnutLabelLines = {
    id: 'doughnutLabelLines',
    afterDraw(chart, args, options) {
      const { ctx, width, height } = chart;

      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
          const halfWidth = width / 2;
          const halfHeight = height / 2;
          const xLine = x >= halfWidth ? x + 20 : x - 20;
          const yLine = y >= halfHeight ? y + 20 : y - 20;
          const extraLine = x >= halfWidth ? 20 : -20
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeColor = 'black';
          ctx.stroke();
          // const textWidth = ctx.measureText(chart.data.labels[index]).width;
          // ctx.font = '12px Arial';
          const textPosition = x >= halfWidth ? 'left' : 'right'
          ctx.textAlign = textPosition;
          ctx.textBaseLine = 'alphabetic';
          ctx.fillStyle = '#2C3E50';
          ctx.fontSize = '12px';
          ctx.fontFamily = 'Montserrat';
          ctx.fillText(chart.data.labels[index] + " " + chart.data.datasets[i].data[index] + "%", xLine + extraLine, yLine);
        })
      })
    }
  }

  constructor(private store: Store, public router: Router) { }

  ngOnInit() { }

  ngAfterViewInit() {

    this.updateChart(this.consumption);

    // this.energy$.subscribe((consumption) => {
    //   if (consumption) {
    //     this.updateChart(consumption);
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEnergyMngt({ portsId: this.selectedPortId }));
      }
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetEnergyMngmt());
  }

  updateChart(consumption: any) {
    if (this.energyConsumptionChart) {
      this.energyConsumptionChart.destroy();
    }

    //API READY TO RETURN WHEN DATA AVAILABLE
    // const energySourceLabels = consumption['energy_source'].map((source: any) => source.name);
    // const energySourceValues = consumption['energy_source'].map((source: any) => Math.round(source.value));

    const ctx = this.myChartCanvas.nativeElement.getContext('2d');
    this.energyConsumptionChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        //API READY TO RETURN WHEN DATA AVAILABLE
        // labels: energySourceLabels,
        labels: ['Renewables', 'Oil', 'Nuclear', 'Natural Gas', 'Coal'],
        datasets: [{
          // label: '# of Votes',
          //API READY TO RETURN WHEN DATA AVAILABLE
          // data: energySourceValues,

          data: [11, 38, 32, 8, 11],
          backgroundColor: [
            '#16a085',  // Renewables
            '#474787',//oil
            '#6c5ce7', //nuclear
            '#9b59b6',  //natural gas
            '#34495e', //coal
          ],
          borderColor: '#fff',
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: 'white',
            fontSize: 0,
          },
        },
        maintainAspectRatio: false,
        layout: {
          padding: 20
        },
        legend: {
          display: false
        },
        title: {
          display: false,
          text: 'Energy Consumption by Source',
          fontSize: 15,
          fontColor: '#2c3e50',
          fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
        },
        aspectRatio: 3,
        responsive: true,
        cutoutPercentage: 60,
      },
      plugins: [this.doughnutLabelLines],
    });
  }
}
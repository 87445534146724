import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';

import { EnergyMgtLayoutRoutingModule } from './energy-mgt-layout-routing.module';
import { EnergyNavComponent } from './energy-nav/energy-nav.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [EnergyNavComponent,],
  imports: [
    CommonModule,
    EnergyMgtLayoutRoutingModule,
    RouterModule,
    HttpClientModule,


  ]
})
export class EnergyMgtLayoutModule { }

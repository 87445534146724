import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import * as atlas from 'azure-maps-control';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { Store } from "@ngrx/store";
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { AZURE_MAP } from 'src/app/shared/constants/map.constant';
import { ICON } from 'src/assets/icons/assets.constant';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { EquipmentDetail } from 'src/app/shared/models';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PortMapModel } from 'src/app/shared/models/port-map.model';
import { selectPortMap } from 'src/app/shared/state/operations/operations.selectors';
import { getPortMap } from 'src/app/shared/state/operations/operations.action';
import { JobOrderComponent } from 'src/app/operations-dashboard/components/job-order/job-order.component';


@Component({
  selector: 'app-map-equipment-map-view',
  templateUrl: './map-equipment-map-view.component.html',
  styleUrls: ['./map-equipment-map-view.component.scss'],
})
export class MapEquipmentMapViewComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('mapview', { static: true }) mapElement: ElementRef;
  @ViewChild('markerContainer', { static: false }) markerContainer: ElementRef;
  @ViewChild('icon', { static: true }) icon: ElementRef;
  @Input() public zoom: number = AZURE_MAP.PORT_MAP_ZOOM;
  @Input() public operatorNameEnabled = false;
  @Input() selectedPortId: string;

  mapdetails$: Observable<PortMapModel[]> = this.store.select(selectPortMap);
  public markers: atlas.HtmlMarker[] = [];
  public map: atlas.Map;
  public latitude: number = 38.4510;
  public longitude: number = 15.90500;
  public mapTypeIds = { ...AZURE_MAP.MAP_TYPE_IDS };
  public mapTypeId: string = AZURE_MAP.DEFAULT_MAP_TYPE_ID;
  public mapResponse: any;
  public mapview: any;
  public mapTypeControlOptions = {
    style: 'default',
    position: 'top-right',
  };
  public equipmentIcons = {
    ...ICON.EQUIPMENT_STATUS,
    MAINTENANCE: ICON.EQUIPMENT_STATUS.MAINTENANCE,
  };

  constructor(
    private titlecasePipe: TitleCasePipe,
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getPortMap({ portId: this.selectedPortId }));
      }
    }
  }

  ngAfterViewInit(): void {
    this.mapdetails$.subscribe((mapview) => {
      if (mapview?.length) {
        this.azureMap(mapview);
      }
    })
  }

  azureMap(mapview: PortMapModel[]) {
    // Reuse existing map instance if it exists, otherwise create a new one
    if (!this.map) {
      this.map = new atlas.Map(this.mapElement.nativeElement, {
        center: [mapview[0].map_lot_center, mapview[0].map_lat_center], // Use the first element in mapview to set initial center
        zoom: this.zoom,
        style: 'road_shaded_relief',
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: environment.azureMapSubcriptionKey,
        },
        showFeedbackLink: false,
        showLogo: false,
        language: 'en-US',
      });

      this.map.events.add('load', () => {
        this.map.getCanvasContainer().style.cursor = 'grab';
      });
    } else {
      // If map already exists, set the new center position
      this.map.setCamera({
        center: [mapview[0].map_lot_center, mapview[0].map_lat_center],
        zoom: this.zoom,
      });
    }

    // Clear existing markers
    this.map.markers.clear();

    // Add new markers
    mapview.forEach((view) => {
      const markerPosition = { position: [view.longitude, view.latitude] };

      const marker = new atlas.HtmlMarker({
        position: markerPosition.position,
        htmlContent: this.getHTMLContent(
          view.status,
          view.equipment_type,
          view.maintenance_status,
          view.equipment_name,
          view.current_operator
        ),
      });

      const popup = new atlas.Popup();

      marker.getElement().addEventListener('mouseenter', () => {
        popup.open(this.map);
        this.map.getCanvasContainer().style.cursor = 'pointer';
      });

      marker.getElement().addEventListener('click', () => {
        this.clickViewDetails(view);
      });

      marker.getElement().addEventListener('mouseleave', () => {
        popup.close();
        this.map.getCanvasContainer().style.cursor = 'grab';
      });

      this.map.markers.add(marker);
    });
  }

  clickViewDetails(equipmentDetail: EquipmentDetail) {
    this.dialog.open(JobOrderComponent, {
      data: {
        port_id: this.selectedPortId,
        ...equipmentDetail,
      },
    });
  }

  getEquipmentList(mapview: PortMapModel) {
    if (mapview.current_operator === undefined || mapview.current_operator === "") {
      mapview.current_operator = MESSAGE_DISPLAY.NO_OPERATOR_OR_PILOT.replace(
        ".",
        ""
      );
    }

    if (Number.isNaN(mapview.latitude)) {
      mapview.latitude = null;
    }

    if (Number.isNaN(mapview.longitude)) {
      mapview.longitude = null;
    }

    return mapview;
  }

  getHTMLContent(
    equipmentStatus: string,
    equipmentType: string,
    maintenanceStatus: boolean,
    equipmentName: string,
    currentOperator: string
  ): string {
    const equipmentIcon = this.getIconSrc(equipmentStatus, equipmentType);
    const maintenanceIcon = this.equipmentIcons.MAINTENANCE;

    const operatorName = this.operatorNameEnabled
      ? `<div class="marker-operator" style="font-weight: bold;">${currentOperator}</div>`
      : '';

    const maintenanceBadge = maintenanceStatus
      ? `<img style="width: 35px; height: 35px; position: absolute; top: 30px; right: 10px; z-index: 5;" src="${maintenanceIcon}" class="equipment-status-maintenance" />`
      : '';

    return `
      <div class="equipment-status-icon" style="text-align: center; position: relative;">
        <img style="width: 50px; height: 50px; position: relative;" src="${equipmentIcon}" class="equipment-status-img"/>
        ${maintenanceBadge}
      </div>
      <div class="marker-details" style="text-align: center;font-weight: bold;">
        <div class="marker-equipment" style="font-weight: bold;">${equipmentName}</div>
        ${operatorName}
      </div>`;
  }

  getIconSrc(equipmentStatus: string, equipmentType: string) {
    equipmentStatus = this.titlecasePipe.transform(equipmentStatus);

    let equipmentIcon;
    switch (equipmentStatus) {
      case EQUIPMENT.EQUIPMENT_STATUS.ACTIVE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].ACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.INACTIVE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].INACTIVE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.IDLE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].IDLE;
        break;
      case EQUIPMENT.EQUIPMENT_STATUS.NON_PRODUCTIVE:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].NON_PRODUCTIVE;
        break;
      default:
        equipmentIcon = this.equipmentIcons[equipmentType.toUpperCase()].INACTIVE;
        break;
    }

    return equipmentIcon;
  }

}

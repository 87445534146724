import { createAction, props } from '@ngrx/store';
import {
  ErrorResponse,
  SocialResponsibilityModel,
  WorkforceDiversityModel,
  EngagementAndTrainingMetricsModel,
  LTIFModel,
  SafetyAndHealthMetricsModel
} from '../../models';

export const getSocialResponsibility = createAction(
  '[Sustainability Dashboard Page] Get Social Responsibility',
  props<{ portsId?: string }>()
);

export const getSocialResponsibilitySuccess = createAction(
  '[Sustainability Dashboard Page] Get Social Responsibility Success',
  props<{ socialResponsibility: SocialResponsibilityModel }>()
);

export const getSocialResponsibilityFailure = createAction(
  '[Sustainability Dashboard Page] Get Social Responsibility Failure',
  props<{ error: ErrorResponse }>()
);

export const getWorkforceDiversity = createAction(
  '[Social Responsibility Page] Get Workforce Diversity',
  props<{ year?: string }>()
);

export const getWorkforceDiversitySuccess = createAction(
  '[Social Responsibility Page] Get Workforce Diversity Success',
  props<{ workforceDiversity: WorkforceDiversityModel }>()
);

export const getWorkforceDiversityFailure = createAction(
  '[Social Responsibility Page] Get Workforce Diversity Failure',
  props<{ error: ErrorResponse }>()
);

export const getEngagementAndTrainingMetrics = createAction(
  '[Social Responsibility Page] Get Engagement and Training Metrics',
  props<{ year?: string }>()
);

export const getEngagementAndTrainingMetricsSuccess = createAction(
  '[Social Responsibility Page] Get Engagement and Training Metrics Success',
  props<{ engagementTrainingMetrics: EngagementAndTrainingMetricsModel }>()
);

export const getEngagementAndTrainingMetricsFailure = createAction(
  '[Social Responsibility Page] Get Engagement and Training Metrics Failure',
  props<{ error: ErrorResponse }>()
);

export const getSafetyAndHealthMetrics = createAction(
  '[Social Responsibility Page] Get Safety and Health Metrics',
  props<{ year?: string }>()
);

export const getSafetyAndHealthMetricsSuccess = createAction(
  '[Social Responsibility Page] Get Safety and Health Metrics Success',
  props<{ safetyHealthMetrics: SafetyAndHealthMetricsModel }>()
);

export const getSafetyAndHealthMetricsFailure = createAction(
  '[Social Responsibility Page] Get Safety and Health Metrics Failure',
  props<{ error: ErrorResponse }>()
);

export const getLostTimeInjuryFrequency = createAction(
  '[Social Responsibility Page] Get Lost Time Injury Frequency',
  props<{ year?: string }>()
);

export const getLostTimeInjuryFrequencySuccess = createAction(
  '[Social Responsibility Page] Get Lost Time Injury Frequency Success',
  props<{ lostTimeInjuryFrequency: LTIFModel }>()
);

export const getLostTimeInjuryFrequencyFailure = createAction(
  '[Social Responsibility Page] Get Lost Time Injury Frequency Failure',
  props<{ error: ErrorResponse }>()
);

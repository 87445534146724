import { createAction, props } from '@ngrx/store';
import { PortModel } from '../../models/port.models';
import { ErrorResponse } from '../../models/error-response.model';

export const getPort = createAction('[Dashboard] Get Port');

export const getPortSuccess = createAction(
  '[Get Port API] Get Port Successful',
  props<{ ports: Array<PortModel> }>()
);

export const getPortFailure = createAction(
  '[Get Port API] Get Port Failed',
  props<{ error: ErrorResponse }>()
);
import { createAction, props } from '@ngrx/store';
import { CarbonMngmtAndEmissionReductionModel } from '../../models/sustainability.model';
import { ErrorResponse } from '../../models/error-response.model';
import { CarbonEmissionReduceSummaryModel } from '../../models/carbon-emission-reduce-summary.model';
import { OverallCarbonIntensityModel } from '../../models/overallcarbonint.model';
import { PortElectrificationInsightModel } from '../../models/port-electrification-insight.model';
import { CarbonCreditTradingSequestrationModel } from '../../models/carbon-credit-trading-sequestration.model';
import { PortElectrificationOverviewModel } from '../../models/port-electrification-overview.model';
import { EmissionFactorsModel } from '../../models/emission-factors.model';
import { RenewableEnergyShareModel } from '../../models/renewable-energy-share.model';
import { RenewableEnergyGraphModel } from '../../models/renewable-energy-graph.model';
import { QuarterlyPlantShareModel } from '../../models/quarterly-share.model';

export const getCarbonMngmtAndEmissionReduction = createAction(
  '[Sustainability Dashboard] Get Carbon Mngmt And Emission Reduction',
  props<{ portsId?: string }>()
);

export const getCarbonMngmtAndEmissionReductionSuccess = createAction(
  '[Sustainability Dashboard] Get Carbon Mngmt And Emission Reduction Successful',
  props<{ carbonMngmtEmission: CarbonMngmtAndEmissionReductionModel }>()
);

export const getCarbonMngmtAndEmissionReductionFailure = createAction(
  '[Sustainability Dashboard] Get Carbon Mngmt And Emission Reduction Failed',
  props<{ error: ErrorResponse }>()
);

export const getOverallCarbonIntensity = createAction(
  '[Carbon Management Page] Get Overall Carbon Intensity',
  props<{ years?: string }>()
);

export const getOverallCarbonIntensitySuccess = createAction(
  '[Carbon Management Page] Get Overall Carbon Intensity Success',
  props<{ overallCarbonIntensity: OverallCarbonIntensityModel }>()
);

export const getOverallCarbonIntensityFailure = createAction(
  '[Carbon Management Page] Get Overall Carbon Intensity Failure',
  props<{ error: ErrorResponse }>()
);

export const getCarbonEmissionReduceSummary = createAction(
  '[Carbon Management Page] Get Carbon Emission Reduce Summary',
  props<{ years?: string }>()
);

export const getCarbonEmissionReduceSummarySuccess = createAction(
  '[Carbon Management Page] Get Carbon Emission Reduce Summary Success',
  props<{ carbonEmissionSummary: CarbonEmissionReduceSummaryModel }>()
);

export const getCarbonEmissionReduceSummaryFailure = createAction(
  '[Carbon Management Page] Get Carbon Emission Reduce Summary Failure',
  props<{ error: ErrorResponse }>()
);

export const getPortElectrificationOverview = createAction(
  '[Carbon Management Page] Get Port Electrification Overview',
  props<{ year?: string }>()
);

export const getPortElectrificationOverviewSuccess = createAction(
  '[Carbon Management Page] Get Port Electrification Overview Success',
  props<{ portElectrificationOverview: PortElectrificationOverviewModel }>()
);

export const getPortElectrificationOverviewFailure = createAction(
  '[Carbon Management Page] Get Port Electrification Overview Failure',
  props<{ error: ErrorResponse }>()
);

export const getPortElectrificationInsight = createAction(
  '[Carbon Management Page] Get Port Electrification Insight',
  props<{ year?: string }>()
);

export const getPortElectrificationInsightSuccess = createAction(
  '[Carbon Management Page] Get Port Electrification Insight Success',
  props<{ portElectrificationInsight: PortElectrificationInsightModel }>()
);

export const getPortElectrificationInsightFailure = createAction(
  '[Carbon Management Page] Get Port Electrification Insight Failure',
  props<{ error: ErrorResponse }>()
);

export const getCarbonCreditTradingSequestration = createAction(
  '[Carbon Management Page] Get Carbon Credit Trading Sequestration',
  props<{ year?: string }>()
);

export const getCarbonCreditTradingSequestrationSuccess = createAction(
  '[Carbon Management Page] Get Carbon Credit Trading Sequestration Success',
  props<{ carbonCreditTradingSequestration: CarbonCreditTradingSequestrationModel }>()
);

export const getCarbonCreditTradingSequestrationFailure = createAction(
  '[Carbon Management Page] Get Carbon Credit Trading Sequestration Failure',
  props<{ error: ErrorResponse }>()
);

export const getEmissionFactors = createAction(
  '[Carbon Management Page] Get Emission Factors'
);

export const getEmissionFactorsSuccess = createAction(
  '[Carbon Management Page] Get Emission Factors Successful',
  props<{ emissionFactors: Array<EmissionFactorsModel> }>()
);

export const getEmissionFactorsFailure = createAction(
  '[Carbon Management Page] Get Emission Factors Failed',
  props<{ error: ErrorResponse }>()
);

export const getRenewableEnergyShare = createAction(
  '[Carbon Management Page] Get Renewable Energy Share',
  props<{ year?: string }>()
);

export const getRenewableEnergyShareSuccess = createAction(
  '[Carbon Management Page] Get Renewable Energy Share Success',
  props<{ renewableEnergyShare: RenewableEnergyShareModel }>()
);

export const getRenewableEnergyShareFailure = createAction(
  '[Carbon Management Page] Get Renewable Energy Share Failure',
  props<{ error: ErrorResponse }>()
);

export const getRenewableEnergyGraph = createAction(
  '[Carbon Management Page] Get Renewable Energy Graph',
  props<{ year?: string }>()
);

export const getRenewableEnergyGraphSuccess = createAction(
  '[Carbon Management Page] Get Renewable Energy Graph Successful',
  props<{ renewableEnergyGraph: RenewableEnergyGraphModel }>()
);

export const getRenewableEnergyGraphFailure = createAction(
  '[Carbon Management Page] Get Renewable Energy Graph Failed',
  props<{ error: ErrorResponse }>()
);


export const  getQuarterlyShare = createAction(
  '[Carbon Management Page] Get Quarterly Share',
  props<{ year?: string }>()
);

export const  getQuarterlyShareSuccess  = createAction(
  '[Carbon Management Page] Get Quarterly Share Successful',
  props<{ quarterlyShare: QuarterlyPlantShareModel} >()
);

export const  getQuarterlyShareFailure = createAction(
  '[Carbon Management Page] Get Quarterly Share Failed',
  props<{ error: ErrorResponse }>()
);



import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { WasteManagementState } from "./waste-management.reducer";


export const selectWasteManagementState = (state: AppState) => state.wasteManagement;
export const selectWasteManagement = createSelector(
    selectWasteManagementState,
  (state: WasteManagementState) => state.wasteManagement
);





export const PAGINATOR = {
  EQUIPMENT: {
    LIST: {
      pageIndex: 0,
      pageSize: 10,
      pageSizeOptions: [5, 10, 20, 30, 40, 50]
    },
    HISTORICAL_MAINTENANCE: {
      pageIndex: 0,
      pageSize: 10,
      pageSizeOptions: []
    }
  },
  DASHBOARD: {
    EQUIPMENT_STATUS: {
      pageIndex: 0,
      pageSize: 5,
      pageSizeOptions: [5, 10, 20, 30, 40, 50]
    },
    OPERATING_STATUS: {
      pageIndex: 0,
      pageSize: 5,
      pageSizeOptions: [5, 10, 20, 30, 40, 50]
    },
    SENSOR_EVALUATION: {
      pageIndex: 0,
      pageSize: 3,
      pageSizeOptions: [3, 6, 9, 12, 16, 20]
    },
  }
};
export const OUTBOUND_TRUCKS = {
  PAGINATOR: {
    PAGE_SIZE: '5',
    PAGE_INDEX: '0',
    PAGE_SIZE_OPTIONS: ['5', '10', '20', '30', '40', '50']
  }
}

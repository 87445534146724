import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { OperationState } from "./operations.reducers";


export const selectOperationState = (state: AppState) => state.operations;

export const selectOperation = createSelector(
  selectOperationState,
  (state: OperationState) => state.operation
);

export const selectPortProductivity = createSelector(
  selectOperationState,
  (state: OperationState) => state.portProductivity
);

export const selectPortMap = createSelector(
  selectOperationState,
  (state: OperationState) => state.portMap
);

export const selectBerthingStatus = createSelector(
  selectOperationState,
  (state: OperationState) => state.berthingStatus
);

export const selectOperatingStatus = createSelector(
  selectOperationState,
  (state: OperationState) => state.operatingStatus
);

export const selectEquipmentStatus = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentStatus
);

export const selectEquipmentJobOrder = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentJobOrder
);

export const selectEquipmentList = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentList
);

export const selectEquipmentSelectedPortId = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentSelectedPortId
);

export const selectEquipmentDetails = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentDetails
);

export const selectCraneProductivity = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneProductivity
);

export const selectCraneMovement = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneMovement
);

export const selectCraneUtilization = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneUtilizationHistory
);

export const selectMovementTimeOverview = createSelector(
  selectOperationState,
  (state: OperationState) => state.movementTimeOverview
);

export const selectEquipmentMaintenanceSummary = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentMaintenanceSummary
);

export const selectEquipmentPartInspection = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentPartsInspection
);

export const selectHistoricalMaintenance = createSelector(
  selectOperationState,
  (state: OperationState) => state.historicalMaintenance
);

export const selectEquipmentMaintenanceAlert = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentAlert
);

export const selectAnalyticsPortId = createSelector(
  selectOperationState,
  (state: OperationState) => state.analyticsSelectedPortId
);

export const selectKpiPortId = createSelector(
  selectOperationState,
  (state: OperationState) => state.kpiSelectedPortId
);

export const selectEquipmentFailureChart = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentFailureChart
);

export const selectEquipmentAlarmAnalysis = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentAlarmAnalysis
);

export const selectTotalContainersMovement = createSelector(
  selectOperationState,
  (state: OperationState) => state.totalContainersMovement
);

export const selectContainersMovementTypes = createSelector(
  selectOperationState,
  (state: OperationState) => state.containersMovementTypes
);

export const selectCraneUtilizationAnalytics = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneUtilizationAnalytics
);

export const selectPortProductivityEquipmentBerthUtilization = createSelector(
  selectOperationState,
  (state: OperationState) => state.portEquipmentBerth
);

export const selectCraneSensorEvaluation = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneSensorEvaluation
);

export const selectTopVesselDelay = createSelector(
  selectOperationState,
  (state: OperationState) => state.topVesselDelay
);

export const selectBerthingStatusOverview = createSelector(
  selectOperationState,
  (state: OperationState) => state.berthingStatusOverview
);

export const selectQuayYardStatus = createSelector(
  selectOperationState,
  (state: OperationState) => state.quayYardStatus
);

export const selectTugboatStatus = createSelector(
  selectOperationState,
  (state: OperationState) => state.tugboatStatus
);

export const selectEquipmentAlertAnalytics = createSelector(
  selectOperationState,
  (state: OperationState) => state.equipmentAlertsAnalytics
);

export const selectAlertsLogData = createSelector(
  selectOperationState,
  (state: OperationState) => state.logData
);

export const selectPortProductivityKpi = createSelector(
  selectOperationState,
  (state: OperationState) => state.portProductivityKpi
);

export const selectCraneKpi = createSelector(
  selectOperationState,
  (state: OperationState) => state.craneKpi
);

export const selectTugboatKpi = createSelector(
  selectOperationState,
  (state: OperationState) => state.tugboatKpi
);

export const selectAverageTurnTime = createSelector(
  selectOperationState,
  (state: OperationState) => state.averageTurnTime
);

export const selectContainerGateMoves = createSelector(
  selectOperationState,
  (state: OperationState) => state.containerGateMoves
);

export const selectOverstayingTrucks = createSelector(
  selectOperationState,
  (state: OperationState) => state.overstayingTrucks
);

export const selectAverageContainerDwellTime = createSelector(
  selectOperationState,
  (state: OperationState) => state.averageContainerDwellTime
);

export const selectYardContainerSizeType = createSelector(
  selectOperationState,
  (state: OperationState) => state.yardContainerSizeType
);

export const selectHazardousMaterial = createSelector(
  selectOperationState,
  (state: OperationState) => state.hazardousMaterial
);

import { Component, OnInit } from '@angular/core';
import * as bioData from '../../../shared/mock-data/biodiversity-data.json'


@Component({
  selector: 'app-biodiversity-page',
  templateUrl: './biodiversity-page.component.html',
  styleUrls: ['./biodiversity-page.component.scss']
})
export class BiodiversityPageComponent implements OnInit {
  public dataBiodiversity;
  constructor() { }

  ngOnInit(): void {
    this.dataBiodiversity = bioData.biodiversity;
  }

}

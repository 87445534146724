import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobOrderComponent } from 'src/app/operations-dashboard/components/job-order/job-order.component';
import { MatTableDataSource } from '@angular/material/table';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TugboatList, TugboatStatusModel } from 'src/app/shared/models/tugboat-list.model';
import { selectTugboatStatus } from 'src/app/shared/state/operations/operations.selectors';
import { getTugboatStatus } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-tugboat-status',
  templateUrl: './tugboat-status.component.html',
  styleUrls: ['./tugboat-status.component.scss']
})
export class TugboatStatusComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  tugboatStatus$: Observable<TugboatStatusModel> = this.store.select(selectTugboatStatus);
  public displayedColumns = ['tugboatId', 'idlingRate', 'utilizationRate', 'viewWordOrder'];
  dataSource = new MatTableDataSource<TugboatList>();
  public tugboatStatus = { ...EQUIPMENT.EQUIPMENT_STATUS, MAINTENANCE: EQUIPMENT.EQUIPMENT_STATUS.MAINTENANCE };
  public utilPieChartLabels: string[] = [];
  public utilPieChartType: string = CHART.TYPE.PIE;
  public utilPieChartLegend: boolean = false;
  public showIdlingRate: boolean = false;
  public showUtilizationRate: boolean = false;
  public noData = true;


  constructor(
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getTugboatStatus({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tugboatStatus$.subscribe((data) => {
        if (data?.tugboat_list?.length > 0) {
          this.createTable(data);
          this.noData = false;
        } else {
          this.noData = true;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getTugboatStatus({ portId: this.selectedPortId }));
      }
    }
  }

  createTable(data: TugboatStatusModel) {
    this.dataSource.data = data.tugboat_list;
  }

  clickViewDetails(tugboatList: TugboatList): void {
    this.dialog.open(JobOrderComponent, {
      data: {
        port_id: this.selectedPortId,
        ...tugboatList
      }
    });
  }
}

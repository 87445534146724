import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { SocialResponsibilityState } from "./social-responsibility.reducer";

export const selectSocialResponsibilityState = (state: AppState) =>
  state.socialResponsibility;

export const selectSocialResponsibility = createSelector(
  selectSocialResponsibilityState,
  (state: SocialResponsibilityState) => state.socialResponsibility
);

export const selectWorkforceDiversity = createSelector(
  selectSocialResponsibilityState,
  (state: SocialResponsibilityState) => state.workforceDiversity
);

export const selectEngagementTrainingMetrics = createSelector(
  selectSocialResponsibilityState,
  (state: SocialResponsibilityState) => state.engagementTrainingMetrics
);

export const selectSafetyAndHealthMetrics = createSelector(
  selectSocialResponsibilityState,
  (state: SocialResponsibilityState) => state.safetyHealthMetrics
);

export const selectLostTimeInjuryFrequency = createSelector(
  selectSocialResponsibilityState,
  (state: SocialResponsibilityState) => state.lostTimeInjuryFrequency
);


export const ICON: any = {
  ARROW_GREEN_DOWN: 'assets/icons/arrow-down-green.svg',
  ARROW_GREEN_UP: 'assets/icons/kpi-up.svg',
  ARROW_RED_DOWN: 'assets/icons/kpi-red-down.svg',
  ARROW_RED_UP: 'assets/icons/arrow-up-red.svg',
  EQUIPMENT_STATUS: {
    CRANE: {
      ACTIVE: '/assets/icons/Crane-Active.svg',
      IDLE: '/assets/icons/Crane-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/Crane-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/Crane-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/Crane-NonProductive.svg'
    },
    QUAYCRANE: {
      ACTIVE: '/assets/icons/Crane-Active.svg',
      IDLE: '/assets/icons/Crane-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/Crane-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/Crane-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/Crane-NonProductive.svg'
    },
    RTG: {
      ACTIVE: '/assets/icons/RTG-Active.svg',
      IDLE: '/assets/icons/RTG-Idle.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/RTG-Inactive.svg', // Engine is off
      INACTIVE: '/assets/icons/RTG-Inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/RTG-NonProductive.svg'
    },
    TUGBOAT: {
      ACTIVE: '/assets/icons/tugboat_active.svg',
      IDLE: '/assets/icons/tugboat_idling.svg', // Turned on but there is no movement
      NON_UTILIZED: '/assets/icons/tugboat_inactive.svg', // Engine is off,
      INACTIVE: '/assets/icons/tugboat_inactive.svg', // Engine is off
      NON_PRODUCTIVE: '/assets/icons/tugboat_non_productive.svg'
    },
    MAINTENANCE: '/assets/icons/Alert-Amber.svg'
  }
}

export const IMG: any = {
  DIGITAL_TWIN_BLACK: 'assets/images/digital-twin-black.png',
  OPERATIONS_BLACK: 'assets/images/operations-black.png',
  OPERATIONS_WHITE: 'assets/images/operations-white.png',
  PORT_AUTHORITY_BLACK: 'assets/images/port-authority-black.png',
  SETTINGS_BLACK: 'assets/images/settings-black.png',
  SUSTAINABILITY_BLACK: 'assets/images/sustainability-black.png',
  SUSTAINABILITY_WHITE: 'assets/images/sustainability-white.png',
  YARD_MNGMT_BLACK: 'assets/images/yard-mngmt-black.png'
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionalMapRoutingModule } from './regional-map-routing.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RegionalMapRoutingModule
  ]
})
export class RegionalMapModule { }

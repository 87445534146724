import { Component, OnInit, OnChanges, Input, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable } from 'rxjs';
import { CHART, MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { YardContainerSizeModel } from 'src/app/shared/models/yard-container-size.model';
import { getYardContainerSizeType } from 'src/app/shared/state/operations/operations.action';
import { selectYardContainerSizeType } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-yard-inventory',
  templateUrl: './yard-inventory.component.html',
  styleUrls: ['./yard-inventory.component.scss']
})
export class YardInventoryComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  selectedPortId: string;

  yardContainerSizeType$: Observable<YardContainerSizeModel[]> = this.store.select(selectYardContainerSizeType);
  private chartDataSet = CHART.YARD_INVENTORY_CONTAINER.DATASET;
  public barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    tooltips: {
      callbacks: {}
    },
    plugins: {
      labels: {
        fontSize: 0,
      },
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        maxBarThickness: 30,
        gridLines: { display: false }
      }],
      yAxes: [{
        ticks: {
          stepSize: 1000
        },
        scaleLabel: {
          display: true
        }
      }]
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: String = CHART.CHART_TYPE.bar;
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [{
    data: [],
    label: '',
    stack: 'a',
    backgroundColor: '',
    hoverBackgroundColor: ''
  }];
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = false;

  private yardinventorydata: any[] = [];

  constructor(private store: Store, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.store.dispatch(getYardContainerSizeType({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    this.yardContainerSizeType$.subscribe((data) => {
      if (data) {
        this.yardinventorydata = data;
        // data.sort((a, b) => a.size > b.size ? 1 : -1);
        // data.reverse();
        this.initializeYardContainerSizeData(data);
        this.noData = false;
        this.cdr.detectChanges();
      } else {
        this.noData = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getYardContainerSizeType({ portId: this.selectedPortId }));
      }
    }
  }

  initializeYardContainerSizeData(data: YardContainerSizeModel[]) {
    this.barChartLabels = [];
    this.barChartData = [];
    this.chartDataSet.empty = [];
    this.chartDataSet.full = [];
    this.chartDataSet.other = [];

    data.map(el => {
      this.barChartLabels.push(el.description);
      this.chartDataSet.empty.push(el.empty);
      this.chartDataSet.full.push(el.full);
      this.chartDataSet.other.push(el.other);
    });

    Object.entries(this.chartDataSet).forEach((el, index) => {
      const item = {
        data: [...el[1]],
        label: CHART.YARD_INVENTORY_CONTAINER.LABELS[index],
        stack: el[0] == 'size' ? 'b' : 'a',
        backgroundColor: CHART.YARD_INVENTORY_CONTAINER.COLORS[index],
        hoverBackgroundColor: CHART.YARD_INVENTORY_CONTAINER.COLORS[index]
      }
      this.barChartData.push(item);
    });

    this.barChartOptions.tooltips.callbacks = {
      title: function (context) {
        return this.yardinventorydata[context[0].index].description;
      }.bind(this),
      label: function (context) {
        const index = context.index;
        const text = context.datasetIndex === 2 ? 'Other' : context.datasetIndex === 1 ? 'Full' : 'Empty'; return `${text}: ${context.value}`;



      }
    };


    this.cdr.detectChanges();
  }
}
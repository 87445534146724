import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-opti-service',
  templateUrl: './opti-service.component.html',
  styleUrls: ['./opti-service.component.scss']
})
export class OptiServiceComponent implements OnInit, AfterViewInit {
  activeButton: number = 1;
  constructor() { }

  ngOnInit(): void {
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }

  ngAfterViewInit() {



  }

}

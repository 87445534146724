import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectIsValidSession, selectUserDetails } from "../shared/state/user/user.selectors";
import { getUserDetails } from "../shared/state/user/user.actions";
import { SessionManager } from "../shared";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  public isValidSession = sessionStorage.getItem('isValidSession');
  public isValidSessionState$ = this.store.select(selectIsValidSession);
  public user = this.store.select(selectUserDetails);

  constructor(private store: Store) { }

  ngOnInit(): void {
    const oid = SessionManager.getFromToken("oid");
    this.isValidSessionState$.subscribe(isSessionCreated => {
      if (isSessionCreated) {
        this.isValidSession = `${isSessionCreated}`;
      } 
    });

    if(this.isValidSession === 'true'){
      this.store.dispatch(getUserDetails({ userId: oid }));
    }
  }
}


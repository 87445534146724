import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { PortModel } from "src/app/shared/models";
import { getPort } from "src/app/shared/state/port/port.actions";
import { selectPorts } from "src/app/shared/state/port/port.selectors";



@Component({
  selector: 'app-energy-dashboard',
  templateUrl: './energy-dashboard.component.html',
  styleUrls: ['./energy-dashboard.component.scss']
})
export class EnergyDashboardComponent implements OnInit, AfterViewInit {
  public showEquipmentOptions = false;
  public showEquipmentTypeOptions = false;
  public showPortOptions = false;

  public isEquipmentArrowUpVisible = false;
  public isEquipmentTypeArrowUpVisible = false;
  public isPortArrowUpVisible = false;
  public selectedEquipment = 'Equipment';
  public equipmentTypes: string[] = ['QuayCrane', 'RTG'];
  public allEquipments: string[] = [];

  quayCranes01: string[] = ['QC01', 'QC02', 'QC03'];
  quayCranes02: string[] = ['QGC01', 'QGC02', 'QGC03'];
  quayCranes03: string[] = ['STS01', 'STS02', 'STS03'];
  rtgs01: string[] = ['RTG01', 'RTG02'];
  rtgs02: string[] = ['RG01', 'RG02'];
  rtgs03: string[] = ['RT01', 'RT02'];
  All: string[] = ['RT01', 'RT02'];

  public selectedType = 'All';
  public showOptions = false;
  public isArrowUpVisible = false;
  public ports$ = this.store.select(selectPorts);
  public selectedPortId: string;
  equipmentshowOptions: boolean = false;
  isEquipmentUpVisible: boolean = false;
  public selectedOption: string = "Select Port";
  public noData: boolean;
  public equipmentName = 'All';
  public type = "All";
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getPort());
    this.updateEquipmentTypes();
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 0) {
        
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }

  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;
  }

  toggleEquipmentTypeOptions() {
    this.showEquipmentTypeOptions = !this.showEquipmentTypeOptions;
    this.showEquipmentOptions = false;
    this.isEquipmentTypeArrowUpVisible = !this.isEquipmentTypeArrowUpVisible;
    this.isEquipmentArrowUpVisible = false;
  }
  toggleEquipmentOptions() {
    this.showEquipmentOptions = !this.showEquipmentOptions;
    this.isEquipmentArrowUpVisible = !this.isEquipmentArrowUpVisible;
  }


  showCheckboxes() {
    if ((this.showOptions = true)) {
      this.isArrowUpVisible = true;
    } else if ((this.showOptions = false)) {
      this.isArrowUpVisible = false;
    }
  }

  handleCheckboxClick(port: any): void {
    this.selectedOption = port.port_name;
    this.selectedPortId = port.port_id;
  }

  handleOptionClick(equipment) {
    this.selectedEquipment = equipment;
    this.showEquipmentOptions = false;
    this.isEquipmentArrowUpVisible = false;

  }

  TypehandleOptionClick(selectedType: string) {
    this.selectedType = selectedType;
    this.showEquipmentTypeOptions = false;
    this.isEquipmentTypeArrowUpVisible = false;
  }

  handleMouseLeave() {
    this.showOptions = false;
    this.isArrowUpVisible = false;
    this.showEquipmentOptions = false;
    this.showEquipmentTypeOptions = false;
    this.isEquipmentArrowUpVisible = false;
    this.isEquipmentTypeArrowUpVisible = false;
  }
  updateEquipmentTypes() {
    this.selectedEquipment = 'All';
    const port = "PORT01";

    switch (this.selectedType) {
      case 'All':
        if (port === "PORT01") {
          this.allEquipments = [...this.quayCranes01, ...this.rtgs01];
        } else if (port === "PORT02") {
          this.allEquipments = [...this.quayCranes02, ...this.rtgs02];
        } else if (port === "PORT03") {
          this.allEquipments = [...this.quayCranes03, ...this.rtgs03];
        }
        break;
      case 'QuayCrane':
        if (port === "PORT01") {
          this.allEquipments = this.quayCranes01;
        } else if (port === "PORT02") {
          this.allEquipments = this.quayCranes02;
        } else if (port === "PORT03") {
          this.allEquipments = this.quayCranes03;
        }
        break;
      case 'RTG':
        if (port === "PORT01") {
          this.allEquipments = this.rtgs01;
        } else if (port === "PORT02") {
          this.allEquipments = this.rtgs02;
        } else if (port === "PORT03") {
          this.allEquipments = this.rtgs03;
        }
        break;
      default:
        this.allEquipments = [];
        break;
    }

    this.getOverallData();
  }



  getOverallData() {
    //future need for debugging
    // console.log('Selected Equipment:', this.selectedEquipment);
    // console.log('Selected Type:', this.selectedType);

  }


}

import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { SustainabilityState } from "./sustainability.reducer";

export const selectSustainabilityState = (state: AppState) => state.sustainability;

export const selectCarbonMngmtEmission = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.carbonMngmtEmission
);

export const selectStatus = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.status
);

export const selectOverallCarbonIntensity = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.overallCarbonIntensity
);

export const selectCarbonEmissionSummary = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.carbonEmissionSummary
);

export const selectPortElectrificationOverview = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.portElectrificationOverview
);

export const selectPortElectrificationInsight = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.portElectrificationInsight
);

export const selectCarbonCreditTradingSequestration = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.carbonCreditTradingSequestration
);

export const selectEmissionFactors = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.emissionFactors
);

export const selectRenewableEnergyShare = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.renewableEnergyShare
);

export const selectRenewableEnergyGraph = createSelector(
  selectSustainabilityState,
  (state: SustainabilityState) => state.renewableEnergyGraph
);


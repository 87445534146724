import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { ICON } from 'src/assets/icons/assets.constant';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART } from 'src/app/shared/constants/chart.constant';

@Component({
  selector: 'app-seabins-collection',
  templateUrl: './seabins-collection.component.html',
  styleUrls: ['./seabins-collection.component.scss']
})
export class SeabinsCollectionComponent implements OnInit {
  private seabinsStackedBar: Chart;

  constructor() { }

  ngOnInit(): void {
    this.createHorizontalStackedBar(); //Waste Collected Chart
  }

  createHorizontalStackedBar() {
    if (this.seabinsStackedBar) {
      this.seabinsStackedBar.destroy();
    }

    const canvas = document.getElementById('seabinCollectionChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "seabinCollectionChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d'); // Get the 2D context
    const COLOR = CHART.COLOR.WASTE_COLLECTED_BY_SEABINS;

    this.seabinsStackedBar = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: ['Share %'],
        datasets: [
          {
            label: 'Plastics',
            data: [23],
            backgroundColor: COLOR.BLUE,
          },
          {
            label: 'Bottles',
            data: [18],
            backgroundColor: COLOR.ORANGE,
          },
          {
            label: 'Foams',
            data: [16],
            backgroundColor: COLOR.GRAY,
          },
          {
            label: 'Hard Fragments',
            data: [15],
            backgroundColor: COLOR.YELLOW,
          },
          {
            label: 'Film',
            data: [8],
            backgroundColor: COLOR.BLUE_1,
          },
          {
            label: 'Cigarettes',
            data: [8],
            backgroundColor: COLOR.GREEN,
          },
          {
            label: 'Others',
            data: [7],
            backgroundColor: COLOR.BLUE_2,
          },
          {
            label: 'Pellets',
            data: [5],
            backgroundColor: COLOR.BROWN,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'point',
          callbacks: {},
        },
        hover: {
          mode: 'point',
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: true,
                fontFamily: 'Roboto',
                fontSize: 15,
                fontStyle: 'normal',
                fontColor: COLOR.DARK_BLUE,
                callback: function (value) {
                  return value + '%';
                },
              },
            },
          ],
          yAxes: [{ stacked: true, display: false }],
          gridLines: { display: true },
        },
        legend: {
          display: true,
          position: 'top',
          align: 'center',
          labels: {
            fontSize: 12,
            boxWidth: 10,
            padding: 24
          }
        },
        plugins: {
          datalabels: {
            display: true,
            color: COLOR.WHITE,
            anchor: 'center',
            align: 'center',
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              family: 'Roboto',
              size: 15,
              weight: 'bold',
            },
            formatter: function (value, ctx) {
              if (value > 0) {
                return value + '%';
              }
            },
          },
        },
      },
      plugins: [ChartDataLabels]
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? 'percent-red' : 'percent-green';
  }
}

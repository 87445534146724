import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { ErrorResponse } from '../models/error-response.model';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private serviceUrl = environment.basePath + API.USER;

  constructor(private http: HttpClient) { }

  createUserSession(): Observable<ApiResponse | ErrorResponse | any>{
    return this.http.post<ApiResponse | ErrorResponse>(`${this.serviceUrl}/session`, {});
  }

  getUserDetailById(userId: string): Observable<ApiResponse | ErrorResponse | any>{
    return this.http.get<ApiResponse | ErrorResponse>(`${this.serviceUrl}/${userId}`);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationsDashboardComponent } from './operations-dashboard.component';
import { OperationsNavModule } from './operations-nav/operations-nav.module';
import { EquipmentDetailsModule } from './equipment-details/equipment-details.module';
import { ServicesInfoModule } from './operations-nav/port-optimization/opti-service/services-info/services-info.module';

const routes: Routes = [
  {
    path: "",
    component: OperationsDashboardComponent,
    data: { breadcrumb: "Operations Dashboard" },
    children: [
      {
        path: "",
        redirectTo: "page",
        pathMatch: "full"
      },
      {
        path: "page",
        loadChildren: () => OperationsNavModule,
        data: { breadcrumb: "" },
      },
      {
        path: "equipment",
        loadChildren: () => EquipmentDetailsModule,
        data: { breadcrumb: "" },
      },
      {
        path: "info-service",
        loadChildren: () => ServicesInfoModule,
        data: { breadcrumb: "" },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsDashboardRoutingModule { }

import { createReducer, on } from '@ngrx/store';
import { BiodiversityModel } from '../../models/biodiversity.model';
import { ErrorResponse } from '../../models/error-response.model';
import { ApiStatus } from '../../enum/api-status.enum';
import {
  getBiodiversity,
  getBiodiversityFailure,
  getBiodiversitySuccess,
  getQuarterlyShare,
  getQuarterlyShareFailure,
  getQuarterlyShareSuccess,
  getTreePlantingMetrics,
  getTreePlantingMetricsFailure,
  getTreePlantingMetricsSuccess,
  getCommunityEducation,
  getCommunityEducationFailure,
  getCommunityEducationSuccess,
  getProtectionWaters,
  getProtectionWatersFailure,
  getProtectionWatersSuccess
} from './biodiversity.actions';
import { QuarterlyPlantShareModel } from '../../models/quarterly-share.model';
import { ProtectionWatersModel } from '../../models/protection-waters.model';
import { CommunityEducationModel } from '../../models/community-education.model';
import { TotalPlantingTreesModel } from '../../models/total-planted-trees.model';

export interface BiodiversityState {
  quarterlyShare: QuarterlyPlantShareModel;
  biodiversity: BiodiversityModel,
  treePlantingMetrics: TotalPlantingTreesModel,
  protectionWaters: ProtectionWatersModel,
  communityEducation: CommunityEducationModel,
  error: ErrorResponse,
  status: 'pending' | 'loading' | 'error' | 'success'
}

export const initialState: BiodiversityState = {
  biodiversity: null,
  quarterlyShare: null,
  treePlantingMetrics: null,
  protectionWaters: null,
  communityEducation: null,
  error: null,
  status: ApiStatus.Pending
}

export const biodiverisityReducer = createReducer(
  initialState,
  on(getBiodiversity, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getBiodiversitySuccess, (state, { biodiversity }) => ({
    ...state,
    biodiversity: biodiversity,
    status: ApiStatus.Success,
    error: null
  })),
  on(getBiodiversityFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getQuarterlyShare, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),

  on(getQuarterlyShareSuccess, (state, { quarterlyShare }) => ({
    ...state,
    quarterlyShare: quarterlyShare,
    status: ApiStatus.Success,
    error: null
  })),
  on(getQuarterlyShareFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getTreePlantingMetrics, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null,
  })),
  on(getTreePlantingMetricsSuccess, (state, { TreePlantingMetrics }) => ({
    ...state,
    treePlantingMetrics: TreePlantingMetrics,
    status: ApiStatus.Success,
    error: null,
  })),
  on(getTreePlantingMetricsFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error,
  })),
  on(getProtectionWaters, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getProtectionWatersSuccess, (state, { protectionWaters }) => ({
    ...state,
    protectionWaters: protectionWaters,
    status: ApiStatus.Success,
    error: null
  })),
  on(getProtectionWatersFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
  on(getCommunityEducation, (state) => ({
    ...state,
    status: ApiStatus.Loading,
    error: null
  })),
  on(getCommunityEducationSuccess, (state, { communityEducation }) => ({
    ...state,
    communityEducation: communityEducation,
    status: ApiStatus.Success,
    error: null
  })),
  on(getCommunityEducationFailure, (state, { error }) => ({
    ...state,
    status: ApiStatus.Error,
    error: error
  })),
);
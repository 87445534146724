import { Component, OnInit, ViewChild, OnChanges, OnDestroy, Input, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { WorkOrderComponent } from 'src/app/operations-dashboard/components/work-order/work-order.component';
import { Store } from "@ngrx/store";
import { AlertsAnalyticsModel } from 'src/app/shared/models';
import { Observable } from 'rxjs';
import { selectEquipmentAlertAnalytics } from 'src/app/shared/state/operations/operations.selectors';
import { getEquipmentAlertAnalytics } from 'src/app/shared/state/operations/operations.action';


@Component({
  selector: 'app-equipment-alerts',
  templateUrl: './equipment-alerts.component.html',
  styleUrls: ['./equipment-alerts.component.scss']
})
export class EquipmentAlertsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  @Input()
  selectedPortId: string;

  equipmenteAlerts$: Observable<AlertsAnalyticsModel[]> = this.store.select(selectEquipmentAlertAnalytics);
  public dataSource = new MatTableDataSource<AlertsAnalyticsModel>();
  public columnHeaders = ['dataPoint', 'description', 'equipment', 'current', 'workRequest', 'time'];
  public pageIndex = 0;
  public pageSize = 5;
  public noAlerts = true;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;

  constructor(
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getEquipmentAlertAnalytics({ portId: this.selectedPortId }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.equipmenteAlerts$.subscribe((data) => {
        if (data?.length) {
          this.createTable(data);
        }
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getEquipmentAlertAnalytics({ portId: this.selectedPortId }));
      }
    }
  }

  createTable(alertsList: AlertsAnalyticsModel[]) {
    if (alertsList !== undefined) {
      if (alertsList.length === 0) {
        this.noAlerts = true;
      } else {
        this.dataSource.data = alertsList;
        this.dataSource.paginator = this.paginator;
        this.noAlerts = false;
      }
    }
  }

  popUpAlert(alert: AlertsAnalyticsModel) {
    const dialogRef = this.dialog.open(WorkOrderComponent, {
      data: { ...alert }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        alert.raised = result.raised;
      }
    });
  }
}

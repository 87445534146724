import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { ServicesInfoRoutingModule } from './services-info-routing.module';
import { VesselInfoComponent } from './vessel-info/vessel-info.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';
import { BerthRegistComponent } from './vessel-info/berth-regist/berth-regist.component';
import { BookingHistoryComponent } from './vessel-info/booking-history/booking-history.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ChronologyComponent } from './booking-info/chronology/chronology.component';
import { TransactionHistoryComponent } from './booking-info/transaction-history/transaction-history.component';
import { UsageInfoComponent } from './booking-info/usage-info/usage-info.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [VesselInfoComponent,
    BookingInfoComponent,
    BerthRegistComponent,
    BookingHistoryComponent,
    ChronologyComponent,
    TransactionHistoryComponent,
    UsageInfoComponent,],
  imports: [
    CommonModule,
    ServicesInfoRoutingModule, HttpClientModule, MatTableModule, MatPaginatorModule, ChartsModule
  ],
  providers: [DatePipe]
})
export class ServicesInfoModule { }

export const API: any = {
  DASHBOARD: '/dashboard-management/dashboard',
  USER: '/user-management/users',
  PORTS: '/port-management',
  KPI: '/sustainability-management/sustainability/kpi',
  SUSTAINABILITY: '/sustainability-management/sustainability',
  OPERATIONS: '/operations-management/operations',
  PORTAUTHORITY: '/port-authority-management/port-authority'
};

export const RESPONSE_CODE = {
  OK: 200
};

export const STREAM_EVENT = {
  DASHBOARD: 'dashboard',
  ANALYTICS: 'analytics',
  PORT_MAP: 'portMap',
  OVERVIEW: 'overview',
  WEATHER: 'portMap',
  EQUIPMENT_LIST: 'equipmentList',
  KPI: 'kpi',
  LOGS: 'utilities',
  EQUIPMENT: {
    PERFORMANCE: 'performance',
    MAINTENANCE: 'maintenance'
  },
  YARD_OPERATIONS: 'yard'
};
export const YARD_OPS = {
  PATH: '/assets/sampleData/yard-operations-test.json',
  PATHS: [
    "/yard/outboundTruck",
    "/yard/hazardousMaterials",
    "/yard/chassisList",
    "/yard/containerGateMoves",
    "/yard/containerTypeSize",
    "/yard/accessoriesType",
    "/yard/empties",
    "/yard/avgTurnTime"
  ],
}
export const YARD_OPERATIONS = {
  HAZARDOUS_MATERIAL: {
    COLORS: ['#0132d5','#839fff', '#b2c2fd', '#5d41e3', '#917ef2', '#cfc6ff'],
    DATASET_MAIN: {
      'categ1': [],
      'categ2': [],
      'categ3': [],
      'categ4': [],
      'categ5': [],
      'categ6': [],
      'categ7': [],
      'categ8': [],
      'categ9': []
    },
    DATASET_SUB_DIVISION: {
      'sub1': [],
      'sub2': [],
      'sub3': [],
      'sub4': [],
      'sub5': [],
      'sub6': []
    },
    LABELS: ['Sub .1', 'Sub .2', 'Sub .3', 'Sub .4', 'Sub .5', 'Sub .6']
  },
  CONTAINER_DWELL_TIME: {
    COLORS: ['#2B6FB8', '#FFBD04'],
    DATASET_SET: {
      'import': [],
      'export': []
    },
    LABELS: ['Import', 'Export']
  },
  EMPTIES_IN_YARD: {
    COLORS: ['#0132d5','#839fff', '#b2c2fd', '#5d41e3', '#917ef2'],
    DATASET_SET: {
      'container1': [],
      'container2': [],
      'container3': [],
      'container4': [],
      'container5': [],
    },
  },
}
export const CHART = {
  STACK_O: 'Stack 0',
  ROTATION_0: 0,
  ROTATION_90: 90,
  SCALE_0: 0,
  SCALE_100: 100,
  TOOLTIP_MODE_NEAREST: 'nearest',
  COLOR_NAME: {
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
  },
  COLOR: {
    GREEN: '#27ae60',
    YELLOW: '#edb500',
    RED: '#e74c3c',
    WHITE: '#D3D3D3',
    GRAY: '#A9A9A9',
    BLUE: '#0077F7',
    LIGHT_BLUE_1: '#E2F5FF',
    LIGHT_BLUE_2: '#D3F1FF',
    ANALYTICS: {
      green: '#089700',
      orange: '#FFBD04',
      blue: '#2B6FB8',
      violet: '#8e44ad',
      torquoise: '#1abc9c',
    },
    YARD_OPERATION: {
      dark_blue: '#0a36cd'
    },
    FAULT_MESSAGES: {
      lan_blue: '#1e3799',
      azraq_blue: '#4a69bd',
      livid: '#6a89cc'
    }
  },
  YARD_INVENTORY_CONTAINER: {
    COLORS: ['#ed0010', '#edb500', '#0032d6'],
    DATASET: {
      "empty": [],
      "full": [],
      "other": []
    },
    LABELS: ['Empty', 'Full', 'Other']
  },
  TYPE: {
    BAR: 'bar',
    PIE: 'pie',
    LINE: 'line'
  },
  CHART_TYPE: {
    bar: 'bar',
    doughnut: 'doughnut',
    line: 'line',
    pie: 'pie',
    horizontalBar: 'horizontalBar',
    scatter: 'scatter',
  },
}
export const STREAM_TAG = {
  BERTH_STATUS: 'berthStatus',
  CRANE_STATUS: 'craneStatus',
  TUGBOAT_STATUS: 'tugboatStatus',
  OPERATING_STATUS: 'operatingStatus',
  EQUIPMENT_STATUS: 'equipmentStatus',
  EQUIPMENT_LIST: 'equipmentList',
  EQUIPMENT_DETAILS: 'equipmentDetails',
  EQUIPMENT_PERFORMANCE_CRANE_MOVEMENT: 'craneMovementSteps',
  EQUIPMENT_PERFORMANCE_FUEL_EFFICIENCY: 'fuelEfficiency',
  EQUIPMENT_PERFORMANCE_CRANE_PRODUCTIVITY: 'craneProductivity',
  EQUIPMENT_PERFORMANCE_DAILY_UTILIZATION: 'dailyUtilizationHistory',
  EQUIPMENT_PERFORMANCE_IOT_READINGS: 'iotReading',
  EQUIPMENT_MAINTENANCE_SUMMARY: 'maintenanceSummary',
  EQUIPMENT_MAINTENANCE_HISTORICAL_MAINTENANCE: 'historicalMaintenanceData',
  EQUIPMENT_MAINTENANCE_DUE_FOR_CHECKUP: 'componentsInDueForCheckup',
  ALERTS: 'alerts',
  WEATHER: 'weather',
  LOGS: 'logs',
  CRANE_KPI: 'craneKpi',
  TUGBOAT_KPI: 'tugboatKpi',
  PORT_PRODUCTIVITY: 'portProductivity',
  SENSOR_EVALUATION: 'iotSensorEvaluation',
  GATE_MOVES: 'container-gate-moves',
  TURN_TIME: 'average-turn-time',
  YARD_INVENTORY_CONTAINERS: 'yard-inventory-containers',
  OUTBOUND_TRUCKS: 'outbound-trucks',
  HAZARDOUS_MATERIAL: 'hazardous-material',
  YARD_INVENTORY_CHASSIS: 'yard-inventory-chassis',
  YARD_INVENTORY_ACCESSORIES: 'yard-inventory-accessories',
  EMPTIES_IN_YARD: 'empties-in-yard',
  CONTAINER_DWELL_TIME: 'average-dwell-time'
};

export const MESSAGE_DISPLAY = {
  NO_OPERATOR: 'No operator in charge at the moment.',
  NO_PILOT: 'No pilot in charge at the moment.',
  NO_OPERATOR_OR_PILOT: 'No operator nor pilot in charge.',
  N_A: 'n/a',
  LAST_UPDATED: 'Last updated time: ',
  NO_WORK_ORDER_CRANE: 'No assigned job order to this Crane at the moment.',
  NO_WORK_ORDER_RTG: 'No assigned job order to this RTG at the moment.',
  NO_WORK_ORDER_TUGBOAT: 'No assigned job order to this Tugboat at the moment.',
  NO_ALERTS: 'There are no alerts logged at the moment.',
  NO_DATA_FOR_THE_SELECTED_FILTER: 'No data to be display for the selected filter. Kindly select another filter.',
  SUCCESSFULLY_RAISED: 'Successfully Raised',
  SESSION_EXPIRED: 'Your Session has expired. Kindly try to login again.',
  NO_DATA_TO_DISPLAY: 'There is no data to display.'
};
export const OUTBOUND_TRUCKS = {
  PAGINATOR: {
    PAGE_SIZE: '5',
    PAGE_INDEX: '0',
    PAGE_SIZE_OPTIONS: ['5', '10', '20', '30', '40', '50']
  }
}
export const APIs = {
  DASHBOARD: '/dashboard',
  ANALYTICS: '/analytics/iotSensorEvaluation',
  PORT_MAP: '/portMap',
  WEATHER: '/weather',
  OVERVIEW: '/overview',
  EQUIPMENT_LIST: '/equipment/list',
  KPI: '/kpi',
  LOGS_AND_ALERTS: '/utilities',
  YARD_OPERATIONS: {
    GATE_MOVES: '/yard/containerGateMoves',
    TURN_TIME: '/yard/avgTurnTime',
    YARD_INVENTORY_CONTAINERS: '/yard/containerTypeSize',
    OUTBOUND_TRUCKS: '/yard/outboundTruck',
    HAZARDOUS_MATERIAL: '/yard/hazardousMaterials',
    YARD_INVENTORY_CHASSIS: '/yard/chassisList',
    YARD_INVENTORY_ACCESSORIES: '/yard/accessoriesType',
    EMPTIES_IN_YARD: '/yard/empties',
    CONTAINER_DWELL_TIME: '/yard/avgDwellTime',
  },
  EQUIPMENT: {
    PERFORMANCE: '/equipment/performance',
    MAINTENANCE: '/equipment/maintenance'
  },
  EquipmentList: {
    PATH: '/equipment/list',
    EVENT: 'equipmentList'
  },
  Logs: {
    PATH: '/utilities/logs',
    EVENT: 'logs'
  },
  Alerts: {
    PATH: '/utilities/alerts',
    EVENT: 'alerts'
  },
};

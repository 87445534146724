import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { IMG } from 'src/app/shared/constants/assets.constant';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  private selectedPage: string;
  sustainabilityIconSrc: string = IMG.SUSTAINABILITY_BLACK;
  digTwinIconSrc: string = IMG.DIGITAL_TWIN_BLACK;
  operationsIconSrc: string = IMG.OPERATIONS_BLACK;
  portAuthIconSrc: string = IMG.PORT_AUTHORITY_BLACK;
  settingsIconSrc: string = IMG.SETTINGS_BLACK;
  yardMngmtIconSrc: string = IMG.YARD_MNGMT_BLACK;
  energymgtIconSrc: string = IMG.ENERGY_MGT_BLACK;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.selectedPage = this.activatedRoute.firstChild.snapshot.routeConfig.path;
      this.setIcons(this.selectedPage);
    });
  }

  isPageSelected(pageTitle: string): boolean {
    return pageTitle === this.selectedPage;
  }

  setIcons(pageTitle: string) {
    this.operationsIconSrc = pageTitle == 'operations-dashboard' ? IMG.OPERATIONS_WHITE : IMG.OPERATIONS_BLACK;
    this.sustainabilityIconSrc = pageTitle == 'sustainability-dashboard' ? IMG.SUSTAINABILITY_WHITE : IMG.SUSTAINABILITY_BLACK;
    this.yardMngmtIconSrc = pageTitle == 'yard-management' ? IMG.YARD_MNGMT_BLACK : IMG.YARD_MNGMT_BLACK;
    this.portAuthIconSrc = pageTitle == 'port-authority' ? IMG.PORT_AUTHORITY_BLACK : IMG.PORT_AUTHORITY_BLACK;
    this.digTwinIconSrc = pageTitle == 'digital-twin' ? IMG.DIGITAL_TWIN_BLACK : IMG.DIGITAL_TWIN_BLACK;
    this.settingsIconSrc = pageTitle == 'settings' ? IMG.SETTINGS_BLACK : IMG.SETTINGS_BLACK;
    this.energymgtIconSrc = pageTitle == 'energy-dashboard' ? IMG.ENERGY_MGT_BLACK : IMG.ENERGY_MGT_BLACK;
  }
}






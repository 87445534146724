import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss']
})
export class CostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}

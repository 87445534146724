import { createAction, props } from '@ngrx/store';
import { ErrorResponse } from '../../models/error-response.model';
import { WaterConservationModel } from '../../models/water-conservation.model';
import { WaterQualityProfileModel } from '../../models/water-quality-profile.model';
import { WaterQualityIndexComplianceModel } from '../../models/water-quality-index-compliance.model';
import { WaterUsageShareModel } from '../../models/water-usage-share.model';
import { WaterFootprintUsageEfficiencyModel } from '../../models/water-footprint-usage-efficiency.mode';
import { WaterSourceDiversityModel } from '../../models/water-source-diversity.model';
import { WaterRecyclingRateTreatEffModel } from '../../models/water-recycling-rate-treat-efficiency.model';

export const getWaterConservation = createAction(
    '[Sustainability Dashboard Page] Get Water Conservation',
    props<{ portsId?: string }>()
);

export const getWaterConservationSuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Conservation Success',
    props<{ waterConservation: WaterConservationModel }>()
);

export const getWaterConservationFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Conservation Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterQualityProfile = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Profile',
    props<{ year?: string }>()
);

export const getWaterQualityProfileSuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Profile Success',
    props<{ waterQualityProfile: WaterQualityProfileModel }>()
);

export const getWaterQualityProfileFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Profile Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterQualityIndexCompliance = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Index Compliance',
    props<{ year?: string }>()
);

export const getWaterQualityIndexComplianceSuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Index Compliance Success',
    props<{ waterQualityIndexCompliance: WaterQualityIndexComplianceModel }>()
);

export const getWaterQualityIndexComplianceFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Quality Index Compliance Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterUsageShare = createAction(
    '[Sustainability Dashboard Page] Get Water Usage Share',
    props<{ year?: string }>()
);

export const getWaterUsageShareSuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Usage Share Success',
    props<{ waterUsageShare: WaterUsageShareModel }>()
);

export const getWaterUsageShareFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Usage Share Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterFootprintUsageEfficiency = createAction(
    '[Sustainability Dashboard Page] Get Water Footprint Usage Efficiency',
    props<{ year?: string }>()
);

export const getWaterFootprintUsageEfficiencySuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Footprint Usage Efficiency Success',
    props<{ waterFootprintUsageEfficiency: WaterFootprintUsageEfficiencyModel }>()
);

export const getWaterFootprintUsageEfficiencyFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Footprint Usage Efficiency Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterSourceDiversity = createAction(
    '[Sustainability Dashboard Page] Get Water Source Diversity',
    props<{ year?: string }>()
);

export const getWaterSourceDiversitySuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Source Diversity Success',
    props<{ waterSourceDiversity: WaterSourceDiversityModel }>()
);

export const getWaterSourceDiversityFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Source Diversity Failure',
    props<{ error: ErrorResponse }>()
);

export const getWaterRecyclingRateTreatEff = createAction(
    '[Sustainability Dashboard Page] Get Water Recycling Rate and Treatment Efficiency',
    props<{ year?: string }>()
);

export const getWaterRecyclingRateTreatEffSuccess = createAction(
    '[Sustainability Dashboard Page] Get Water Recycling Rate and Treatment Efficiency Success',
    props<{ waterRecyclingRateTreatEff: WaterRecyclingRateTreatEffModel }>()
);

export const getWaterRecyclingRateTreatEffFailure = createAction(
    '[Sustainability Dashboard Page] Get Water Recycling Rate and Treatment Efficiency Failure',
    props<{ error: ErrorResponse }>()
);
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { EnergyManagementState } from "./energy-management.reducer";

export const selectEnergyManagementState = (state: AppState) => state.energy;

export const selectEnergyManagement = createSelector(
  selectEnergyManagementState,
  (state: EnergyManagementState) => state.energy
);

export const selectEnergyMngmtStatus = createSelector(
  selectEnergyManagementState,
  (state: EnergyManagementState) => state.status
);

import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appAgmOverlay]'
})
export class AgmOverlayDirective {
  private zIndex = 100;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseover') onHover() {
    this.renderer.addClass(this.elementRef.nativeElement.parentNode.querySelector('.marker-details'), 'hover');
    this.renderer.setStyle(this.elementRef.nativeElement.parentNode, 'z-index', this.zIndex);
  }

  @HostListener('mouseout') onMouseOut() {
    this.renderer.removeClass(this.elementRef.nativeElement.parentNode.querySelector('.marker-details'), 'hover');
    this.renderer.removeStyle(this.elementRef.nativeElement.parentNode, 'z-index');
  }
}

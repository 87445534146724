import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CarbonService } from 'src/app/shared/common-function/carbon.service';
import { CHART } from 'src/app/shared/constants/chart.constant';


@Component({
  selector: 'app-carbon-footprint',
  templateUrl: './carbon-footprint.component.html',
  styleUrls: ['./carbon-footprint.component.scss']
})
export class CarbonFootprintComponent implements OnInit {
  totalFootprint: number;
  reportingYear: number;
  totalConsumption: number;
  totalFootprintScope1: number;
  totalFootprintScope2: number;
  totalFootprintPercentageScope1: number;
  totalFootprintPercentageScope2: number;
  totalConsumptionDividedBy22: number;
  selectedEmissionTypes: string[] = [];
  selectedEmissionFactorPrice: number;
  carbonData: any;

  constructor(
    private carbonService: CarbonService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.totalFootprint = this.carbonService.getTotalFootprint();
    this.reportingYear = this.carbonService.getReportingYear();
    this.totalConsumption = this.carbonService.getTotalConsumption();
    this.totalFootprintScope1 = this.carbonService.getTotalFootprintScope1();
    this.totalFootprintScope2 = this.carbonService.getTotalFootprintScope2();
    this.totalFootprintPercentageScope1 = this.carbonService.getTotalFootprintPercentageScope1();
    this.totalFootprintPercentageScope2 = this.carbonService.getTotalFootprintPercentageScope2();
    this.totalConsumptionDividedBy22 = this.carbonService.getTotalConsumptionDividedBy22();
    this.selectedEmissionTypes = this.carbonService.getSelectedEmissionTypes();
    this.selectedEmissionFactorPrice = this.carbonService.getSelectedEmissionFactorPrice();
    this.cdr.detectChanges();
    this.createPieChart();
  }

  createPieChart() {
    const canvas = document.getElementById('emissionsChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "emissionsChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');

    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Scope 2', 'Scope 1'],
        datasets: [{
          data: [
            parseFloat(this.totalFootprintPercentageScope2.toFixed(2)),
            parseFloat(this.totalFootprintPercentageScope1.toFixed(2))
          ],
          backgroundColor: [CHART.COLOR.EMISSIONS.GREEN, CHART.COLOR.EMISSIONS.DARK_BLUE],
        }]
      },
      options: {
        responsive: true,
        tooltips: {
          enabled: true,
          titleFontSize: 14,
          bodyFontSize: 14,
          caretPadding: 20,
          yPadding: 10
        },
        legend: {
          display: false,
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            color: CHART.COLOR.EMISSIONS.WHITE,
            align: 'center',
            textAlign: 'center',
            formatter: (value, ctx) => {
              if (value < 11) {
                return null;
              }
              const label = ctx.chart.data.labels[ctx.dataIndex];
              const data = ctx.chart.data.datasets[0].data[ctx.dataIndex];
              return `${label}\n${data}%`;
            },
            font: {
              size: 14,
              weight: 'bold',
              family: 'Montserrat'
            },
            textShadowColor: CHART.COLOR.EMISSIONS.BLACK,
            textShadowBlur: 10,
          }
        }
      },
      plugins: [ChartDataLabels]
    });
  }
}

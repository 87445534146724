import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PortModel } from 'src/app/shared/models';
import { getPort } from 'src/app/shared/state/port/port.actions';
import { selectPorts } from 'src/app/shared/state/port/port.selectors';

@Component({
  selector: 'app-port-authority-layout',
  templateUrl: './port-authority-layout.component.html',
  styleUrls: ['./port-authority-layout.component.scss']
})
export class PortAuthorityLayoutComponent implements OnInit {
  ports$: Observable<PortModel[]> = this.store.select(selectPorts);
  selectedPortId: string;


  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getPort());
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ports$.subscribe((portsList) => {
        if (portsList?.length > 1) {
          this.initializeSelectedPorts(portsList);
        }
      });
    }, 0);
  }


  initializeSelectedPorts(portsList: PortModel[]) {
    this.selectedPortId = portsList.find((port) => port.port_code == 'P1').port_id;

  }



}

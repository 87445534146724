import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import moment, { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { CHART } from 'src/app/shared/constants/chart.constant';
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { MoveTimeData, MovementTimeOverviewModel } from 'src/app/shared/models/movement-time-overview.model';
import { selectMovementTimeOverview } from 'src/app/shared/state/operations/operations.selectors';
import { getMovementTimeOverview } from 'src/app/shared/state/operations/operations.action';


@Component({
  selector: 'app-movement-time-overview',
  templateUrl: './movement-time-overview.component.html',
  styleUrls: ['./movement-time-overview.component.scss']
})
export class MovementTimeOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild(BaseChartDirective, { static: false })
  public chart: BaseChartDirective;

  @Input()
  portId: string;

  @Input()
  equipmentId: string;

  movementOverview$: Observable<MovementTimeOverviewModel> = this.store.select(selectMovementTimeOverview);
  public isZoom: boolean = false;
  public ChartData: any[];
  public ChartLabels: string[] = [];
  public ChartOptions: any;
  public ChartColors: any[];
  public ChartLegend: boolean;
  public ChartType: string;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = true;
  public chartIsReady = false;
  public initialFilter = 0;
  public currentDate = new Date();
  public initialDateFilter = new Date();
  public rangeTo: string = null;
  public rangeFrom: string = null;
  public minDate = new Date();
  public dateRangeError: boolean;
  public scatterData: scatterData[] = [];
  public dateAcquired: string[] = [];
  public initialDF = '2022-01-01'; 
  public initialDT = '2022-01-07'; 
  public totalTimeArr: number[] = [];
  public totalTimeAve: scatterData[] = [];
  public totalDataCount: number[] = [];
  public topTrendsChart: scatterData[] = [];
  public topChecked: boolean = true;
  public aveChecked: boolean = false;
  public movementime: MovementTimeOverviewModel[];
  public dateAcq: any;
  public movetimeData: any;
  public timeStamp: any;
  public daily: any;
  public moveDuration: any;

  constructor(
    private datePipe: DatePipe,
    private store: Store
  ) {
    datePipe = new DatePipe('en-US');
  }


  ngOnInit() {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    // this.rangeFrom = this.datePipe.transform(this.initialDateFilter.setDate(this.initialDateFilter.getDate() - 7), 'yyyy-MM-dd');
    // this.rangeTo = this.datePipe.transform(this.currentDate.setDate(this.currentDate.getDate() - 1), 'yyyy-MM-dd');
    this.rangeFrom = this.initialDF;
    this.rangeTo = this.initialDT;
    this.store.dispatch(getMovementTimeOverview({ portId: this.portId, equipmentId: this.equipmentId, startDate: this.rangeFrom, endDate: this.rangeTo }));
  }

  ngAfterViewInit(): void {
    this.movementOverview$.subscribe((movementOverview) => {
      if (movementOverview) {
        this.getCraneDailyCraneMovementDuration(movementOverview);
        this.processCraneMovements(movementOverview);
      }
    });
  }

  processCraneMovements(data: MovementTimeOverviewModel): void {
    data.move_duration.forEach(duration => {
      `${duration.date_acquired}`;
      console.log(duration.date_acquired)
      duration.move_time_data.forEach(timeData => {
        ` ${timeData.timestamp},${timeData.daily_move_duration}`;
      });
    });
  }

  getCraneDailyCraneMovementDuration(data: MovementTimeOverviewModel) {
    let totalTime: number;

    if (!data || data === undefined || data === null) {
      this.noData = true;
    } else {
      if (data.equipment_name !== null) {
        data.move_duration.forEach((item) => {
          this.dateAcquired.push(moment.utc(item.date_acquired).format('YYYY-MM-DD 00:00:00'));
          totalTime = 0;
          this.totalDataCount.push(item.move_time_data.length);

          //getting top trends
          let val = (item.move_time_data.sort(this.compareMovementTime).slice(0, 1));
          if (val.length == 0) {

            this.topTrendsChart.push({
              x: moment.utc(this.rangeFrom).format('YYYY-MM-DD 00:00:00'), y: null
            });
          } else {
            val.forEach(element => {
              this.topTrendsChart.push({
                x: moment.utc(element.timestamp).format('YYYY-MM-DD 00:00:00'), y: element.daily_move_duration
              });
            });
          }

          item.move_time_data.map((crane: MoveTimeData, index) => {
            totalTime += crane.daily_move_duration;

            if (index == 0) {
              let d = new Date(crane.timestamp).toISOString();

              this.scatterData.push({
                x: moment.utc(d).format('YYYY-MM-DD HH:mm:ss'), y: null
              })
            }
            this.scatterData.push({
              x: moment.utc(crane.timestamp).format('YYYY-MM-DD HH:mm:ss'),
              y: crane.daily_move_duration
            })
          })
          this.totalTimeArr.push(totalTime);
        });
      }

      this.dateAcquired.forEach((element, index) => {
        let ave = this.totalTimeArr[index] / this.totalDataCount[index];
        this.totalTimeAve.push({
          x: element, y: ave
        })
      });

      const COLOR = CHART.COLOR.ANALYTICS;
      this.ChartData = [
        {
          type: 'line',
          data: this.topChecked == true ? this.topTrendsChart : [],
          fill: false,
          borderColor: COLOR.TORQUOISE,
          pointRadius: 5,
          pointHoverRadius: 8,
          tension: 0
        }, {
          type: 'line',
          data: this.aveChecked == true ? this.totalTimeAve : [],
          fill: false,
          borderColor: CHART.COLOR.YELLOW,
          pointRadius: 5,
          pointHoverRadius: 8,
          tension: 0
        }, {
          data: this.scatterData,
          fill: false,
          borderColor: COLOR.VIOLET,
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ];
      this.ChartLabels;
      this.initializeChart();
      this.noData = false;
      this.chartIsReady = true;
    }
  }


  onResetChart() {
    // @ts-ignore
    this.chart.chart.resetZoom();
  }

  onZoom() {
    if (!this.isZoom) {
      this.isZoom = true;

      // @ts-ignore
      this.chart.chart.options.zoom.enabled = true;
      // @ts-ignore
      this.chart.chart.options.zoom.drag.enabled = true;
    } else {
      this.isZoom = false;

      // @ts-ignore
      this.chart.chart.options.zoom.enabled = false;
      // @ts-ignore
      this.chart.chart.options.zoom.drag.enabled = false
      // @ts-ignore
      this.chart.chart.resetZoom();
    }

    //console.log(this.chart);
    this.chart.chart.update();
  }


  initializeChart() {
    const COLOR = CHART.COLOR.ANALYTICS;
    this.ChartOptions = {
      responsive: true,
      bezierCurve: false,
      maintainAspectRatio: false,
      tooltips: {
        titleFontFamily: CHART.FONTS.GOTHAM,
        bodyFontFamily: CHART.FONTS.GOTHAM,
        callbacks: {

          label: function (context) {
            return "Time: " + moment.utc(context.xLabel).format("hh:mm a");
          },
          beforeLabel: function (context) {
            return "Date: " + moment.utc(context.xLabel).format("MMM DD YYYY");
          },
          afterLabel: function (context) {
            return `Value: ${context.value}`;
          }
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            callback: (value) => {
              return new Date(value).toLocaleDateString("en-US", { month: "short", day: 'numeric' });
            }
          },
          plugins: {
            labels: {
              fontSize: 0,
            },
          },
          offset: true,
          gridLines: { display: true },
          min: '2020-09-02 00:00:00',
          max: '2020-09-07 24:00:00',
          type: 'time',
          time: {
            unit: 'day',
          },
          position: 'bottom',
          beginAtZero: true,
        }],
        yAxes: [{
          beginAtZero: true,
        }]
      },
      zoom: {
        mode: 'xy',
        drag: {
          enabled: this.isZoom,
        },

      }
    };
    this.ChartColors = [
      { backgroundColor: COLOR.TORQUOISE },
      { backgroundColor: CHART.COLOR.YELLOW },
      { backgroundColor: COLOR.VIOLET },
    ];
    this.ChartLegend = false;
    this.ChartType = CHART.TYPE.SCATTER;
  }

  compareMovementTime(a, b) {
    if (a.dailyMoveDuration < b.dailyMoveDuration) {
      return 1;
    }
    if (a.dailyMoveDuration > b.dailyMoveDuration) {
      return -1;
    }
    return 0;
  }

  onCheckBoxChanged(event: any) {
    if (event.target.id == "ave") {
      if (!event.target.checked) {
        this.aveChecked = false;
        this.ChartData[1].data = [];
      } else {
        this.aveChecked = true;
        this.ChartData[1].data = this.totalTimeAve;

      }
    }
    if (event.target.id == "top") {
      if (!event.target.checked) {
        this.topChecked = false;
        this.ChartData[0].data = [];
      } else {
        this.topChecked = true;
        this.ChartData[0].data = this.topTrendsChart;

      }
    }
  }

  onFilterSelected() {
    this.scatterData = [];
    this.dateAcquired = [];
    this.chartIsReady = false;
    this.ChartData = [''];
    this.ChartLabels = [];
    this.totalTimeArr = [];
    this.totalTimeAve = [];
    this.totalDataCount = [];
    this.topTrendsChart = [];

    if (this.rangeFrom !== null && this.rangeTo !== null) {
      this.store.dispatch(getMovementTimeOverview({ portId: this.portId, equipmentId: this.equipmentId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    } else {
      this.rangeFrom = this.datePipe.transform(this.initialDateFilter.setDate(this.initialDateFilter.getDate() - 7), 'yyyy-MM-dd');
      this.rangeTo = this.datePipe.transform(this.currentDate.setDate(this.currentDate.getDate() - 1), 'yyyy-MM-dd');


      this.store.dispatch(getMovementTimeOverview({ portId: this.portId, equipmentId: this.equipmentId, startDate: this.rangeFrom, endDate: this.rangeTo }));
    }
  }

  //Added to
  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let selectedDate = event.value.toLocaleDateString();
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

    if (type === "DateFrom") {
      this.rangeFrom = selectedDate
    } else {
      this.rangeTo = selectedDate
    }
    if (this.rangeFrom >= this.rangeTo) {
      this.dateRangeError = true;
    } else {
      this.dateRangeError = false;
      console.log(this.aveChecked + " " + this.topChecked);
      this.onFilterSelected();
    }
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }

}

interface scatterData {
  x: any;
  y: number | null
}

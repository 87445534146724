import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ICON } from 'src/app/shared/constants/assets.constant';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Store } from '@ngrx/store';
import { selectWaterRecyclingRateTreatEff, selectWaterSourceDiversity } from 'src/app/shared/state/water-conservation/water-conservation.selectors';
import { getWaterRecyclingRateTreatEff, getWaterSourceDiversity } from 'src/app/shared/state/water-conservation/water-conservation.actions';
import { Observable } from 'rxjs';
import { WaterRecyclingRateTreatEffModel, WaterSourceDiversityModel } from 'src/app/shared/models';
import { CHART } from 'src/app/shared/constants/chart.constant';

@Component({
  selector: 'app-water-diversity',
  templateUrl: './water-diversity.component.html',
  styleUrls: ['./water-diversity.component.scss']
})
export class WaterDiversityComponent implements OnInit {
  @Input() selectedYear: string;

  waterSourceDiversity$: Observable<WaterSourceDiversityModel> = this.store.select(selectWaterSourceDiversity);
  waterRecyclingTreatmentEff$: Observable<WaterRecyclingRateTreatEffModel> = this.store.select(selectWaterRecyclingRateTreatEff);
  private waterSourceDoughnutChart: Chart;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(getWaterSourceDiversity({}));
    this.store.dispatch(getWaterRecyclingRateTreatEff({}));
  }

  ngAfterViewInit(): void {
    this.waterSourceDiversity$.subscribe((water_source_diversity) => {
      if (water_source_diversity) {
        this.createDonutChart(water_source_diversity);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedYear && !changes.selectedYear.firstChange;

    if (isValidChange) {
      if (this.selectedYear !== "Select Year") {
        this.store.dispatch(getWaterSourceDiversity({ year: this.selectedYear }));
        this.store.dispatch(getWaterRecyclingRateTreatEff({ year: this.selectedYear }));
      } else {
        this.store.dispatch(getWaterSourceDiversity({}));
        this.store.dispatch(getWaterRecyclingRateTreatEff({}));
      }
    }
  }

  createDonutChart(data: WaterSourceDiversityModel) {
    if (this.waterSourceDoughnutChart) {
      this.waterSourceDoughnutChart.destroy();
    }

    const canvas = document.getElementById('waterSourceChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas element with id "waterSourceChart" not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    const CODES = CHART.CODES.WATER_DIVERSITY;
    const COLORS = CHART.COLOR.WATER_DIVERSITY;
    const colorMapping = {
      [CODES.RAINWATER]: COLORS.BLUE,
      [CODES.SURFACE_WATER]: COLORS.BLUE_1,
      [CODES.SEAWATER]: COLORS.BLUE_2,
      [CODES.GROUNDWATER]: COLORS.BLUE_3,
      [CODES.DESALINATED_WATER]: COLORS.BLUE_4
    };
    const legendOrder = Object.keys(colorMapping);
    const sortedWaterSourceDiversity = data.water_source_diversity.slice().sort((a, b) => legendOrder?.indexOf(a.code) - legendOrder?.indexOf(b.code));
    const waterDiversityCodes = sortedWaterSourceDiversity.map((source) => source.code);
    const waterDiversityLabel = sortedWaterSourceDiversity.map((source: any, index: number) => `${source.parameter}`);
    const waterDiversityData = sortedWaterSourceDiversity.map((source: any) => Math.round(source.percentage_share));
    const backgroundColors = waterDiversityCodes.map(code => colorMapping[code] || COLORS.BLACK);

    this.waterSourceDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: waterDiversityLabel,
        datasets: [{
          data: waterDiversityData,
          backgroundColor: backgroundColors
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          titleFontSize: 15,
          bodyFontSize: 15,
          caretPadding: 20,
          yPadding: 10
        },
        title: {
          display: false,
          text: 'Water Source Mix Diversity',
          position: 'top',
          fontSize: 12,
          fontColor: COLORS.BLACK_1,
          padding: 5,
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            fontColor: COLORS.BLACK_1,
            fontSize: 12,
            boxWidth: 10,
            fontFamily: 'Montserrat',
            padding: 18,
            filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0,
          }
        },
        plugins: {
          labels: {
            render: () => { },
          },
          datalabels: {
            display: true,
            color: COLORS.WHITE,
            textAlign: 'center',
            font: {
              lineHeight: 1.6,
              size: 14,
            },
            formatter: function (value, context) {
              if (value > 0) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(',');
                return value + '%';
              } else {
                value = "";
                return value;
              }
            }
          }
        }
      },
      plugins: [ChartDataLabels]
    });
  }

  getAbsoluteValue(percent: number): number {
    return Number(Math.abs(percent).toFixed(2));
  }

  arrowIcon(percent: number): string {
    return percent < 0 ? ICON.ARROW_RED_DOWN : ICON.ARROW_GREEN_UP;
  }

  numColor(percent: number): string {
    return percent < 0 ? 'percent-red' : 'percent-green';
  }
}

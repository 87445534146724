import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { THRESHOLD } from 'src/app/shared/constants/threshold.constant';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';

//to be removed
import { feConfig } from 'src/app/shared/mock-data/fe-config.json';
import { CraneAssignedModel } from 'src/app/shared/models';


@Component({
  selector: 'app-berth-cranes-gmph',
  templateUrl: './berth-cranes-gmph.component.html',
  styleUrls: ['./berth-cranes-gmph.component.scss'],
})
export class BerthCranesGmphComponent implements OnInit, OnChanges {
  @Input() public craneAssigned: any;
  private newCraneAssigned = [];
  public chartIsReady = false;
  private thresholdStatus = { ...THRESHOLD.STATUS };


  constructor(private roundPipe: RoundPipe) { }


  ngOnInit() { }


  ngOnChanges() {
    this.getCranesAssigned();
    this.chartIsReady = true;

  }

  getCranesAssigned() {
    if (this.craneAssigned !== null && this.craneAssigned !== undefined) {
      if (this.craneAssigned.length !== 0) {
        this.craneAssigned.map((crane: CraneAssignedModel) => {
          if (crane.equipment_name !== undefined && crane.equipment_name !== null) {
            const craneTarget = this.getPphTarget(crane.equipment_name);
            crane.crane_pph = this.roundPipe.transform(crane.crane_pph);
            let craneFill = this.roundPipe.transform((crane.crane_pph / craneTarget) * 100);
            craneFill = (craneFill > 100 ? 100 : craneFill);
            const craneBuffer = 100 - craneFill;
            const thresholdStatus = this.pphThresholdChecker(crane.equipment_name, crane.crane_pph);
            const newCrane = {
              ...crane,
              craneFill,
              craneBuffer,
              craneTarget,
              thresholdStatus
            };
            this.newCraneAssigned.push(newCrane);
            return newCrane;
          } else {
            return crane;
          }
        });
      } else {
        return this.craneAssigned;
      }
    } else {
      return this.craneAssigned;
    }
  }

  getPphTarget(craneId: string): number {
    if (craneId !== null || craneId !== undefined) {
      const envHighhreshold = feConfig[craneId + 'BCHActualHighThreshold'];
      if (envHighhreshold !== undefined) {
        return Number(envHighhreshold);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  pphThresholdChecker(craneId: string, aggValue: number) {
    let thresholdStatus: string;
    const envLowThreshold = feConfig[craneId + 'BCHActualLowThreshold'];
    const envHighhreshold = feConfig[craneId + 'BCHActualHighThreshold'];

    if (envLowThreshold !== undefined && envHighhreshold !== undefined) {
      if (aggValue < envLowThreshold) {
        thresholdStatus = this.thresholdStatus.LOW;
      } else if (aggValue >= envLowThreshold && aggValue <= envHighhreshold) {
        thresholdStatus = this.thresholdStatus.MID;
      } else if (aggValue > envHighhreshold) {
        thresholdStatus = this.thresholdStatus.HIGH;
      }
    } else {
      thresholdStatus = this.thresholdStatus.LOW;
    }
    return thresholdStatus;
  }

}

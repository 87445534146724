import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseChartDirective } from 'ng2-charts';
import { RoundPipe } from 'src/app/shared/custom-pipes/round.pipe';
import { MESSAGE_DISPLAY } from 'src/app/shared/constants/api.constant';
import { TopVesselDelay } from 'src/app/shared/models/top-vessel-delay';
import { CHART, } from 'src/app/shared/constants/chart.constant';
import { Store } from '@ngrx/store';
import { selectTopVesselDelay } from 'src/app/shared/state/operations/operations.selectors';
import { getTopVesselDelay } from 'src/app/shared/state/operations/operations.action';

@Component({
  selector: 'app-vessel-delay',
  templateUrl: './vessel-delay.component.html',
  styleUrls: ['./vessel-delay.component.scss']
})
export class VesselDelayComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: false })
  chart: BaseChartDirective;

  @Input()
  selectedPortId: string;

  topvesselDelay$: Observable<TopVesselDelay[]> = this.store.select(selectTopVesselDelay);
  public hrBarChartType = CHART.TYPE.HORIZONTAL_BAR;
  public selectedFilterDays = CHART.FILTERS.LAST_SEVEN_DAYS;
  private filterDays = { ...CHART.FILTERS };
  public filterDaysArr = [];

  public hrBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    tooltips: { titleFontFamily: 'Gotham', bodyFontFamily: 'Gotham' },
    scales: {
      xAxes: [{
        autoSkip: false,
        ticks: { min: 0, stepValue: 500, fontFamily: 'Gotham', fontSize: 12 },
        gridLines: { display: false }
      }],
      yAxes: [{
        ticks: { fontFamily: 'Gotham', fontSize: 12 }
      }],
      plugins: {
        labels: {
          fontSize: 0,
        },
      },
    }
  };
  public hrBarChartLabels: string[] = [];
  public hrBarChartData: any[] = [
    {
      data: [],
      label: CHART.CHART_LABELS.HOUR
    }
  ];
  public hrBarChartLegend = false;
  public hrBarChartColors: any[] = [{
    backgroundColor: []
  }];

  public topVesselDelay: TopVesselDelay[] = [];
  public chartReady = true;
  public noDataToDisplay = MESSAGE_DISPLAY.NO_DATA_TO_DISPLAY;
  public noData = false;
  noDataMessage = MESSAGE_DISPLAY.NO_DATA_FOR_THE_SELECTED_FILTER;

  constructor(private roundPipe: RoundPipe, private store: Store) {
  }

  ngOnInit() {
    this.getFilterDays();

    this.store.dispatch(getTopVesselDelay({ portId: this.selectedPortId, filter: this.selectedFilterDays }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.topvesselDelay$.subscribe((data) => {
        if (data?.length > 0) {
          this.initializeTopVesselDelays(data);
          if (this.chart && this.chart.chart) {
            this.chart.update();
          }
        } else {
          this.noData = true;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isValidChange: boolean = changes.selectedPortId && !changes.selectedPortId.firstChange;

    if (this.selectedPortId && isValidChange) {
      if (this.selectedPortId !== "Select Port") {
        this.store.dispatch(getTopVesselDelay({ portId: this.selectedPortId, filter: this.selectedFilterDays }));
      }
    }
  }

  getFilterDays() {
    this.filterDaysArr = Object.keys(this.filterDays).map(key => ({ type: key, value: this.filterDays[key] }));
  }

  initializeTopVesselDelays(data: TopVesselDelay[]) {
    this.emptyChartData();
    this.topVesselDelay = data;
    this.topVesselDelay = this.topVesselDelay.filter(value => value.waiting_time_delay > 0);
    this.topVesselDelay = this.topVesselDelay.sort((a, b) => b.waiting_time_delay - a.waiting_time_delay);
    this.topVesselDelay = this.topVesselDelay.map((vesselDelay: TopVesselDelay) => {
      vesselDelay.waiting_time_delay = this.roundPipe.transform(vesselDelay.waiting_time_delay);
      this.hrBarChartData[0].data.push(vesselDelay.waiting_time_delay);
      this.hrBarChartLabels.push(vesselDelay.voyage_number);
      return vesselDelay;
    });
    this.noData = false;
    this.chartReady = true;
    this.hrBarChartColors = [{ backgroundColor: CHART.COLOR.ANALYTICS.BLUE }];
  }

  updateTopVesselDelays() {
    this.store.dispatch(getTopVesselDelay({ portId: this.selectedPortId, filter: this.selectedFilterDays }));
  }

  emptyChartData() {
    this.hrBarChartData[0].data = [];
    this.hrBarChartLabels = [];
  }

  public chartClicked(event: any): void { }
  public chartHovered(event: any): void { }
}

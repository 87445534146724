import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-services-info',
  templateUrl: './services-info.component.html',
  styleUrls: ['./services-info.component.scss']
})
export class ServicesInfoComponent implements OnInit {


  public activeButton: number = 1;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    
  }

  toggleButton(buttonNumber: number) {
    this.activeButton = buttonNumber;
  }


}

import { Component, OnInit } from '@angular/core';
import * as waterMgnt from '../../../shared/mock-data/water-management.json'



@Component({
  selector: 'app-water-mngt-page',
  templateUrl: './water-mngt-page.component.html',
  styleUrls: ['./water-mngt-page.component.scss']
})
export class WaterMngtPageComponent implements OnInit {
  public dataSourceWaterManagement
  constructor() { }

  ngOnInit(): void {
    this.dataSourceWaterManagement= waterMgnt.waterManagement;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EQUIPMENT } from 'src/app/shared/constants/equipment.constant';
import { EquipmentDetailsModel } from 'src/app/shared/models/equipment-details.model';
import { selectEquipmentSelectedPortId } from 'src/app/shared/state/operations/operations.selectors';

@Component({
  selector: 'app-performance-target',
  templateUrl: './performance-target.component.html',
  styleUrls: ['./performance-target.component.scss']
})
export class PerformanceTargetComponent implements OnInit {

  equipmentSelectedPortId$ = this.store.select(selectEquipmentSelectedPortId);
  equipmentTypes = { ...EQUIPMENT.EQUIPMENT_TYPE };
  portId: string;
  equipmentType: string;
  equipmentId: string;
  equipmentName: string;
  equipmentDetails: EquipmentDetailsModel;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {
    this.activatedRoute.parent.paramMap.subscribe(params => {
      this.equipmentType = params.get('equipmentType');
      this.equipmentId = params.get('equipmentId');
      this.equipmentName = params.get('equipmentName');
    });
  }

  ngOnInit(): void {
    this.equipmentSelectedPortId$.subscribe((portId) => {
      if (portId) {
        this.portId = portId;
      } else {
        this.router.navigate(['/operations-dashboard/page/equipment-list']);
      }
    })
  }
}

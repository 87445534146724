import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Chart from 'chart.js';


@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.scss']
})
export class SustainabilityComponent implements OnInit {



  public sidecontent = [
    { id: 1, title: "Avg Training hrs / month", value: "0.8", },
    { id: 2, title: "Diversity", value: "+5%" },
    { id: 3, title: "Local Employee", value: "+8%" },
    { id: 4, title: "Solid Waste", value: "-24%" },
  ]

  public data = {
    labels: ['pH', 'Salinity', 'Turbidity', 'DO'],
    datasets: [{
      data: [45, 86, 55, 98],
      backgroundColor: [
        '#273c75',
        '#9c88ff',
        '#218c74',
        '#00a8ff',
      ],

      borderWidth: 1
    }]
  };

  public config = {
    type: 'horizontalBar',
    data: this.data,
    options: {

      legend: {
        display: false,

      },
      scales: {
        yAxes: [{
          ticks: {
          },
          scaleLabel: {
            //display: false  // This line will remove the x-axis label
          },
          gridLines: {
            display: false,
          },
          // barPercentage: 1,
        }],
        xAxes: [{
          scaleShowLabels: false,
          ticks: {
          },
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: false,
          },

          barPercentage: 200,
        }]
      }
    }
  };
  constructor(
    public router: Router) { }

  ngOnInit() {
    const waterChart = document.getElementById('waterChart') as HTMLCanvasElement;
    const myChart = new Chart(waterChart, this.config);
    Chart.defaults.global.defaultFontSize = 10;

  }



}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-port-settings',
  templateUrl: './port-settings.component.html',
  styleUrls: ['./port-settings.component.scss']
})
export class PortSettingsComponent implements OnInit {
  public featureList: portFeatures[] =[
    {title:"MMBF VS MTBF", label:"Mean Between Failures"},
    {title:"Alarm Analysis", label:"Alarm analysis chart"},
    {title:"Movements Container", label:"Total Number of movements and containers"},
    {title:"Crane Utilization", label:"Utilization for crane graph"},
    {title:"Port Productivity", label:"Equipment Utilization and Berth Utilization"},
    {title:"Top Vessel Delay", label:"Top 10 Vessel Delay"},
    {title:"Sensor Evaluation", label:"Evaluation of sensors"},
  ];
  constructor() { }

  ngOnInit() {

  }

}

export interface portFeatures{
  title:string;
  label:string;
}